export enum Aggregator {
  CURRENT = 'CURRENT',
  ONE_WEEK_PRIOR = 'ONE_WEEK_PRIOR',
  EXPECTED_BASELINE = 'EXPECTED_BASELINE',
  ACTUAL_BASELINE = 'ACTUAL_BASELINE',
  NINETY_DAYS_WX = 'NINETY_DAYS_W_EXCLUDED',
  BASELINE_UPPER_LIMIT = 'BASELINE_LIMIT',
}

export type HourlyUsage = {
  date: string
  value?: number
}

export interface WaterUsage {
  deviceId: string
  from: string
  to: string
  aggregated: { [aggregator: string]: HourlyUsage[] }
}

export interface deviceWaterUsage {
  device_id: string
  date: string
  flow: number | null
  hour: number
}

export interface deviceWaterUsageUOM {
  uom: string
  usage_data: deviceWaterUsage[]
}

export interface SingleDeviceWaterUsage {
  usage?: deviceWaterUsage[]
  totalUsage: number | null
}

export interface MultipleDevicesWaterUsage extends SingleDeviceWaterUsage {
  totalUsageByDevice: { string: number } | {}
}

export interface WaterUsageStats {
  deviceId: string
  hourlyUsage: HourlyUsage[]
  dailyUsage: number
  avgDaily: number
  weeklyUsage: number
  avgWeekly: number
  monthlyUsage: number
  avgMonthly: number
  yearlyUsage: number
}

export interface WaterUsageCosts extends WaterUsageStats {
  dailyUsageFormatted?: string
  avgDailyFormatted?: string
  weeklyUsageFormatted?: string
  avgWeeklyFormatted?: string
  monthlyUsageFormatted?: string
  avgMonthlyFormatted?: string
  yearlyUsageFormatted?: string
  currency?: string
}

export interface YearlyStatsTotal {
  hot: number | null
  cold: number | null
  total: number | null
}

export type WaterUsageQuery = {
  deviceId: string
  to: string
  from: string
  dateType?: string
  aggregators?: Aggregator[]
  uom?: string
}

export type WaterUsageQueryMultipleDevices = Omit<WaterUsageQuery, 'deviceId'> & {
  deviceIds: string[]
}
