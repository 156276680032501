import React from 'react'
import { LayoutContextProvider } from './layout/context/layout.context'
import { UserContextProvider } from './user/context/user.context'
import { AuthContextProvider } from './auth/context/auth.context'

export const MySmartflowContext = ({ children }: any) => {
  return (
    <LayoutContextProvider>
      <AuthContextProvider>
        <UserContextProvider>
          {children}
        </UserContextProvider>
      </AuthContextProvider>
    </LayoutContextProvider>
  )
}
