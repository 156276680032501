import backend from '@api/backend'
import { displayToast } from '@common/utils/appToast'
import { ActionTypes } from '../context/user.reducer'

export const loadUserAction = async (dispatch: any) => {
  try {
    dispatch({ type: ActionTypes.UPDATING })
    const userData = await (await backend.get(`/users/me/`)).data
    dispatch({ type: ActionTypes.LOAD_USER, payload: { data: userData } })
  } catch (error) {
    console.log(error)
    dispatch({ type: ActionTypes.ERROR })
    displayToast({
      type: 'error',
      message: 'An Error occurred while trying to load user profile.',
      id: 'load-profile-error',
    })
  }
}

export const updateProfileAction = async (
  { firstName, lastName, mobile }: { firstName: string; lastName: string; mobile: string },
  dispatch: any,
) => {
  try {
    dispatch({ type: ActionTypes.UPDATING_PROFILE })
    const userInfo = await backend.patch(`/users/me/update_profile`, {
      first_name: firstName,
      last_name: lastName,
      mobile,
    })
    dispatch({ type: ActionTypes.PROFILE_UPDATED, payload: { data: userInfo.data } })
    displayToast({
      type: 'success',
      message: 'User updated successfully',
    })
  } catch (error: any) {
    console.log(error)
    displayToast({
      type: 'error',
      message: error?.response?.data?.detail || 'Something went wrong',
    })
    dispatch({ type: ActionTypes.ERROR })
  }
}

export const updateUserPreferences = async ({ uom }: { uom: string }, dispatch: any) => {
  try {
    dispatch({ type: ActionTypes.UPDATING_PREFERENCES })
    const userInfo = await backend.patch(`/users/me/update_profile`, {
      user_preferences: {
        uom: uom,
      },
    })
    dispatch({ type: ActionTypes.PREFERENCES_UPDATED, payload: { data: userInfo.data } })
    displayToast({
      type: 'success',
      message: 'User preferences updated successfully',
    })
  } catch (error: any) {
    console.log(error)
    displayToast({
      type: 'error',
      message: error?.response?.data?.detail || 'Something went wrong',
    })
    dispatch({ type: ActionTypes.ERROR })
  }
}

export const registerUserAction = async (
  {
    firstname,
    lastname,
    mobile,
    newPassword,
    newPasswordConfirmation,
    token,
    uom,
  }: {
    firstname: string
    lastname: string
    mobile: string
    newPassword: string
    newPasswordConfirmation: string
    token: string
    uom: string
  },
  dispatch: any,
) => {
  try {
    dispatch({ type: ActionTypes.CONFIRMING_EMAIL })
    const userConfirmed = await backend.post(`/users/register/${token}`, {
      first_name: firstname,
      last_name: lastname,
      mobile,
      password: newPassword,
      verify_password: newPasswordConfirmation,
      user_preferences: {
        uom,
      },
    })
    dispatch({ type: ActionTypes.EMAIL_CONFIRMED, payload: { data: userConfirmed } })
    displayToast({
      type: 'success',
      message: 'Account created successfully',
    })
  } catch (e: any) {
    console.log(e)
    dispatch({ type: ActionTypes.ERROR })
    displayToast({
      type: 'error',
      message:
        `${e?.response?.data?.message}, Error code: ${e?.response?.data?.error_code}` ||
        `An Error occurred while registering your account, Error code: ${e?.response?.data?.error_code}. Please try again...`,
    })
  }
}

export const confirmEmailAction = async (
  {
    newPassword,
    newPasswordConfirmation,
    token,
  }: { newPassword: string; newPasswordConfirmation: string; token: string },
  dispatch: any,
) => {
  try {
    dispatch({ type: ActionTypes.CONFIRMING_EMAIL })
    const userConfirmed = await backend.post(`/users/confirm_email/${token}`, {
      new_password: newPassword,
      verify_password: newPasswordConfirmation,
    })
    dispatch({ type: ActionTypes.EMAIL_CONFIRMED, payload: { data: userConfirmed } })
  } catch (error) {
    console.log(error)
    dispatch({ type: ActionTypes.ERROR })
  }
}

export const resetPasswordAction = async (
  {
    newPassword,
    newPasswordConfirmation,
    token,
  }: { newPassword: string; newPasswordConfirmation: string; token: string },
  dispatch: any,
) => {
  try {
    dispatch({ type: ActionTypes.RESETTING_PASSWORD })
    const userConfirmed = await backend.post(`/users/me/reset_password/${token}`, {
      new_password: newPassword,
      verify_password: newPasswordConfirmation,
    })
    dispatch({ type: ActionTypes.PASSWORD_RESET, payload: { data: userConfirmed } })
  } catch (error) {
    console.log(error)
    dispatch({ type: ActionTypes.ERROR })
  }
}

export const updateMyPasswordAction = async (
  {
    email,
    currentPassword,
    newPassword,
    newPasswordConfirmation,
  }: {
    email: string
    currentPassword: string
    newPassword: string
    newPasswordConfirmation: string
  },
  dispatch: any,
) => {
  try {
    dispatch({ type: ActionTypes.UPDATING_PASSWORD })
    const userConfirmed = await backend.put(`/users/me/change_password`, {
      password: currentPassword,
      new_password: newPassword,
      verify_password: newPasswordConfirmation,
    })
    dispatch({ type: ActionTypes.PASSWORD_UPDATED, payload: { data: userConfirmed } })
    displayToast({
      type: 'success',
      message: 'Password updated successfully',
    })
    displayToast({
      type: 'info',
      message: 'You are being logging out...',
    })
  } catch (error: any) {
    console.log(error)
    dispatch({ type: ActionTypes.ERROR })
    displayToast({
      type: 'error',
      message: error?.response?.data?.message || 'Something went wrong',
    })
  }
}

export const forgotPasswordAction = async ({ email }: { email: string }, dispatch: any) => {
  try {
    dispatch({ type: ActionTypes.SENDING_FORGOT_EMAIL })
    const isEmailSent = await backend.post(`/users/me/forgot_password`, {
      email,
    })
    dispatch({ type: ActionTypes.FORGOT_PASSWORD_SENT, payload: { data: isEmailSent } })
    displayToast({
      type: 'success',
      message: 'Email sent successfully',
    })
  } catch (error) {
    console.log(error)
    dispatch({ type: ActionTypes.ERROR })
    displayToast({
      type: 'error',
      message: 'An Error occurred while requesting your password reset email. Please try again...',
    })
  }
}

export const requestConfirmEmailAction = async ({ email }: { email: string }, dispatch: any) => {
  try {
    dispatch({ type: ActionTypes.REQUESTING_CONFIRM_EMAIL })
    const isEmailSent = await backend.post(`/users/request_confirm_email`, {
      email,
    })
    dispatch({ type: ActionTypes.CONFIRM_EMAIL_SENT, payload: { data: isEmailSent } })
  } catch (error) {
    console.log(error)
    dispatch({ type: ActionTypes.ERROR })
  }
}

export const deleteUserAction = async ({ userId }: { userId: number }, dispatch: any) => {
  try {
    dispatch({ type: ActionTypes.UPDATING })
    await backend.delete(`/users/remove_user/${userId}`)
    dispatch({ type: ActionTypes.SUCCESS, payload: { data: userId } })
  } catch (error) {
    console.log(error)
    dispatch({ type: ActionTypes.ERROR })
  }
}
