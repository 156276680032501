import { Button, Col, Form, Modal, Row, Spinner, Tab, Tabs } from 'react-bootstrap'
import Device, { DeviceIDTypes } from '@context/device/model/device'
import { useDeviceDispatch, useDeviceState } from '@context/device/context/device.context'
import { useEffect, useState } from 'react'
import {
  useDeviceComments,
  useDeviceCommentsCreation,
  useDeviceCommentsUpdate,
} from '@data/comments/deviceComments/deviceComments'
import CommentComponent from '../Components/Comment/CommentComponent'
import { IComments } from '@data/comments/model/comments.model'

interface IModalProps {
  show: boolean
  deviceData: Device
  onHide: () => void
}

const CustomerJourneyDeviceModal: React.FC<IModalProps> = ({
  show,
  deviceData,
  onHide,
}: IModalProps) => {
  const { updateDeviceData, updateDeviceLastComment } = useDeviceDispatch()
  const { deviceUpdating } = useDeviceState()

  const [newDeviceName, setNewDeviceName] = useState('')
  const [newReport30DaySent, setNewReport30DaySent] = useState(false)
  const [newEsgReportSent, setNewEsgReportSent] = useState(false)
  const [newSustainabilityReportSent, setNewSustainabilityReportSent] = useState(false)
  const [newSetUpPaymentReceived, setNewSetUpPaymentReceived] = useState(false)
  const [newAccountRenewalStatus, setNewAccountRenewalStatus] = useState(false)

  const [newCommentData, setNewCommentData] = useState({})

  const [didDeviceInfoChange, setDidDeviceInfoChange] = useState(false)

  const { data: deviceComments, isLoading } = useDeviceComments(deviceData?.deviceId)

  const { mutate: createNewComment, isPending, isError, isSuccess } = useDeviceCommentsCreation()
  const { mutate: updateComment } = useDeviceCommentsUpdate()

  useEffect(() => {
    if (show && deviceData) {
      setNewDeviceName(deviceData.deviceSettings.deviceName)
      setNewReport30DaySent(deviceData.deviceSettings.report30Day)
      setNewEsgReportSent(deviceData.deviceSettings.esgReport)
      setNewSustainabilityReportSent(deviceData.deviceSettings.sustainabilityReport)
      setNewSetUpPaymentReceived(deviceData.deviceSettings.setUpPayment)
      setNewAccountRenewalStatus(deviceData.deviceSettings.renewalOverdueStatus)
    }
  }, [show, deviceData])

  useEffect(() => {
    const wasDeviceInfoUpdated = wasDeviceDataUpdated()

    if (wasDeviceInfoUpdated) {
      // Some value has changed
      setDidDeviceInfoChange(true)
    } else {
      setDidDeviceInfoChange(false)
    }
  }, [
    deviceData,
    newDeviceName,
    newReport30DaySent,
    newEsgReportSent,
    newSustainabilityReportSent,
    newSetUpPaymentReceived,
    newAccountRenewalStatus,
  ])

  const wasDeviceDataUpdated = () => {
    if (deviceData && deviceData.deviceSettings) {
      if (
        deviceData.deviceName !== newDeviceName ||
        deviceData.deviceSettings.report30Day !== newReport30DaySent ||
        deviceData.deviceSettings.esgReport !== newEsgReportSent ||
        deviceData.deviceSettings.sustainabilityReport !== newSustainabilityReportSent ||
        deviceData.deviceSettings.setUpPayment !== newSetUpPaymentReceived ||
        deviceData.deviceSettings.renewalOverdueStatus !== newAccountRenewalStatus
      ) {
        return true
      }
    }

    return false
  }

  useEffect(() => {
    if (isSuccess) {
      updateDeviceLastComment(newCommentData)
    }
  }, [isSuccess, newCommentData])

  const handleUpdate = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    if (!deviceData) return

    const updatedDevice = { ...deviceData }

    if (wasDeviceDataUpdated()) {
      updatedDevice.deviceName = newDeviceName
      updatedDevice.deviceSettings.report30Day = newReport30DaySent
      updatedDevice.deviceSettings.esgReport = newEsgReportSent
      updatedDevice.deviceSettings.sustainabilityReport = newSustainabilityReportSent
      updatedDevice.deviceSettings.setUpPayment = newSetUpPaymentReceived
      updatedDevice.deviceSettings.renewalOverdueStatus = newAccountRenewalStatus

      if (deviceData?.dUUID) {
        await updateDeviceData(deviceData?.dUUID, DeviceIDTypes.dUUID, updatedDevice)
      }
    }

    onHide()
  }

  const handleNewComment = (newComment: string, newDepartment: string, newCategory: string) => {
    const newCommentData = {
      device_id: deviceData.deviceId,
      comment: newComment,
      department: newDepartment,
      category: newCategory,
    }

    setNewCommentData(newCommentData)
    createNewComment(newCommentData)

    setTimeout(() => {
      onHide()
    }, 1000)
  }

  const handleUpdateComment = (comment: IComments, updatedComment: string) => {
    const updatedCommentData = {
      device_id: deviceData.deviceId,
      comment_id: comment.id,
      comment: updatedComment,
      department: comment.department,
      category: comment.category,
    }

    updateComment(updatedCommentData)
    onHide()
  }

  if (!deviceData) return null

  return (
    <Modal
      show={show}
      onHide={onHide}
      backdrop="static"
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="device-information-modal"
    >
      <Modal.Header className="modal-header" closeButton>
        <Modal.Title id="contained-modal-title-vcenter">{`${deviceData?.deviceName} - ${deviceData?.deviceId}`}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Tabs transition={false} id="controlled-tab">
          <Tab eventKey={'reports'} title="Reports">
            <Form className="device-information-form mt-4">
              <Row className="mb-2">
                <Form.Group as={Col} controlId="formGridName">
                  <Form.Label>Device Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter a name"
                    value={newDeviceName}
                    onChange={(e) => setNewDeviceName(e.target.value)}
                  />
                </Form.Group>
              </Row>

              <Row className="mb-2">
                <Form.Group as={Col} controlId="formGridId">
                  <Form.Label>ID</Form.Label>
                  <Form.Control defaultValue={deviceData.deviceId} disabled />
                </Form.Group>
                <Form.Group as={Col} controlId="formGridId"></Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} controlId="formGrid30DayReport">
                  <Form.Check
                    type="checkbox"
                    inline
                    onChange={() => setNewReport30DaySent(!newReport30DaySent)}
                    checked={newReport30DaySent}
                    label="30 Day Report Completed"
                    id={`inline-30-day-report-completed`}
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="formGrid30DayReport">
                  <Form.Check
                    type="checkbox"
                    inline
                    onChange={() => setNewEsgReportSent(!newEsgReportSent)}
                    checked={newEsgReportSent}
                    label="ESG Report Completed"
                    id={`inline-esg-report-completed`}
                  />
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} controlId="formGrid30DayReport">
                  <Form.Check
                    type="checkbox"
                    inline
                    onChange={() => setNewSustainabilityReportSent(!newSustainabilityReportSent)}
                    checked={newSustainabilityReportSent}
                    label="Sustainability Report Completed"
                    id={`inline-sustainability-report-completed`}
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="formGrid30DayReport">
                  <Form.Check
                    type="checkbox"
                    inline
                    onChange={() => setNewSetUpPaymentReceived(!newSetUpPaymentReceived)}
                    checked={newSetUpPaymentReceived}
                    label="SetUp Payment Received"
                    id={`inline-set-up-payment-received`}
                  />
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} controlId="formGrid30DayReport">
                  <Form.Check
                    type="checkbox"
                    inline
                    onChange={() => setNewAccountRenewalStatus(!newAccountRenewalStatus)}
                    checked={newAccountRenewalStatus}
                    label="Account Renewal over Due"
                    id={`inline-account-renewal-over-due`}
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="formGrid30DayReport"></Form.Group>
              </Row>

              <div className="d-flex justify-content-between w-100">
                <Button id="close-modal" onClick={onHide} variant="outline">
                  Close
                </Button>
                <Button
                  id="update-device-pulses"
                  size="lg"
                  variant="primary"
                  type="button"
                  className="mt-4"
                  disabled={!didDeviceInfoChange}
                  onClick={handleUpdate}
                >
                  {deviceUpdating ? (
                    <>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      <span>Loading...</span>
                    </>
                  ) : (
                    <>Update</>
                  )}
                </Button>
              </div>
            </Form>
          </Tab>
          <Tab eventKey={'comments'} title="Comments" className="mt-4">
            {!isLoading ? (
              <div className="overflow-auto" style={{ maxHeight: '600px' }}>
                <CommentComponent
                  comments={deviceComments}
                  handleNewComment={handleNewComment}
                  handleUpdateComment={handleUpdateComment}
                  showDepartmentAndCategory
                  isPending={isPending}
                  isError={isError}
                  isSuccess={isSuccess}
                />
              </div>
            ) : (
              <div className="d-flex justify-content-center m-auto h-100 mt-5 mb-5">
                <div className="spinner-border text-primary m-auto">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            )}
          </Tab>
        </Tabs>
        <></>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  )
}

export default CustomerJourneyDeviceModal
