import { useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Form, Modal } from 'react-bootstrap'
import { AlertGroup, AlertStatus } from '@context/alert/model/alert.model'
import Handlebars from 'handlebars'
import Select from 'react-select'
import backend from '@api/backend'
import CreatableSelect from 'react-select/creatable'
import { useDeviceState } from '@context/device/context/device.context'
import { useAlertDispatch, useAlertState } from '@context/alert/context/alert.context'
import moment from 'moment-timezone'

const templates = [
  {
    id: 'd-f44d61745da24440b9fce6bac8bf4f82',
    name: 'Peak Alert',
  },
  {
    id: 'd-d2dde918b35441a1b45821f35e68eb27',
    name: 'Offpeak Alert',
  },
  {
    id: 'd-3a106a521c8d4b18b69dc13cbb193f23',
    name: 'Alert Resolved',
  },
]

interface Recipient {
  id: number
  email: string
}

export default function AckAlertModal({
  alertGroup,
  priorityTier,
  status,
  onHide,
  chartRef,
  chartImage,
  activeAlertId,
}: {
  alertGroup: AlertGroup
  priorityTier: number
  status: AlertStatus
  onHide: () => void
  chartRef: any
  chartImage: string | null
  activeAlertId: string
}) {
  const { devices } = useDeviceState()
  const { ackAlerts } = useAlertDispatch()
  const { filteredQueue } = useAlertState()
  const navigate = useNavigate()

  const [selectedTemplate, setSelectedTemplate] = useState<any>(
    status === AlertStatus.CLOSED ? templates.find((o) => o.name === 'Alert Resolved') : null,
  )
  const alertTemplates = templates.filter((t) =>
    status === AlertStatus.CLOSED ? t.name === 'Alert Resolved' : t.name !== 'Alert Resolved',
  )

  const mapDynamicProperties = () => {
    return {
      device_name: alertGroup.deviceLocation,
      alert_tier: priorityTier.toString(),
      additional_info_pre: additionalInfoPre,
      additional_info_1: additionalInfo1,
      no_hours: numberOfHours,
      liters_hour: litres,
      contact_name: contactName,
    }
  }

  const renderTemplate = (template: any) => setRenderedEmail(template(mapDynamicProperties()))

  const [templateContent, setTemplateContent] = useHookWithRefCallback(renderTemplate)

  const [renderedEmail, setRenderedEmail] = useState('')
  const [allRecipients, setAlertRecipients] = useState<{
    to: Recipient[]
    bcc: Recipient[]
    cc: Recipient[]
  }>({ to: [], cc: [], bcc: [] })
  const [toRecipients, setToRecipients] = useState<Recipient[]>([])
  const [ccRecipients, setCcRecipients] = useState<Recipient[]>([])
  const [bccRecipients, setBccRecipients] = useState<Recipient[]>([])
  const [contactName, setContactName] = useState('Team')
  const [additionalInfo1, setAdditionalInfo1] = useState('')
  const [additionalInfoPre, setAdditionalInfoPre] = useState('')
  const [litres, setLitres] = useState('')
  const [numberOfHours, setNumberOfHours] = useState(
    String(moment(alertGroup.endAlertTime).diff(moment(alertGroup.startAlertTime), 'hours') + 1),
  )

  useEffect(() => {
    if (!(devices && devices.length > 0)) return
    // const device = devices.find(d => d.deviceId === alertGroup.deviceId);
    // backend.get(`/customer_locations/${device?.deviceLocationId}/alert_recipients`)
    backend
      .get(`/usage_alerts/${alertGroup.deviceId}/alert_recipients`)
      .then((response) => response.data)
      .then((response) => setAlertRecipients(response))
  }, [devices])

  useEffect(() => {
    setToRecipients(allRecipients.to)
    setCcRecipients(allRecipients.cc)
    setBccRecipients(allRecipients.bcc)
  }, [allRecipients])

  function useHookWithRefCallback(fn: (node: any) => void) {
    const ref = useRef<any>()
    const setRef = useCallback((node: any) => {
      if (node) {
        fn(node)
      }
      ref.current = node
    }, [])

    return [ref, setRef] as any[]
  }

  useEffect(() => {
    if (!selectedTemplate) return
    backend
      .get(`/alert_templates/${selectedTemplate.id}`)
      .then((response) => response.data)
      .then(function (response) {
        Handlebars.registerHelper('helperMissing', function (/* dynamic arguments */) {
          let options = arguments[arguments.length - 1]
          // let args = Array.prototype.slice.call(arguments, 0,arguments.length-1)
          return new Handlebars.SafeString(`{{${options.name}}}`)
        })
        Handlebars.registerHelper('equals', function (this: any, lvalue, rvalue, options) {
          if (arguments.length < 3) throw new Error('Handlebars Helper equal needs 2 parameters')
          if (lvalue !== rvalue) {
            return options.inverse(this)
          } else {
            return options.fn(this)
          }
        })
        const template = Handlebars.compile(response.content.html_content)
        setTemplateContent(template)
      })
  }, [selectedTemplate])

  useEffect(() => {
    if (!templateContent.current) return
    renderTemplate(templateContent.current)
  }, [additionalInfo1, additionalInfoPre, numberOfHours, litres, contactName])

  async function sendEmail() {
    if (!selectedTemplate || toRecipients.length === 0) return

    const request = {
      template_id: selectedTemplate.id,
      alert_ids: alertGroup.alerts.map((a) => a.id),
      recipients: {
        to: toRecipients.map((r) => r.email),
        cc: ccRecipients.map((r) => r.email),
        bcc: bccRecipients.map((r) => r.email),
      },
      dynamic_template_data: mapDynamicProperties(),
      action_type: status,
      attachments: [
        {
          data: chartImage
            ? chartImage.substring(chartImage.indexOf(';base64,') + ';base64,'.length)
            : null,
          mime: 'image/png',
          file_name: 'water_usage.png',
          disposition: 'inline',
          cid: 'image_1',
        },
      ],
    }

    try {
      const btn = document.getElementById('send-email-button')! as HTMLButtonElement
      btn.disabled = true
      await backend.post(`usage_alerts/${alertGroup.deviceId}/send_alert`, request)
      await ackAlerts(alertGroup, priorityTier, status)
      redirect()
    } catch (e) {
      console.error((e as any)?.message)
    }
  }

  async function ackAlertWithoutEmail() {
    try {
      const btn = document.getElementById('send-ack-button')! as HTMLButtonElement
      btn.disabled = true
      await ackAlerts(alertGroup, priorityTier, status)
      redirect()
    } catch (e) {
      console.error((e as any)?.message)
    }
  }

  const redirect = () => {
    const currentAlertIndex = filteredQueue.findIndex((queue) => queue.deviceId === activeAlertId)

    if (filteredQueue[currentAlertIndex + 1] !== undefined) {
      navigate(`/alert-queue/${filteredQueue[currentAlertIndex + 1].deviceId}`, { replace: true })
    } else {
      navigate('/alert-queue', { replace: true })
    }
  }

  const hideModal = () => {
    chartRef.current.setDatasetVisibility(0, true)
    chartRef.current.setDatasetVisibility(3, true)
    onHide()
  }

  return (
    <Modal show={true} onHide={hideModal} fullscreeen="true" size="xl">
      <Modal.Header className="modal-header" closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {status === 2 ? 'Resolve ' : 'Acknowledge '} Tier {priorityTier.toString()} Alert -{' '}
          {alertGroup.deviceLocation} - {alertGroup.deviceId}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container">
          <div className="row">
            <div className="col">
              <Form>
                <Form.Group className="mb-3">
                  <Form.Label>E-Mail Template</Form.Label>
                  <Select
                    name="templates"
                    classNamePrefix="select"
                    className="basic-multi-select"
                    value={selectedTemplate}
                    isDisabled={!alertGroup.hourlyAlertsSentDate && status === 2}
                    onChange={(alertTemplates: any) => setSelectedTemplate(alertTemplates)}
                    options={alertTemplates}
                    getOptionValue={(option) => option.id}
                    getOptionLabel={(option) => `${option.name}`}
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Recipient To:</Form.Label>
                  <CreatableSelect
                    isMulti
                    isDisabled={
                      !selectedTemplate || (!alertGroup.hourlyAlertsSentDate && status === 2)
                    }
                    value={toRecipients}
                    onChange={(options: any) => setToRecipients(options)}
                    options={allRecipients.to}
                    getOptionValue={(option: any) =>
                      option.__isNew__ ? option.value : String(option.id)
                    }
                    getOptionLabel={(option: any) =>
                      option.__isNew__ ? option.label : String(option.email)
                    }
                    onCreateOption={(input) => {
                      setToRecipients([...toRecipients, { id: -1, email: input }])
                    }}
                  />
                </Form.Group>

                {allRecipients.cc.length > 0 ? (
                  <Form.Group className="mb-3">
                    <Form.Label>Recipient CC:</Form.Label>
                    <CreatableSelect
                      isMulti
                      isDisabled={
                        !selectedTemplate || (!alertGroup.hourlyAlertsSentDate && status === 2)
                      }
                      value={ccRecipients}
                      onChange={(options: any) => setCcRecipients(options)}
                      options={allRecipients.cc}
                      getOptionValue={(option: any) =>
                        option.__isNew__ ? option.value : String(option.id)
                      }
                      getOptionLabel={(option: any) =>
                        option.__isNew__ ? option.label : String(option.email)
                      }
                      onCreateOption={(input) => {
                        setCcRecipients([...ccRecipients, { id: -1, email: input }])
                      }}
                    />
                  </Form.Group>
                ) : null}

                <Form.Group className="mb-3">
                  <Form.Label>Recipient BCC:</Form.Label>
                  <CreatableSelect
                    isMulti
                    isDisabled={
                      !selectedTemplate || (!alertGroup.hourlyAlertsSentDate && status === 2)
                    }
                    value={bccRecipients}
                    onChange={(options: any) => setBccRecipients(options)}
                    options={allRecipients.bcc}
                    getOptionValue={(option: any) =>
                      option.__isNew__ ? option.value : String(option.id)
                    }
                    getOptionLabel={(option: any) =>
                      option.__isNew__ ? option.label : String(option.email)
                    }
                    onCreateOption={(input) => {
                      setBccRecipients([...bccRecipients, { id: -1, email: input }])
                    }}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                  <Form.Label>Contact Name</Form.Label>
                  <Form.Control
                    type="text"
                    readOnly={
                      !selectedTemplate || (!alertGroup.hourlyAlertsSentDate && status === 2)
                    }
                    value={contactName}
                    onChange={(e) => setContactName(e.target.value)}
                  />
                </Form.Group>

                {status !== 2 ? (
                  <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                    <Form.Label>Number of Hours</Form.Label>
                    <Form.Control
                      type="text"
                      readOnly={!selectedTemplate}
                      value={numberOfHours}
                      onChange={(e) => setNumberOfHours(e.target.value)}
                    />
                  </Form.Group>
                ) : null}

                {status !== 2 ? (
                  <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                    <Form.Label>Litres</Form.Label>
                    <Form.Control
                      type="text"
                      readOnly={!selectedTemplate}
                      value={litres}
                      onChange={(e) => setLitres(e.target.value)}
                    />
                  </Form.Group>
                ) : null}

                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                  <Form.Label>Additional Info Pre</Form.Label>
                  <Form.Control
                    as="textarea"
                    readOnly={
                      !selectedTemplate || (!alertGroup.hourlyAlertsSentDate && status === 2)
                    }
                    value={additionalInfoPre}
                    onChange={(e) => setAdditionalInfoPre(e.target.value)}
                    rows={3}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                  <Form.Label>Additional Info 1</Form.Label>
                  <Form.Control
                    as="textarea"
                    readOnly={
                      !selectedTemplate || (!alertGroup.hourlyAlertsSentDate && status === 2)
                    }
                    value={additionalInfo1}
                    onChange={(e) => setAdditionalInfo1(e.target.value)}
                    rows={3}
                  />
                </Form.Group>

                {status === 2 ? (
                  alertGroup.hourlyAlertsSentDate !== null ? (
                    <Button
                      id="send-email-button"
                      style={{ marginRight: '1em', marginBottom: '1em' }}
                      variant="primary"
                      type="button"
                      onClick={() => sendEmail()}
                      disabled={!selectedTemplate || toRecipients.length === 0}
                    >
                      Resolve Alert and Send
                    </Button>
                  ) : null
                ) : (
                  <Button
                    id="send-email-button"
                    style={{ marginRight: '1em', marginBottom: '1em' }}
                    variant="primary"
                    type="button"
                    onClick={() => sendEmail()}
                    disabled={!selectedTemplate || toRecipients.length === 0}
                  >
                    Acknowledge Alert and Send
                  </Button>
                )}

                {alertGroup.hourlyAlertsSentDate === null ? (
                  <Button
                    id="send-ack-button"
                    variant="secondary"
                    type="button"
                    onClick={() => ackAlertWithoutEmail()}
                  >
                    {status === 2 ? 'Archive ' : 'Acknowledge '}Without Sending
                  </Button>
                ) : null}
              </Form>
            </div>
            <div className="col">
              {/*<img width={700} src={usageGraph} />*/}
              {chartImage ? <img alt="Alert Graph" width={700} src={chartImage} /> : null}
              <div dangerouslySetInnerHTML={{ __html: renderedEmail }}></div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}
