import { useCallback, useEffect, useState } from 'react'
import { Button, Spinner } from 'react-bootstrap'
import Select from 'react-select'
import useWebSocket from 'react-use-websocket'
import { useDeviceState } from '@context/device/context/device.context'
import Device, { DeviceIDTypes } from '@context/device/model/device'
import backend from '@api/backend'

import './RealTimeFlow.scss'

let maxValue = 30

interface rtfPayload {
  message_id: string
  status: number
  payload: {
    Flow: number
    device_id: string
    enqueued_time: string
    messageType: string
  }
}

const updateCanvas = (value?: number, status?: number) => {
  const canvas = document.getElementById('myCanvas') as HTMLCanvasElement
  let ctx = canvas?.getContext('2d')
  if (!ctx) return

  ctx.clearRect(0, 0, canvas.width, canvas.height)

  const PI = Math.PI
  const PI2 = PI * 2
  let cx = canvas.width / 2
  let cy = canvas.width / 2
  let r = canvas.width / 2 - 16
  const min = PI * 0.75
  const max = PI2 + PI * 0.25

  if (value && value > maxValue) maxValue = value! * 1.2

  let highBreakoff = maxValue

  let percent =
    value === undefined ? 0 : value > 0 ? (Math.min(highBreakoff, value) / maxValue) * 100 : 1

  ctx.textAlign = 'center'
  ctx.textBaseline = 'middle'
  ctx.fillStyle = 'gray'
  ctx.imageSmoothingEnabled = true

  ctx.beginPath()
  ctx.arc(cx, cy, r, min, max)
  ctx.strokeStyle = 'lightgray'
  ctx.lineWidth = 14
  ctx.stroke()

  ctx.beginPath()
  ctx.arc(cx, cy, r, min, min + ((max - min) * percent) / 100)
  ctx.strokeStyle = '#1689CA'
  ctx.lineWidth = 15
  ctx.stroke()

  ctx.font = '26px verdana'
  if (value !== undefined) ctx.fillStyle = '#1689CA'
  if (status === 1) {
    ctx.fillText(value !== undefined ? value.toFixed(2) : '0', cx, cy - 15)
    //ctx.fillText(c.width + " " + c.height + " " + cx + " " + cy, cx, cy)
    ctx.fillStyle = 'gray'
    ctx.font = '12px verdana'
    ctx.fillText('l/min', cx, cy + 15)
  } else if (status === 2) {
    ctx.fillText('Connecting...', cx, cy - 15)
    ctx.fillStyle = 'gray'
    ctx.font = '12px verdana'
  } else if (status === 0) {
    ctx.fillText('No data received', cx, cy - 15)
    ctx.fillStyle = 'gray'
    ctx.font = '12px verdana'
  } else {
    ctx.fillText('Ready', cx, cy - 15)
    ctx.fillStyle = 'gray'
    ctx.font = '12px verdana'
  }
}

function Dashboard() {
  const [socketUrl, setSocketUrl] = useState('wss://example.com')
  const { devices } = useDeviceState()
  const { lastMessage, readyState, getWebSocket } = useWebSocket(socketUrl, {
    onOpen: () => console.log('opened'),
    onClose: (event) => closeWS(event),
  })
  const [selectedDevice, setSelectedDevice] = useState<Device>()
  const [rtfRunning, setRtfRunning] = useState<boolean>(false)

  const connType = import.meta.env.VITE_APP_API_URI!.includes('localhost') ? 'ws' : 'wss'
  const wsURL = import.meta.env.VITE_APP_API_URI!.replace(/^https?:\/\//, '')

  const closeWS = (event: any) => {
    console.log(event)
  }

  const canvasRef = useCallback((canvas: HTMLCanvasElement) => {
    if (!canvas) return
    canvas.style.height = `${canvas.offsetWidth}px`
    canvas.width = canvas.offsetWidth
    canvas.height = canvas.offsetHeight
    updateCanvas()
  }, [])

  useEffect(() => {
    const jsonString = lastMessage?.data
    const message: rtfPayload | undefined = jsonString && JSON.parse(jsonString)
    if (!message) return

    const { status, payload } = message
    if (status === 1) {
      const { messageType, Flow } = payload
      updateCanvas(Flow / 1000, status)
      if (messageType === 'END_RTF') {
        setRtfRunning(false)
        getWebSocket()?.close()
      }
    } else {
      updateCanvas(undefined, status)
    }
  }, [lastMessage])

  useEffect(() => {
    window.addEventListener(
      'resize',
      () => {
        const canvas = document.getElementById('myCanvas') as HTMLCanvasElement
        if (!canvas) return
        canvas.style.height = `${canvas.offsetWidth}px`
        canvas.width = canvas.offsetWidth
        canvas.height = canvas.offsetHeight
        updateCanvas()
      },
      false,
    )
  }, [])

  useEffect(() => {
    if (selectedDevice) {
      setRtfRunning(false)
      updateCanvas()
    }
    getWebSocket()?.close()
  }, [selectedDevice])

  useEffect(() => {
    if (readyState === 3) {
      setRtfRunning(false)
    }
  }, [readyState])

  const go = useCallback(() => {
    updateCanvas()
    if (!selectedDevice) return

    if (import.meta.env.VITE_APP_ENV_STATE === 'demo') {
      const newSocketUrl: string = `${connType}://${wsURL}/devices/${selectedDevice.deviceId}/rtf_demo`
      setSocketUrl(newSocketUrl)
      setRtfRunning(true)
    } else {
      setRtfRunning(true)
      backend
        .get(`devices/${selectedDevice.dUUID}/rtf_token`, {
          params: { id_type: DeviceIDTypes.dUUID },
        })
        .then((resp: any) => {
          const {
            data: { token },
          } = resp
          updateCanvas(undefined, 2)
          const newSocketUrl: string = `${connType}://${wsURL}/devices/${selectedDevice.deviceId}/rtf?token=${token}`
          if (newSocketUrl === socketUrl) {
            getWebSocket()?.close()
          }
          setSocketUrl(newSocketUrl)
        })
    }
  }, [selectedDevice])

  return (
    <div className="rtf-management">
      <h1>Real Time Flow</h1>
      <div className="container-fluid flex-container ">
        <div className="row">
          <div className="section-header">
            <div className="">Device:</div>
          </div>
          <div className="col-md-6">
            <div className="multiselect-container">
              <Select
                isDisabled={rtfRunning}
                classNamePrefix="select"
                className="basic-multi-select"
                value={selectedDevice}
                onChange={(device: any) => setSelectedDevice(device)}
                options={devices}
                name="device"
                getOptionValue={(option) => option.deviceId}
                getOptionLabel={(option) => `${option.deviceName}`}
                isClearable={false}
              />
            </div>
          </div>
        </div>
        {selectedDevice ? (
          <div className="rtf-view-container">
            <canvas ref={canvasRef} id="myCanvas" />
            <Button disabled={rtfRunning} onClick={() => go()}>
              {rtfRunning ? (
                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
              ) : null}
              Start RTF
            </Button>
          </div>
        ) : (
          <div className="no-device-message">Select a device</div>
        )}
      </div>
    </div>
  )
}

export default Dashboard
