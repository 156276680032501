import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import Table from 'react-bootstrap/Table'
import moment from 'moment-timezone'
import {
  hasPermissions,
  itemFromPath,
  formatCurrency,
  getBatteryIconClass,
  getBatteryPercentage,
  getSignalStatus,
  sortObjectsByKey,
  getBatteryStatus,
  getDeviceStatus,
  areAllReportsCompleted,
  isReportDue,
  isReportOverDue,
} from '@common/utils/helperFunctions'
import SectorIcon from '../../general/SectorIcon/SectorIcon'
import Device, { DeviceIDTypes, DeviceSettings } from '@context/device/model/device'
import { useDeviceDispatch, useDeviceState } from '@context/device/context/device.context'
import { useAccountState } from '@context/account/context/account.context'
import { useAuthState } from '@context/auth/context/auth.context'
import { useLocationState } from '@context/location/context/location.context'
import { CSVLink } from 'react-csv'
import DateRangeComponent from '../../main/Components/Calendar/DateRangeSelector'
import { FaBan } from 'react-icons/fa'
import { Pagination } from '../Pagination/Pagination'
import { ChevronDown, ChevronUp } from 'lucide-react'
import { IColumn } from '../../main/DeviceManagement/DeviceInformationManager/constants'
import Select from 'react-select'
import Account from '@context/account/model/Account'
import { ReportsByYearMonth } from '@data/reportsTracking/model/reportsTracking.model'

import './CustomSearchTable.scss'

interface ICustomSearchTable {
  dataArray: any[]
  columns: IColumn[]
  onRowClickHandler?: (rowData: any) => void
  filterByDate?: boolean
  exportToCSV?: boolean
  allowColumnPicker?: boolean
  displayPagination?: boolean
  itemsPerPage?: number
  tabKey?: 'account' | 'location' | 'device'
}

const CustomSearchTable: React.FC<ICustomSearchTable> = ({
  dataArray,
  columns,
  onRowClickHandler,
  filterByDate = false,
  exportToCSV = false,
  allowColumnPicker = false,
  displayPagination = false,
  itemsPerPage = 30,
  tabKey = 'device',
}: ICustomSearchTable) => {
  const { accounts } = useAccountState()
  const { devices } = useDeviceState()
  const { locations } = useLocationState()
  const { permissions } = useAuthState()

  const { updateDeviceData } = useDeviceDispatch()

  const [sortingConf, setSortingConf] = useState({ key: '', order: '' })
  const [originalData, setOriginalData] = useState(dataArray)
  const [csvData, setCsvData] = useState<any>([])
  const [showColumnPickerOptions, setShowColumnPickerOptions] = useState<boolean>(false)

  const [columnsArr, setColumnsArr] = useState([...columns])
  const [visibleColumns, setVisibleColumns] = useState<IColumn[]>([])

  const [searchParams, setSearchParams] = useSearchParams()
  const currentPage = searchParams.get('page') ? Number(searchParams.get('page')) : 1

  useEffect(() => {
    if (columns.some((column) => column.checked)) {
      setVisibleColumns(columnsArr.filter((column) => column.checked))
    } else {
      setVisibleColumns([...columns])
    }
  }, [])

  useEffect(() => {
    if (dataArray && dataArray.length >= 0) {
      setOriginalData(dataArray)
    }
  }, [dataArray])

  const filter: { [columnName: string]: IColumn['filter'] } = {}

  columnsArr.forEach((column) => {
    if (column.filter && typeof column.key === 'string') {
      // If the column has a filter property
      filter[column.key] = column.filter // Assign the filter property to the filter variable
    }
  })

  const searchParamsResult: { [key: string]: string | null } = {}

  // Assign search parameters to the object
  Object.keys(filter).forEach((columnName) => {
    searchParamsResult[columnName] = searchParams.get(`${columnName}`)
  })

  let filteredData: any[] = originalData

  filteredData = filteredData.filter((data) => {
    return Object.entries(searchParamsResult).every(([key, value]) => {
      if (value !== null) {
        let dataProp = undefined

        if (key.includes('deviceSettings')) {
          dataProp = data.deviceSettings[key.split('.')[1] as keyof DeviceSettings]
        } else if (key === 'accountName') {
          dataProp = data.accountId
        } else if (key === 'locationCountry') {
          dataProp = data.deviceLocationId
        } else if (key === 'companyAlias') {
          dataProp = data.deviceLocationId
        } else if (key.includes('reports')) {
          dataProp = data.reports
        } else if (key.includes('location.')) {
          const prop = key.split('.')
          dataProp = data[prop[1] as keyof Location]
        } else if (key.includes('account.')) {
          const prop = key.split('.')
          dataProp = data[prop[1] as keyof Account]
        } else {
          dataProp = data[key as keyof Device]
        }

        if (key === 'reports.exSummaryReport') {
          switch (value) {
            case 'Up to date':
              if (areAllReportsCompleted(data.reports?.exSummaryReport)) {
                return dataProp
              } else return false
            case 'Due':
              if (isReportDue(data.reports?.exSummaryReport)) {
                return dataProp
              } else return false
            case 'Over due':
              if (isReportOverDue(data.reports?.exSummaryReport)) {
                return dataProp
              } else return false
            default:
              break
          }
        }

        if (key === 'deviceSettings.active') {
          if (value === 'Active') {
            return dataProp === true
          } else {
            return dataProp === false
          }
        }

        if (
          key === 'deviceSettings.sustainabilityReport' ||
          key === 'deviceSettings.report30Day' ||
          key === 'deviceSettings.esgReport' ||
          key === 'deviceSettings.setUpPayment' ||
          key === 'deviceSettings.renewalOverdueStatus' ||
          key === 'comments'
        ) {
          if (value === 'Yes') {
            return dataProp === true
          } else {
            return dataProp === false
          }
        }

        if (key.includes('lastComment')) {
          if (value === ' ') {
            return !!dataProp
          } else if (value === '-') {
            return dataProp === undefined || dataProp === null
          } else {
            const comment = dataProp?.comment.toLowerCase()

            return comment?.includes(value.trim().toLowerCase())
          }
        }

        if (key === 'batteryStatus') {
          return getBatteryStatus(data) === value
        }
        if (key === 'deviceStatus') {
          return (
            getDeviceStatus(data, hasPermissions(permissions, ['DASHBOARD:STATUS:VIEW'])) === value
          )
        }

        if (typeof dataProp === 'string') {
          if (key === 'deviceName') {
            return (
              dataProp.toLowerCase().includes(value.trim().toLowerCase()) ||
              String(data.deviceId).includes(value.trim().toLowerCase())
            )
          } else if (key === 'accountName') {
            const accountName = accounts.filter((account) => dataProp === account.id)[0]?.name

            return accountName?.toLowerCase().includes(value.trim().toLowerCase())
          } else if (key === 'locationCountry') {
            const country = locations.filter((location) => dataProp === location.id)[0]?.country

            return country === value
          } else if (key === 'companyAlias') {
            const alias = locations.filter((location) => dataProp === location.id)[0]?.companyAlias
            return alias?.toLowerCase().includes(value.trim().toLowerCase())
          } else {
            return dataProp.toLowerCase().includes(value.trim().toLowerCase())
          }
        } else if (typeof dataProp === 'number') {
          if (key === 'deviceId') {
            return String(data.deviceId).includes(value.trim().toLowerCase())
          }
          return dataProp === parseFloat(value)
        }
      }
      return true
    })
  })

  const fromDateSearch = searchParams.get('fromDate')
  const toDateSearch = searchParams.get('toDate')

  if (filterByDate) {
    if (fromDateSearch && !toDateSearch) {
      filteredData = filteredData.filter((data) => {
        if (tabKey === 'device') {
          return moment(data.deviceSettings.installDate).isAfter(moment(fromDateSearch))
        } else if (tabKey === 'location') {
          return (
            data.settings.onboardingStatus &&
            moment(data.settings.onboardingDate).isAfter(moment(fromDateSearch))
          )
        }
      })
    }

    if (!fromDateSearch && toDateSearch) {
      filteredData = filteredData.filter((data) => {
        if (tabKey === 'device') {
          return moment(data.deviceSettings.installDate).isBefore(moment(toDateSearch))
        } else if (tabKey === 'location') {
          return (
            data.settings.onboardingStatus &&
            moment(data.settings.onboardingDate).isBefore(moment(toDateSearch))
          )
        }
      })
    }

    if (fromDateSearch && toDateSearch) {
      filteredData = filteredData.filter((data) => {
        if (tabKey === 'device') {
          return moment(data.deviceSettings.installDate).isBetween(
            moment(fromDateSearch),
            moment(toDateSearch),
          )
        } else if (tabKey === 'location') {
          return (
            data.settings.onboardingStatus &&
            moment(data.settings.onboardingDate).isBetween(
              moment(fromDateSearch),
              moment(toDateSearch),
            )
          )
        }
      })
    }
  }

  filteredData =
    sortingConf.order !== ''
      ? sortObjectsByKey([...filteredData], sortingConf.key, sortingConf.order)
      : filteredData

  function resetCurrentPage() {
    setSearchParams((state) => {
      state.set('page', String(1))

      return state
    })
  }

  function getSortingClass(columnKey: string) {
    return sortingConf.key === columnKey && sortingConf.order !== ''
      ? `fas fa-arrow-${sortingConf.order === 'ASC' ? 'down' : 'up'}`
      : ''
  }

  function generateCsvFile() {
    let exportData: any = []

    if (filterByDate && fromDateSearch && toDateSearch) {
      exportData = [
        ['Filtered Results', ''],
        ['Install Date', ''],
        ['from', moment(fromDateSearch).format('DD-MM-YYYY')],
        ['to', moment(toDateSearch).format('DD-MM-YYYY')],
      ]
    }

    const totalDevicesInstalled = [['Total Devices Installed'], [`${devices.length}`], ['']]

    const groupedByAccount: { [accountId: string]: Device[] } = {}

    filteredData.forEach((data) => {
      if (!groupedByAccount[data.accountId]) {
        groupedByAccount[data.accountId] = []
      }
      groupedByAccount[data.accountId].push(data)
    })

    for (const groupAccount in groupedByAccount) {
      const accountDevices = groupedByAccount[groupAccount]
      const accountName = accounts.filter((account) => groupAccount === account.id)[0]?.name

      exportData = [
        ...exportData,
        [' '],
        [' '],
        ['Account Name', ''],
        [
          `${accountName} (${accountDevices.length} ${
            accountDevices.length > 1 ? 'devices' : 'device'
          })`,
          '',
        ],
        [''],
        ['Device', 'ID', 'Sector', 'Cost', 'Type', 'Install Date', 'Monitoring Start Date'],
      ]
      accountDevices.map(
        (device) =>
          (exportData = [
            ...exportData,
            [
              device?.deviceName,
              device.deviceId,
              device?.deviceSettings?.sectorType,
              device.deviceSettings?.cost && device.deviceSettings?.currency
                ? formatCurrency(device.deviceSettings?.cost, device.deviceSettings?.currency)
                : null,
              device.deviceSettings.hot ? 'Hot' : 'Cold',
              moment(device.deviceSettings?.installDate).format('DD-MM-YYYY HH:mm'),
              moment(device.deviceSettings?.monitoringStartDate).format('DD-MM-YYYY HH:mm'),
            ],
          ]),
      )
    }

    const data = [...totalDevicesInstalled, ...exportData].filter((v) => v !== null)

    setCsvData(data)
  }

  let paginatedData: Device[] = []

  if (currentPage && displayPagination) {
    const startIndex = (Number(currentPage) - 1) * itemsPerPage
    const endIndex = startIndex + itemsPerPage

    paginatedData = filteredData.slice(startIndex, endIndex)
  }

  const totalPages = Math.ceil(filteredData.length / itemsPerPage)

  function onColumnHeaderClick(columnKey: string, sortable: boolean = true) {
    if (!sortable) return
    let order = ''
    if (sortingConf.key === columnKey) {
      if (sortingConf.order === '') {
        order = 'ASC'
      } else if (sortingConf.order === 'ASC') {
        order = 'DESC'
      } else {
        order = ''
      }
    } else {
      order = 'ASC'
    }
    setSortingConf({ key: columnKey, order })
  }

  const handleOnRowClick = (rowData: any) => {
    if (onRowClickHandler) {
      onRowClickHandler(rowData)
    }
  }

  const getFilterComponent = (column: any) => {
    switch (column.filter.type) {
      case 'string': {
        const hasValue = searchParams.get(`${column.key}`)

        return (
          <div className="search-bar" style={{ width: '100%' }}>
            <span className="fa fa-search search-icon"></span>
            <input
              type="text"
              className="form-control"
              placeholder="Search..."
              value={searchParams.get(`${column.key}`) || ''}
              onChange={(e) =>
                setSearchParams((state) => {
                  if (e.target.value !== '') {
                    state.set(`${column.key}`, e.target.value)
                  } else {
                    state.delete(`${column.key}`)
                  }

                  resetCurrentPage()

                  return state
                })
              }
            />
            {hasValue && (
              <span
                className="fa fa-times clear-icon"
                onClick={() =>
                  setSearchParams((state) => {
                    state.delete(`${column.key}`)

                    resetCurrentPage()

                    return state
                  })
                }
              ></span>
            )}
          </div>
        )
      }

      case 'select': {
        return (
          <Select
            classNamePrefix="select"
            className="font-weight-normal"
            defaultValue={{ value: '', label: '' }}
            onChange={(e) =>
              setSearchParams((state) => {
                if (e && e.value && e.value !== '') {
                  state.set(`${column.key}`, e.value)
                } else {
                  state.delete(`${column.key}`)
                }
                resetCurrentPage()

                return state
              })
            }
            options={[
              { value: '', label: 'All' },
              ...column.filter.options.map((option: string) => {
                return { value: option, label: option }
              }),
            ]}
            value={{
              value: searchParams.get(`${column.key}`) || '',
              label: searchParams.get(`${column.key}`) || '',
            }}
            isClearable={false}
          />
        )
      }

      case 'date': {
        return (
          <DateRangeComponent
            from={null}
            to={null}
            fromCallback={(from: any) => {
              setSearchParams((state) => {
                if (from) {
                  state.set(`fromDate`, moment(from).format('YYYY-MM-DD'))
                } else {
                  state.delete(`fromDate`)
                }
                resetCurrentPage()
                return state
              })
            }}
            toCallback={(to: any) => {
              setSearchParams((state) => {
                if (to) {
                  state.set(`toDate`, moment(to).format('YYYY-MM-DD'))
                } else {
                  state.delete(`toDate`)
                }
                resetCurrentPage()
                return state
              })
            }}
            label={' '}
            isClearable
          />
        )
      }
    }
  }

  const tableHeaders = visibleColumns.map((column) => (
    <th
      key={typeof column.key === 'string' ? column.key : column.name}
      className={`${column.sortable ? 'clickable' : ''} align-middle`}
      style={{
        minWidth: column.minWidth || '120px',
        width: '20%',
      }}
    >
      <div className="d-flex flex-column">
        <div
          onClick={() =>
            typeof column.key === 'string' ? onColumnHeaderClick(column.key, column.sortable) : {}
          }
        >
          {column.name}

          {typeof column.key === 'string' && <i className={getSortingClass(column.key)}></i>}
        </div>

        {column.filter && getFilterComponent(column)}
      </div>
    </th>
  ))

  function renderBatteryLevel(batteryLevel: number, label: string, vendor: string) {
    const percentage = getBatteryPercentage(batteryLevel, vendor)
    return (
      <>
        <span className={getBatteryIconClass(percentage)} />
        <span className="battery-level">
          {percentage}% ({label})
        </span>
      </>
    )
  }

  function renderDeviceStatus(device: Device) {
    const deviceStatus = getDeviceStatus(
      device,
      hasPermissions(permissions, ['DASHBOARD:STATUS:VIEW']),
    )

    if (deviceStatus === 'Offline' && hasPermissions(permissions, ['ACCOUNT:ADMIN:SMARTFLOW'])) {
      return (
        <>
          Offline
          <small style={{ fontSize: '0.7em' }}>{` (last seen: ${moment(
            device.lastOnline,
          ).fromNow()})`}</small>
        </>
      )
    }

    return deviceStatus
  }

  const checkReportStatus = (report: ReportsByYearMonth) => {
    const currentYear = new Date().getFullYear()
    const currentYearReports = report[currentYear] || {}

    const monthStatusElements: JSX.Element[] = []

    Object.keys(currentYearReports).forEach((month, index) => {
      const monthReports = currentYearReports[Number(month)]
      const allStatusTrue = monthReports.every((report) => report.status === true)
      const monthName = moment()
        .month(Number(month) - 1)
        .format('MMM')
      const className = allStatusTrue ? 'text-success' : 'text-danger'

      monthStatusElements.push(
        <b key={month} className={className}>
          {monthName}
        </b>,
      )

      // Add separator if it's not the last element
      if (index < Object.keys(currentYearReports).length - 1) {
        monthStatusElements.push(<span key={`separator-${month}`}> • </span>)
      }
    })

    if (monthStatusElements.length == 0) {
      return '-'
    }

    return monthStatusElements
  }

  const renderDeviceLastComment = (device: Device) => {
    if (device.lastComment) {
      return (
        <div
          style={{
            maxHeight: '100px',
            overflow: 'auto',
            whiteSpace: 'pre-wrap',
          }}
        >
          {device.lastComment.comment}
        </div>
      )
    }

    return <span>-</span>
  }

  const renderCheckbox = (device: Device, rowKey: string) => {
    const keys = rowKey.split('.')

    let value = device as any
    for (const key of keys) {
      value = value[key]
    }

    if (typeof value !== 'boolean') {
      console.error('Invalid key or non-boolean value')
      return null
    }

    return (
      <div className="d-flex justify-content-center p-2 mt-3">
        <input
          type="checkbox"
          style={{ width: '20px', height: '20px' }}
          onClick={(e) => e.stopPropagation()}
          checked={value}
          onChange={(e) => handleUpdateCheckboxValue(device, rowKey, !value)}
        />
      </div>
    )
  }

  const handleUpdateCheckboxValue = async (
    deviceData: Device,
    rowKey: string,
    newValue: boolean,
  ) => {
    const updatedDevice = { ...deviceData }
    const keys = rowKey.split('.')
    let currentObject: any = updatedDevice

    for (let i = 0; i < keys.length - 1; i++) {
      currentObject = currentObject[keys[i]]
    }

    currentObject[keys[keys.length - 1]] = newValue

    if (deviceData?.dUUID) {
      await updateDeviceData(deviceData?.dUUID, DeviceIDTypes.dUUID, updatedDevice)
    }
  }

  const getLocationProperty = (device: any, key: string) => {
    const deviceLocation = locations.find((location) => location.id == device.id)

    if (deviceLocation && key == 'location.onboardDate') {
      if (deviceLocation.settings.onboardingStatus) {
        return moment(deviceLocation.settings.onboardingDate).format('DD-MM-YYYY')
      }
      return '-'
    }

    if (deviceLocation && key == 'location.lastComment') {
      if (deviceLocation.lastComment) {
        return (
          <div
            style={{
              maxHeight: '100px',
              overflow: 'auto',
              whiteSpace: 'pre-wrap',
            }}
          >
            {deviceLocation.lastComment.comment}
          </div>
        )
      }
    }

    return '-'
  }

  const getAccountProperty = (data: any, key: string) => {
    if (data && key === 'account.lastComment') {
      if (data.lastComment) {
        return (
          <div
            style={{
              maxHeight: '100px',
              overflow: 'auto',
              whiteSpace: 'pre-wrap',
            }}
          >
            {data.lastComment.comment}
          </div>
        )
      }

      return '-'
    }
  }

  const tableRows = (paginatedData.length > 0 ? paginatedData : filteredData).map(
    (device: any, idx) => {
      return (
        <tr key={idx} onClick={() => handleOnRowClick(device)}>
          {visibleColumns.map((row, idxCol) => {
            const deviceAccount = accounts.filter((account) => device.accountId === account.id)[0]
            return (
              <td key={idx + '-' + idxCol}>
                <>
                  <div>
                    {typeof row.key === 'function' ? (
                      row.key(device)
                    ) : row.key.includes('location.') ? (
                      getLocationProperty(device, row.key)
                    ) : row.key.includes('account.') ? (
                      getAccountProperty(device, row.key)
                    ) : row?.filter?.type === 'date' ? (
                      moment(itemFromPath(row.key, device)).format('DD-MM-YYYY')
                    ) : row.key === 'deviceName' ? (
                      <>
                        <SectorIcon
                          sector={device?.deviceSettings?.sectorType}
                          occupants={device?.deviceSettings?.occupants}
                        />{' '}
                        {itemFromPath(row.key, device)}
                        <br />
                        {row.key === 'deviceName' && `id: ${itemFromPath('deviceId', device)}`}
                        {device?.childDeviceIds && device?.childDeviceIds?.length > 0 && (
                          <>
                            <br />
                            <span className="stamp main-meter"> MAIN METER</span>
                          </>
                        )}
                        {device?.deviceSettings?.masterDeviceIdRef && (
                          <>
                            <br />
                            <span className="stamp sub-meter"> SUB METER</span>
                          </>
                        )}
                      </>
                    ) : row.key === 'accountName' ? (
                      deviceAccount?.name || '-'
                    ) : row.key === 'locationCountry' ? (
                      locations.filter((location) => device.deviceLocationId == location.id)[0]
                        ?.country || '-'
                    ) : row.key === 'companyAlias' ? (
                      locations.filter((location) => device.deviceLocationId == location.id)[0]
                        ?.companyAlias || '-'
                    ) : row.key === 'batteryLevel' ? (
                      <div className="battery-container">
                        {device.batteryLevel ? (
                          <>
                            <div className="battery-container-int">
                              {renderBatteryLevel(device.batteryLevel, 'Int', device.deviceVendor)}
                            </div>
                            {device.batteryLevelExt ? (
                              <div className="battery-container-ext">
                                {renderBatteryLevel(
                                  device.batteryLevelExt,
                                  'Ext',
                                  device.deviceVendor,
                                )}
                              </div>
                            ) : null}
                          </>
                        ) : (
                          <div className="battery-container">
                            <FaBan />
                          </div>
                        )}
                      </div>
                    ) : row.key === 'signalStrength' ? (
                      <div className="signal-container">
                        <span>{device.signal ? getSignalStatus(device.signal) : <FaBan />}</span>
                      </div>
                    ) : row.key === 'batteryStatus' ? (
                      <div className="power-container">
                        {device.batteryStatus ? getBatteryStatus(device) : <FaBan />}
                      </div>
                    ) : row.key === 'deviceStatus' ? (
                      device.deviceVendor === 'SMARTFLOW' ||
                      device.deviceVendor === 'METASPHERE' ||
                      device.deviceVendor === 'PLUM' ? (
                        renderDeviceStatus(device)
                      ) : (
                        <FaBan />
                      )
                    ) : row.key === 'deviceSettings.active' ? (
                      device.deviceSettings.active ? (
                        'Active'
                      ) : (
                        'Inactive'
                      )
                    ) : row.key === 'reports.exSummaryReport' ? (
                      checkReportStatus(itemFromPath(row.key, device))
                    ) : row.key === 'lastComment' ? (
                      renderDeviceLastComment(device)
                    ) : row.key === 'deviceSettings.report30Day' ||
                      row.key === 'deviceSettings.esgReport' ||
                      row.key === 'deviceSettings.sustainabilityReport' ||
                      row.key === 'deviceSettings.setUpPayment' ||
                      row.key === 'deviceSettings.renewalOverdueStatus' ? (
                      renderCheckbox(device, row.key)
                    ) : (
                      itemFromPath(row.key, device)
                    )}
                  </div>
                </>
              </td>
            )
          })}
        </tr>
      )
    },
  )

  const handleCheckboxChange = (columnName: string) => {
    const updatedColumns = columnsArr.map((column) =>
      column.name === columnName ? { ...column, checked: !column.checked } : column,
    )

    setColumnsArr(updatedColumns)

    setVisibleColumns(updatedColumns.filter((column) => column.checked))
  }

  return (
    <div className="custom-search-table">
      {exportToCSV && (
        <div className=" d-flex justify-content-end w-100 mb-3">
          <CSVLink
            data={csvData}
            onClick={generateCsvFile}
            className="btn btn-primary"
            filename={`filteredDevices_${moment().format('DD-MM-YYYY_HH:mm')}.csv`}
          >
            Generate CSV Report
          </CSVLink>
        </div>
      )}

      {allowColumnPicker && (
        <div className="mt-4 mb-4">
          <div
            onClick={() => setShowColumnPickerOptions(!showColumnPickerOptions)}
            style={{ cursor: 'pointer', width: '10%' }}
          >
            <span>Column Picker</span>
            {showColumnPickerOptions ? <ChevronUp /> : <ChevronDown />}
          </div>
          {showColumnPickerOptions && (
            <div className="container mt-2 border p-2 ml-0">
              <div className="row">
                {columnsArr.map((column) => (
                  <div key={column.name} className="col-8 col-md-4 col-lg-3 mb-2">
                    <div>
                      <input
                        type="checkbox"
                        id={column.name}
                        checked={column.checked}
                        disabled={column.isFixed}
                        onChange={() => handleCheckboxChange(column.name)}
                      />
                      <label htmlFor={column.name} className="ml-1">
                        {column.name}
                      </label>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      )}

      <h4>Total results {filteredData.length}</h4>

      <Table responsive>
        <thead>
          <tr>{tableHeaders}</tr>
        </thead>
        <tbody style={{ height: '300px' }}>
          {tableRows.length > 0 ? (
            tableRows
          ) : (
            <tr>
              <td colSpan={tableHeaders.length} className="text-center align-middle">
                no results found
              </td>
            </tr>
          )}
        </tbody>
      </Table>

      {displayPagination && paginatedData.length > 0 && tableRows.length > 0 && (
        <Pagination
          pages={totalPages}
          itemsPerPage={itemsPerPage}
          items={filteredData.length}
          page={currentPage}
        />
      )}
    </div>
  )
}

export default CustomSearchTable
