import React, { useState } from 'react'
import { Form, Col, Button, Spinner, Row, InputGroup } from 'react-bootstrap'
import { EyeIcon, EyeOffIcon } from 'lucide-react'
import { Link } from 'react-router-dom'
import { validateEmailStructure } from '@common/utils/helperFunctions'
import { useAuthDispatch, useAuthState } from '@context/auth/context/auth.context'

import './LoginForm.scss'

const Login: React.FC = () => {
  const { login } = useAuthDispatch()
  const { loggingIn, error } = useAuthState()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [isEmailValid, setIsEmailValid] = useState<boolean>(true)
  const [isPasswordValid, setIsPasswordValid] = useState<boolean>(true)
  const [isPasswordShown, setIsPasswordShown] = useState<boolean>(false)

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    if (email) {
      const isThisEmailValid = validateEmailStructure(email)
      setIsEmailValid(isThisEmailValid)
      if (isThisEmailValid) {
        if (password) {
          setIsPasswordValid(true)
          login(email, password)
        }
      }
    }
  }

  return (
    <div className="background">
      <div className="login-form">
        <img src="/assets/img/logo.svg" alt="Smartflow Logo" width="200" />
        <h2 className="mt-3 mb-3">Welcome</h2>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Form.Group as={Col} controlid="email-id" className="form-group">
              <Form.Label>Email Address</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter email"
                onChange={(e) => setEmail(e.target.value.trim())}
                isInvalid={!isEmailValid}
              />
              <Form.Control.Feedback type="invalid">Incorrect email format</Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row>
            <Form.Label>Password</Form.Label>
            <InputGroup as={Col} controlid="password-id" className="form-group">
              <Form.Control
                type={isPasswordShown ? 'text' : 'password'}
                placeholder="Enter password"
                onChange={(e) => setPassword(e.target.value)}
                isInvalid={!isPasswordValid}
              />
              <InputGroup.Text
                role="button"
                className="rounded-right"
                onClick={() => setIsPasswordShown(!isPasswordShown)}
              >
                {isPasswordShown ? <EyeIcon /> : <EyeOffIcon />}
              </InputGroup.Text>
              <Form.Control.Feedback type="invalid">Password too short!</Form.Control.Feedback>
            </InputGroup>
          </Row>
          <Button
            variant="primary"
            disabled={!email || !password}
            type="submit"
            className="d-flex justify-content-center align-items-center gap-3"
          >
            {loggingIn && !error ? (
              <>
                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                <span>Logging In...</span>
              </>
            ) : (
              <>Login</>
            )}
          </Button>
        </Form>
        <div className="forgot-password-link">
          <Link to="/forgot-password" className="text-decoration-none">
            Forgot your password?
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Login
