import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import backend from '@api/backend'
import {
  ReportsTrackingAPIResponse,
  ReportsTracking,
  ReportDataMutation,
} from './model/reportsTracking.model'
import Account from '@context/account/model/Account'
import { displayToast } from '@common/utils/appToast'

export const useReportsTracking = () => {
  return useQuery({
    queryKey: ['reports-traking'],
    queryFn: () => getReportsTracking(),
    enabled: true,
    staleTime: 1000 * 60 * 15, // 15 minutes,
  })
}

export const useAssociateReportsWithAccounts = (
  accounts: Account[],
  reports: ReportsTracking[] | undefined,
) => {
  return useQuery({
    queryKey: ['associate-reports-accounts', accounts, reports],
    queryFn: () => associateReportsAndDevices(accounts, reports),
    enabled: !!accounts && !!reports,
    staleTime: 1000 * 60 * 15, // 15 minutes,
  })
}

export function handleReportUpdateReactQuery() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: updateReport,

    onSuccess: (newReports: ReportsTrackingAPIResponse[]) => {
      queryClient.setQueryData(['reports-traking'], (oldReports: ReportsTracking[]) => {
        const mergedReports = oldReports.map((oldReport) => {
          const matchingNewReport = newReports.find(
            (newReport) =>
              newReport.device_id_ref === oldReport.deviceIdRef && newReport.id === oldReport.id,
          )

          if (matchingNewReport) {
            // If the status is different, replace the old report with the new one
            if (matchingNewReport.status !== oldReport.status) {
              return { ...oldReport, ...matchingNewReport }
            }
          }
          return oldReport // Return old report if no match or if status is the same
        })

        return mergedReports
      })

      displayToast({
        type: 'success',
        message: 'Reports updated successfully',
      })

      queryClient.invalidateQueries({ queryKey: ['associate-reports-accounts'] })
    },
  })
}

async function updateReport({ accountId, data }: ReportDataMutation) {
  const response = await backend.patch(`/reports/account/${accountId}/tracking`, data)

  return response.data
}

async function associateReportsAndDevices(
  accounts: Account[],
  reports: ReportsTracking[] | undefined,
) {
  if (accounts && reports) {
    const reportsMap = reports.reduce(
      (acc, report) => {
        if (!acc[report.accountId]) {
          acc[report.accountId] = {}
        }
        if (!acc[report.accountId][report.reportName]) {
          acc[report.accountId][report.reportName] = {}
        }
        if (!acc[report.accountId][report.reportName][report.year]) {
          acc[report.accountId][report.reportName][report.year] = {}
        }
        if (!acc[report.accountId][report.reportName][report.year][report.month]) {
          acc[report.accountId][report.reportName][report.year][report.month] = []
        }
        acc[report.accountId][report.reportName][report.year][report.month].push(report)
        return acc
      },
      {} as {
        [key: number]: {
          [key: string]: { [year: number]: { [month: number]: ReportsTracking[] } }
        }
      },
    )

    // Combine accounts with their corresponding reports
    return accounts.map((account) => {
      return {
        ...account,
        reports: account.id ? reportsMap[Number(account.id)] : {},
      }
    })
  } else {
    return []
  }
}

async function getReportsTracking() {
  const response = await backend.get('reports/tracking')

  const reports: ReportsTracking[] = response.data.map((report: ReportsTrackingAPIResponse) => {
    return {
      year: report.year,
      month: report.month,
      reportName: report.report_name.replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase()),
      status: report.status,
      deviceIdRef: report.device_id_ref,
      date: report.date,
      id: report.id,
      dUUID: report.d_uuid,
      accountId: report.account_id_ref,
    }
  })

  return reports
}
