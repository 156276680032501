import { useEffect, useState } from 'react'
import { Doughnut } from 'react-chartjs-2'

import { useDeviceState } from '@context/device/context/device.context'
import { HistoricalAlert, StatsDevices } from '@context/alert/model/alert.model'

const HistoricalStats = ({
  historical,
  totalChartRef,
  top5ChartRef,
}: {
  historical: HistoricalAlert[]
  totalChartRef: React.RefObject<HTMLDivElement>
  top5ChartRef: React.RefObject<HTMLDivElement>
}) => {
  const { devices } = useDeviceState()
  const [historicalStats, setHistoricalStats] = useState<StatsDevices>()

  useEffect(() => {
    const stats = historical.reduce(
      (s, h) => {
        const device = devices.find((d) => d.deviceId === h.deviceId)
        if (!device) return s

        return {
          tier1Total: s.tier1Total + h.tier1.length,
          tier2Total: s.tier2Total + h.tier2.length,
          tier3Total: s.tier3Total + h.tier3.length,
          totalAll: s.totalAll + h.tier1.length + h.tier2.length + h.tier3.length,
          devices: [
            ...s.devices,
            {
              deviceId: device.deviceId,
              deviceName: device.deviceName,
              tier1: h.tier1.length,
              tier2: h.tier1.length,
              tier3: h.tier1.length,
              total: h.tier1.length + h.tier2.length + h.tier3.length,
            },
          ],
        }
      },
      { tier1Total: 0, tier2Total: 0, tier3Total: 0, totalAll: 0, devices: [] } as StatsDevices,
    )

    setHistoricalStats(stats)
  }, [historical, devices])

  return historicalStats ? (
    <div className="container mt-5 mb-5">
      <div className="row">
        <div className="col mb-3" ref={totalChartRef}>
          <Doughnut
            id="totalDoughnut"
            options={{
              responsive: true,
              aspectRatio: 2,
              plugins: {
                legend: {
                  display: true,
                  position: 'bottom' as const,
                },
              },
            }}
            data={{
              labels: ['Tier 1', 'Tier 2', 'Tier 3'],
              datasets: [
                {
                  label: '# total',
                  data: [
                    historicalStats.tier1Total,
                    historicalStats.tier2Total,
                    historicalStats.tier3Total,
                  ],
                  backgroundColor: ['rgb(255, 99, 132)', 'rgb(255, 205, 86)', 'rgb(54, 162, 235)'],
                  hoverOffset: 4,
                },
              ],
            }}
            plugins={[
              {
                id: 'totalDoughnut',
                afterDraw(chart) {
                  const width = chart.chartArea.width
                  const height = chart.chartArea.height
                  const ctx = chart.ctx
                  const data = chart.data

                  ctx.restore()
                  ctx.font = 1 + 'em sans-serif'
                  ctx.textBaseline = 'middle'

                  const sum = data.datasets.reduce((acc: number, current: any) => {
                    return (
                      acc +
                      current.data.reduce(
                        (subTotal: number, currentValue: number) => subTotal + currentValue,
                        0,
                      )
                    )
                  }, 0)

                  const text = `Total #`
                  const textX = Math.round((width - ctx.measureText(text).width) / 2)
                  const textY = height / 2

                  ctx.fillText(text, textX, textY)
                  ctx.fillText(String(sum), textX + 5, textY + 18)
                  ctx.save()
                },
              },
            ]}
          />
        </div>
        <div className="col" ref={top5ChartRef}>
          <Doughnut
            id="top5Doughnut"
            options={{
              responsive: true,
              aspectRatio: 2,
              plugins: {
                legend: {
                  display: true,
                  position: 'bottom' as const,
                },
              },
            }}
            data={{
              labels: historicalStats.devices
                .sort((a, b) => b.total - a.total)
                .slice(0, 5)
                .reverse()
                .map((d) => `${d.deviceName}`),
              datasets: [
                {
                  label: 'devices',
                  data: historicalStats.devices
                    .sort((a, b) => b.total - a.total)
                    .slice(0, 5)
                    .reverse()
                    .map((d) => d.total),
                  backgroundColor: [
                    'rgb(255, 99, 132)',
                    'rgb(54, 162, 235)',
                    'rgb(255, 206, 86)',
                    'rgb(75, 192, 192)',
                    'rgb(153, 102, 255)',
                  ],
                  hoverOffset: 4,
                },
              ],
            }}
            plugins={[
              {
                id: 'top5Doughnut',
                afterDraw(chart) {
                  const width = chart.chartArea.width
                  const height = chart.chartArea.height
                  const ctx = chart.ctx

                  ctx.restore()
                  ctx.font = '0.5 em sans-serif'
                  ctx.textBaseline = 'middle'

                  const text = 'Top 5'
                  const textX = Math.round((width - ctx.measureText(text).width) / 2)
                  const textY = height / 2

                  ctx.fillText(text, textX, textY)
                  ctx.save()
                },
              },
            ]}
          />
        </div>
      </div>
    </div>
  ) : null
}

export default HistoricalStats
