import { useSearchParams } from 'react-router-dom'
import { Button } from 'react-bootstrap'
import { ChevronLeft, ChevronRight, ChevronsLeft, ChevronsRight } from 'lucide-react'
import { useEffect, useState } from 'react'

interface PaginationProps {
  pages: number
  itemsPerPage: number
  items: number
  page: number
}

export function Pagination({ items, itemsPerPage, page, pages }: PaginationProps) {
  const [_, setSearchParams] = useSearchParams()

  const [currentPage] = useState(1)

  useEffect(() => {
    setSearchParams((state) => {
      state.set('page', String(currentPage))

      return state
    })
  }, [currentPage])

  function firstPage() {
    setSearchParams((params) => {
      params.set('page', '1')

      return params
    })
  }

  function previousPage() {
    if (page - 1 <= 0) {
      return
    }

    setSearchParams((params) => {
      params.set('page', String(page - 1))

      return params
    })
  }

  function nextPage() {
    if (page + 1 > pages) {
      return
    }

    setSearchParams((params) => {
      params.set('page', String(page + 1))

      return params
    })
  }

  function lastPage() {
    setSearchParams((params) => {
      params.set('page', String(pages))

      return params
    })
  }
  return (
    <div className="d-flex flex-column flex-md-row align-items-center justify-content-between mt-5 mb-5">
      <span className="mb-2 mb-md-0">
        Showing {itemsPerPage > items ? items : itemsPerPage} of {items} items
      </span>
      <div className="d-flex gap-2">
        <span className="d-flex align-items-center mb-2 mb-md-0">
          Page {page} of {pages}
        </span>

        <div className="d-flex gap-1">
          <Button
            variant="outline-secondary"
            size="sm"
            onClick={firstPage}
            disabled={page - 1 <= 0}
          >
            <ChevronsLeft className="size-4" />
          </Button>
          <Button
            variant="outline-secondary"
            size="sm"
            onClick={previousPage}
            disabled={page - 1 <= 0}
          >
            <ChevronLeft className="size-4" />
          </Button>
          <Button
            variant="outline-secondary"
            size="sm"
            onClick={nextPage}
            disabled={page + 1 > pages}
          >
            <ChevronRight className="size-4" />
          </Button>
          <Button
            variant="outline-secondary"
            size="sm"
            onClick={lastPage}
            disabled={page + 1 > pages}
          >
            <ChevronsRight className="size-4" />
          </Button>
        </div>
      </div>
    </div>
  )
}
