import Popover from "react-bootstrap/Popover";
import React from "react";

export const popoverActive = (
    <Popover
        id="popover-trigger-hover-focus"
        title="Location Active"
    >
        Set the state of the location. Inactive locations and all associated devices will not be visible
        to the client
    </Popover>
);

export const popoverSuspended = (
    <Popover
        id="popover-trigger-hover-focus"
        title="Location Suspended"
    >
        Set the suspended state of the location. Suspended locations and all associated devices will not be visible
        to the client
    </Popover>
);