import React, { useEffect, useRef, useState } from 'react'
import Select from 'react-select'
import DatePicker from 'react-datepicker'
import { Bar } from 'react-chartjs-2'
import { Button } from 'react-bootstrap'
import Device from '@context/device/model/device'
import Account from '@context/account/model/Account'
import { useAccountState } from '@context/account/context/account.context'
import { useDevicesWaterUsage } from '@data/waterUsage/waterUsage'
import { IInvoiceProps, IInvoiceTotalProps, ITenantProps } from './interfaces'
import { buildPDFInvoice } from './helper-functions'
import { Chart as ChartJS } from 'chart.js'
import { getDailyDatasets, getChartOptions } from '../../WaterUsage/helper-functions'
import { convertToUnitOfVolume, roundToTwo } from '@common/utils/helperFunctions'
import { formatCurrency } from '@common/utils/helperFunctions'
import jsPDF from 'jspdf'
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import { UOM_OPTIONS } from '../../DeviceManagement/DeviceInformationManager/constants'
import { useUserState } from '@context/user/context/user.context'

import moment, { Moment } from 'moment'

import 'react-datepicker/dist/react-datepicker.css'
import 'react-datepicker/dist/react-datepicker-cssmodules.css'
import './Invoice.scss'

const TODAY: Moment = moment()

const Invoice: React.FC<IInvoiceProps> = ({ devices }: IInvoiceProps) => {
  const { accounts } = useAccountState()
  const { userInfo } = useUserState()

  const [deviceSelected, setDeviceSelected] = useState<Device | null>(null)
  const [didSomethingChange, setDidSomethingChange] = useState(true)
  const [fileName, setFileName] = useState('water_supply_bill')
  const [fromDate, setFromDate] = useState<Date>(TODAY.clone().subtract(30, 'days').toDate())
  const [toDate, setToDate] = useState<Date>(TODAY.clone().toDate())
  const [dailyDatasets, setDailyDatasets] = useState<any>()
  const [deviceCost, setDeviceCost] = useState<number>(1)
  const [displayTermsAndConditions, setDisplayTermsAndConditions] = useState<boolean>(false)
  const [termsAndConditions, setTermsAndConditions] = useState<string>('Payment is due in 15 days.')

  const [tenantsData, setTenantsData] = useState<ITenantProps[]>([])
  const [selectedUom, setSelectedUom] = useState(userInfo.preferences?.uom || 'Liters')

  const [enableFetchData, setEnableFetchData] = useState<boolean>(false)

  const [displayInvoice, setDisplayInvoice] = useState(false)
  const [totalUsage, setTotalUsage] = useState<null | number>(null)
  const [totalUsageVolume, setTotalUsageVolume] = useState<null | string>(null)

  const chartRef = useRef<ChartJS<'bar', number[], string>>(null)

  const deviceAccount = accounts.filter((acc) => acc.id === deviceSelected?.accountId)

  const from = moment(fromDate).format('YYYY-MM-DDT00:00:00')
  const to = moment(toDate).format('YYYY-MM-DDT23:59:59')
  const queryPeriod = {
    deviceIds: [deviceSelected?.deviceId!],
    from: from,
    to: to,
    uom: selectedUom,
  }

  const {
    data: selectedDeviceUsage,
    isLoading,
    isFetching,
    refetch,
  } = useDevicesWaterUsage(queryPeriod, enableFetchData)

  useEffect(() => {
    if (selectedDeviceUsage) {
      const dailyDatasets_local = getDailyDatasets(selectedDeviceUsage?.usage[0])
      setDailyDatasets(dailyDatasets_local)
      if (selectedDeviceUsage?.totalUsage) {
        const totalUsageConverted = convertToUnitOfVolume(
          selectedUom,
          selectedDeviceUsage?.totalUsage,
        )
        setTotalUsage(roundToTwo(selectedDeviceUsage?.totalUsage / 1000))
        setTotalUsageVolume(totalUsageConverted)
        setDisplayInvoice(true)
      } else {
        setTotalUsage(null)
      }
    }
  }, [selectedDeviceUsage, selectedUom])

  useEffect(() => {
    setDidSomethingChange(true)
  }, [fromDate, toDate])

  useEffect(() => {
    setDidSomethingChange(true)
  }, [termsAndConditions])

  useEffect(() => {
    setDidSomethingChange(true)

    if (deviceSelected && deviceSelected?.deviceSettings.cost) {
      setDeviceCost(deviceSelected?.deviceSettings.cost)
    }
  }, [deviceSelected])

  const handleDeviceSelection = (selectedOption: Device) => {
    if (selectedOption && Object.keys(selectedOption).length > 0) {
      const currentSelectedDevice = deviceSelected
      const from = moment(fromDate).format('DD-MM-YYYY')
      const to = moment(toDate).format('DD-MM-YYYY')
      setDeviceSelected(selectedOption)
      setFileName(`${selectedOption.deviceName}-${from}_${to}_water_supply_bill`)
      if (!currentSelectedDevice || selectedOption.deviceId !== currentSelectedDevice.deviceId) {
        setDidSomethingChange(true)
      }
    }
  }

  const handleFromRangeChange = (date: Date | null) => {
    if (date && date.getTime() !== fromDate.getTime()) {
      setFromDate(date)
      setDidSomethingChange(true)
    }
  }

  const handleToRangeChange = (date: Date | null) => {
    if (date && date.getTime() !== toDate.getTime()) {
      setToDate(date)
      setDidSomethingChange(true)
    }
  }

  const generateTenantFields = () => {
    let index = 0
    const html = []

    if (tenantsData.length > 0) {
      do {
        html.push(
          <div key={index}>
            <div className="row tenant d-flex">
              <div className="section-header row">
                <div className="col-sm">Active Tenant:</div>

                <div className="col-sm">
                  <OverlayTrigger
                    delay={{ hide: 450, show: 300 }}
                    overlay={(props: any) => <Tooltip {...props}>Remove tentant</Tooltip>}
                    placement="bottom"
                  >
                    <i
                      className="fas fa-times"
                      id={String(index)}
                      style={{ color: 'red', cursor: 'pointer' }}
                      onClick={(e: any) => deleteTenant(Number(e.target.id))}
                    ></i>
                  </OverlayTrigger>
                </div>
              </div>

              <div className="col-md-6 pl-0">
                <input
                  type="text"
                  id={String(index)}
                  className="form-control mb-3"
                  placeholder="Name"
                  maxLength={50}
                  value={tenantsData[index]?.name}
                  onChange={(e) => onChangeTenantInfo(e.target.id, 'name', e.target.value)}
                />
              </div>
            </div>
          </div>,
        )
        index++
      } while (index < tenantsData.length)

      return html
    }
  }

  const addNewTenantField = () => {
    const newTenant = { name: '' }

    setTenantsData([...tenantsData, newTenant])
    setDidSomethingChange(true)
  }

  const onChangeTenantInfo = (id: string, filed: string, newValue: string) => {
    const index = Number(id)

    const newTenantsArray = tenantsData.map((obj, i) =>
      i === index ? { ...obj, [filed]: newValue } : obj,
    )

    setTenantsData(newTenantsArray)
    setDidSomethingChange(true)
  }

  const deleteTenant = (index: number) => {
    const newArray = tenantsData.filter((_, i) => i !== index)

    setTenantsData(newArray)
    setDidSomethingChange(true)
  }

  const handleUomChange = (uom: string) => {
    setSelectedUom(uom)
    setDidSomethingChange(true)
  }

  const generateInvoice = () => {
    if (deviceSelected) {
      setEnableFetchData(true)
      refetch()
      // setDisplayInvoice(true)
      setDidSomethingChange(false)
    }
  }

  const downloadPdfInvoice = () => {
    if (deviceSelected) {
      if (!chartRef) return
      if (!chartRef.current) return

      const chartImage = chartRef.current.toBase64Image()

      buildPDFInvoice(
        deviceSelected as Device,
        deviceAccount[0] as Account,
        fromDate,
        toDate,
        tenantsData,
        chartImage,
        reportTotals,
        displayTermsAndConditions,
        termsAndConditions,
        totalUsageVolume,
      ).then((pdf: jsPDF) => {
        pdf.save(fileName)
      })
    }
  }

  // const deviceAddress = [
  //   deviceSelected?.deviceInfo.addressLine1,
  //   deviceSelected?.deviceInfo.addressLine2,
  //   deviceSelected?.deviceInfo.town,
  //   deviceSelected?.deviceInfo.postcode,
  //   deviceSelected?.deviceInfo.county,
  // ]

  const accountAddress = [
    deviceAccount[0]?.addressLine1.replaceAll('\r\n', ' '),
    deviceAccount[0]?.addressLine2,
    deviceAccount[0]?.town,
    deviceAccount[0]?.postcode,
    deviceAccount[0]?.county,
  ]

  const reportTotals: IInvoiceTotalProps = {
    usageTotal: totalUsage ? totalUsage : null,
    deviceCost,
    costTotal: totalUsage ? roundToTwo(totalUsage * deviceCost) : null,
    currency: deviceSelected?.deviceSettings.currency || null,
  }

  return (
    <div className="invoice">
      <h1>Water Supply Bill</h1>

      <div className="main-content">
        <div className="row">
          <div className="section-header">
            <div className="">Device Location:</div>
          </div>
          <div className="col-md-6">
            <div className="multiselect-container">
              <Select
                classNamePrefix="select"
                className="basic-multi-select"
                value={deviceSelected}
                onChange={(device: any) => handleDeviceSelection(device)}
                options={devices}
                name="device"
                getOptionValue={(option) => option.deviceId}
                getOptionLabel={(option) => `${option.deviceName}`}
                isClearable={false}
                isLoading={isLoading || devices.length === 0}
                isDisabled={isLoading || devices.length === 0}
              />
            </div>
          </div>
        </div>
        <div className="row range">
          <div className="section-header">
            <div className="">Range:</div>
          </div>
          <div className="col-md-3 date-picker">
            <span>From: </span>
            <DatePicker
              selected={fromDate}
              onChange={handleFromRangeChange}
              maxDate={moment(toDate).clone().subtract(1, 'days').toDate()}
              placeholderText="Select a date after 5 days ago"
              dateFormat="dd/MM/yyyy"
            />
          </div>
          <div className="col-md-3 date-picker">
            <span>To: </span>
            <DatePicker
              selected={toDate}
              onChange={handleToRangeChange}
              maxDate={TODAY.toDate()}
              placeholderText="Select a date after 5 days ago"
              dateFormat="dd/MM/yyyy"
            />
          </div>
        </div>
        <div className="row w-25">
          <div className="section-header">
            <div className="">Unit of measure</div>
          </div>
          <Select
            classNamePrefix="select"
            value={{
              value: selectedUom,
              label: selectedUom,
            }}
            onChange={(e: any) => handleUomChange(e.value)}
            options={UOM_OPTIONS.map((uom) => {
              return {
                value: uom,
                label:
                  uom === deviceSelected?.deviceSettings?.uom
                    ? `${uom} (device default)`
                    : uom === userInfo.preferences?.uom
                      ? `${uom} (your set preference)`
                      : uom,
              }
            })}
            getOptionValue={(option) => option.value}
            getOptionLabel={(option) => `${option.label}`}
            isClearable={false}
          />
        </div>

        {generateTenantFields()}

        {tenantsData.length === 0 && (
          <div className="add-tenant-button mb-2 mt-2">
            <Button variant="primary" onClick={addNewTenantField} disabled={!deviceSelected}>
              Add Tenant
            </Button>
          </div>
        )}

        {!displayTermsAndConditions && (
          <div className="add-tenant-button mb-2">
            <Button
              variant="primary"
              onClick={() => setDisplayTermsAndConditions(true)}
              disabled={!deviceSelected}
            >
              Add Terms & Conditions
            </Button>
          </div>
        )}

        {displayTermsAndConditions && (
          <>
            <label htmlFor="terms&conditions">Terms & Conditions</label>
            <textarea
              className="w-25 p-3 text-break"
              name="terms&conditions"
              id="terms&conditions"
              cols={1}
              rows={5}
              value={termsAndConditions}
              onChange={(e) => setTermsAndConditions(e.target.value)}
            ></textarea>
          </>
        )}

        <div className="get-data-button mt-3">
          <Button
            variant="primary"
            onClick={generateInvoice}
            disabled={!deviceSelected || !didSomethingChange}
          >
            Generate Invoice
          </Button>
        </div>

        {displayInvoice && !didSomethingChange && !isLoading && !isFetching && (
          <>
            <div className="get-data-button mt-3">
              <Button variant="primary" onClick={downloadPdfInvoice} disabled={!deviceSelected}>
                Donwload Invoice PDF
              </Button>
            </div>
            <div className="invoice-container mt-5 border border-dark ">
              <div className="invoice-header d-flex flex-row mr-4 ml-4 mt-4 pb-3 justify-content-sm-between border-bottom border-dark">
                <div>
                  <h3>Water Supply Bill</h3>
                </div>
                <div className=" align-items-end">
                  <h5>
                    <strong>{deviceAccount[0]?.name}</strong>
                  </h5>
                  <small>
                    {accountAddress.map((address, index) => (
                      <div key={index}>{address}</div>
                    ))}
                  </small>
                </div>
              </div>
              <div className="d-flex flex-row mt-2 pl-5 pr-5 justify-content-sm-between">
                <small className="d-flex flex-column">
                  <strong>Device Location:</strong>
                  <small> {deviceSelected?.deviceName}</small>
                  {/*<small>*/}
                  {/*  {deviceAddress.map((address, index) => (*/}
                  {/*    <div key={index}>{address}</div>*/}
                  {/*  ))}*/}
                  {/*</small>*/}
                </small>

                {tenantsData[0]?.name.length > 0 && (
                  <small className="d-flex flex-column">
                    <strong>Invoice issued for:</strong>
                    {tenantsData.map((tenant, index) => {
                      return (
                        <div key={index} className="tenants-list d-flex flex-column">
                          <small>{tenant.name}</small>
                        </div>
                      )
                    })}
                  </small>
                )}
              </div>

              <div className="d-flex flex-column align-items-center mt-5 mb-5">
                <div>Billing Period</div>
                {`${moment(fromDate).format('MMMM Do YYYY')} to ${moment(toDate).format(
                  'MMMM Do YYYY',
                )}`}
              </div>

              <div className="d-flex flex-row mb-3">
                <div className="data-visualizations">
                  <Bar
                    width={2500}
                    height={600}
                    data={dailyDatasets}
                    ref={chartRef}
                    options={getChartOptions('daily', selectedUom)}
                  />
                </div>
                <div className="d-flex flex-column justify-content-center align-items-end ml-3">
                  <div>
                    {`Total Spend: ${
                      reportTotals.costTotal && reportTotals?.currency
                        ? formatCurrency(reportTotals.costTotal, reportTotals.currency)
                        : 'N/A'
                    }`}
                  </div>
                  <div>{`Total Water Consumed: ${totalUsageVolume}`}</div>
                  <div>
                    {`Cost per ${totalUsageVolume?.split(' ')[1]}: ${
                      reportTotals.deviceCost && reportTotals?.currency
                        ? formatCurrency(
                            roundToTwo(Number(reportTotals.deviceCost)),
                            reportTotals.currency,
                          )
                        : 'N/A'
                    }`}
                  </div>
                  <strong className="mt-3 h5 border-bottom border-dark">
                    {`Total Due: ${
                      reportTotals.costTotal && reportTotals?.currency
                        ? formatCurrency(reportTotals.costTotal, reportTotals.currency)
                        : 'N/A'
                    }`}
                  </strong>
                </div>
              </div>

              {displayTermsAndConditions && termsAndConditions.length > 0 && (
                <div
                  className="mt-5 pl-3 mb-5"
                  style={{ whiteSpace: 'pre-line', fontSize: '.85rem' }}
                >
                  <span className="h5 font-weight-bold">Terms & Conditions</span>
                  <br />
                  <br />
                  {termsAndConditions}
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default Invoice
