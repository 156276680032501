import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import backend from '@api/backend'
import {
  IComments,
  ICommentsRaw,
  NewLocationCommentData,
  UpdateLocationComment,
} from '../model/comments.model'
import { displayToast } from '@common/utils/appToast'

export const useLocationComments = (locationId: string | undefined) => {
  const query = useQuery({
    queryKey: ['location-comments', locationId],
    queryFn: () => getLocationComments(locationId),
    enabled: true,
    staleTime: 1000 * 60 * 15, // 15 minutes,
  })

  return query
}

export const useLocationCommentsCreation = () => {
  const queryClient = useQueryClient()

  const mutate = useMutation({
    mutationFn: postLocationComment,
    onSuccess: (data: ICommentsRaw, { location_id }) => {
      const newComment = {
        id: data.id,
        comment: data.comment,
        createdAt: data.date,
        updatedAt: data.valid_from,
        author: data.user_name,
        department: data.department,
        category: data.category,
        history: [],
      }

      const updatedComments = queryClient.setQueryData(
        ['location-comments', location_id],
        (old: IComments[]) => {
          return [newComment, ...old]
        },
      )

      displayToast({
        type: 'success',
        message: 'Comment created successfully',
      })

      return updatedComments
    },
    onError: () => {
      displayToast({
        type: 'error',
        message: 'Something went wrong',
      })
    },
  })

  return mutate
}

export async function getLocationComments(locationId: string | undefined) {
  const response = await backend.get(`/customer_locations/${locationId}/comments`, {
    params: { history: true },
  })

  response.data.sort((a: ICommentsRaw, b: ICommentsRaw) => b.id - a.id)

  const locationComments: IComments[] = response.data.map((comment: ICommentsRaw) => {
    return {
      id: comment.id,
      comment: comment.comment,
      createdAt: comment.date,
      updatedAt: comment.valid_from,
      author: comment.user_name,
      department: comment.department,
      category: comment.category,
      history: comment.history?.map((history: any) => {
        return {
          id: history.id,
          comment: history.comment,
          author: history.user_name,
          createdAt: history.valid_from,
        }
      }),
    }
  })

  return locationComments
}

export const useLocationCommentsUpdate = () => {
  const queryClient = useQueryClient()

  const mutate = useMutation({
    mutationFn: updateLocationComment,
    onSuccess: (data: ICommentsRaw, { location_id }) => {
      const updatedComment = {
        id: data.id,
        comment: data.comment,
        createdAt: data.date,
        updatedAt: data.valid_from,
        author: data.user_name,
        department: data.department,
        category: data.category,
        history: data.history?.map((h) => ({
          comment: h.comment,
          author: h.user_name,
          createdAt: h.valid_from,
          updatedAt: data.valid_to,
        })),
      }

      const updatedComments = queryClient.setQueryData(
        ['location-comments', location_id],
        (oldComments: IComments[]) => {
          return oldComments.map((comment: IComments) =>
            comment.id === updatedComment.id ? updatedComment : comment,
          )
        },
      )

      displayToast({
        type: 'success',
        message: 'Comment updated successfully',
      })

      return updatedComments
    },
    onError: () => {
      displayToast({
        type: 'error',
        message: 'Something went wrong',
      })
    },
  })

  return mutate
}

async function postLocationComment(data: NewLocationCommentData) {
  const response = await backend.post(`customer_locations/${data.location_id}/comments`, data)

  return response.data
}

async function updateLocationComment(data: UpdateLocationComment) {
  const response = await backend.patch(`customer_locations/${data.location_id}/comments`, data)

  return response.data
}
