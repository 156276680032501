import backend from '@api/backend'
import { ActionTypes } from '../customer-manager.reducer'
import { UserAccountAssoc } from '../../user/model/user'

export const updateUserAccountAction = (id: string, accountUpdate: UserAccountAssoc[]) => {
  return async (dispatch: any) => {
    dispatch({
      type: ActionTypes.USER_UPDATING,
    })
    try {
      const response = await backend.patch(
        `/users/${id}/accounts`,
        accountUpdate.map((a) => ({
          account_id: a.accountId,
          auto_add_locations: a.autoAddLocations,
          admin: a.admin,
          auto_add_alerts: a.autoAddAlerts,
          invoice: a.invoice,
          account_contact: a.accountContact
        })),
      )
      dispatch({
        type: ActionTypes.USER_UPDATED,
        payload: { updatedUser: response.data },
      })
    } catch (error: any) {
      dispatch({
        type: ActionTypes.ERROR,
        payload: { errorMsg: error.response.data },
      })
    }
  }
}
