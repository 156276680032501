import React, { useEffect } from 'react'
import { useAccountState } from '../../account/context/account.context'
import { useLocationState } from '../../location/context/location.context'
import { useAuthState } from '../../auth/context/auth.context'
import {
  loadDeviceAction,
  loadDevicesAction,
  sendMessageToDevice,
  setPulses,
  updateDevice,
  updateValvesAction,
  resetDeviceAction,
  updateFirmwareAction,
  realTimeFlowTokenAction,
  loadTimezones,
  updateDeviceLastCommentAction,
} from '../action/devices.action'
import {
  loadStagedDevicesAction,
  loadTestDevicesAction,
  promoteToStagedAction,
} from '../action/testDevices.action'
import Device, { ValvesState, DeviceIDTypes } from '../model/device'
import { Action, ActionTypes, initialState, reducer } from './device.reducer'
import { hasPermissions } from '@common/utils/helperFunctions'

const StateContext = React.createContext(initialState)
const DispatchContext = React.createContext(undefined as any)

export const DeviceContextProvider = ({ children }: any) => {
  const [state, dispatch] = React.useReducer(reducer, initialState)
  const { selectedAccounts } = useAccountState()
  const { selectedLocations } = useLocationState()
  const { permissions } = useAuthState()

  useEffect(() => {
    loadDevicesAction(dispatch, permissions)
    if (hasPermissions(permissions, ['ACCOUNT:ADMIN:SMARTFLOW'])) {
      loadTimezones(dispatch)
    }
  }, [])

  useEffect(() => {
    if (permissions.findIndex((p) => p.name === 'READ:DEVICE:TEST') >= 0)
      loadTestDevicesAction(dispatch)
  }, [permissions])

  useEffect(() => {
    if (permissions.findIndex((p) => p.name === 'READ:DEVICE:STGD') >= 0)
      loadStagedDevicesAction()(dispatch)
  }, [permissions])

  useEffect(() => {
    dispatch({
      type: ActionTypes.FILTER_ACCOUNT_DEVICES,
      payload: { selectedAccounts, selectedLocations },
    })
  }, [selectedAccounts, selectedLocations])

  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>{children}</DispatchContext.Provider>
    </StateContext.Provider>
  )
}

export const useDeviceState = () => {
  return React.useContext(StateContext)
}

export const useDeviceDispatch = () => {
  const dispatch = React.useContext(DispatchContext) as (action: Action) => any
  const { permissions } = useAuthState()

  if (dispatch === undefined) {
    throw new Error('useDeviceDispatch must be used within a DeviceContextProvider')
  }

  const updateDeviceData = React.useCallback(
    async (deviceId: string, idType: DeviceIDTypes, device: Device) =>
      await updateDevice(deviceId, idType, device, permissions)(dispatch),
    [dispatch],
  )

  const updateDeviceLastComment = React.useCallback(
    async (comment: any) => await updateDeviceLastCommentAction(comment)(dispatch),
    [dispatch],
  )

  const resetDevice = React.useCallback(
    async (deviceId: string, idType: DeviceIDTypes) =>
      await resetDeviceAction(deviceId, idType, { key: 'Reset', value: 1 })(dispatch),
    [dispatch],
  )

  const updateDevicePulses = React.useCallback(
    async (deviceId: string, idType: DeviceIDTypes, newPulses: number) =>
      await setPulses(deviceId, idType, newPulses, permissions)(dispatch),
    [dispatch],
  )

  const updateFirmwareFilename = React.useCallback(
    async (deviceId: string, idType: DeviceIDTypes, newFirmwareVersion: string) =>
      await updateFirmwareAction(deviceId, idType, newFirmwareVersion)(dispatch),
    [dispatch],
  )

  const startRTF = React.useCallback(
    async (deviceId: string) =>
      await sendMessageToDevice(deviceId, {
        key: 'GetRealTimeFlow',
        value: 10,
      })(dispatch),
    [dispatch],
  )

  const updateValves = React.useCallback(
    async (deviceId: string, newValveState: ValvesState) =>
      await updateValvesAction(deviceId, newValveState)(dispatch),
    [dispatch],
  )

  const commissionValves = React.useCallback(
    async (deviceId: string) =>
      await sendMessageToDevice(deviceId, {
        key: 'CommissionValves',
        value: 1,
      })(dispatch),
    [dispatch],
  )

  const promoteToStaged = React.useCallback(
    async (deviceId: string) => await promoteToStagedAction(deviceId)(dispatch),
    [dispatch],
  )

  const setSelectedDevices = React.useCallback(
    (selectedDevices: Device[]) => {
      dispatch({ type: ActionTypes.SELECT_DEVICES, payload: selectedDevices })
    },
    [dispatch],
  )

  const loadDevice = React.useCallback(
    async (deviceId: string, idType: DeviceIDTypes) =>
      await loadDeviceAction(deviceId, idType, permissions)(dispatch),
    [dispatch],
  )

  const loadDevices = React.useCallback(
    async () => await loadDevicesAction(dispatch, permissions),
    [dispatch],
  )

  const realTimeFlowToken = React.useCallback(
    async (deviceId: string, idType: DeviceIDTypes) =>
      await realTimeFlowTokenAction(deviceId, idType)(dispatch),
    [dispatch],
  )

  return React.useMemo(
    () => ({
      updateDeviceData,
      resetDevice,
      updateFirmwareFilename,
      updateDevicePulses,
      updateValves,
      promoteToStaged,
      startRTF,
      commissionValves,
      setSelectedDevices,
      loadDevice,
      loadDevices,
      realTimeFlowToken,
      updateDeviceLastComment,
      // loadDevicesTimezone,
    }),
    [
      updateDeviceData,
      resetDevice,
      updateFirmwareFilename,
      updateDevicePulses,
      updateValves,
      promoteToStaged,
      startRTF,
      commissionValves,
      setSelectedDevices,
      loadDevice,
      loadDevices,
      realTimeFlowToken,
      updateDeviceLastComment,
      // loadDevicesTimezone,
    ],
  )
}
