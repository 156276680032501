import Account from '../../account/model/Account'
import CustomerLocation from '../../account/model/CustomerLocation'

export enum ActionTypes {
  INIT,
  SELECT_LOCATIONS,
  LOADING,
  ERROR,
  FILTER_ACCOUNT_LOCATIONS,
}

interface State {
  locations: CustomerLocation[]
  allLocations: CustomerLocation[]
  selectedLocations: CustomerLocation[]
  loading: boolean
  error: boolean
}

export interface Action {
  type: ActionTypes
  payload?: any
}

export const initialState: State = {
  locations: [],
  allLocations: [],
  selectedLocations: [],
  loading: true,
  error: false,
}

export const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case ActionTypes.INIT:
      const { accounts } = action.payload
      const allLocations: CustomerLocation[] = action.payload?.locations.map((location: any) => {
        return {
          id: `${String(location.id)}`,
          name: location.location_name,
          accountIdRef: `${String(location.account_id_ref)}`,
          active: location.active,
          suspended: location.suspended,
          addressLine1: location.address_line_1,
          addressLine2: location.address_line_2,
          town: location.town,
          county: location.county,
          postcode: location.postcode,
          country: location.country,
          settings: {
            onboardingStatus: location?.settings?.onboarding_status,
            onboardingDate: location?.settings?.onboarding_date,
          },
          lastComment: location.last_comment,
          companyAlias: location.company_alias
        }
      })

      const locations: CustomerLocation[] = allLocations.filter(
        (location) =>
          accounts.findIndex(
            (account: Account) =>
              location.active && account.id == location.accountIdRef && account.isActive,
          ) > -1,
      )

      return {
        ...state,
        locations: locations,
        selectedLocations: locations,
        allLocations: allLocations,
        loading: false,
        error: false,
      }
    case ActionTypes.SELECT_LOCATIONS:
      return { ...state, selectedLocations: action.payload, loading: false, error: false }
    case ActionTypes.FILTER_ACCOUNT_LOCATIONS:
      const selectedAccounts: Account[] = action.payload
      const loc = state.allLocations.filter(
        (location) =>
          selectedAccounts.findIndex((account) => account.id == location.accountIdRef) > -1,
      )
      return { ...state, locations: loc, selectedLocations: loc }
    case ActionTypes.LOADING:
      return { ...state, loading: true, error: false }
    case ActionTypes.ERROR:
      return { ...state, loading: false, error: true }
    default:
      return state
  }
}
