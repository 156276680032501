import { useState } from 'react'
import moment from 'moment'
import Select from 'react-select'
import { Col, Form, Row } from 'react-bootstrap'
import { Reports, ReportsTracking } from '@data/reportsTracking/model/reportsTracking.model'

type ExSummaryReportProps = {
  reportData: Reports
  onReportUpdate: (updatedReport: ReportsTracking[]) => void
}

const ExSummaryReport: React.FC<ExSummaryReportProps> = ({ reportData, onReportUpdate }) => {
  const [currentYear, setCurrentYear] = useState<number>(new Date().getFullYear())
  const [reports, setReports] = useState<Reports>(reportData)

  const handleCheckboxChange = (reportName: string, year: number, month: number, index: number) => {
    const updatedReports = { ...reports }
    const rep = updatedReports[reportName][year][month]

    const newStatus = !rep[0].status

    rep[0].status = newStatus

    const reportsToUpdate = rep.map((report) => ({
      ...report,
      status: newStatus, // Set the new status for all reports
    }))

    onReportUpdate(reportsToUpdate)
  }

  const handleYearChange = (newYear: number) => {
    setCurrentYear(newYear)
  }

  const renderReports = () => {
    const currentReports = reports.exSummaryReport[currentYear] || []

    return Object.keys(currentReports).map((month, index) => (
      <div key={index}>
        <Form.Group as={Col} controlId="formGrid30DayReport">
          <Form.Check
            type="checkbox"
            className={`${currentReports[Number(month)][0].status ? 'text-success' : 'text-danger'}`}
            inline
            onChange={() =>
              handleCheckboxChange('exSummaryReport', currentYear, Number(month), index)
            }
            checked={currentReports[Number(month)][0].status}
            label={moment()
              .month(Number(month) - 1)
              .format('MMMM')}
            id={`inline-report-${month}`}
          />
        </Form.Group>
      </div>
    ))
  }

  const renderYearOptions = () => {
    const years = Object.keys(reports.exSummaryReport).map(Number)

    return years.map((year) => ({
      value: year.toString(),
      label: year.toString(),
    }))
  }

  return (
    <div className="border border-dark p-3">
      <Row className="mb-4">
        <Form.Group as={Col} controlId="formGrid30DayReport" className="w-100">
          <Form.Label>Select Year: </Form.Label>
          <Select
            classNamePrefix="select"
            defaultValue={{
              value: currentYear.toString(),
              label: currentYear.toString(),
            }}
            value={{
              value: currentYear.toString(),
              label: currentYear.toString(),
            }}
            onChange={(e: any) => handleYearChange(Number(e.value))}
            options={renderYearOptions()}
            getOptionValue={(option) => option.value}
            getOptionLabel={(option) => `${option.label}`}
            isClearable={false}
          />
        </Form.Group>
      </Row>

      <div>{renderReports()}</div>
    </div>
  )
}

export default ExSummaryReport
