import { hasPermissions, filterNewlyInstalledDevices } from '@common/utils/helperFunctions'
import { useAuthState } from '@context/auth/context/auth.context'
import { AlertStatusComponent } from './Dashboard/AlertStatusComponent'
import { CurrentWaterUsageComponent } from './Dashboard/CurrentWaterUsageComponent'
import { DeviceStatusComponent } from './Dashboard/DeviceStatusComponent'
import { NewlyInstalledDevicesComponent } from './Dashboard/NewlyInstalledDevicesComponent'
import { useDeviceState } from '@context/device/context/device.context'
import { UserDeviceListComponent } from './Dashboard/UserDeviceListComponent'

function Home() {
  const { permissions } = useAuthState()
  const { devices } = useDeviceState()
  const statusShow = hasPermissions(permissions, ['DASHBOARD:STATUS:VIEW'])
  const devicesShow = hasPermissions(permissions, ['READ:DEVICE:PROD'])
  const alertsShow =
    hasPermissions(permissions, ['READ:ALERTS:USAGE:IN_PROGRESS']) ||
    hasPermissions(permissions, ['READ:ALERTS:USAGE:CLOSED'])
  const usageShow = hasPermissions(permissions, ['READ:USAGE'])
  const newlyInstalledDevicesShow = hasPermissions(permissions, ['ACCOUNT:ADMIN:SMARTFLOW'])

  const newlyInstalledDevices = filterNewlyInstalledDevices(devices)

  return (
    <div className="home">
      <div className="row mb-4">
        <div className={statusShow ? 'col-xl-8' : 'col-xl-6'}>
          {statusShow ? (
            <DeviceStatusComponent />
          ) : devicesShow ? (
            <UserDeviceListComponent />
          ) : null}
        </div>
        <div className={statusShow ? 'col-xl-4' : 'col-xl-6'}>
          {alertsShow ? <AlertStatusComponent /> : null}
          {newlyInstalledDevices.length > 0 && newlyInstalledDevicesShow && (
            <NewlyInstalledDevicesComponent devices={newlyInstalledDevices} />
          )}
        </div>
      </div>
      <div className="row mb-4">
        <div className="col">{usageShow ? <CurrentWaterUsageComponent /> : null}</div>
      </div>
    </div>
  )
}

export default Home
