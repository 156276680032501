import React, { useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'

import moment from 'moment-timezone'
import 'react-datepicker/dist/react-datepicker-cssmodules.css'
import 'react-datepicker/dist/react-datepicker.css'
import { Moment } from 'moment/moment'
import { IDateRange } from './interfaces'

const TODAY: Moment = moment()

const DateRangeComponent: React.FC<IDateRange> = ({
  from,
  to,
  fromCallback,
  toCallback,
  minDate,
  isClearable = false,
  label,
}: IDateRange) => {
  const [fromDate, setFromDate] = useState<Date | null>(from)
  const [toDate, setToDate] = useState<Date | null>(to)

  useEffect(() => {
    const from = moment(fromDate)
    const to = moment(toDate)
    if (from.isSameOrAfter(to)) {
      setFromDate(to.subtract(1, 'days').toDate())
    }
  }, [toDate])

  useEffect(() => {
    fromCallback(fromDate)
  }, [fromDate])

  useEffect(() => {
    toCallback(toDate)
  }, [toDate])

  const handleFromRangeChange = (dates: [Date | null, Date | null]) => {
    if (dates) {
      const [start, end] = dates
      setFromDate(start)
      setToDate(end)
    } else {
      setFromDate(null)
    }
  }

  return (
    <div className="date-range-selector">
      <div className="col-md-12 main-content p-0">
        <div className="range">
          <div className="col-md-6 col-sm-12 section-header p-0">
            <div>{label || 'Range:'}</div>
          </div>
          <DatePicker
            wrapperClassName="w-100"
            selected={minDate ? (fromDate && fromDate > minDate ? fromDate : minDate) : fromDate}
            onChange={handleFromRangeChange}
            maxDate={TODAY.toDate()}
            minDate={minDate}
            dateFormat="dd/MM/yyyy"
            isClearable={isClearable}
            startDate={fromDate}
            endDate={toDate}
            selectsRange
            showIcon
            showMonthDropdown
            showYearDropdown
          />
        </div>
      </div>
    </div>
  )
}

export default DateRangeComponent
