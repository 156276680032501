import { NavLink } from 'react-router-dom'
import { useAlertState } from '@context/alert/context/alert.context'
import { useAuthState } from '@context/auth/context/auth.context'
import { AlertStatus } from '@context/alert/model/alert.model'
import { hasPermissions } from '@common/utils/helperFunctions'
import { useDeviceState } from '@context/device/context/device.context'

export function AlertStatusComponent() {
  const { filteredQueue, isLoading } = useAlertState()
  const { devices } = useDeviceState()
  const { permissions } = useAuthState()
  const alertsShowNew = hasPermissions(permissions, ['READ:ALERTS:USAGE:OPEN'])
  const alertsShowInProgress = hasPermissions(permissions, ['READ:ALERTS:USAGE:IN_PROGRESS'])
  const alertsShowHistory = hasPermissions(permissions, ['READ:ALERTS:USAGE:HISTORY'])
  const alertsShowReview = hasPermissions(permissions, ['READ:ALERTS:USAGE'])
  const isUserSmartFlowAdmin = hasPermissions(permissions, ['ACCOUNT:ADMIN:SMARTFLOW'])
  return (
    <div
      className={`card border-left-${filteredQueue.length === 0 ? 'success' : 'danger'} shadow h-${
        isUserSmartFlowAdmin ? 'auto mh-50' : '100'
      } py-2 mb-2`}
    >
      <div className="card-body">
        <div className="row no-gutters align-items-center">
          <div className="col">
            <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
              Alert Status
            </div>

            {isLoading ? (
              <div className="row no-gutters align-items-center ">
                <div className="d-flex justify-content-center m-auto h-100">
                  <div className="spinner-border text-primary m-auto ">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              </div>
            ) : (
              <>
                <div className="row no-gutters align-items-center mb-3 px-3">
                  <div className="col-auto">
                    {alertsShowNew ? (
                      <div className="h5 mb-1 mr-3 font-weight-bold text-gray-800">
                        New: {filteredQueue.filter((a) => a.status === AlertStatus.OPEN).length}
                      </div>
                    ) : null}
                    {alertsShowInProgress ? (
                      <div className="h5 mb-1 mr-3 font-weight-bold text-gray-800">
                        Active Alerts:{' '}
                        {filteredQueue.filter((a) => a.status === AlertStatus.IN_PROGRESS).length}
                      </div>
                    ) : null}
                    {alertsShowReview ? (
                      <NavLink to="/alert-queue" className="btn btn-secondary btn-sm">
                        Review Alerts
                      </NavLink>
                    ) : null}
                  </div>
                </div>
                <div className="row no-gutters align-items-center px-3">
                  <div className="col">
                    {alertsShowHistory ? (
                      <NavLink to="/historical-alerts" className="btn btn-secondary btn-sm">
                        Historical Alerts
                      </NavLink>
                    ) : null}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
