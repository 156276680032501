import React from 'react'
import { useEffect, useRef, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { Button, Card, Col, Spinner, Row } from 'react-bootstrap'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LineController,
  PointElement,
  Filler,
} from 'chart.js'
import backend from '@api/backend'
import moment from 'moment-timezone'
import Select from 'react-select'
import jsPDF from 'jspdf'
import { useAlertDispatch, useAlertState } from '@context/alert/context/alert.context'
import { AlertGroup, AlertStatus } from '@context/alert/model/alert.model'
import { hasPermissions } from '@common/utils/helperFunctions'
import { useAuthState } from '@context/auth/context/auth.context'
import { useDeviceState } from '@context/device/context/device.context'
import AckAlertModal from './AckAlertModal'
import { FaClock } from 'react-icons/fa'
import SectorIcon from '../../general/SectorIcon/SectorIcon'
import WaterUseChart from './WaterUseChart'
import { UOM_OPTIONS } from '../DeviceManagement/DeviceInformationManager/constants'
import { useUserState } from '@context/user/context/user.context'
import { buildAlertPDF } from './helper-functions'
import { MessageCircleMore } from 'lucide-react'
import {
  useAlertComments,
  useAlertCommentsCreation,
  useAlertCommentsUpdate,
} from '@data/comments/alertComments/alertComments'
import { IComments } from '@data/comments/model/comments.model'
import CommentComponent from '../Components/Comment/CommentComponent'

import './AlertQueueItem.scss'

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LineController,
  PointElement,
  Filler,
)

interface IAlertQueueItemProps {
  alertGroupItem?: AlertGroup
  historical?: boolean
}

interface IAckAlertItem {
  alertGroup: AlertGroup
  priorityTier: number
  status: AlertStatus
}

interface EmergencyContact {
  name: string
  email: string
  mobile: string
}

// const plugin = {
//     id: 'afterRender',
//
//     afterRender: function(chart: any, options: any) {
//         console.log("After render")
//     }
// }S
// ChartJS.register(plugin);

function AlertQueueItem({ alertGroupItem, historical = false }: IAlertQueueItemProps) {
  const { ackAlerts } = useAlertDispatch()
  const { permissions } = useAuthState()
  const { devices } = useDeviceState()
  const { filteredQueue } = useAlertState()
  const { userInfo } = useUserState()

  const params = useParams()
  const navigate = useNavigate()
  const { id } = params

  const [estimatedLeak, setEstimatedLeak] = useState<number>(0)
  // const [estimatedLeakCost, setEstimatedLeakCost] = useState<number>(0);
  const [ackAlert, setAckAlert] = useState<IAckAlertItem>()
  const hasUpdateAlertPermission = hasPermissions(permissions, ['UPDATE:ALERTS:USAGE'])
  const chartRef = useRef<ChartJS<'line', number[], string>>(null)
  const [localChartRef, setLocalChartRef] =
    useState<React.RefObject<ChartJS<'line', number[], string>>>(chartRef)
  const [chartAnimationState, setChartAnimationState] = useState<boolean>(false)
  const [chartImage, setChartImage] = useState<string | null>(null)
  const [alertGroup, setAlertGroup] = useState<AlertGroup | undefined>(undefined)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isDownloading, setIsDownloading] = useState<boolean>(false)
  const [isLoadingEmergencyContacts, setIsLoadingEmergencyContacts] = useState<boolean>(false)
  const [emergencyContactResult, setEmergencyContactResult] = useState<
    null | string | EmergencyContact[]
  >(null)
  const [selectedUom, setSelectedUom] = useState(userInfo.preferences?.uom || 'Liters')
  const [displayAlertComments, setDisplayAlertComments] = useState(false)

  const { data: alertComments } = useAlertComments(alertGroup?.deviceId, alertGroup?.id)

  const { mutate: createNewComment } = useAlertCommentsCreation()
  const { mutate: updateComment } = useAlertCommentsUpdate()

  useEffect(() => {
    if (!chartRef) return
    const { current } = localChartRef
    if (!current) return

    if (!chartAnimationState) {
      const base64Img: string = current.toBase64Image()
      setChartImage(base64Img)
    }
  }, [chartAnimationState])

  useEffect(() => {
    if (id) {
      const alert = filteredQueue.find((queue) => queue.deviceId === id)
      setAlertGroup(alert)
      setIsLoading(false)
      setEmergencyContactResult(null)
      setDisplayAlertComments(false)
    }
  }, [params, filteredQueue])

  useEffect(() => {
    if (alertGroupItem) {
      setAlertGroup(alertGroupItem)
      setIsLoading(false)
    }
  }, [alertGroupItem])

  useEffect(() => {
    if (displayAlertComments) {
      const element = document.getElementById('alertCommentsComponent')

      element?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [displayAlertComments])

  if (!alertGroup) return <div />
  const device = devices.find((d) => d.dlId === alertGroup.dlId)

  function downloadWaterGraph() {
    setIsDownloading(true)
    const chartElement = document.getElementById('chart-element') as HTMLElement

    if (chartElement && device && alertGroup) {
      buildAlertPDF(
        chartElement,
        device,
        alertGroup.priorityTier,
        startAlertTime,
        endAlertTime,
        mostRecentAlert,
        alertDuration,
        alertClosed,
      )
        .then((pdf: jsPDF) => {
          const fileName = `${device?.deviceName}_Alert_Tier${alertGroup?.priorityTier}_${moment
            .utc(alertGroup?.alertCloseDate)
            .tz(moment.tz.guess())
            .format('DD/MM/YYYY LT')}`
          pdf.save(fileName)
          setIsDownloading(false)
        })
        .catch((error) => {
          console.log('Something wrong happened while generating PDF: ', error)
        })
    }
  }

  function canAckAlert(alertGroup: AlertGroup) {
    return hasUpdateAlertPermission && alertGroup.status !== AlertStatus.CLOSED
  }

  const activeAlerts = alertGroup.alerts.filter((alert) => alert.priorityTier == null)
  const ackAlertList = alertGroup.alerts.filter((alert) => alert.priorityTier != null)

  const actionAlert = async (alertGroup: AlertGroup, priorityTier: number, status: AlertStatus) => {
    if (priorityTier !== 0) {
      if (!chartRef) return
      const { current } = localChartRef
      if (!current) return
      current.setDatasetVisibility(0, false)
      current.setDatasetVisibility(3, false)
      // if (current.data.datasets.length > 2) {
      //   current.data.datasets = current.data.datasets.filter((_,i) => i !== 0 && i !== 3)
      //   current.data.datasets[0].fill = {
      //     target: 1,
      //     above: "rgba(247, 30, 30, 0.55)",   // Area will be red above the origin
      //     below: "rgba(0, 0, 0, 0)"    // And blue below the origin
      //   }
      //   current.data.datasets[0].backgroundColor = ['rgba(247, 30, 30, 0.55)']
      //   current.data.datasets[0].label = 'Wasted Water';
      //   current.update('none')
      // }
      setChartAnimationState(true)
      alertGroup.alerts = alertGroup.alerts.map((a) => {
        return {
          ...a,
          excludeUsage: true,
        }
      })
      setAckAlert({
        alertGroup: alertGroup,
        priorityTier: priorityTier,
        status: status,
      })
    } else {
      alertGroup.alerts = alertGroup.alerts.map((a) => {
        return {
          ...a,
          excludeUsage: false,
        }
      })
      await ackAlerts(alertGroup, priorityTier, status)
      redirect()
    }
  }

  const redirect = () => {
    const currentAlertIndex = filteredQueue.findIndex((queue) => queue.deviceId === id)

    if (filteredQueue[currentAlertIndex + 1] !== undefined) {
      navigate(`/alert-queue/${filteredQueue[currentAlertIndex + 1].deviceId}`, { replace: true })
    } else {
      navigate('/alert-queue', { replace: true })
    }
  }

  const getEmergencyContact = async () => {
    setIsLoadingEmergencyContacts(true)

    try {
      const response = await backend.get(`/devices/${device?.deviceId}/emergency_contacts`)

      if (!response.data.length) {
        setEmergencyContactResult('Emergency Contact not found!')
      } else {
        const emergencyContacts = response.data.map((res: any) => ({
          name: res.first_name,
          mobile: res.mobile,
          email: res.email,
        }))
        setEmergencyContactResult(emergencyContacts)
      }
      setIsLoadingEmergencyContacts(false)
    } catch (e: any) {
      console.error(e)
      setEmergencyContactResult('Emergency Contact not found!')
      setIsLoadingEmergencyContacts(false)
    }
  }

  const handleNewComment = (newComment: string) => {
    const newCommentData = {
      device_id: alertGroup.deviceId,
      alert_group_id: alertGroup.id,
      comment: newComment,
    }

    createNewComment(newCommentData)
  }

  const handleUpdateComment = (comment: IComments, updatedComment: string) => {
    const updatedCommentData = {
      device_id: alertGroup.deviceId,
      comment_id: comment.id,
      comment: updatedComment,
      alert_group_id: alertGroup.id,
    }

    updateComment(updatedCommentData)
  }

  const startAlertTime = `${moment(alertGroup.startAlertTime).format('ddd, DD/MM/YY LT')}`
  const endAlertTime = `${moment(alertGroup.endAlertTime).format('ddd, DD/MM/YY LT')}`

  const alertClosed = alertGroup.alertCloseDate
    ? 'Alert Closed: ' +
      moment.utc(alertGroup.alertCloseDate).tz(moment.tz.guess()).format('DD/MM/YYYY LT')
    : ''

  const mostRecentAlert = `Most recent: ${moment(alertGroup.endAlertTime).fromNow()}`
  const alertDuration = `Duration: ${
    moment(alertGroup.endAlertTime).diff(moment(alertGroup.startAlertTime), 'hours') + 1
  } hours`
  return isLoading ? (
    <div className="spinner-border m-auto mb-5 mt-5 d-flex text-secondary" />
  ) : (
    <>
      <div className="alert-queue-item card shadow mb-4 mx-auto" style={{ width: '95%' }}>
        <div className="card-body">
          <div className="row p-1">
            <div className="col h-100">
              <div className={'d-flex h-100 align-items-center'}>
                <div className={'pr-3 h-100'}>
                  <SectorIcon
                    sector={device?.deviceSettings.sectorType}
                    size={25}
                    color="#000"
                    occupants={device?.deviceSettings.occupants}
                  />
                </div>
                <div className={'h-100 py-2'}>
                  <div>
                    <h5 className="m-0 font-weight-bold text-primary">
                      {device?.deviceName ? device.deviceName : alertGroup.deviceId}
                    </h5>
                    <h6>{alertGroup.deviceId}</h6>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-auto m-auto">
              {canAckAlert(alertGroup) && alertGroup.priorityTier ? (
                <button
                  onClick={() => {
                    actionAlert(alertGroup, alertGroup.priorityTier, AlertStatus.CLOSED)
                  }}
                  type="button"
                  className="mr-sm-0 mr-md-3 btn btn-primary my-md-0 my-2"
                >
                  Archive Alert
                </button>
              ) : null}
            </div>
            <div className="col-auto m-auto">
              <div className="btn-group " role="group">
                {canAckAlert(alertGroup) ? (
                  <button
                    disabled={!canAckAlert(alertGroup)}
                    onClick={() => {
                      actionAlert(alertGroup, 0, AlertStatus.CLOSED)
                    }}
                    type="button"
                    className={`btn ${
                      alertGroup.priorityTier === 0 ? 'btn-primary' : 'btn-outline-secondary'
                    }`}
                  >
                    Normal Flow
                  </button>
                ) : null}
                <button
                  disabled={!canAckAlert(alertGroup)}
                  onClick={() => {
                    actionAlert(alertGroup, 3, AlertStatus.IN_PROGRESS)
                  }}
                  type="button"
                  className={`btn ${
                    alertGroup.priorityTier === 3 ? 'btn-primary' : 'btn-outline-secondary'
                  }`}
                >
                  Tier 3
                </button>
                <button
                  disabled={!canAckAlert(alertGroup)}
                  onClick={() => {
                    actionAlert(alertGroup, 2, AlertStatus.IN_PROGRESS)
                  }}
                  type="button"
                  className={`btn ${
                    alertGroup.priorityTier === 2 ? 'btn-primary' : 'btn-outline-secondary'
                  }`}
                >
                  Tier 2
                </button>
                <button
                  disabled={!canAckAlert(alertGroup)}
                  onClick={() => {
                    actionAlert(alertGroup, 1, AlertStatus.IN_PROGRESS)
                  }}
                  type="button"
                  className={`btn ${
                    alertGroup.priorityTier === 1 ? 'btn-primary' : 'btn-outline-secondary'
                  }`}
                >
                  Tier 1
                </button>
              </div>
            </div>
          </div>

          <div className="row p-1">
            <div className="col m-auto">
              <div className={'row'}>
                <div className={'col-auto'}>
                  <div className={'d-flex h-100'}>
                    <div className={'pr-3 h-100'}>
                      <span className={'d-flex h-100 align-items-center'}>
                        <FaClock size={20} style={{ color: 'black' }}></FaClock>
                      </span>
                    </div>
                    <div className={'h-100 py-2'}>
                      <h5>
                        {hasUpdateAlertPermission ? (
                          <span
                            style={{
                              color: 'red',
                              fontWeight: 800,
                            }}
                          >
                            {' '}
                            {alertGroup.alerts.length} alerts ({activeAlerts.length} active /{' '}
                            {ackAlertList.length} acknowledged)
                            <br />
                          </span>
                        ) : null}
                        <span style={{ color: 'red', fontWeight: 400 }}>
                          {moment(alertGroup.startAlertTime).format('ddd, DD/MM/YY LT')}
                        </span>
                        <small> to </small>
                        <span style={{ color: 'red', fontWeight: 400 }}>
                          {moment(alertGroup.endAlertTime).format('ddd, DD/MM/YY LT')}
                        </span>
                      </h5>
                    </div>
                  </div>
                </div>
                <div className={'col-auto'}>
                  <h6>{mostRecentAlert}</h6>
                  <h6>{alertDuration}</h6>
                </div>

                <div className={'col-auto'}>
                  {historical && !hasUpdateAlertPermission ? null : (
                    <h6>
                      {alertGroup.hourlyAlertsSentDate !== null
                        ? 'Alert Sent: ' +
                          moment
                            .utc(alertGroup.hourlyAlertsSentDate)
                            .tz(moment.tz.guess())
                            .format('DD/MM/YYYY LT')
                        : 'No Alert Sent'}
                    </h6>
                  )}

                  <h6>{alertClosed}</h6>

                  {hasUpdateAlertPermission ? <h6>Acknowledged By: {alertGroup.ackBy}</h6> : null}
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-between align-items-center mt-4 row">
              <div className="col-sm-3 ml-2 mb-3 ">
                <div className="section-header">
                  <div className="">Unit of measure</div>
                </div>
                <Select
                  classNamePrefix="select"
                  value={{
                    value: selectedUom,
                    label: selectedUom,
                  }}
                  onChange={(e: any) => setSelectedUom(e.value)}
                  options={UOM_OPTIONS.map((uom) => {
                    return {
                      value: uom,
                      label:
                        uom === device?.deviceSettings?.uom
                          ? `${uom} (device default)`
                          : uom === userInfo.preferences?.uom
                            ? `${uom} (your set preference)`
                            : uom,
                    }
                  })}
                  getOptionValue={(option) => option.value}
                  getOptionLabel={(option) => `${option.label}`}
                  isClearable={false}
                />
              </div>

              <div
                className="col-sm-3 d-flex flex-row-reverse"
                style={{ maxHeight: '150px', overflowY: 'auto' }}
              >
                {emergencyContactResult ? (
                  typeof emergencyContactResult === 'string' ? (
                    emergencyContactResult
                  ) : (
                    <div>
                      <span>Emergency Contact(s) found:</span>
                      <hr />
                      {emergencyContactResult.map((contact, index) => (
                        <div className="emergency-contact d-flex flex-column ">
                          <span>{`name: ${contact.name}`}</span>

                          <span>{`email: ${contact.email}`}</span>

                          <span>{`mobile: ${contact.mobile}`}</span>
                          <hr />
                        </div>
                      ))}
                    </div>
                  )
                ) : (
                  hasPermissions(permissions, ['USER:ADMIN:SMARTFLOW']) && (
                    <Button onClick={getEmergencyContact} variant="outline-primary">
                      {isLoadingEmergencyContacts ? <Spinner size="sm" /> : 'Get emergency contact'}
                    </Button>
                  )
                )}
              </div>
            </div>
          </div>

          <div className="col">
            <Card>
              <Card.Body>
                <WaterUseChart
                  alertGroup={alertGroup}
                  setEstimatedLeakCallback={setEstimatedLeak}
                  setChartRef={setLocalChartRef}
                  chartAnimationCallback={setChartAnimationState}
                  selectedUom={selectedUom}
                  chartId="chart-element"
                />
                <Row>
                  <Col xs={6}>
                    <Button
                      onClick={() => downloadWaterGraph()}
                      variant="secondary"
                      disabled={isDownloading}
                      className="mt-4"
                    >
                      {isDownloading ? (
                        <span className="spinner-border spinner-border-sm text-primary"></span>
                      ) : (
                        <>
                          <span className="fas fa-download fa-lg mr-2"></span>
                          Download
                        </>
                      )}
                    </Button>
                  </Col>
                  {hasPermissions(permissions, ['ACCOUNT:ADMIN:SMARTFLOW']) && (
                    <Col xs={6} className="d-flex justify-content-end align-items-center">
                      <MessageCircleMore
                        role="button"
                        onClick={() => setDisplayAlertComments(!displayAlertComments)}
                        size={35}
                      />

                      {alertComments && alertComments.length > 0 && (
                        <span className="badge text-danger p-0 fs-6 mb-4">
                          {alertComments.length < 9 ? alertComments.length : '9+'}
                        </span>
                      )}
                    </Col>
                  )}
                </Row>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
      {ackAlert && !chartAnimationState ? (
        <AckAlertModal
          alertGroup={ackAlert.alertGroup}
          priorityTier={ackAlert.priorityTier}
          status={ackAlert.status}
          onHide={() => setAckAlert(undefined)}
          chartRef={localChartRef}
          chartImage={chartImage}
          activeAlertId={id || ''}
        />
      ) : null}

      <div
        id="alertCommentsComponent"
        className="p-0 ml-5 w-75"
        style={{ maxHeight: '400px', overflowY: 'scroll', overflowX: 'hidden' }}
      >
        {displayAlertComments && (
          <CommentComponent
            comments={alertComments}
            handleNewComment={handleNewComment}
            handleUpdateComment={handleUpdateComment}
          />
        )}
      </div>
    </>
  )
}

export default AlertQueueItem
