import { Button, Spinner, Tab, Tabs } from 'react-bootstrap'
import CustomSearchTable from '../../general/CustomSearchTable/CustomSearchTable'
import { useDeviceDispatch, useDeviceState } from '@context/device/context/device.context'
import {
  CustomerJourneyAccountColumns,
  CustomerJourneyDeviceColumns,
  CustomerJourneyLocationColumns,
} from './DeviceInformationManager/constants'
import {
  useReportsTracking,
  useAssociateReportsWithAccounts,
} from '@data/reportsTracking/reportsTracking'
import { useState } from 'react'
import CustomerJourneyDeviceModal from './CustomerJourneyDeviceModal'
import { useSearchParams } from 'react-router-dom'
import CustomerJourneyLocationModal from './CustomerJourneyLocationModal'
import { useLocationState } from '@context/location/context/location.context'
import { useAccountState } from '@context/account/context/account.context'
import CustomerJourneyAccountModal from './CustomerJourneyAccountModal'
import moment from 'moment'

const CustomerJourney = () => {
  const { devices, loadingDevices } = useDeviceState()
  const { selectedAccounts } = useAccountState()
  const { loadDevices } = useDeviceDispatch()

  const [selectedData, setSelectedData] = useState()
  const [searchParams, setSearchParams] = useSearchParams()

  const { data: reports, isLoading, isFetching } = useReportsTracking()
  const { data: accountWithReports } = useAssociateReportsWithAccounts(selectedAccounts, reports)

  const { locations } = useLocationState()

  const [modalShow, setModalShow] = useState(false)
  const [tabKey, setTabKey] = useState<'device' | 'account' | 'location' | 'retention'>('device')

  function onRowClick(rowData: any) {
    if (rowData) {
      setSelectedData(rowData)
      setModalShow(true)
    }
  }

  function onHideDeviceModal() {
    setModalShow(false)
    setSelectedData(undefined)
  }

  function onTabChange(newTab: 'device' | 'account' | 'location' | 'retention') {
    setSelectedData(undefined)
    setModalShow(false)
    setTabKey(newTab)

    if (searchParams.get('page')) {
      setSearchParams((state) => {
        state.set('page', String(1))

        return state
      })
    }
  }

  const retentionDevices = devices.filter(
    (device) =>
      moment().diff(moment(device.deviceSettings.installDate), 'months') > 11 &&
      !device.lastComment,
  )

  return (
    <div className="device-information-manager">
      <div className="mt-3 mb-3">
        <h1>Customer Journey</h1>
      </div>
      {loadingDevices || isLoading || isFetching ? (
        <div className="d-flex justify-content-center mt-5">
          <Spinner
            as="span"
            animation="border"
            role="status"
            aria-hidden="true"
            variant="primary"
          />
        </div>
      ) : (
        <Tabs
          transition={false}
          id="controlled-tab-example"
          activeKey={tabKey}
          onSelect={(k: any) => onTabChange(k)}
        >
          <Tab eventKey={'account'} title="Account">
            <div className="mt-5">
              {accountWithReports && tabKey === 'account' && (
                <CustomSearchTable
                  dataArray={accountWithReports}
                  columns={CustomerJourneyAccountColumns}
                  onRowClickHandler={(rowData) => onRowClick(rowData)}
                  allowColumnPicker
                  displayPagination
                />
              )}
            </div>
          </Tab>
          <Tab eventKey={'location'} title="Location">
            <div className="mt-5">
              {locations && tabKey === 'location' && (
                <CustomSearchTable
                  dataArray={locations}
                  columns={CustomerJourneyLocationColumns}
                  onRowClickHandler={(rowData) => onRowClick(rowData)}
                  allowColumnPicker
                  displayPagination
                  filterByDate
                  tabKey={tabKey}
                />
              )}
            </div>
          </Tab>
          <Tab eventKey={'device'} title="Device">
            <div className="mt-5">
              <Button variant="outline-primary" onClick={loadDevices}>
                Refresh Devices
              </Button>
              {devices && tabKey === 'device' && (
                <CustomSearchTable
                  dataArray={devices}
                  columns={CustomerJourneyDeviceColumns}
                  onRowClickHandler={(rowData) => onRowClick(rowData)}
                  filterByDate
                  allowColumnPicker
                  displayPagination
                />
              )}
            </div>
          </Tab>
          <Tab eventKey={'retention'} title="Retention ">
            <CustomSearchTable
              dataArray={retentionDevices}
              columns={CustomerJourneyDeviceColumns}
              onRowClickHandler={(rowData) => onRowClick(rowData)}
              filterByDate
              allowColumnPicker
              displayPagination
            />

            {selectedData && (tabKey === 'device' || tabKey === 'retention') && (
              <CustomerJourneyDeviceModal
                show={modalShow}
                onHide={onHideDeviceModal}
                deviceData={selectedData}
              />
            )}
            {selectedData && tabKey === 'location' && (
              <CustomerJourneyLocationModal
                show={modalShow}
                onHide={onHideDeviceModal}
                locationData={selectedData}
              />
            )}
            {selectedData && tabKey === 'account' && (
              <CustomerJourneyAccountModal
                show={modalShow}
                onHide={onHideDeviceModal}
                accountData={selectedData}
              />
            )}
          </Tab>
        </Tabs>
      )}
    </div>
  )
}

export default CustomerJourney
