import { KeyRound } from 'lucide-react'

import AccountSelector from './AccountSelector'

import './AccountManagement.scss'

const Dashboard = () => {
  return (
    <div className="home">
      <h1>
        <KeyRound className={'mr-3'} size={40} />
        Account Management
      </h1>
      <AccountSelector />
    </div>
  )
}

export default Dashboard
