import { useEffect, useState } from 'react'
import moment from 'moment-timezone'
import { Tab, Table, Tabs, Col, Row, Nav } from 'react-bootstrap'
import { RiMailSendFill } from 'react-icons/ri'
import { GiCancel } from 'react-icons/gi'
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Account from '@context/account/model/Account'
import Device from '@context/device/model/device'
import User, { UserInvite } from '@context/user/model/user'
import {
  useCustomerManagerDispatch,
  useCustomerManagerState,
} from '@context/customerManager/customer-manager.context'
// import { useUserDispatch, useUserState } from '@context/user/context/user.context'
import { useAuthState } from '@context/auth/context/auth.context'
import CustomerLocation from '@context/account/model/CustomerLocation'
import { FaRegClock, FaPlus, FaExchangeAlt, FaTrash, FaCog } from 'react-icons/fa'
import { FaXmark, FaCheck } from 'react-icons/fa6'
import { hasPermissions } from '@common/utils/helperFunctions'
import UserEditModal from './UserModal/UserEditModal'
import AddDeviceModal from './AddDeviceModal/AddDeviceModal'
import SwapDeviceModal from './AddDeviceModal/SwapDeviceModal'
import LocationEditModal from './LocationModal/LocationEditModal'
import { InviteModal } from './UserModal/UserInvitationModal'
import ResendModal from './UserModal/ResendModal'
import AlertEditModal from './UserModal/AlertEditModal'
import DeleteModal from '../../general/Modals/DeleteModal'
import CommentComponent from '../Components/Comment/CommentComponent'
import { EditInviteModal } from './UserModal/EditUserInviteModal'
import { Phone } from 'lucide-react'
import AccountReports from './AccountReports'
import { IComments } from '@data/comments/model/comments.model'
import {
  useAccountComments,
  useAccountCommentsCreation,
  useAccountCommentsUpdate,
} from '@data/comments/accountComments/accountComments'

const AccountDetails = ({ selectedAccount }: { selectedAccount: Account }) => {
  const { devicesByLocation, usersByAccount, locationsByAccount, invitationsByAccount } =
    useCustomerManagerState()
  const {
    removeDeviceFromAccount,
    removeUserFromAccount,
    removeLocationFromAccount,
    swapDevice,
    loadUsersByAccount,
    loadLocationsByAccount,
    loadInvitationsByAccount,
    updateUserInvite,
    resendUserInvite,
  } = useCustomerManagerDispatch()
  // const { requestingConfirmEmail, error } = useUserState()
  const { permissions } = useAuthState()
  // const { requestConfirmEmail } = useUserDispatch()

  const [accountLocations, setAccountLocations] = useState<CustomerLocation[]>([])
  const [locationDevices, setLocationDevices] = useState<Device[]>([])
  const [accountUsers, setAccountUsers] = useState<User[]>([])
  const [editModalUser, setEditModalUser] = useState<User>()
  const [inviteUserModal, setInviteUserModal] = useState<User>()
  const [editModalLocation, setEditModalLocation] = useState<CustomerLocation>()
  const [selectedLocation, setSelectedLocation] = useState<CustomerLocation>()
  const [addDeviceModal, setAddDeviceModal] = useState<boolean>(false)
  const [swapDeviceModal, setSwapDeviceModal] = useState<Device>()
  const [deleteUserModal, setDeleteUserModal] = useState<User | undefined>()
  const [deleteDeviceModal, setDeleteDeviceModal] = useState<Device | undefined>()
  const [deleteLocationModal, setDeleteLocationModal] = useState<CustomerLocation | undefined>()
  // const [confirmEmailSent, setConfirmEmailSent] = useState<boolean>(false)
  const [tabKey, setTabKey] = useState<string>('users_tab')
  const [editInvitation, setEditInvitation] = useState<any>()
  const [deactivateInviteModal, setDeactivateInviteModal] = useState<any | undefined>()
  const [resendInviteModal, setResendInviteModal] = useState<number | undefined>()
  const [editUserAlertsModal, setEditUserAlertsModal] = useState<User | undefined>()

  const canUserCreateDevices = hasPermissions(permissions, ['UPDATE:ACCOUNT:DEVICE'])
  const canUserSeeLocations = hasPermissions(permissions, ['READ:CUST_LOCATION'])
  const canUserUpdateLocations = hasPermissions(permissions, ['UPDATE:CUST_LOCATION'])
  const isUserSmartflowAdmin = hasPermissions(permissions, ['ACCOUNT:ADMIN:SMARTFLOW'])

  const { data: accountComments } = useAccountComments(selectedAccount.id, isUserSmartflowAdmin)

  const {
    mutate: createNewAccountComment,
    isPending,
    isError,
    isSuccess,
  } = useAccountCommentsCreation()

  const { mutate: updateComment } = useAccountCommentsUpdate()

  useEffect(() => {
    setAccountUsers([])
    setLocationDevices([])
    setAccountLocations([])
    setSelectedLocation(undefined)
    if (selectedAccount.id.length > 0) {
      loadUsersByAccount(selectedAccount)
      loadLocationsByAccount(selectedAccount)
      loadInvitationsByAccount(selectedAccount)
    }
  }, [selectedAccount])

  useEffect(() => {
    if (!locationsByAccount) return
    setAccountLocations(locationsByAccount[selectedAccount.id] || [])
  }, [selectedAccount, locationsByAccount])

  useEffect(() => {
    if (!devicesByLocation || !selectedLocation) return
    setLocationDevices(
      (selectedLocation && selectedLocation.id && devicesByLocation[selectedLocation.id]) || [],
    )
  }, [selectedLocation, devicesByLocation])

  useEffect(() => {
    if (!usersByAccount) return
    setAccountUsers(usersByAccount[selectedAccount.id] || [])
  }, [selectedAccount, usersByAccount])

  // useEffect(() => {
  //   if (!requestingConfirmEmail && !error) {
  //     setConfirmEmailSent(true)
  //   } else {
  //     setConfirmEmailSent(false)
  //   }
  // }, [requestingConfirmEmail, error])

  // const resendConfirmationEmail = (user: User) => {
  //   requestConfirmEmail(user.email)
  // }

  const handleDeactivateUserInvite = (invite: UserInvite, account: Account) => {
    const updatedInvite: UserInvite = { ...invite, active: false }
    updateUserInvite(updatedInvite, account)
  }

  const inviteStatus = (invite: UserInvite) => {
    if (invite.accountCreated) {
      return (
        <span>
          <FaCheck color={'green'} className={'mr-1'} size={28} />
          Account created
        </span>
      )
    } else if (moment(invite.expiryDate) < moment.utc()) {
      return (
        <span>
          <FaRegClock className={'text-danger mr-1'} size={28} />
          Expired
        </span>
      )
    } else if (invite.active && !invite.accountCreated) {
      return (
        <span>
          <RiMailSendFill color={'green'} className={'mr-1'} size={28} />
          Mail sent
        </span>
      )
    } else {
      return (
        <span>
          <GiCancel className={'text-danger mr-1'} size={28} />
          Inactive
        </span>
      )
    }
  }

  const handleCreateComment = async (
    newComment: string,
    newDepartment: string,
    newCategory: string,
  ) => {
    const newCommentData = {
      accountId: selectedAccount.id,
      comment: newComment,
      department: newDepartment,
      category: newCategory,
    }

    createNewAccountComment(newCommentData)
  }

  const handleCommentEdition = async (comment: IComments, updatedComment: string) => {
    const updatedCommentData = {
      accountId: selectedAccount.id,
      comment_id: comment.id,
      comment: updatedComment,
      department: comment.department,
      category: comment.category,
    }

    updateComment(updatedCommentData)
  }

  const inactiveUsers = accountUsers.filter((user: User) => !user.active)

  const getUsersTableHeader = () => {
    return (
      <thead>
      <tr>
        {hasPermissions(permissions, ['ACCOUNT:ADMIN:SMARTFLOW']) && <th></th>}
        <th>First Name</th>
        <th>Last Name</th>
        <th>E-Mail</th>
        <th>Status</th>
        <th>Account Admin</th>
        <th>Account Contact</th>
        <th>Locations</th>
        <th>Actions</th>
      </tr>
      </thead>
    )
  }

  const getUsersTableBody = (user: any) => {
    return (
      <tr key={user.id}>
        {hasPermissions(permissions, ['ACCOUNT:ADMIN:SMARTFLOW']) && (
          <td>
            {user.customerLocations.some((loc: any) => loc.emergencyContact) ? (
              <Phone color="red" />
            ) : (
              ''
            )}
          </td>
        )}

        <td>{user.firstname}</td>
        <td>{user.lastname}</td>
        <td>{user.email}</td>
        <td>{user.active ? 'Active' : 'Inactive'}</td>
        <td>
          {user.adminAccountIds.includes(selectedAccount.id) ? <FaCheck color={'green'} className={'mr-1'} size={28} /> : <FaXmark color={'red'} className={'mr-1'} size={28} />}
        </td>
        <td>{user.accountContactIds.includes(selectedAccount.id) ? <FaCheck color={'green'} className={'mr-1'} size={28} /> : <FaXmark color={'red'} className={'mr-1'} size={28} />}</td>
        <td>
        {accountLocations.every((loc) => user.customerLocationIds.includes(loc.id!)) ? (
            'all locations'
          ) : (
            <ul>
              {user.customerLocationIds
                .map((lid: string) => accountLocations.find((l) => l.id === lid))
                .filter((loc: any) => !!loc)
                .map((location: CustomerLocation) => (
                  <li key={location.id}>
                    {location.name}
                    {user.adminCustomerLocationIds.includes(location.id) ? (
                      <span className="badge badge-primary ml-1">Admin</span>
                    ) : null}
                  </li>
                ))}
            </ul>
          )}
        </td>
        <td>
          {hasPermissions(permissions, ['UPDATE:USER']) ? (
            <>
              <OverlayTrigger
                delay={{ hide: 450, show: 300 }}
                overlay={(props: any) => <Tooltip {...props}>Edit User</Tooltip>}
                placement="bottom"
              >
                <i
                  className="fa fa-pencil-alt fa-lg mr-3 text-info"
                  style={{ cursor: 'pointer' }}
                  onClick={() => setEditModalUser(user)}
                ></i>
              </OverlayTrigger>
              <OverlayTrigger
                delay={{ hide: 450, show: 300 }}
                overlay={(props: any) => <Tooltip {...props}>Edit Alerts Subscriptions</Tooltip>}
                placement="bottom"
              >
                <i
                  className="fa fa-bell fa-lg mr-3 text-info"
                  style={{ cursor: 'pointer' }}
                  onClick={() => setEditUserAlertsModal(user)}
                ></i>
              </OverlayTrigger>
            </>
          ) : null}
        </td>
      </tr>
    )
  }

  return (
    <div>
      <div className="mt-5">
        {!selectedAccount.isActive && (
          <h4 className="text-danger font-weight-bold mb-5">
            <u>*This account is Inactive*</u>
          </h4>
        )}
        <Tab.Container defaultActiveKey="locations_and_devices">
          <Row>
            <Col sm={2}>
              <Nav variant="pills" className="flex-column">
                {canUserSeeLocations && (
                  <Nav.Item>
                    <Nav.Link eventKey="locations_and_devices">Locations & Devices</Nav.Link>
                  </Nav.Item>
                )}
                <Nav.Item>
                  <Nav.Link eventKey="users_and_invitations">Users & Invitations</Nav.Link>
                </Nav.Item>
                {hasPermissions(permissions, ['READ:ACCOUNT:COMMENT']) && (
                  <Nav.Item>
                    <Nav.Link eventKey="account_notes">Account Notes</Nav.Link>
                  </Nav.Item>
                )}
                {hasPermissions(permissions, ['ACCOUNT:ADMIN:SMARTFLOW']) && (
                  <Nav.Item>
                    <Nav.Link eventKey="account_reports">Reports</Nav.Link>
                  </Nav.Item>
                )}
              </Nav>
            </Col>
            <Col sm={10}>
              <Tab.Content>
                <Tab.Pane eventKey="locations_and_devices">
                  <>
                    <div style={{ display: 'flex' }} className="mb-2">
                      <h2 className="my-auto mr-3">{`Locations ${accountLocations.length > 3 ? '(' + accountLocations.length + ')' : ''}`}</h2>
                      {canUserUpdateLocations ? (
                        <button
                          onClick={() => setEditModalLocation({} as any)}
                          className="my-auto btn btn-sm btn-primary"
                        >
                          <FaPlus className={'mr-1'} />
                          Add Location to Account
                        </button>
                      ) : null}
                    </div>
                    <Table>
                      <thead>
                      <tr>
                        <th>Location</th>
                        <th>Sub Entity</th>
                        <th>Active</th>
                        <th>Suspended</th>
                        <th>&nbsp;</th>
                      </tr>
                      </thead>
                      <tbody>
                        {accountLocations.map((loc: CustomerLocation) => (
                          <tr
                            key={loc.id}
                            className={
                              (selectedLocation?.id === loc.id ? 'selected table-primary' : '') +
                              ' selectable'
                            }
                          >
                            <td onClick={() => setSelectedLocation(loc)}>{loc.name}</td>
                            <td onClick={() => setSelectedLocation(loc)}>{loc.companyAlias}</td>
                            <td onClick={() => setSelectedLocation(loc)}>{loc.active  ? <FaCheck color={'green'} className={'mr-1'} size={28} /> : <FaXmark color={'red'} className={'mr-1'} size={28} />}</td>
                            <td onClick={() => setSelectedLocation(loc)}>{loc.suspended ? <FaCheck color={'green'} className={'mr-1'} size={28} /> : <FaXmark color={'red'} className={'mr-1'} size={28} />}</td>
                            <td>
                              {canUserUpdateLocations ? (
                                <button
                                  className="btn btn-sm btn-info mr-1"
                                  onClick={() => setEditModalLocation(loc)}
                                >
                                  <FaCog className={'mr-1'} />
                                  Edit
                                </button>
                              ) : null}
                              {hasPermissions(permissions, ['DELETE:CUST_LOCATION']) ? (
                                <button
                                  onClick={() => setDeleteLocationModal(loc as any)}
                                  className="btn btn-sm btn-danger mr-1"
                                >
                                  <FaTrash className={'mr-1'} />
                                  Delete
                                </button>
                              ) : null}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>

                    <div>
                      <div style={{ display: 'flex' }} className="mb-2">
                        <h2 className="my-auto mr-3">{`Devices ${locationDevices.length > 0 ? '(' + locationDevices.length + ')' : ''}`}</h2>
                        {canUserCreateDevices && selectedLocation ? (
                          <button
                            onClick={() => setAddDeviceModal({} as any)}
                            className="my-auto btn btn-sm btn-primary"
                          >
                            <FaPlus className={'mr-1'} />
                            New Device
                          </button>
                        ) : null}
                      </div>
                      {selectedLocation ? (
                        locationDevices.length === 0 ? (
                          <div className={'my-3'}>
                            <h5 className="my-auto mr-3">
                              No devices associated with this location
                            </h5>
                          </div>
                        ) : (
                          <Table>
                            <thead>
                              <tr>
                                <th>Device Name</th>
                                <th>Device Id</th>
                                <th>&nbsp;</th>
                              </tr>
                            </thead>
                            <tbody>
                              {locationDevices.map((device) => (
                                <tr key={device.deviceId}>
                                  <td>{device.deviceName}</td>
                                  <td>{device.deviceId}</td>
                                  <td>
                                    {hasPermissions(permissions, ['DEVICE:MOVE:STGD_PROD']) ? (
                                      <button
                                        onClick={() => setSwapDeviceModal(device)}
                                        className="btn btn-sm btn-info mr-1"
                                      >
                                        <FaExchangeAlt className={'mr-1'} />
                                        Swap
                                      </button>
                                    ) : null}

                                    {/*{hasPermissions(permissions, ["DELETE:ACCOUNT:DEVICE"]) ?*/}
                                    {/*    <button*/}
                                    {/*        onClick={() => setDeleteDeviceModal(device)}*/}
                                    {/*        className="btn btn-sm btn-danger mr-1"*/}
                                    {/*    >*/}
                                    {/*        <span className="fas fa-trash mr-1"></span>*/}
                                    {/*        Delete*/}
                                    {/*    </button> : null*/}
                                    {/*}*/}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        )
                      ) : (
                        <div className={'my-3'}>
                          <h5 className="my-auto mr-3">Click a location to view devices</h5>
                        </div>
                      )}
                    </div>
                  </>
                </Tab.Pane>
                <Tab.Pane eventKey="users_and_invitations">
                  <>
                    <div>
                      <Tabs
                        id="controlled-tab"
                        activeKey={tabKey}
                        onSelect={(k: any) => setTabKey(k)}
                      >
                        <Tab eventKey="users_tab" title="Users">
                          <div className="mb-3 d-flex">
                            <h2 className="my-auto mr-3 mt-3">Users</h2>
                            {hasPermissions(permissions, ['CREATE:USER:INVITE']) ? (
                              <button
                                onClick={() => setInviteUserModal({} as any)}
                                className="my-auto btn btn-sm btn-primary mr-1"
                              >
                                <FaPlus className={'mr-1'} />
                                Invite New User
                              </button>
                            ) : null}
                          </div>
                          <Table>
                            {getUsersTableHeader()}
                            <tbody>
                              {accountUsers
                                .filter((user: User) => user.active)
                                .map((user: any) => getUsersTableBody(user))}
                            </tbody>
                          </Table>
                        </Tab>
                        {hasPermissions(permissions, ['READ:USER:INVITE']) ? (
                          <Tab
                            eventKey="invite_tab"
                            title="Invitations"
                            // className={'h-100'}
                          >
                            <div
                              // style={{ display: 'flex' }}
                              className="mb-3 mt-3"
                            >
                              <h2 className="my-auto mr-3">User Invitations</h2>
                            </div>
                            <Table>
                              <thead>
                                <tr>
                                  <th>E-Mail</th>
                                  <th>Status</th>
                                  <th>Created Date</th>
                                  <th>Modified Date</th>
                                  <th>Expires</th>
                                  <th>Used</th>
                                  <th>Actions</th>
                                </tr>
                              </thead>
                              <tbody>
                                {invitationsByAccount.map((i: any) => (
                                  <tr key={i.id}>
                                    <td>{i.email}</td>
                                    <td>{inviteStatus(i)}</td>
                                    <td>{moment(i.createAt).format('YYYY-MM-DD HH:mm')}</td>
                                    <td>
                                      {i.updatedAt
                                        ? moment(i.updatedAt).format('YYYY-MM-DD HH:mm')
                                        : null}
                                    </td>
                                    <td>{moment(i.expiryDate).format('YYYY-MM-DD HH:mm')}</td>
                                    <td>{
                                      i.accountCreated ? <FaCheck color={'green'} className={'mr-1'} size={28} /> : <FaXmark color={'red'} className={'mr-1'} size={28} />}
                                    </td>
                                    <td>
                                      {i.active ? (
                                        <>
                                          <OverlayTrigger
                                            delay={{ hide: 450, show: 300 }}
                                            overlay={(props: any) => (
                                              <Tooltip {...props}>Edit Invitation</Tooltip>
                                            )}
                                            placement="bottom"
                                          >
                                            <i
                                              className="fa fa-pencil-alt fa-lg mr-2 text-info "
                                              style={{ cursor: 'pointer' }}
                                              onClick={() => setEditInvitation(i)}
                                            ></i>
                                          </OverlayTrigger>

                                          {moment(i.expiryDate) > moment.utc() ? (
                                            <OverlayTrigger
                                              delay={{ hide: 450, show: 300 }}
                                              overlay={(props: any) => (
                                                <Tooltip {...props}>Resend Invitation</Tooltip>
                                              )}
                                              placement="bottom"
                                            >
                                              <i
                                                className="fa fa-envelope fa-lg mr-2 text-info "
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => setResendInviteModal(i)}
                                              ></i>
                                            </OverlayTrigger>
                                          ) : null}

                                          <OverlayTrigger
                                            delay={{ hide: 450, show: 300 }}
                                            overlay={(props: any) => (
                                              <Tooltip {...props}>Deactivate Invitation</Tooltip>
                                            )}
                                            placement="bottom"
                                          >
                                            <i
                                              className="fa fa-minus-circle fa-lg text-danger "
                                              style={{ cursor: 'pointer' }}
                                              onClick={() => setDeactivateInviteModal(i)}
                                            ></i>
                                          </OverlayTrigger>
                                        </>
                                      ) : null}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          </Tab>
                        ) : null}

                        {inactiveUsers.length ? (
                          <Tab eventKey="inactive_users_tab" title="Inactive Users">
                            <Table>
                              {getUsersTableHeader()}
                              <tbody>
                                {inactiveUsers.map((user: any) => getUsersTableBody(user))}
                              </tbody>
                            </Table>
                          </Tab>
                        ) : null}
                      </Tabs>
                    </div>
                  </>
                </Tab.Pane>
                <Tab.Pane eventKey={'account_notes'}>
                  <div className="ml-5">
                    <CommentComponent
                      comments={accountComments}
                      handleNewComment={handleCreateComment}
                      handleUpdateComment={handleCommentEdition}
                      showDepartmentAndCategory
                      isSuccess={isSuccess}
                      isPending={isPending}
                      isError={isError}
                    />
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey={'account_reports'}>
                  <AccountReports account={selectedAccount} />
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </div>

      {selectedAccount && editModalUser !== undefined ? (
        <UserEditModal
          show={true}
          user={editModalUser}
          account={selectedAccount!}
          onHide={() => setEditModalUser(undefined)}
        />
      ) : null}
      {selectedAccount && inviteUserModal ? (
        <InviteModal
          show={true}
          user={inviteUserModal}
          account={selectedAccount}
          onHide={() => setInviteUserModal(undefined)}
        />
      ) : null}
      {selectedAccount && editInvitation ? (
        <EditInviteModal
          show={true}
          invite={editInvitation}
          account={selectedAccount!}
          onHide={() => setEditInvitation(undefined)}
        />
      ) : null}
      {editModalLocation !== undefined ? (
        <LocationEditModal
          show={true}
          account={selectedAccount!}
          data={editModalLocation!}
          onHide={() => setEditModalLocation(undefined)}
        />
      ) : null}
      <AddDeviceModal
        show={addDeviceModal}
        customerLocation={selectedLocation as any}
        onHide={() => setAddDeviceModal(false)}
      />
      {swapDeviceModal !== undefined ? (
        <SwapDeviceModal
          show={true}
          data={swapDeviceModal}
          onHide={() => setSwapDeviceModal(undefined)}
          onSave={(device) => {
            swapDevice(swapDeviceModal!, device)
            setSwapDeviceModal(undefined)
          }}
        />
      ) : null}
      {deleteUserModal !== undefined ? (
        <DeleteModal
          show={true}
          data={deleteUserModal}
          onHide={() => setDeleteUserModal(undefined)}
          onDelete={(user: User) => removeUserFromAccount(user, selectedAccount.id)}
          toString={(user: User) => `${user.firstname} ${user.lastname}`}
          name={'User'}
        />
      ) : null}
      {deleteDeviceModal !== undefined ? (
        <DeleteModal
          show={true}
          data={deleteDeviceModal}
          onHide={() => setDeleteDeviceModal(undefined)}
          onDelete={(device: Device) => removeDeviceFromAccount(device, selectedAccount)}
          toString={(device: Device) => `${device.deviceName} (#${device.deviceId})`}
          name={'Device'}
        />
      ) : null}
      {deleteLocationModal !== undefined ? (
        <DeleteModal
          show={true}
          data={deleteLocationModal}
          onHide={() => setDeleteLocationModal(undefined)}
          onDelete={(loc: CustomerLocation) => removeLocationFromAccount(loc, selectedAccount)}
          toString={(loc: CustomerLocation) => `${loc.name}`}
          name={'Location'}
        />
      ) : null}
      {deactivateInviteModal !== undefined ? (
        <DeleteModal
          show={true}
          data={deactivateInviteModal}
          onHide={() => setDeactivateInviteModal(undefined)}
          onDelete={(invite: UserInvite) => handleDeactivateUserInvite(invite, selectedAccount)}
          toString={(invite: UserInvite) => `${invite.email}`}
          name={'Invitation'}
          action={'Deactivate'}
        />
      ) : null}
      {resendInviteModal !== undefined ? (
        <ResendModal
          show={true}
          data={resendInviteModal}
          onHide={() => setResendInviteModal(undefined)}
          onSend={(invite: UserInvite) => resendUserInvite(invite.id)}
          toString={(invite: UserInvite) => `${invite.email}`}
          name={'Invitation'}
          action={'Resend'}
        />
      ) : null}

      {selectedAccount && editUserAlertsModal ? (
        <AlertEditModal
          show={true}
          user={editUserAlertsModal}
          account={selectedAccount!}
          onHide={() => setEditUserAlertsModal(undefined)}
        />
      ) : null}
    </div>
  )
}

export default AccountDetails
