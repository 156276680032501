import React, { useState, useEffect } from 'react'
import { Modal, Form, Button, Col, Row } from 'react-bootstrap'
import Select from 'react-select'
import { useDeviceState } from '@context/device/context/device.context'
import Device, { TestDevice } from '@context/device/model/device'

import './Modal.scss'

interface IModalProps {
  show: boolean
  data?: Device
  onSave: (device: TestDevice) => void
  onHide: () => void
}

const SwapDeviceModal: React.FC<IModalProps> = ({ show, data, onSave, onHide }: IModalProps) => {
  const [selectedDevice, setSelectedDevice] = useState<TestDevice>()
  const { stagedDevices } = useDeviceState()

  useEffect(() => {}, [])

  return (
    <Modal
      onHide={onHide}
      show={show}
      backdrop="static"
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="modal-header" closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {data
            ? 'Swap Device for #' + data.deviceId + ' (' + data.deviceName + ')'
            : 'Swap Devices'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form className="device-information-form">
          <Row>
            <Form.Group as={Col} controlId="formGridName">
              <Form.Label>Staged Device:</Form.Label>
              <Select
                classNamePrefix="select"
                value={selectedDevice}
                onChange={(d: any) => setSelectedDevice(d)}
                options={stagedDevices}
                getOptionValue={(option) => option.deviceId}
                getOptionLabel={(option) => `${option.deviceName}`}
                isClearable={false}
              />
            </Form.Group>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button className="btn btn-secondary" onClick={onHide}>
          Cancel
        </Button>
        <Button disabled={!selectedDevice} onClick={() => onSave(selectedDevice!)}>
          Activate Device
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default SwapDeviceModal
