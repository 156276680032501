export const DEPARTMENT_VALUES = ['tech', 'sales', 'accounts', 'operations', 'monitoring']

export const CATEGORY_VALUES = [
  'general',
  'device_replacement',
  'sensor_replacement',
  'alerts',
  'device_issue',
  'network_issue',
]
