export interface AlertGroup {
  id: string
  deviceId: string
  deviceLocation: string
  priorityTier: number
  status: AlertStatus
  startAlertTime: string
  endAlertTime: string
  startAlertTimeUTC: string
  endAlertTimeUTC: string
  alertCloseDate?: string
  alerts: Alert[]
  triggerType: string
  hourlyAlertsSentDate: string
  dlId: number
  ackBy?: string
  estimatedLeakTotal: number
  deviceUom?: string
}

export interface Alert {
  id: number
  time: string
  createdAt: string
  priorityTier: number
  alert_score: number
  status: AlertStatus
  triggerType: string
  actioned: boolean
  ackBy?: string
  estimatedLeak: number
  valid: boolean
  excludeUsage?: boolean
  gid: string
  hourlyAlertsSent: HourlyAlertSent
}

interface HourlyAlertSent {
  action_type: number
  sent_at: string
  user_id_ref: number
}

export interface HistoricalAlert {
  deviceId: string
  tier1: string[]
  tier2: string[]
  tier3: string[]
}

export enum AlertStatus {
  OPEN,
  IN_PROGRESS,
  CLOSED,
}

export interface AlertStat {
  ackt_result: number
  alert_count: number
}

export interface AlertStats {
  [key: string]: AlertStat[]
}

export interface AlertQuery {
  queryStart: string
  queryEnd: string
}

export interface Stats {
  tier1Total: number
  tier2Total: number
  tier3Total: number
  totalAll: number
}

export interface StatsDevices extends Stats {
  devices: {
    deviceId: string
    deviceName: string
    tier1: number
    tier2: number
    tier3: number
    total: number
  }[]
}
