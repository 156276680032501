import moment from 'moment-timezone'
import { useEffect, useRef, useState } from 'react'

import { Card, Col, Container, Modal, Row, Tab, Tabs } from 'react-bootstrap'

import {
  ArcElement,
  Chart as ChartJS,
  Legend,
  Tooltip,
  CategoryScale,
  LinearScale,
  BarElement,
  InteractionItem,
} from 'chart.js'
import { Pie, Bar, getElementAtEvent } from 'react-chartjs-2'

import { useDeviceState } from '@context/device/context/device.context'
import Device from '@context/device/model/device'
import { WaterUsageStats } from '@data/waterUsage/model/waterUsage.model'

import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Button from 'react-bootstrap/Button'
import ButtonToolbar from 'react-bootstrap/Dropdown'
import { BsFillExclamationCircleFill } from 'react-icons/bs'
import { useUserState } from '@context/user/context/user.context'
import { useWaterStatsFilter, useWaterUsageStat } from '@data/waterUsage/waterUsage'
import { calculateCo2Produced } from '@common/utils/helperFunctions'

import 'bootstrap/dist/css/bootstrap.css'
import './layout.scss'

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement)

// export interface MonthDataAgg {
//     [key: string]: {
//         monthlyAverage: number;
//         monthlyUsage: number;
//     }
// }

export interface MonthDataAgg {
  [key: string]: number
}

export interface UsageData {
  [key: string]: number
}

export interface YearData {
  label: string
  data: UsageData
  backgroundColor?: string
}

export interface YearDataSet {
  [key: string]: YearData
}

export interface GraphDataSet {
  label: string
  data: number[]
}

export interface ModalMonthData {
  month: string
  usage: number
}

export interface ModalGraphData {
  [key: string]: ModalMonthData
}

const canvasBackgroundColour = {
  id: 'canvasBackgroundColour',
  beforeDraw: function (chart: any, options: any) {
    const { ctx } = chart
    ctx.save()
    ctx.globalCompositeOperation = 'destination-over'
    ctx.fillStyle = 'rgba(135, 187, 250, 0.0)'
    ctx.fillRect(0, 0, chart.width, chart.height)
    ctx.restore()
  },
}

export function UsageBarChart({ filteredAggStats }: { filteredAggStats: any }) {
  const { loadingDevices } = useDeviceState()
  const { userInfo } = useUserState()
  const [graphData, setGraphData] = useState<GraphDataSet[] | null>(null)
  const [graphLabels, setGraphLabels] = useState<number[]>([])
  const chartRef = useRef<ChartJS<'bar', number[], string>>(null)
  const [showUsageByDeviceModal, setShowUsageByDeviceModal] = useState<boolean>(false)
  const [modalGraphData, setModalGraphData] = useState<{
    data: ModalGraphData
    month: string
    year: string
  }>({ data: {}, month: '', year: '' })
  // const loadingDevices2 = true
  const graphBgColours: string[] = [
    'rgba(255, 206, 86, 0.8)',
    'rgba(54, 162, 235, 0.8)',
    'rgb(124,179, 246, 0.8)',
    'rgb(255, 99, 132)',
    'rgb(75, 192, 192)',
  ]

  useEffect(() => {
    if (Object.keys(filteredAggStats).length === 0) {
      setGraphData(null)
      setGraphLabels([])
      return
    }
    const currentYear = moment.utc().year()
    const currentMonth = moment.utc().month() + 1
    let maxMonthNum: number = 12
    let minMonthNum: number = 1
    const deviceData: YearDataSet = Object.keys(filteredAggStats).reduce(
      (acc: YearDataSet, currentValue: string) => {
        const yearData = filteredAggStats[currentValue]
        const yearKeys: string[] = Object.keys(yearData)
        // if (yearKeys.includes(currentYear.toString())) maxMonthNum = currentMonth
        // if (yearKeys.includes(currentYear.toString()) && currentMonth < minMonthNum) minMonthNum = currentMonth;

        yearKeys.forEach((year) => {
          if (!acc[year]) {
            acc[year] = {
              label: year,
              data: {},
            } as YearData
          }
          const monthData: MonthDataAgg = yearData[year]
          Object.keys(monthData).forEach((monthNum: string) => {
            if (year === currentYear.toString() && parseInt(monthNum) < minMonthNum)
              minMonthNum = parseInt(monthNum)

            const monthlyUsage = monthData[monthNum]
            if (!acc[year].data[monthNum]) {
              acc[year].data[monthNum] = monthlyUsage
            } else {
              acc[year].data[monthNum] += monthlyUsage
            }
          })
        })
        return acc
      },
      {},
    )

    // let labels: number[] = []
    let requiredMonths: { [key: number]: number } = {}

    for (let i = minMonthNum; i <= maxMonthNum; i++) {
      requiredMonths[i] = 0
    }

    const graphData: GraphDataSet[] = Object.values(deviceData).map(
      (data: YearData, index: number) => {
        // Object.keys(data.data).forEach((d) => {
        //   if (parseInt(d) <= maxMonthNum && !labels.includes(parseInt(d))) labels.push(parseInt(d))
        // })

        const dataAllMonths: UsageData = { ...requiredMonths, ...data.data }
        // return {...data, data: Object.values(data.data), backgroundColor: graphBgColours[index]}
        return {
          ...data,
          data: Object.values(dataAllMonths),
          backgroundColor: graphBgColours[index],
        }
      },
    )
    // const sortedLabels: number[] = labels.sort((a, b) => a - b)
    const labels = Array.from(Array(12), (_, index) => index + 1)
    setGraphData(graphData)
    setGraphLabels(labels)
  }, [filteredAggStats])

  const onClick = (event: any) => {
    if (!chartRef || !graphData) return

    const { current } = chartRef
    if (!current) return

    const datasetElement: InteractionItem[] = getElementAtEvent(current, event)
    if (datasetElement.length === 0) return

    const clickedYear: string = graphData[datasetElement[0].datasetIndex].label
    const clickedMonth: number = graphLabels[datasetElement[0].index]

    const deviceData: ModalGraphData = Object.keys(filteredAggStats).reduce(
      (acc: ModalGraphData, dlID: string) => {
        const filteredYear = Object.keys(filteredAggStats[dlID])
          .filter((year: string) => year === clickedYear)
          .reduce((cur: any, yearKey: string) => {
            const monthData = filteredAggStats[dlID][yearKey]
            const filteredMonthData = Object.keys(monthData)
              .filter((month: string) => parseInt(month) === clickedMonth)
              .reduce((acc: any, monthKey) => {
                return { ...acc, month: monthKey, usage: monthData[monthKey] }
              }, {})
            return { ...cur, ...filteredMonthData }
          }, {})

        if (Object.keys(filteredYear).length !== 0) {
          if (!acc[dlID]) acc[dlID] = filteredYear
        }
        return acc
      },
      {},
    )
    const data: { data: ModalGraphData; month: string; year: string } = {
      data: deviceData,
      month: clickedMonth.toString(),
      year: clickedYear,
    }
    setModalGraphData(data)
    setShowUsageByDeviceModal(true)
  }

  return (
    <>
      <Card className="summary-card card-bg-colour" style={{ width: '100%', position: 'relative' }}>
        <Card.Body>
          {!graphData && loadingDevices ? (
            <Container fluid className={'px-0'}>
              <Row className={'no-gutters align-items-center'}>
                <Col>
                  <div className="d-flex justify-content-center m-auto h-100">
                    <div className="spinner-border text-primary m-auto">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          ) : (
            <Bar
              options={{
                responsive: true,
                aspectRatio: 3.0,
                plugins: {
                  legend: {
                    display: true,
                  },
                  tooltip: {
                    callbacks: {
                      label: function (context: any) {
                        const { formattedValue } = context
                        return [`${formattedValue} ${userInfo.preferences?.uom || 'Liters'}`]
                      },
                    },
                  },
                },
                scales: {
                  y: {
                    position: 'left',
                    title: {
                      display: true,
                      text: `${userInfo.preferences?.uom || 'Liters'} Consumed`,
                    },
                    grid: {
                      display: false,
                    },
                    ticks: {
                      suggestedMin: 0,
                    },
                  },
                } as any,
              }}
              data={{
                labels: graphLabels.map((value: number) => moment(value, 'MM').format('MMM')),
                datasets: graphData || [],
              }}
              plugins={[canvasBackgroundColour]}
              onClick={onClick}
              ref={chartRef}
            />
          )}
        </Card.Body>
      </Card>
      {showUsageByDeviceModal ? (
        <UsageByDeviceModal
          onHide={() => setShowUsageByDeviceModal(false)}
          modalGraphData={modalGraphData}
        />
      ) : null}
    </>
  )
}

export function UsagePieChart({ filteredAggStats }: { filteredAggStats: any }) {
  const { devices, loadingDevices } = useDeviceState()
  const { userInfo } = useUserState()
  // const loadingDevices2 = true
  const [tabKey, setTabKey] = useState<string>('top5')
  const [totalUsage, setTotalUsage] = useState<number>(0)

  const currentYear = moment.utc().year()
  const currentMonth = moment.utc().month() + 1
  const prevMonth = moment.utc().month()

  const {
    data: waterUsageStats,
    isLoading,
    isFetching,
  } = useWaterUsageStat(userInfo.preferences.uom)

  const {
    data: filteredUsageStats,
    isLoading: loadingFilters,
    isFetching: fetchingFilters,
  } = useWaterStatsFilter(devices, waterUsageStats)

  useEffect(() => {
    const totalUsage =
      filteredUsageStats?.filteredWaterUsageStats &&
      Object.values(filteredUsageStats?.filteredWaterUsageStats).reduce(
        (acc: number, u: WaterUsageStats) => {
          return acc + u.yearlyUsage
        },
        0,
      )

    if (totalUsage) {
      setTotalUsage(totalUsage)
    }
  }, [filteredUsageStats?.filteredWaterUsageStats])

  const sortedTop5: WaterUsageStats[] | undefined =
    filteredUsageStats?.filteredWaterUsageStats &&
    Object.values(filteredUsageStats?.filteredWaterUsageStats)
      .sort((a: WaterUsageStats, b: WaterUsageStats) => b.yearlyUsage - a.yearlyUsage)
      .slice(0, 5)

  const labelsTop5: (string | undefined)[] | undefined =
    sortedTop5 &&
    sortedTop5.map((u: WaterUsageStats) => {
      const device: Device | undefined = devices.find((d) => d.deviceId === u.deviceId)
      if (device) {
        return device.deviceName
      }
    })

  const monthlyAverage: { [key: string]: number } = Object.keys(filteredAggStats).reduce(
    (acc: any, deviceID: string) => {
      const yearData = filteredAggStats[deviceID]
      const filteredYear: number = Object.keys(yearData)
        .filter((value: string) => value === currentYear.toString())
        .reduce((cur: number, yearKey: string) => {
          const monthData: MonthDataAgg = yearData[yearKey]
          const totalMonthlyUsage = Object.keys(monthData)
            .filter((month) => month !== currentMonth.toString())
            .reduce(
              (acc, currentVal, currentIndex) => {
                const monthlyUsage = monthData[currentVal]
                return {
                  total: acc.total + monthlyUsage,
                  noMonths: currentIndex + 1,
                  min: prevMonth.toString() === currentVal ? monthlyUsage : acc.min,
                }
              },
              { total: 0, noMonths: 0, min: 0 },
            )
          const avgDiff: number =
            totalMonthlyUsage.total / totalMonthlyUsage.noMonths - totalMonthlyUsage.min
          return avgDiff && avgDiff > 0 ? avgDiff : 0
        }, 0)

      if (filteredYear > 0) {
        return { ...acc, [deviceID]: filteredYear }
      } else {
        return acc
      }
    },
    {},
  )
  const s: [string, number][] = Object.entries(monthlyAverage)
    .sort((a: [string, number], b: [string, number]) => {
      return b[1] - a[1]
    })
    .slice(0, 5)

  const labelsTopSavers: (string | undefined)[] = s.map((u: [string, number]) => {
    const device: Device | undefined = devices.find((d: Device) => d.dlId?.toString() === u[0])
    if (device) {
      return device.deviceName
    }
  })

  return (
    <Card
      className="summary-card card-bg-colour h-100"
      style={{ width: '100%', position: 'relative' }}
    >
      <Card.Body className={'top5-stats'}>
        <Tabs id="controlled-tab" activeKey={tabKey} onSelect={(k: any) => setTabKey(k)}>
          <Tab eventKey="top5" title="Top 5 Consumers" className={'h-100'}>
            {loadingDevices || isLoading || isFetching || loadingFilters || fetchingFilters ? (
              <Container fluid className={'px-0'}>
                <Row className={'no-gutters align-items-center'}>
                  <Col>
                    <div className="d-flex justify-content-center m-auto h-100">
                      <div className="spinner-border text-primary m-auto">
                        <span className="sr-only">Loading...</span>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            ) : (
              <div className="container">
                <div className="row justify-content-center align-items-center">
                  <div className={'mt-3'}>
                    <h4 className={'text-secondary font-weight-bold'}>
                      Top Consumers {moment.utc().year()}
                    </h4>
                  </div>
                  <Pie
                    options={{
                      responsive: true,
                      aspectRatio: 1.5,
                      layout: {
                        padding: {
                          top: 5,
                        },
                      },
                      plugins: {
                        legend: {
                          display: true,
                          position: 'bottom' as const,
                          align: 'start',
                        },
                        // title: {
                        //     display: true,
                        //     text: "My chart Title",
                        //     position: "top",
                        //     align: "center",
                        //     padding: 5,
                        //     font: {
                        //         size: 15,
                        //     },
                        // },
                        tooltip: {
                          callbacks: {
                            label: function (context: any) {
                              const { parsed, label } = context
                              const totalTop5: number =
                                context.chart._metasets[context.datasetIndex].total
                              const percentageTop5: number = parseFloat(
                                ((parsed / totalTop5) * 100).toFixed(1),
                              )
                              const percentageTotal: number = parseFloat(
                                ((parsed / totalUsage) * 100).toFixed(1),
                              )
                              return [
                                `${label}`,
                                `${percentageTotal}% of Total Usage`,
                                `${percentageTop5}% of Top 5 Usage`,
                              ]
                            },
                          },
                        },
                      },
                    }}
                    data={{
                      labels: labelsTop5,
                      datasets: [
                        {
                          label: 'Total Percentage',
                          data: sortedTop5?.map((u) => u.yearlyUsage),
                          backgroundColor: [
                            'rgba(255, 99, 132, 0.8)',
                            'rgba(54, 162, 235, 0.8)',
                            'rgba(255, 206, 86, 0.8)',
                            'rgba(75, 192, 192, 0.8)',
                            'rgba(153, 102, 255, 0.8)',
                            'rgba(255, 159, 64, 0.8)',
                          ],
                          // borderColor: [
                          //     "rgba(255, 99, 132, 1)",
                          //     "rgba(54, 162, 235, 1)",
                          //     "rgba(255, 206, 86, 1)",
                          //     "rgba(75, 192, 192, 1)",
                          //     "rgba(153, 102, 255, 1)",
                          //     "rgba(255, 159, 64, 1)",
                          // ],
                          hoverOffset: 4,
                        },
                      ],
                    }}
                    plugins={[canvasBackgroundColour]}
                  />
                </div>
              </div>
            )}
          </Tab>
          <Tab eventKey="top5_save" title="Top 5 Savers" className={'h-100'}>
            {sortedTop5 ? (
              <div className="container">
                <div className="row justify-content-center align-items-center">
                  {s.length === 0 ? (
                    <div>
                      <BsFillExclamationCircleFill size={50} color={'red'} />
                      <span className={'pl-2'}>
                        No reduction in usage for {moment(prevMonth, 'MM').format('MMMM')}
                      </span>
                    </div>
                  ) : (
                    <>
                      <div className={'mt-3'}>
                        <h4 className={'text-secondary font-weight-bold'}>
                          Top savers for month of {moment(prevMonth, 'MM').format('MMMM')}
                        </h4>
                      </div>
                      <Pie
                        options={{
                          responsive: true,
                          aspectRatio: 1.5,
                          layout: {
                            padding: {
                              top: 5,
                            },
                          },
                          plugins: {
                            legend: {
                              display: true,
                              position: 'bottom' as const,
                              align: 'start',
                            },
                            tooltip: {
                              callbacks: {
                                label: function (context: any) {
                                  const { parsed } = context
                                  return [
                                    `${Math.round(parsed)} ${userInfo.preferences?.uom || 'Liters'} ${String.fromCharCode(
                                      8595,
                                    )} on monthly average`,
                                  ]
                                },
                              },
                            },
                          },
                        }}
                        data={{
                          labels: labelsTopSavers,
                          datasets: [
                            {
                              label: 'Total Percentage',
                              data: s.map((value: [string, number]) => value[1]),
                              backgroundColor: [
                                'rgba(255, 99, 132, 0.8)',
                                'rgba(54, 162, 235, 0.8)',
                                'rgba(255, 206, 86, 0.8)',
                                'rgba(75, 192, 192, 0.8)',
                                'rgba(153, 102, 255, 0.8)',
                                'rgba(255, 159, 64, 0.8)',
                              ],
                              // borderColor: [
                              // "rgba(0, 0, 0, 1)",
                              // "rgba(255, 99, 132, 1)",
                              // "rgba(54, 162, 235, 1)",
                              // "rgba(255, 206, 86, 1)",
                              // "rgba(75, 192, 192, 1)",
                              // "rgba(153, 102, 255, 1)",
                              // "rgba(255, 159, 64, 1)",
                              // ],
                              hoverOffset: 4,
                            },
                          ],
                        }}
                        plugins={[canvasBackgroundColour]}
                      />
                    </>
                  )}
                </div>
              </div>
            ) : (
              'Select an account'
            )}
          </Tab>
        </Tabs>
      </Card.Body>
    </Card>
  )
}

export function UsageByDeviceModal({
  onHide,
  modalGraphData,
}: {
  onHide: () => void
  modalGraphData: { data: ModalGraphData; month: string; year: string }
}) {
  const { devices } = useDeviceState()
  const { userInfo } = useUserState()
  const indexInterval: number = 20
  const [index, setIndex] = useState<{ start: number; end: number }>({
    start: 0,
    end: indexInterval,
  })
  const monthName: string = moment(modalGraphData.month, 'MM').format('MMMM')
  const sortedData: [string, ModalMonthData][] = Object.entries(modalGraphData.data).sort(
    (x: [string, ModalMonthData], y: [string, ModalMonthData]) => y[1].usage - x[1].usage,
  )
  const graphData: number[] = Object.values(sortedData.slice(index.start, index.end)).map(
    (value: [string, ModalMonthData]) => value[1].usage,
  )
  const graphLabels: (string | undefined)[] = sortedData
    .slice(index.start, index.end)
    .map((value: [string, ModalMonthData]) => {
      const d = devices.find((d) => d.dlId?.toString() === value[0])
      if (d) return d.deviceName
    })

  const clickNext = (newIndex: number) => {
    if (newIndex > sortedData.length) newIndex = sortedData.length
    setIndex({ start: index.end, end: newIndex })
  }

  const clickPrev = (newIndex: number) => {
    if (newIndex < 0) newIndex = 0
    setIndex({ start: newIndex, end: index.start })
  }

  return (
    <Modal show={true} onHide={onHide} fullscreeen="true" size="xl">
      <Modal.Header className={'modal-header'} closeButton>
        <Modal.Title id={'contained-modal-title-vcenter'} className={'text-center w-100'}>
          {monthName} {modalGraphData.year} Usage per device
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className={'card-bg-colour'}>
        <div className="container">
          <div className={'row'}>
            <ButtonToolbar aria-label="Toolbar with button groups">
              <ButtonGroup className="mr-2" aria-label="First ">
                <Button
                  variant="outline-secondary"
                  disabled={index.start <= 0}
                  onClick={() => clickPrev(index.start - 20)}
                >
                  Prev {indexInterval}
                </Button>
              </ButtonGroup>

              <ButtonGroup aria-label="Second">
                <Button
                  variant="outline-secondary"
                  disabled={index.end >= sortedData.length}
                  onClick={() => clickNext(index.end + 20)}
                >
                  Next {indexInterval}
                </Button>
              </ButtonGroup>
            </ButtonToolbar>
          </div>
          <div className="row">
            <div className="col">
              <Bar
                options={{
                  responsive: true,
                  aspectRatio: 2,
                  plugins: {
                    legend: {
                      display: true,
                    },
                    tooltip: {
                      callbacks: {
                        label: function (context: any) {
                          const {
                            parsed: { y },
                            label,
                            formattedValue,
                          } = context
                          const device: Device | undefined = devices.find(
                            (d) => d.deviceName === label,
                          )
                          const waterType: string =
                            device && device?.deviceSettings.hot ? 'Hot Water' : 'Cold Water'
                          if (device) {
                            const co2Produced = calculateCo2Produced(
                              userInfo.preferences.uom,
                              y,
                              [device],
                              device.deviceSettings.hot ? 'hot' : 'cold',
                            )
                            return [
                              `${waterType} Device`,
                              `${formattedValue} ${userInfo.preferences.uom} used`,
                              `${Math.round(co2Produced.value) + co2Produced.unit} Co2 produced`,
                            ]
                          }
                        },
                      },
                    },
                  },
                  scales: {
                    y: {
                      position: 'left',
                      title: {
                        display: true,
                        text: `${userInfo.preferences?.uom || 'Liters'} Consumed`,
                      },
                      grid: {
                        display: false,
                      },
                      ticks: {
                        suggestedMin: 0,
                      },
                    },
                  } as any,
                }}
                data={{
                  labels: graphLabels,
                  datasets: [
                    {
                      label: moment(modalGraphData.month, 'MM').format('MMM'),
                      data: graphData,
                      backgroundColor: ['rgba(54, 162, 235, 0.8)'],
                    },
                  ],
                }}
                plugins={[canvasBackgroundColour]}
              />
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}
