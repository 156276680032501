import React, { useState } from 'react'
import { Button, Col, Form, InputGroup, Spinner, Row } from 'react-bootstrap'
import { EyeIcon, EyeOffIcon } from 'lucide-react'
import { Link, useParams } from 'react-router-dom'

import { useUserDispatch, useUserState } from '@context/user/context/user.context'
import {
  decodeJWT,
  validatePasswordStructure,
  validatePhoneNumberStructure,
} from '@common/utils/helperFunctions'
import { JwtPayloadCustom } from '@context/auth/model/token'
import { OptionsMap } from '../DeviceManagement/DeviceInformationManager/constants'

import './ConfirmEmail.scss'
const ConfirmEmail: React.FC = () => {
  const { confirmingEmail, emailConfirmed } = useUserState()
  const { registerUser } = useUserDispatch()
  let { token } = useParams<{ token: string }>()

  const [newFirstname, setNewFirstname] = useState<string>('')
  const [newLastname, setNewLastname] = useState<string>('')
  const [newMobile, setNewMobile] = useState<string>('')
  const [newPassword, setNewPassword] = useState<string>('')
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState<string>('')
  const [isPasswordShown, setIsPasswordShown] = useState<boolean>(false)

  const [isPasswordValid, setIsPasswordValid] = useState<boolean>(false)
  const [isMobiledValid, setIsMobiledValid] = useState<boolean>(false)
  const [doPasswordsMatch, setDoPasswordsMatch] = useState<boolean>(false)

  let defaultUom = 'Liters'

  if (token) {
    const decodedAccessToken: JwtPayloadCustom = decodeJWT(token)

    defaultUom = OptionsMap[decodedAccessToken.country].uom
  }

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    if (
      newFirstname &&
      newLastname &&
      newMobile &&
      newPassword &&
      newPasswordConfirmation &&
      isPasswordValid &&
      doPasswordsMatch
    ) {
      registerUser(
        newFirstname,
        newLastname,
        newMobile,
        newPassword,
        newPasswordConfirmation,
        String(token),
        defaultUom,
      )
    }
  }

  const onNewPasswordChange = (password: string) => {
    setNewPassword(password)
    const isValid = validatePasswordStructure(password)
    setIsPasswordValid(isValid)
  }

  const onMobileChange = (phone: string) => {
    setNewMobile(phone)
    const isValid = validatePhoneNumberStructure(phone)

    setIsMobiledValid(isValid)
  }

  const onNewConfirmationPasswordChange = (password: string) => {
    setNewPasswordConfirmation(password)
    const areMatching = newPassword === password
    setDoPasswordsMatch(areMatching)
  }

  if (!token) {
    return <div>Invalid token</div>
  }

  return (
    <div className="confirm-email background">
      <div className="login-form">
        <img src="/assets/img/logo.svg" alt="Smartflow Logo" width="200" />
        <h3 className="mt-3">Confirm your account</h3>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Form.Group as={Col} controlId="formFirstName">
              <Form.Label>First name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter a Firstname"
                value={newFirstname}
                onChange={(e) => setNewFirstname(e.target.value)}
              />
            </Form.Group>
          </Row>
          <Row className="mt-2">
            <Form.Group as={Col} controlId="formLastName">
              <Form.Label>Last name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter a Lastname"
                value={newLastname}
                onChange={(e) => setNewLastname(e.target.value)}
              />
            </Form.Group>
          </Row>
          <Row className="mt-2">
            <Form.Group as={Col} controlId="formMobile">
              <Form.Label>Mobile</Form.Label>
              <Form.Control
                type="tel"
                placeholder="Enter a Mobile"
                value={newMobile}
                onChange={(e) => onMobileChange(e.target.value)}
                isInvalid={!isMobiledValid && !!newMobile}
              />
              <Form.Control.Feedback type="invalid">
                <div className="input-feedback_error">Please enter a valid mobile number</div>
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row className="mt-2">
            <Form.Label>New Password</Form.Label>
            <InputGroup as={Col} controlId="forgot-email-id" className="form-group">
              <Form.Control
                type={isPasswordShown ? 'text' : 'password'}
                placeholder="Enter new password"
                onChange={(e) => onNewPasswordChange(e.currentTarget.value)}
                isInvalid={!!newPassword && !isPasswordValid}
              />
              <InputGroup.Text
                role="button"
                className="rounded-right"
                onClick={() => setIsPasswordShown(!isPasswordShown)}
              >
                {isPasswordShown ? <EyeIcon /> : <EyeOffIcon />}
              </InputGroup.Text>
              <Form.Control.Feedback type="invalid">
                <div className="input-feedback_error">
                  Password should:
                  <ul>
                    <li>be (at least) 8 characters long </li>
                    <li>have at least 1 lowercase character </li>
                    <li>have at least 1 uppercase character </li>
                    <li>have at least 1 number </li>
                    <li>have at least 1 special symbol </li>
                  </ul>
                </div>
              </Form.Control.Feedback>
            </InputGroup>
          </Row>
          <Row className="mt-2">
            <Form.Label>Confirm New Password</Form.Label>
            <InputGroup as={Col} controlId="forgot-email-id" className="form-group">
              <Form.Control
                type={isPasswordShown ? 'text' : 'password'}
                placeholder="Enter new password again"
                onChange={(e) => onNewConfirmationPasswordChange(e.currentTarget.value)}
                isInvalid={!!newPassword && !!newPasswordConfirmation && !doPasswordsMatch}
              />
              <InputGroup.Text
                role="button"
                className="rounded-right"
                onClick={() => setIsPasswordShown(!isPasswordShown)}
              >
                {isPasswordShown ? <EyeIcon /> : <EyeOffIcon />}
              </InputGroup.Text>
              <Form.Control.Feedback type="invalid">
                <div className="input-feedback_error">Passwords don't match!</div>
              </Form.Control.Feedback>
            </InputGroup>
          </Row>
          <Button
            variant="primary"
            type="submit"
            disabled={
              !newPassword ||
              !newPasswordConfirmation ||
              !isPasswordValid ||
              !doPasswordsMatch ||
              !newFirstname ||
              !newLastname ||
              !newMobile ||
              !isPasswordValid ||
              !isMobiledValid ||
              emailConfirmed
            }
          >
            {confirmingEmail ? (
              <>
                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                <span>Creating account...</span>
              </>
            ) : (
              <>Confirm</>
            )}
          </Button>
          <Link to="/">
            <Button variant="secondary">
              <i className="fas fa-fw  fa-arrow-left" />
              Back to Login
            </Button>
          </Link>
        </Form>
      </div>
    </div>
  )
}

export default ConfirmEmail
