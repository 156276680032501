import { useState, useEffect } from 'react'
import axios, { InternalAxiosRequestConfig, AxiosError, AxiosResponse, AxiosInstance } from 'axios'
import { InternalAxiosRequestConfigCustom } from '@context/auth/model/request'
import { useAuthDispatch } from '@context/auth/context/auth.context'

const backend: any = {
  production_URI: 'https://api.smartflowmonitoring.com/v3',
}

const getUrl = () => {
  console.log('--------------------------------------')
  console.log(import.meta.env.VITE_APP_API_URI)
  console.log('--------------------------------------')
  if (import.meta.env.VITE_APP_API_URI) {
    return import.meta.env.VITE_APP_API_URI
  } else {
    return backend.production_URI
  }
}

const customAxios: AxiosInstance = axios.create({
  baseURL: getUrl(),
  headers: {
    Accept: 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
    'Access-Control-Allow-Headers': 'Content-Type',
  },
})

export const AxiosInterceptor = ({ children }: any) => {
  const [isSet, setIsSet] = useState(false)
  const { refresh } = useAuthDispatch()
  const reqResInterceptor = (config: InternalAxiosRequestConfig) => {
    const token = localStorage.getItem('access-token')
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    return config
  }
  const reqErrInterceptor = async (error: AxiosError | Error) => Promise.reject(error)

  const resResInterceptor = (config: AxiosResponse) => {
    return config
  }

  const resErrInterceptor = async (error: AxiosError) => {
    const originalRequest: InternalAxiosRequestConfigCustom | undefined = error.config
    if (
      error.response &&
      error.response.status === 401 &&
      originalRequest &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true
      try {
        await refresh()
        const token = localStorage.getItem('access-token')
        customAxios.defaults.headers.common['Authorization'] = `Bearer ${token}`
        originalRequest.headers.Authorization = `Bearer ${token}`
        return customAxios(originalRequest)
      } catch (e) {
        console.log(e)
        return Promise.reject(error)
      }
    }
    return Promise.reject(error)
  }

  useEffect(() => {
    setIsSet(true)

    const reqInterceptor = customAxios.interceptors.request.use(
      reqResInterceptor,
      reqErrInterceptor,
    )

    const resInterceptor = customAxios.interceptors.response.use(
      resResInterceptor,
      resErrInterceptor,
    )

    return () => {
      customAxios.interceptors.request.eject(reqInterceptor)
      customAxios.interceptors.response.eject(resInterceptor)
    }
  }, [])

  return isSet && children
}

export default customAxios
