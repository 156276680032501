import backend from '@api/backend'
import { AlertGroup, AlertQuery, AlertStatus } from '../model/alert.model'
import { displayToast } from '@common/utils/appToast'
import { ActionTypes } from '../context/alert.reducer'

export type AlertStatsQuery = {
  deviceId: string
  to: string
  from: string
  type?: string
}

export type AlertStatsQueryArray = AlertStatsQuery[]

export const loadAlertsAction = () => {
  return async (dispatch: any) => {
    dispatch({
      type: ActionTypes.LOADING,
    })
    try {
      const response = await backend.get('/usage_alerts?status=open&status=in_progress')
      dispatch({
        type: ActionTypes.INIT,
        payload: { alerts: [...response.data.data], historicalAlerts: [] },
      })
    } catch (error) {
      dispatch({
        type: ActionTypes.ERROR,
        error,
      })
    }
  }
}

export const loadHistoricalAlertsAction = (query: AlertQuery | null = null) => {
  return async (dispatch: any) => {
    dispatch({
      type: ActionTypes.ALERT_UPDATING,
    })
    try {
      let url: string = '/usage_alerts/history?ack_result=1&ack_result=2&ack_result=3'
      if (query) {
        const dateQuery: string = `&closed_start_date=${query.queryStart}&closed_end_date=${query.queryEnd}`
        url = `${url}${dateQuery}`
      }
      const historicalResponse = await backend.get(url)
      dispatch({
        type: ActionTypes.HISTORICAL_ALERTS,
        payload: { historicalAlerts: historicalResponse.data },
      })
    } catch (error) {
      dispatch({
        type: ActionTypes.ERROR,
        error,
      })
    }
  }
}

export const loadHistoricalAlertsStatsDeviceAction = (query: any) => {
  return async (dispatch: any) => {
    dispatch({
      type: ActionTypes.LOADING,
    })
    const payload: any = {}
    try {
      await Promise.all(
        Object.values(query).map(async (q: any) => {
          const response = await backend.get(
            `/usage_alerts/${q.deviceId}/stats?start_date=${q.from}&end_date=${q.to}`,
          )
          payload[q.type] = response.data
        }),
      )
      dispatch({
        type: ActionTypes.SINGLE_DEVICE_STATS,
        payload: payload,
      })
    } catch (error) {
      dispatch({
        type: ActionTypes.ERROR,
        error,
      })
    }
  }
}

export const loadMultipleDevicesHistoricalAlertsStatsAction = (query: any) => {
  return async (dispatch: any) => {
    dispatch({
      type: ActionTypes.LOADING,
    })

    try {
      // Create an array of promises for the requests
      const promises = await query[0].deviceIds.map(async (deviceId: string) => {
        return await Promise.all(
          Object.values(query).map(async (q: any) => {
            const response = await backend.get(
              `/usage_alerts/${deviceId}/stats?start_date=${q.from}&end_date=${q.to}`,
            )
            return { [q.type]: response.data }
          }),
        )
      })

      // Wait for all promises to resolve
      const allResponses = await Promise.all(promises)

      // Flatten the array of responses
      const payload = [].concat(...allResponses)

      dispatch({
        type: ActionTypes.MULTIPLE_DEVICES_STATS,
        payload: payload,
      })
    } catch (error) {
      dispatch({
        type: ActionTypes.ERROR,
        error,
      })
    }
  }
}

export const ackAlertsAction = (
  alertGroup: AlertGroup,
  priorityTier: number,
  status: AlertStatus,
) => {
  return async (dispatch: any) => {
    dispatch({
      type: ActionTypes.ALERT_UPDATING,
    })
    try {
      const alerts = alertGroup.alerts.map((a) => {
        return {
          id: a.id,
          // ack_comment: comment,
          ack_result: priorityTier,
          status: status === AlertStatus.CLOSED ? 'closed' : 'in_progress',
          valid: a.valid,
          exclude_usage: a.excludeUsage,
        }
      })
      const alertsUpdate = {
        group_id: alertGroup.id,
        // comment: comment,
        alerts: alerts,
      }
      await backend.patch(`/usage_alerts/${alertGroup.deviceId}`, alertsUpdate)
      alertGroup.status = status
      dispatch({
        type: ActionTypes.ACK_ALERT,
        payload: { alertGroup, priorityTier },
      })
      displayToast({
        type: 'success',
        message: 'Success',
      })
    } catch (error) {
      dispatch({
        type: ActionTypes.ERROR,
        error,
      })
      displayToast({
        type: 'error',
        message: 'Something went wrong, please try again',
      })
    }
  }
}
