import backend from '@api/backend'
import { ActionTypes } from '../context/auth.reducer'
import { displayToast } from '@common/utils/appToast'

export const loginAction = async (
  { email, password }: { email: string; password: string },
  dispatch: any,
) => {
  console.log('Running loginAction..............')
  try {
    dispatch({ type: ActionTypes.LOGGING_IN })
    const resp = await backend.post(
      `/users/login/token/`,
      new URLSearchParams({
        username: email,
        password: password,
        grant_type: 'password',
      }),
      {
        headers: {
          ContentType: 'application/x-www-form-urlencoded',
        },
      },
    )
    localStorage.setItem('access-token', resp.data.access_token)
    localStorage.setItem('refresh-token', resp.data.refresh_token)
    dispatch({ type: ActionTypes.LOGIN, payload: { token: resp.data } })
  } catch (error) {
    console.log(error)
    dispatch({ type: ActionTypes.ERROR })
    displayToast({
      type: 'error',
      message: 'An Error occurred while trying to login. Please try again...',
    })
  }
}

export const logoutAction = async (dispatch: any) => {
  localStorage.clear()
  dispatch({ type: ActionTypes.LOGOUT })
}

export const refreshAction = async (dispatch: any) => {
  try {
    const refreshToken = localStorage.getItem('refresh-token')
    if (!refreshToken) {
      await logoutAction(dispatch)
      return
    }

    localStorage.clear()
    const resp = await backend.post(`/users/refresh/`, null, {
      params: {
        token: refreshToken,
      },
    })

    if (!resp || resp.status > 299) {
      dispatch({ type: ActionTypes.ERROR })
      return
    }
    dispatch({ type: ActionTypes.UPDATING })
    localStorage.setItem('access-token', resp.data.access_token)
    localStorage.setItem('refresh-token', resp.data.refresh_token)
    dispatch({ type: ActionTypes.REFRESH, payload: { token: resp.data } })
  } catch (error) {
    console.log(error)
    dispatch({ type: ActionTypes.ERROR })
  }
}
