import moment from 'moment'

const TODAY = moment().startOf('day')
const BEGINNING_OF_WEEK = TODAY.clone().startOf('isoWeek')
const BEGINNING_OF_MONTH = TODAY.clone().startOf('month')
const BEGINNING_OF_YEAR = TODAY.clone().startOf('year')

export type Dataset = {
  label: string
  data: number[]
  borderColor: string[] | string
  backgroundColor: string[] | string
  fill?: boolean
  deviceId?: string
}

export function getChartOptions(type: string, selectedUom: string) {
  let text: string
  let xAxis: string
  switch (type) {
    case 'hourly':
      text = TODAY.format('dddd, MMMM Do YYYY')
      xAxis = 'Hour'
      break
    case 'daily':
      text = TODAY.format('dddd, MMMM Do YYYY')
      xAxis = 'Device'
      break
    case 'weekly':
      text = `From ${BEGINNING_OF_WEEK.format('DD/MM/yyyy')} - To:  Today `
      xAxis = 'Device'
      break
    case 'monthly':
      text = `From: ${BEGINNING_OF_MONTH.format('DD/MM/yyyy')} - To: Today`
      xAxis = 'Device'
      break
    case 'annually':
      text = `From: ${BEGINNING_OF_YEAR.format('DD/MM/yyyy')} - To: Today`
      xAxis = 'Device'
      break
    default:
      text = TODAY.format('dddd, MMMM Do YYYY')
      xAxis = 'Hour'
      break
  }

  const chartOptions: any = {
    maintainAspectRatio: false,
    interaction: {
      mode: 'nearest',
    },
    plugins: {
      title: {
        display: true,
        text,
        position: 'top',
        align: 'center',
        padding: 22,
        font: {
          size: 18,
        },
      },
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          color: '#323130',
          boxWidth: 8,
          padding: 8,
          usePointStyle: true,
          font: {
            size: 10,
          },
        },
      },
      tooltips: {
        mode: 'label',
        titleFont: {
          size: 18,
        },
        bodyFont: {
          Size: 18,
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: `${selectedUom}`,
          font: {
            size: 16,
          },
        },
        ticks: {
          suggestedMin: 0,
        },
      },
      x: {
        title: {
          display: true,
          text: xAxis,
          padding: 8,
          font: {
            size: 16,
          },
        },
      },
    },
  }
  return chartOptions
}

export const hourlyLabels = [
  '0.00',
  '1.00',
  '2.00',
  '3.00',
  '4.00',
  '5.00',
  '6.00',
  '7.00',
  '8.00',
  '9.00',
  '10.00',
  '11.00',
  '12.00',
  '13.00',
  '14.00',
  '15.00',
  '16.00',
  '17.00',
  '18.00',
  '19.00',
  '20.00',
  '21.00',
  '22.00',
  '23.00',
]
