import React, { useState } from 'react'
import { Table } from 'react-bootstrap'
import { hasPermissions } from '@common/utils/helperFunctions'
import { useCustomerManagerState } from '@context/customerManager/customer-manager.context'
import { useAuthState } from '@context/auth/context/auth.context'
import User from '@context/user/model/user'
import DeleteModal from '../../../general/Modals/DeleteModal'
import UserEditModal from '../../AccountManagement/UserModal/UserEditModal'

import './UsersComponent.scss'

const UsersComponent = () => {
  const { loading, users } = useCustomerManagerState()
  const [deleteUserModal, setDeleteUserModal] = useState<User>()
  const [editUserModal, setEditUserModal] = useState<User>()
  const [enableUserModal, setEnableUserModal] = useState<User>()

  const { permissions } = useAuthState()

  return (
    <>
      {loading ? (
        'Loading...'
      ) : (
        <>
          <DeleteModal
            action={enableUserModal && enableUserModal.active ? 'Enable' : 'Disable'}
            show={!!enableUserModal}
            data={enableUserModal}
            onHide={() => setEnableUserModal(undefined)}
            toString={(data: any) => `${data.firstname} ${data.lastname} (${data.email})`}
            onDelete={(data: any) => console.log(data)}
          />
          <DeleteModal
            show={!!deleteUserModal}
            data={deleteUserModal}
            onHide={() => setDeleteUserModal(undefined)}
            toString={(data: any) => `${data.firstname} ${data.lastname} (${data.email})`}
            onDelete={(data: any) => console.log(data)}
          />
          {editUserModal !== undefined ? (
            <UserEditModal
              show={!!editUserModal}
              user={editUserModal}
              onHide={() => setEditUserModal(undefined)}
            />
          ) : null}

          {hasPermissions(permissions, ['CREATE:USER']) ? (
            <button
              onClick={() => setEditUserModal({} as any)}
              className="my-auto btn btn-sm btn-primary mr-1"
            >
              <span className="fas fa-plus mr-1"></span>
              New User
            </button>
          ) : null}
          <Table>
            <thead>
              <tr>
                <th>Firstname</th>
                <th>Lastname</th>
                <th>Email</th>
                <th>Active</th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {users.map((user) => (
                <tr key={user.id}>
                  <td>{user.firstname}</td>
                  <td>{user.lastname}</td>
                  <td>{user.email}</td>
                  <td>
                    <input
                      type="checkbox"
                      checked={user.active}
                      onChange={(e) => setEnableUserModal({ ...user, active: e.target.checked })}
                    />
                  </td>
                  <td>
                    {hasPermissions(permissions, ['UPDATE:USER']) ? (
                      <button
                        className="btn btn-sm btn-info mr-1"
                        onClick={() => setEditUserModal(user)}
                      >
                        <span className="fas fa-cog mr-1"></span>
                        Edit
                      </button>
                    ) : null}
                    {hasPermissions(permissions, ['DELETE:USER']) ? (
                      <button
                        className="btn btn-sm btn-danger mr-1"
                        onClick={() => setDeleteUserModal(user)}
                      >
                        <span className="fas fa-trash mr-1"></span>
                        Delete
                      </button>
                    ) : null}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      )}
    </>
  )
}

export default UsersComponent
