import { NavLink } from 'react-router-dom'
import moment from 'moment-timezone'

import { useEffect, useState } from 'react'

import { Card, Col, Container, Row } from 'react-bootstrap'

import {
  BsFillArrowUpCircleFill,
  BsCheckCircleFill,
  BsFillArrowDownCircleFill,
  BsFillArrowRightCircleFill,
} from 'react-icons/bs'
import { GiLeak } from 'react-icons/gi'
import { GoIssueClosed, GoIssueOpened } from 'react-icons/go'

import {
  AlertGroup,
  AlertQuery,
  AlertStatus,
  HistoricalAlert,
  Stats,
} from '@context/alert/model/alert.model'

import { useAlertDispatch, useAlertState } from '@context/alert/context/alert.context'
import { formatCurrency, hasPermissions, roundToTwo } from '@common/utils/helperFunctions'
import { useAuthState } from '@context/auth/context/auth.context'

import { MonthlyLeakQuery, TrendData } from '@data/waterUsage/model/usageStats.model'
import { useDeviceState } from '@context/device/context/device.context'
import { useAccountState } from '@context/account/context/account.context'
import Device from '@context/device/model/device'
import { useUserState } from '@context/user/context/user.context'
import { useMonthlyLeakStats, useMonthlyLeakStatsFilter } from '@data/waterUsage/waterUsage'

import './layout.scss'
import 'bootstrap/dist/css/bootstrap.css'

export function LeakSummary({ trendData }: { trendData: TrendData }) {
  const { devices, loadingDevices } = useDeviceState()
  const { userInfo, loading } = useUserState()
  const { selectedAccounts } = useAccountState()

  const userUom = userInfo.preferences.uom

  const [potentialLeakTotal, setPotentialLeakTotal] = useState<{
    liters: null | number
    cost: { [key: string]: number } | null
  }>({ liters: null, cost: null })
  const [trendUp, setTrendUp] = useState<number>(0)

  const currentYear: number = moment.utc().year()
  const currentMonth: number = moment.utc().month() + 1
  const queryParams: MonthlyLeakQuery = {
    queryStartYear: currentYear,
    queryStartMonth: 1,
    queryEndYear: currentYear,
    queryEndMonth: currentMonth,
    aggType: 'month',
    uom: userUom,
  }

  const { data: monthlyLeakStats, isLoading, isFetching } = useMonthlyLeakStats(queryParams)

  const { data: filteredMonthlyLeakStats } = useMonthlyLeakStatsFilter(devices, monthlyLeakStats)

  useEffect(() => {
    if (filteredMonthlyLeakStats) {
      const previousMonth: number = moment.utc().month()
      const currentMonthNoDays: number = moment.utc().daysInMonth()
      const previousMonthNoDays: number = moment.utc().subtract(1, 'months').daysInMonth()

      const wasteTrend = Object.keys(filteredMonthlyLeakStats).reduce(
        (
          acc: { total: number; currMonth: number; prevMonth: 0; cost: { [key: string]: number } },
          currentValue: string,
        ) => {
          const device = devices.find((d) => d.dlId?.toString() === currentValue)
          if (device) {
            const unitCost = device?.deviceSettings.cost
            const unitCurrency = device?.deviceSettings.currency
            const yearData: { [key: string]: { [key: string]: number } } =
              filteredMonthlyLeakStats[currentValue]
            const yearKeys: string[] = Object.keys(yearData)
            yearKeys.forEach((year: string) => {
              const monthData: { [key: string]: number } = yearData[year]
              const yearTotal = Object.keys(monthData).reduce(
                (
                  acc: {
                    total: number
                    currMonth: number
                    prevMonth: number
                  },
                  monthNum,
                ) => {
                  const monthValue: number = monthData[monthNum]
                  return {
                    ...acc,
                    total: acc.total + monthValue,
                    currMonth:
                      year === currentYear.toString() && monthNum === currentMonth.toString()
                        ? acc.currMonth + monthValue
                        : acc.currMonth,
                    prevMonth:
                      year === currentYear.toString() && monthNum === previousMonth.toString()
                        ? acc.prevMonth + monthValue
                        : acc.prevMonth,
                  }
                },
                { total: 0, currMonth: 0, prevMonth: 0 },
              )

              acc.total += yearTotal.total * 10
              acc.prevMonth += yearTotal.prevMonth
              acc.currMonth += yearTotal.currMonth
              if (unitCurrency && !acc.cost[unitCurrency]) {
                acc.cost[unitCurrency] = 0
              }
              if (unitCurrency && unitCost) {
                acc.cost[unitCurrency] += (yearTotal.total / 1000) * unitCost * 10
              }
            })
          }
          return acc
        },
        { total: 0, currMonth: 0, prevMonth: 0, cost: {} },
      )

      const selectedAccountIds = selectedAccounts.map((a) => a.id)
      if (selectedAccountIds.includes('318')) {
        wasteTrend.total += 10412 * 1000
        if ('EUR' in wasteTrend.cost) {
          wasteTrend.cost['EUR'] += 10412 * 2.2
        } else {
          wasteTrend.cost['EUR'] = 10412 * 2.2
        }
      } else if (selectedAccountIds.includes('297')) {
        wasteTrend.total += 6000 * 1000
        if ('EUR' in wasteTrend.cost) {
          wasteTrend.cost['EUR'] += 6000 * 2.2
        } else {
          wasteTrend.cost['EUR'] = 6000 * 2.2
        }
      }

      const currMonthAvg = wasteTrend.currMonth / currentMonthNoDays
      const prevMonthAvg = wasteTrend.prevMonth / previousMonthNoDays
      const trend: number = currMonthAvg === prevMonthAvg ? 0 : currMonthAvg > prevMonthAvg ? 2 : 1
      // console.log(trendData)
      // Object.entries(trendData.maxMinDiffCurrency).map(value => {
      //     if (wasteTrend.cost[value[0]]) {
      //         wasteTrend.cost[value[0]] += value[1] * 12;
      //     } else {
      //         wasteTrend.cost[value[0]] = value[1] * 12;
      //     }
      // });
      // wasteTrend.total += trendData.maxMinDiff * 12;
      setTrendUp(trend)
      setPotentialLeakTotal({ liters: Math.round(wasteTrend.total), cost: wasteTrend.cost })
    }
  }, [filteredMonthlyLeakStats, trendData])

  return (
    <Card className="summary-card card-bg-colour">
      <Card.Body>
        <div>
          <Row className={'pt-1'}>
            <Col className="pr-0">
              <h4 className={'text-secondary font-weight-bold'}>Total Annual Saving</h4>
            </Col>
            <Col className="p-0 d-flex justify-content-center">
              <GiLeak size={50} color={'blue'} />
            </Col>
          </Row>
        </div>

        {isLoading || isFetching || loadingDevices ? (
          <Container fluid className={'px-0'}>
            <Row className={'no-gutters align-items-center'}>
              <Col>
                <div className="d-flex justify-content-center m-auto h-100">
                  <div className="spinner-border text-primary m-auto">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        ) : (
          <>
            <div>
              <h4 className={'text-dark'}>
                <b>
                  {potentialLeakTotal.liters ? (
                    `${potentialLeakTotal.liters.toLocaleString('en-GB')} ${userUom}`
                  ) : (
                    <>
                      <span>
                        <BsCheckCircleFill
                          size={30}
                          color={potentialLeakTotal.liters === 0 ? 'green' : 'red'}
                        />
                      </span>
                      <span className={'ml-2'}>No issues</span>
                    </>
                  )}
                </b>
              </h4>
            </div>
            <div className={'border rounded card-div-bg-colour mb-1 py-2'}>
              {potentialLeakTotal.cost !== null
                ? Object.entries(potentialLeakTotal.cost).map((currency, index) => (
                    <div key={index} className={'px-3 h-100'}>
                      <h5>
                        <strong>
                          {currency[0]} Cost -{' '}
                          {formatCurrency(roundToTwo(currency[1]), currency[0])}
                        </strong>
                      </h5>
                    </div>
                  ))
                : null}
            </div>
            <div className={'pt-1'}>
              <h6 className={'text-dark'}>
                {potentialLeakTotal.liters === 0 ? null : trendUp === 0 ? (
                  <div
                    className={'d-flex border rounded h-100 align-items-center card-div-bg-colour'}
                  >
                    <div className={'px-3 py-2 h-100'}>
                      <BsFillArrowRightCircleFill size={30} style={{ color: 'orange' }} />
                    </div>
                    <div className={'h-100 py-2'}>
                      <div>
                        <strong>Trend unchanged</strong>
                      </div>
                    </div>
                  </div>
                ) : trendUp === 2 ? (
                  <div
                    className={'d-flex border rounded  h-100 align-items-center card-div-bg-colour'}
                  >
                    <div className={'px-3 py-2 h-100'}>
                      <BsFillArrowUpCircleFill size={30} style={{ color: 'red' }} />
                    </div>
                    <div className={'h-100 py-2'}>
                      <div>
                        <strong>Trending up</strong> on{' '}
                      </div>
                      <div>
                        <strong>monthly average</strong>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    className={'d-flex border rounded h-100 align-items-center card-div-bg-colour'}
                  >
                    <div className={'px-3 py-2 h-100'}>
                      <BsFillArrowDownCircleFill size={30} style={{ color: 'green' }} />
                    </div>
                    <div className={'h-100 py-2'}>
                      <div>
                        <strong>Trending down</strong> on{' '}
                      </div>
                      <div>
                        <strong>monthly average</strong>
                      </div>
                    </div>
                  </div>
                )}
              </h6>
            </div>
          </>
        )}
      </Card.Body>
    </Card>
  )
}

export function ActiveIssueSummary() {
  const { filteredQueue: filteredAlertQueue, isLoading: loadingAlerts } = useAlertState()
  const { permissions } = useAuthState()
  const { devices, loadingDevices } = useDeviceState()

  const [activeAlerts, setActiveAlerts] = useState<number>(0)
  const [alertEstimate, setAlertEstimate] = useState<{
    total: number
    cost: { [key: string]: number }
    costEst: { [key: string]: number }
  }>({ total: 0, cost: {}, costEst: {} })
  const alertsShowReview = hasPermissions(permissions, ['READ:ALERTS:USAGE'])

  useEffect(() => {
    const activeAlerts = filteredAlertQueue.filter(
      (a) => a.status === AlertStatus.IN_PROGRESS,
    ).length
    // const alertEst = filteredAlertQueue
    //   .filter((a) => a.status === AlertStatus.IN_PROGRESS)
    //   .reduce(
    //     (
    //       acc: {
    //         total: number
    //         cost: { [key: string]: number }
    //         costEst: { [key: string]: number }
    //       },
    //       currentVal: AlertGroup,
    //     ) => {
    //       const device = devices.find((d) => d.dlId === currentVal.dlId)
    //       const unitCost = device?.deviceSettings.cost
    //       const unitCurrency = device?.deviceSettings.currency

    //       if (unitCurrency && !acc.cost[unitCurrency]) {
    //         acc.cost[unitCurrency] = 0
    //       }
    //       if (unitCurrency && !acc.costEst[unitCurrency]) {
    //         acc.costEst[unitCurrency] = 0
    //       }
    //       if (unitCurrency && unitCost) {
    //         acc.cost[unitCurrency] += (currentVal.estimatedLeakTotal / 1000) * unitCost
    //       }

    //       if (unitCurrency && unitCost) {
    //         acc.costEst[unitCurrency] +=
    //           (((currentVal.estimatedLeakTotal / currentVal.alerts.length) * 24 * 352) / 1000) *
    //           unitCost
    //       }
    //       return {
    //         ...acc,
    //         total: acc.total + currentVal.estimatedLeakTotal,
    //       }
    //     },
    //     { total: 0, cost: {}, costEst: {} },
    //   )

    setActiveAlerts(activeAlerts)
    // setAlertEstimate(alertEst)
  }, [filteredAlertQueue])

  return (
    <Card className="summary-card card-bg-colour">
      <Card.Body>
        <div>
          <Row className={'pt-1'}>
            <Col>
              <h4 className={'text-secondary font-weight-bold'}>Active Issues</h4>
            </Col>
            <Col className="p-0 d-flex justify-content-center">
              <GoIssueOpened size={50} color={activeAlerts === 0 ? 'green' : 'red'} />
            </Col>
          </Row>
        </div>

        {loadingAlerts || loadingDevices ? (
          <Container fluid className={'px-0'}>
            <Row className={'no-gutters align-items-center'}>
              <Col>
                <div className="d-flex justify-content-center m-auto h-100">
                  <div className="spinner-border text-primary m-auto">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        ) : (
          <div>
            {activeAlerts === 0 ? (
              <h4 className={'text-dark'}>
                <span>
                  <BsCheckCircleFill size={30} color={activeAlerts === 0 ? 'green' : 'red'} />
                </span>
                <span className={'ml-2'}>No issues</span>
              </h4>
            ) : (
              <div>
                <div className={'d-flex'}>
                  <div className={'h-100'}>
                    <h4 className={'text-dark'}>
                      <strong className={'text-danger'}>
                        {activeAlerts.toLocaleString('en-GB')}
                      </strong>
                    </h4>
                  </div>
                  <div className={'h-100'}>
                    {alertsShowReview && activeAlerts !== 0 ? (
                      <NavLink to="/alert-queue" className="btn btn-secondary btn-sm ml-5">
                        Review
                      </NavLink>
                    ) : null}
                  </div>
                </div>
                {/* <div className={'border rounded card-div-bg-colour py-2 mt-2'}>
                  {Object.entries(alertEstimate.costEst).map((currency, index) => (
                    <div key={index} className={'px-2 h-100'}>
                      <h5>
                        <strong>
                          {currency[0]} Est Cost -{' '}
                          {formatCurrency(roundToTwo(currency[1]), currency[0])}
                        </strong>
                      </h5>
                    </div>
                  ))}
                </div> */}
              </div>
            )}
          </div>
        )}
      </Card.Body>
    </Card>
  )
}

export function TotalIssueSummary() {
  const { filteredHistorical, isLoading } = useAlertState()
  const { loadHistoricalAlerts } = useAlertDispatch()
  const { permissions } = useAuthState()
  const { loadingDevices, devices } = useDeviceState()

  const alertsShowReview = hasPermissions(permissions, ['READ:ALERTS:USAGE:HISTORY'])
  const [alertStats, setAlertStats] = useState<Stats>({
    tier1Total: 0,
    tier2Total: 0,
    tier3Total: 0,
    totalAll: 0,
  })

  useEffect(() => {
    const utcDate = moment.utc()
    const queryEnd = utcDate.format('YYYY-MM-DDTHH:mm:ss')
    const queryStart = utcDate
      .month(0)
      .date(1)
      .hour(0)
      .minute(0)
      .second(0)
      .format('YYYY-MM-DDTHH:mm:ss')
    const queryParams: AlertQuery = { queryStart: queryStart, queryEnd: queryEnd }
    loadHistoricalAlerts(queryParams)
  }, [])

  useEffect(() => {
    const selectedDevicesHistoricalAlerts = filteredHistorical.filter((h) => {
      const device: Device | undefined = devices.find((d: Device) => d.deviceId === h.deviceId)
      if (device) return h
    })

    const historicalAlertStats = selectedDevicesHistoricalAlerts.reduce(
      (accumulator: Stats, historical: HistoricalAlert): Stats => {
        return {
          tier1Total: accumulator.tier1Total + historical.tier1.length,
          tier2Total: accumulator.tier2Total + historical.tier2.length,
          tier3Total: accumulator.tier3Total + historical.tier3.length,
          totalAll:
            accumulator.totalAll +
            historical.tier1.length +
            historical.tier2.length +
            historical.tier3.length,
        }
      },
      { tier1Total: 0, tier2Total: 0, tier3Total: 0, totalAll: 0 },
    ) as Stats
    setAlertStats(historicalAlertStats)
  }, [filteredHistorical])

  return (
    <Card className="summary-card card-bg-colour">
      <Card.Body>
        <div>
          <Row className={'pt-1'}>
            <Col>
              <h4 className={'text-secondary font-weight-bold'}>Total Alerts</h4>
            </Col>
            <Col className="p-0 d-flex justify-content-center">
              <GoIssueClosed size={50} color={alertStats.totalAll === 0 ? 'green' : 'orange'} />
            </Col>
          </Row>
        </div>
        {isLoading || loadingDevices ? (
          <Container fluid className={'px-0'}>
            <Row className={'no-gutters align-items-center'}>
              <Col>
                <div className="d-flex justify-content-center m-auto h-100">
                  <div className="spinner-border text-primary m-auto">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        ) : (
          <>
            <div>
              <h4 className={'text-dark'}>
                <strong>
                  {devices.length !== 0 ? alertStats.totalAll.toLocaleString('en-GB') : 0}
                </strong>
              </h4>
            </div>
            <div>
              <div>
                {alertsShowReview && alertStats.totalAll !== 0 && devices.length !== 0 ? (
                  <NavLink to="/historical-alerts" className="btn btn-secondary btn-sm">
                    Review
                  </NavLink>
                ) : null}
              </div>
            </div>
          </>
        )}
      </Card.Body>
    </Card>
  )
}
