import { jsPDF } from 'jspdf'
import { smartflowHeaderImg, SMARTFLOW_FOOTER_IMAGE } from '@common/utils/constants'
import autoTable from 'jspdf-autotable'
import html2canvas from 'html2canvas'

export const buildPDFFile = (
  chartElement: HTMLElement,
  tableElement: HTMLTableElement,
): Promise<jsPDF> => {
  return new Promise((res, rej) => {
    if (chartElement && tableElement) {
      html2canvas(chartElement, { scrollX: -window.screenX })
        .then((canvas) => {
          const chartImgData = (chartElement as HTMLCanvasElement).toDataURL('image/png')
          const pdf = new jsPDF('landscape', 'mm', [297, 210])

          const pdfWidth = pdf.internal.pageSize.getWidth()
          const headerPosX = pdf.internal.pageSize.getWidth() / 2 - pdfWidth / 2

          let posXStart = 10
          let posYStart = 60

          let posY = posYStart
          let posX = posXStart

          autoTable(pdf, {
            html: tableElement,
            startY: posY,
            tableLineColor: [25, 106, 154],
            styles: {
              fontSize: 6,
            },
            margin: {
              top: 60,
              bottom: 40,
              left: 2,
              right: 2,
            },
            columnStyles: {
              0: { cellWidth: 'wrap' },
              1: { cellWidth: 'wrap' },
              2: { cellWidth: 'wrap' },
              3: { cellWidth: 'wrap' },
              4: { cellWidth: 'wrap' },
              5: { cellWidth: 'wrap' },
              6: { cellWidth: 'wrap' },
              7: { cellWidth: 'wrap' },
              8: { cellWidth: 'wrap' },
              9: { cellWidth: 'wrap' },
              10: { cellWidth: 'wrap' },
              11: { cellWidth: 'wrap' },
              12: { cellWidth: 'wrap' },
              13: { cellWidth: 'wrap' },
              14: { cellWidth: 'wrap' },
              15: { cellWidth: 'wrap' },
              16: { cellWidth: 'wrap' },
              17: { cellWidth: 'wrap' },
              18: { cellWidth: 'wrap' },
              19: { cellWidth: 'wrap' },
              20: { cellWidth: 'wrap' },
              21: { cellWidth: 'wrap' },
              22: { cellWidth: 'wrap' },
              23: { cellWidth: 'wrap' },
              24: { cellWidth: 'wrap' },
              25: { cellWidth: 'wrap' },
            },
          })

          posY += 25

          pdf.addImage(chartImgData, 'PNG', posX + 30, posY, 180, 70)

          const pageCount = pdf.internal.pages.length // With first element null
          const realPageCount = pageCount - 1
          const docHeight = pdf.internal.pageSize.height
          for (let i = 1; i <= realPageCount; i++) {
            pdf.setPage(i)

            // Header
            pdf.addImage(smartflowHeaderImg, 'png', headerPosX, 0, pdfWidth, 55)

            // Footer
            const footerImgProps = pdf.getImageProperties(SMARTFLOW_FOOTER_IMAGE)
            const pdfWidthFooter = pdf.internal.pageSize.getWidth()
            const footerRatio = pdfWidthFooter / footerImgProps.width
            const footerHeight = footerImgProps.height * footerRatio - 50
            const footerPosY = docHeight - footerHeight
            pdf.addImage(
              SMARTFLOW_FOOTER_IMAGE,
              'png',
              headerPosX,
              pdf.internal.pageSize.getHeight() - 40,
              pdfWidth,
              50,
              undefined,
              'FAST',
            )

            pdf.setFont('Nunito', 'bold')
            pdf.setTextColor(0, 0, 0)
            pdf.setFontSize(8)
            const footerMsg = `Page ${i} of ${realPageCount}`
            const footerMsgPosY = footerPosY - 10
            pdf.text(footerMsg, 5, footerMsgPosY)
          }

          res(pdf)
        })
        .catch((error) => {
          throw new Error(`Something wrong happened while generating PDF: ${error}`)
        })
    }
  })
}
