import { useEffect, useState } from 'react'
import { Card, Row, Col, Container } from 'react-bootstrap'
import {
  BsFillArrowDownCircleFill,
  BsFillArrowRightCircleFill,
  BsFillArrowUpCircleFill,
  BsFillCloudFill,
  BsThermometerSnow,
  BsThermometerSun,
} from 'react-icons/bs'
import moment from 'moment-timezone'

import { useDeviceState } from '@context/device/context/device.context'
import { HOT_CO2 } from '@common/utils/constants'

import { WaterUsageStats } from '@data/waterUsage/model/waterUsage.model'
import { Trend, TrendData, YearlyStatsTotal } from '@data/waterUsage/model/usageStats.model'
import Device from '@context/device/model/device'
import { useUserState } from '@context/user/context/user.context'
import { calculateCo2Produced, calculateCo2RateAverage } from '@common/utils/helperFunctions'

import { useWaterStatsFilter, useWaterUsageStat } from '@data/waterUsage/waterUsage'

import './layout.scss'

export function ESGSummary({ trendData }: { trendData: TrendData }) {
  const { devices, loadingDevices } = useDeviceState()
  const { userInfo } = useUserState()

  const [devicesCo2RateAverage, setDevicesCo2RateAverage] = useState<number>(10.6)

  const [yearlyESG, setYearlyESG] = useState<YearlyStatsTotal>({
    cold: null,
    hot: null,
    total: null,
  })
  const [trendUp, setTrendUp] = useState<Trend>({ hot: 0, cold: 0, total: 0 })

  const {
    data: waterUsageStats,
    isLoading,
    isFetching,
  } = useWaterUsageStat(userInfo.preferences.uom)

  const {
    data: filteredUsageStats,
    isLoading: loadingFilters,
    isFetching: fetchingFilters,
  } = useWaterStatsFilter(devices, waterUsageStats)

  useEffect(() => {
    const totalYearlyUsage: YearlyStatsTotal | undefined =
      filteredUsageStats?.mainMetersWaterUsageStats &&
      Object.values(filteredUsageStats.mainMetersWaterUsageStats).reduce(
        (acc: YearlyStatsTotal, stat: WaterUsageStats) => {
          const d: Device | undefined = devices.find((d) => d.deviceId === stat.deviceId)
          if (stat.yearlyUsage && acc.cold === null) {
            acc.cold = 0
          }
          if (stat.yearlyUsage && acc.hot === null) {
            acc.hot = 0
          }
          // if (stat.yearlyUsage && acc.total === null) {
          //     acc.total = 0;
          // }
          return {
            cold:
              acc.cold === null
                ? null
                : !d?.deviceSettings.hot
                  ? acc.cold + stat.yearlyUsage
                  : acc.cold,
            hot:
              acc.hot === null
                ? null
                : d?.deviceSettings.hot
                  ? acc.hot + stat.yearlyUsage
                  : acc.hot,
            total: null,
            // totalCO2: acc.total === null ? null : acc.totalCO2 + stat.yearlyUsage
          }
        },
        { cold: null, hot: null, total: null },
      )

    let hotCO2: null | number = null
    let coldCO2: null | number = null
    let totalCO2: null | number = null
    if (totalYearlyUsage?.hot && totalYearlyUsage.cold) {
      hotCO2 = calculateCo2Produced(
        userInfo.preferences.uom,
        totalYearlyUsage.hot,
        devices,
        'hot',
      ).value
      coldCO2 = calculateCo2Produced(
        userInfo.preferences.uom,
        totalYearlyUsage.cold,
        devices,
        'cold',
      ).value
      totalCO2 = Math.round(hotCO2 + coldCO2)
    } else if (totalYearlyUsage?.cold) {
      coldCO2 = calculateCo2Produced(
        userInfo.preferences.uom,
        totalYearlyUsage.cold,
        devices,
        'cold',
      ).value
      totalCO2 = coldCO2
    } else if (totalYearlyUsage?.hot) {
      hotCO2 = calculateCo2Produced(
        userInfo.preferences.uom,
        totalYearlyUsage.hot,
        devices,
        'hot',
      ).value
      totalCO2 = hotCO2
    }

    setYearlyESG({
      cold: coldCO2,
      hot: hotCO2,
      total: totalCO2,
    })
  }, [filteredUsageStats?.filteredWaterUsageStats])

  useEffect(() => {
    const currentMonthNoDays: number = moment.utc().daysInMonth()
    const previousMonthNoDays: number = moment.utc().subtract(1, 'months').daysInMonth()

    let totalCo2PrevMonth = 0
    let totalCo2CurrMonth = 0

    if (trendData.prevMonthHot && trendData.prevMonthCold) {
      totalCo2PrevMonth =
        trendData.prevMonthHot * HOT_CO2 + trendData.prevMonthCold * devicesCo2RateAverage
    } else if (trendData.prevMonthHot) {
      totalCo2PrevMonth = trendData.prevMonthHot * HOT_CO2
    } else if (trendData.prevMonthCold) {
      totalCo2PrevMonth = trendData.prevMonthCold * devicesCo2RateAverage
    }

    if (trendData.currMonthHot && trendData.currMonthCold) {
      totalCo2CurrMonth =
        trendData.currMonthHot * HOT_CO2 + trendData.currMonthCold * devicesCo2RateAverage
    } else if (trendData.currMonthHot) {
      totalCo2CurrMonth = trendData.currMonthHot * HOT_CO2
    } else if (trendData.currMonthCold) {
      totalCo2CurrMonth = trendData.currMonthCold * devicesCo2RateAverage
    }

    const currMonthAvg = totalCo2CurrMonth / currentMonthNoDays
    const prevMonthAvg = totalCo2PrevMonth / previousMonthNoDays
    const trendTotal: number =
      currMonthAvg === prevMonthAvg ? 0 : currMonthAvg > prevMonthAvg ? 2 : 1

    const currMonthAvgHot = trendData.currMonthHot ? trendData.currMonthHot / currentMonthNoDays : 0
    const prevMonthAvgHot = trendData.prevMonthHot
      ? trendData.prevMonthHot / previousMonthNoDays
      : 0
    const trendHot: number =
      currMonthAvgHot === prevMonthAvgHot ? 0 : currMonthAvgHot > prevMonthAvgHot ? 2 : 1

    const currMonthAvgCold = trendData.currMonthCold
      ? trendData.currMonthCold / currentMonthNoDays
      : 0
    const prevMonthAvgCold = trendData.prevMonthCold
      ? trendData.prevMonthCold / previousMonthNoDays
      : 0
    const trendCold: number =
      currMonthAvgCold === prevMonthAvgCold ? 0 : currMonthAvgCold > prevMonthAvgCold ? 2 : 1
    setTrendUp({ total: trendTotal, hot: trendHot, cold: trendCold })
  }, [trendData])

  useEffect(() => {
    if (devices.length > 0) {
      const co2Average = calculateCo2RateAverage(devices)

      setDevicesCo2RateAverage(co2Average)
    }
  }, [devices])

  return (
    <Card className="summary-card card-bg-colour">
      <Card.Body>
        <div>
          <Row className={'pt-1'}>
            <Col>
              <h4 className={'text-secondary font-weight-bold'}>Co2 Produced</h4>
            </Col>
            <Col className="p-0 d-flex justify-content-center">
              <BsFillCloudFill size={50} color={'indigo'} />
            </Col>
          </Row>
        </div>

        {loadingDevices || isLoading || isFetching || loadingFilters || fetchingFilters ? (
          <Container fluid className={'px-0'}>
            <Row className={'no-gutters align-items-center'}>
              <Col>
                <div className="d-flex justify-content-center m-auto h-100">
                  <div className="spinner-border text-primary m-auto">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        ) : (
          <>
            <div>
              <div>
                <h4 className={'text-dark'}>
                  <b>{yearlyESG.total ? `${yearlyESG.total.toLocaleString('en-GB')}Kg` : null}</b>
                </h4>
              </div>
              <div className={'border rounded h-100 align-items-center card-div-bg-colour'}>
                <div className={'px-3 pt-2'}>
                  <h6 className={'text-dark'}>
                    <BsThermometerSnow color={'blue'}></BsThermometerSnow>
                    <span className={'px-2'}>
                      <strong>Cold</strong>{' '}
                      {yearlyESG.cold ? `${yearlyESG.cold.toLocaleString('en-GB')}Kg` : null}
                    </span>
                    {yearlyESG.cold === null ? (
                      'No data available'
                    ) : trendUp.cold === 0 ? (
                      <BsFillArrowRightCircleFill size={20} style={{ color: 'orange' }} />
                    ) : trendUp.cold === 2 ? (
                      <BsFillArrowUpCircleFill size={20} style={{ color: 'red' }} />
                    ) : (
                      <BsFillArrowDownCircleFill size={20} style={{ color: 'green' }} />
                    )}
                  </h6>
                </div>
                <div className={'px-3'}>
                  <h6 className={'text-dark'}>
                    <BsThermometerSun color={'red'}></BsThermometerSun>
                    <span className={'px-2'}>
                      <strong>Hot</strong>{' '}
                      {yearlyESG.hot ? `${yearlyESG.hot.toLocaleString('en-GB')}Kg` : null}
                    </span>
                    {yearlyESG.hot === null ? (
                      'No data available'
                    ) : trendUp.hot === 0 ? (
                      <BsFillArrowRightCircleFill size={20} style={{ color: 'orange' }} />
                    ) : trendUp.hot === 2 ? (
                      <BsFillArrowUpCircleFill size={20} style={{ color: 'red' }} />
                    ) : (
                      <BsFillArrowDownCircleFill size={20} style={{ color: 'green' }} />
                    )}
                  </h6>
                </div>
              </div>
            </div>
            <div className={'pt-2'}>
              <h6 className={'text-dark'}>
                {yearlyESG.total === null ? null : trendUp.total === 0 ? (
                  <div
                    className={'d-flex border rounded h-100 align-items-center card-div-bg-colour'}
                  >
                    <div className={'px-3 py-2 h-100'}>
                      <BsFillArrowRightCircleFill size={30} style={{ color: 'orange' }} />
                    </div>
                    <div className={'h-100 py-2'}>
                      <div>
                        <strong>Trend unchanged</strong>
                      </div>
                    </div>
                  </div>
                ) : trendUp.total === 2 ? (
                  <div
                    className={'d-flex border rounded h-100 align-items-center card-div-bg-colour'}
                  >
                    <div className={'px-3 py-2 h-100'}>
                      <BsFillArrowUpCircleFill size={30} style={{ color: 'red' }} />
                    </div>
                    <div className={'h-100 py-2'}>
                      <div>
                        <strong>Trending up</strong> on{' '}
                      </div>
                      <div>
                        <strong>monthly average</strong>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    className={'d-flex border rounded h-100 align-items-center card-div-bg-colour'}
                  >
                    <div className={'px-3 py-2 h-100'}>
                      <BsFillArrowDownCircleFill size={30} style={{ color: 'green' }} />
                    </div>
                    <div className={'h-100 py-2'}>
                      <div>
                        <strong>Trending down</strong> on{' '}
                      </div>
                      <div>
                        <strong>monthly average</strong>
                      </div>
                    </div>
                  </div>
                )}
              </h6>
            </div>
          </>
        )}
      </Card.Body>
    </Card>
  )
}
