import { useEffect, useState } from 'react'
import {
  useCustomerManagerDispatch,
  useCustomerManagerState,
} from '@context/customerManager/customer-manager.context'
import Account from '@context/account/model/Account'
import { Button, Col, Form, Row } from 'react-bootstrap'

const AccountReports = ({ account }: { account: Account }) => {
  const [exSummaryEnabled, setExSummaryEnabled] = useState<boolean>(false)
  const [isModified, setIsModified] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const { updateAccount } = useCustomerManagerDispatch()

  const { loading, accounts } = useCustomerManagerState()

  const selectedAccount = accounts.find((acc) => acc.id === account.id)

  useEffect(() => {
    setExSummaryEnabled(selectedAccount?.exSummaryEnabled || false)
    setIsModified(false)
  }, [accounts, loading])

  const handleUpdate = () => {
    setIsLoading(true)

    const updateReports = {
      id: account.id,
      exSummaryEnabled,
    }

    try {
      updateAccount(account, updateReports)
      setIsModified(false)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }

  const handleCheckboxChange =
    (setter: React.Dispatch<React.SetStateAction<boolean>>) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setter(event.target.checked)

      const targetName = event.target.name as keyof Account

      if (selectedAccount && selectedAccount[targetName] !== event.target.checked) {
        setIsModified(true)
      } else {
        setIsModified(false)
      }
    }

  return (
    <>
      <Form className="account-reports-form mt-3 col-md-6 col-sm-12">
        <Row className="mt-3">
          <Form.Group as={Col} className="d-flex align-center">
            <Form.Check
              id={'ex-summary-report'}
              name="exSummaryEnabled"
              type="checkbox"
              inline
              label="Executive Summary Report"
              checked={exSummaryEnabled}
              onChange={handleCheckboxChange(setExSummaryEnabled)}
            />
          </Form.Group>
        </Row>

        <div className="d-flex justify-content-end mt-5 mr-5">
          <Button disabled={!isModified || isLoading} onClick={handleUpdate}>
            Save
          </Button>
        </div>
      </Form>
    </>
  )
}

export default AccountReports
