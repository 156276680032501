import React, { useEffect, useState } from 'react'
import { Button, Form, Spinner } from 'react-bootstrap'
import Select from 'react-select'

import { useDeviceDispatch, useDeviceState } from '@context/device/context/device.context'
import Device, { TestDevice, Valve, ValveKey } from '@context/device/model/device'
import { capitalizeFirstLetter } from '@common/utils/helperFunctions'

import './ValvesManagement.scss'

interface IValvesManagementProps {
  devices: Device[] | TestDevice[]
}

const ValvesManagement: React.FC<IValvesManagementProps> = ({
  devices,
}: IValvesManagementProps) => {
  const { loading, deviceUpdating, error } = useDeviceState()
  const { updateValves } = useDeviceDispatch()

  const [selectedDevice, setSelectedDevice] = useState<Device | TestDevice>()
  const [currentValvesState, setCurrentValvesState] = useState<{ [Key in ValveKey]: Valve }>()
  const [didSomethingChange, setDidSomethingChange] = useState<boolean>(false)

  useEffect(() => {
    if (!selectedDevice || deviceUpdating) return
    setCurrentValvesState(
      Object.values(selectedDevice.valves).reduce(
        (acc, valve) => {
          acc[valve.key] = { ...valve }
          return acc
        },
        {} as { [Key in ValveKey]: Valve },
      ),
    )
  }, [selectedDevice, deviceUpdating, devices, error])

  useEffect(() => {
    if (!selectedDevice) return
    setSelectedDevice((sd) => devices.find((d) => d.deviceId === sd?.deviceId))
  }, [devices])

  function handleValveChange(valve: Valve) {
    if (selectedDevice && currentValvesState) {
      const newValveState = { ...currentValvesState }
      newValveState[valve.key].state = currentValvesState[valve.key].state ? 0 : 1
      setCurrentValvesState(newValveState)
    }
  }

  function onValvesUpdateClick(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault()
    if (selectedDevice && currentValvesState) {
      const newValveState = Object.values(currentValvesState).reduce((acc, valve) => {
        if (valve.state !== selectedDevice.valves[valve.key].state) {
          acc[valve.key] = valve.state
        }
        return acc
      }, {} as any)
      if (Object.keys(newValveState).length > 0) {
        updateValves(selectedDevice.deviceId, newValveState)
        setDidSomethingChange(false)
      }
    }
  }

  useEffect(() => {
    if (selectedDevice && currentValvesState) {
      let didIt = false
      Object.values(currentValvesState).forEach((valve) => {
        if (valve.state !== selectedDevice.valves[valve.key].state) {
          didIt = true
          return
        }
      })

      setDidSomethingChange(didIt)
    }
  }, [currentValvesState, selectedDevice])

  return (
    <div className="valves-management">
      <h1>Operate Valves</h1>
      <div className="container-fluid flex-container ">
        <div className="row">
          <div className="section-header">
            <div className="">Device:</div>
          </div>
          <div className="col-md-6">
            <div className="multiselect-container">
              <Select
                classNamePrefix="select"
                className="basic-multi-select"
                value={selectedDevice}
                onChange={(device: any) => setSelectedDevice(device)}
                options={devices}
                name="device"
                getOptionValue={(option) => option.deviceId}
                getOptionLabel={(option) => `${option.deviceName}`}
                isClearable={false}
                isLoading={deviceUpdating || loading || devices.length === 0}
                isDisabled={deviceUpdating || loading || devices.length === 0}
              />
            </div>
          </div>
        </div>
        {selectedDevice && currentValvesState ? (
          <Form>
            {Object.values(currentValvesState).map((valve) => {
              return (
                <div className="valve-container" key={`${selectedDevice.deviceId}-${valve.key}`}>
                  <div className="valve-number">{valve.key}</div>
                  <Form.Check
                    id={valve.key}
                    type="switch"
                    label={valve.name ? capitalizeFirstLetter(valve.name) : '(Unnamed Valve)' }
                    checked={valve.state === 1}
                    onChange={() => handleValveChange(valve)}
                    disabled={valve.state === 7 || valve.state === null}
                  />
                </div>
              )
            })}

            <Button
              variant="primary"
              disabled={!didSomethingChange}
              type="submit"
              onClick={onValvesUpdateClick}
            >
              {deviceUpdating ? (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  <span>Loading...</span>
                </>
              ) : (
                <>Update</>
              )}
            </Button>
          </Form>
        ) : (
          <div className="no-device-message">Select a device</div>
        )}
      </div>
    </div>
  )
}

export default ValvesManagement
