import { useEffect, useState } from 'react'
import moment from 'moment-timezone'
import { Pencil, History } from 'lucide-react'
import Tooltip from 'react-bootstrap/Tooltip'
import Select from 'react-select'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import { Modal, Button, Form, Col, Row, Card, Badge, Spinner } from 'react-bootstrap'
import { IComments } from '@data/comments/model/comments.model'
import { CATEGORY_VALUES, DEPARTMENT_VALUES } from '@data/comments/constants'

import './Comment.scss'

interface CommentsComponent {
  comments: IComments[] | undefined
  handleNewComment: (comment: string, department: string, category: string) => void
  handleUpdateComment: (comment: IComments, newComment: string) => void
  showDepartmentAndCategory?: boolean
  isPending?: boolean
  isError?: boolean
  isSuccess?: boolean
}

const CommentComponent = ({
  comments,
  handleNewComment,
  handleUpdateComment,
  showDepartmentAndCategory = false,
  isPending = false,
  isError = false,
  isSuccess = false,
}: CommentsComponent) => {
  const [displayHistoryModal, setDisplayHistoryModal] = useState(false)
  const [editComment, setEditComment] = useState(false)
  const [newNote, setNewNote] = useState('')
  const [newNoteEdition, setNewNoteEdition] = useState('')
  const [editNoteId, setEditNoteId] = useState(0)

  const [newDepartment, setNewDepartment] = useState('')
  const [newCategory, setNewCategory] = useState('')

  const getTimeAgo = (dateTime: string) => {
    const customDate = moment.utc(dateTime).local()

    const currentDate = moment()
    const timeDifference = currentDate.diff(customDate)

    const seconds = Math.floor(timeDifference / 1000)
    const minutes = Math.floor(seconds / 60)
    const hours = Math.floor(minutes / 60)

    if (hours > 0) {
      if (hours > 23) {
        return `${customDate.format('HH:mm')}h`
      }
      return `${hours} hour${hours > 1 ? 's' : ''} ago`
    } else if (minutes > 0) {
      return `${minutes} minute${minutes > 1 ? 's' : ''} ago`
    } else if (seconds === 0) {
      return 'now'
    } else {
      return `${seconds} second${seconds > 1 ? 's' : ''} ago`
    }
  }

  const onHide = () => {
    setDisplayHistoryModal(false)
  }

  const handleEditComment = (comment: IComments) => {
    setNewNoteEdition(comment.comment)
    setEditNoteId(comment.id)
    setEditComment(true)
  }

  const handleCancelNoteEdition = () => {
    setEditComment(false)
    setEditNoteId(0)
  }

  const handleDisplayNoteHistoryModal = (comment: IComments) => {
    setDisplayHistoryModal(true)
    setEditNoteId(comment.id)
  }

  const isButtonDisabled = showDepartmentAndCategory ? !newCategory || !newDepartment : false

  const handleFocus = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const lengthOfInput = event.target.value.length
    return event.target.setSelectionRange(lengthOfInput, lengthOfInput)
  }

  useEffect(() => {
    if (!isPending && !isError && isSuccess) {
      setNewNote('')
      setNewDepartment('')
      setNewCategory('')
    }
  }, [isPending, isError, isSuccess])

  return (
    <>
      <div className="border col-md-8 col-sm-12">
        {showDepartmentAndCategory && (
          <div className="p-1">
            <Row className="mb-3 mt-3">
              <Form.Group as={Col} controlId="formSectorType">
                <Form.Label>Department</Form.Label>
                <Select
                  classNamePrefix="select"
                  onChange={(d: any) => setNewDepartment(d.value)}
                  options={DEPARTMENT_VALUES.map((department) => {
                    return { value: department, label: department }
                  })}
                  value={{
                    value: newDepartment,
                    label: newDepartment,
                  }}
                />
              </Form.Group>
              <Form.Group as={Col}> </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formSectorType">
                <Form.Label>Category</Form.Label>
                <Select
                  onChange={(d: any) => setNewCategory(d.value)}
                  options={CATEGORY_VALUES.map((category) => {
                    return { value: category, label: category }
                  })}
                  value={{
                    value: newCategory,
                    label: newCategory,
                  }}
                />
              </Form.Group>

              <Form.Group as={Col}></Form.Group>
            </Row>
          </div>
        )}

        <Form.Group className="mb-3 w-100 p-1 text-break" controlId="exampleForm.ControlTextarea1">
          <Form.Control
            as="textarea"
            rows={3}
            className="p-3"
            style={{ resize: 'none', height: '100px' }}
            placeholder="Enter your comment..."
            value={newNote}
            onChange={(e) => setNewNote(e.target.value)}
          />
        </Form.Group>

        <Button
          className="ml-2 mb-3 d-flex flex-end"
          onClick={() => handleNewComment(newNote, newDepartment, newCategory)}
          disabled={!newNote || isButtonDisabled}
        >
          <>
            {isPending && (
              <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
            )}
            <span>Post comment</span>
          </>
        </Button>
      </div>

      {comments &&
        comments.length > 0 &&
        comments.map((comment: IComments) => {
          return (
            <div className="comment-container col-lg-8 col-sm-12" key={comment.id}>
              <Card className="">
                <Card.Body>
                  <Card.Subtitle className="mb-2 text-muted w-100">
                    <div className="d-flex justify-content-between">
                      <div className="pl-0">
                        <div>{`Created by  ${
                          comment.history && comment.history.length > 0
                            ? comment.history[0].author
                            : comment.author
                        } - ${moment(comment.createdAt).format('DD-MM-YYYY')} - ${getTimeAgo(comment.createdAt)}`}</div>
                        {comment.history && comment.history.length > 0 && (
                          <small>{`last updated by  ${
                            comment.history[comment.history.length - 1].author
                          } - ${moment(
                            comment.history[comment.history.length - 1].createdAt,
                          ).format('DD-MM-YYYY')} - ${getTimeAgo(comment.updatedAt)}`}</small>
                        )}
                      </div>

                      <div className="d-flex">
                        {comment.history && comment.history.length > 0 && (
                          <OverlayTrigger
                            delay={{ hide: 450, show: 300 }}
                            overlay={(props: any) => <Tooltip {...props}>history</Tooltip>}
                            placement="top"
                          >
                            <History
                              role="button"
                              size={20}
                              color={'gray'}
                              className="ml-2 mt-1"
                              onClick={() => handleDisplayNoteHistoryModal(comment)}
                            />
                          </OverlayTrigger>
                        )}

                        <OverlayTrigger
                          delay={{ hide: 450, show: 300 }}
                          overlay={(props: any) => <Tooltip {...props}>edit</Tooltip>}
                          placement="top"
                        >
                          <Pencil
                            role="button"
                            size={20}
                            color={'gray'}
                            className="ml-2 mt-1"
                            onClick={() => handleEditComment(comment)}
                          />
                        </OverlayTrigger>
                      </div>
                    </div>
                  </Card.Subtitle>
                  <div>
                    {showDepartmentAndCategory && (
                      <div className="d-flex gap-2">
                        <Badge>{comment.department}</Badge>
                        <Badge>{comment.category}</Badge>
                      </div>
                    )}
                  </div>
                  <div className="mt-3">
                    {editComment && editNoteId === comment.id ? (
                      <div>
                        <Form.Group>
                          <Form.Control
                            as="textarea"
                            rows={3}
                            className="p-3"
                            style={{ resize: 'none', height: '100px', whiteSpace: 'pre-wrap' }}
                            value={newNoteEdition}
                            onChange={(e) => setNewNoteEdition(e.target.value)}
                            autoFocus
                            onFocus={handleFocus}
                          />
                        </Form.Group>

                        <div className="action-buttons mt-2">
                          <Button
                            className="ml-3 mb-3 d-flex flex-end"
                            onClick={() => {
                              handleUpdateComment(comment, newNoteEdition), setEditNoteId(0)
                            }}
                            disabled={!newNoteEdition || newNoteEdition === comment.comment}
                          >
                            Save
                          </Button>
                          <Button
                            className="ml-3 mb-3 d-flex flex-end"
                            variant="secondary"
                            onClick={() => handleCancelNoteEdition()}
                            disabled={!newNoteEdition}
                          >
                            Cancel
                          </Button>
                        </div>
                      </div>
                    ) : (
                      <div style={{ whiteSpace: 'pre-wrap' }}>{comment.comment}</div>
                    )}
                  </div>
                </Card.Body>
              </Card>

              {displayHistoryModal && editNoteId === comment.id && (
                <Modal
                  show={displayHistoryModal}
                  onHide={onHide}
                  backdrop="static"
                  size="lg"
                  centered
                >
                  <Modal.Header className="modal-header" closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">Note History</Modal.Title>
                  </Modal.Header>
                  <Modal.Body style={{ maxHeight: '400px', overflowY: 'scroll' }}>
                    {comment.history &&
                      comment.history
                        .slice()
                        .reverse()
                        .map((history: IComments, index: number) => (
                          <Card key={index} className="comment-container">
                            <Card.Body>
                              <Card.Subtitle className="mb-2 text-muted">
                                <div>{`Created  by  ${
                                  history.author
                                } - ${moment(history.createdAt).format('DD-MM-YYYY')} - ${getTimeAgo(
                                  history.createdAt,
                                )}`}</div>
                              </Card.Subtitle>

                              <Card.Text style={{ whiteSpace: 'pre-wrap' }}>
                                {history.comment}
                              </Card.Text>
                            </Card.Body>
                          </Card>
                        ))}
                  </Modal.Body>
                </Modal>
              )}
            </div>
          )
        })}
    </>
  )
}

export default CommentComponent
