import { useEffect, useState } from 'react'
import moment from 'moment'
import { Button, Col, Form, Modal, Row, Spinner, Table } from 'react-bootstrap'
import { useDeviceDispatch, useDeviceState } from '@context/device/context/device.context'
import { useUserState } from '@context/user/context/user.context'
import { useWaterUsageStat } from '@data/waterUsage/waterUsage'
import { calculateCo2Produced, hasPermissions, roundToTwo } from '@common/utils/helperFunctions'
import Device, { DeviceIDTypes } from '@context/device/model/device'
import SectorIcon from '../../general/SectorIcon/SectorIcon'
import { useAuthState } from '@context/auth/context/auth.context'
import { WaterUsageStats } from '@data/waterUsage/model/waterUsage.model'

import './DeviceCo2Component.scss'

interface WaterUsageStatsWithDevice extends WaterUsageStats {
  device: Device | undefined
}

export const DeviceCo2Component = () => {
  const { userInfo } = useUserState()
  const { devices, deviceUpdating } = useDeviceState()
  const { permissions } = useAuthState()
  const { updateDeviceData } = useDeviceDispatch()

  const { data: waterUsageStats } = useWaterUsageStat(userInfo.preferences.uom)

  const [filteredDevices, setFilteredDevices] = useState<Device[]>(devices)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedDevice, setSelectedDevice] = useState<Device>()
  const [selectedDeviceCo2Rate, setSelectedDeviceCo2Rate] = useState<number | undefined>(undefined)
  const [didDeviceInfoChange, setDidDeviceInfoChange] = useState(false)
  const [searchTerm, setSearchTerm] = useState<string>('')

  const COLUMNS = [
    {
      name: 'Device',
      key: 'device',
    },
    {
      name: (
        <>
          Today
          <br />
          <small>
            <i className="p-0">{moment().format('MMM DD')}</i>
          </small>
        </>
      ),
      key: 'co2-today',
    },
    {
      name: (
        <>
          Week to Date
          <br />
          <small>
            <i className="p-0">
              {moment().startOf('week').format('MMM DD')} to{' '}
              {moment().endOf('week').format('MMM DD')}
            </i>
          </small>
        </>
      ),
      key: 'co2-week',
    },
    {
      name: (
        <>
          Month to Date
          <br />
          <small>
            <i className="p-0">{moment().format('MMMM')}</i>
          </small>
        </>
      ),
      key: 'co2-month',
    },
    {
      name: (
        <>
          Year to Date
          <br />
          <small>
            <i className="p-0">{moment().year()}</i>
          </small>
        </>
      ),
      key: 'co2-year',
    },
  ]

  useEffect(() => {
    if (searchTerm !== '') {
      const foundDevices = filteredDevices.filter(
        (device) =>
          device.deviceName.toLowerCase().includes(searchTerm.trim().toLowerCase()) ||
          String(device.deviceId).includes(searchTerm.trim().toLowerCase()),
      )

      setFilteredDevices(foundDevices)
    } else {
      setFilteredDevices(devices)
    }
  }, [searchTerm, devices])

  useEffect(() => {
    if (selectedDevice && selectedDevice.deviceSettings) {
      if (
        selectedDevice.deviceSettings.co2Rate !== selectedDeviceCo2Rate &&
        selectedDeviceCo2Rate !== 0
      ) {
        // Some value has changed
        setDidDeviceInfoChange(true)
      } else {
        setDidDeviceInfoChange(false)
      }
    }
  }, [selectedDeviceCo2Rate])

  const clearSearchTerm = () => {
    setSearchTerm('')
  }

  const openModal = (device: Device) => {
    const deviceCo2Rate = device.deviceSettings.co2Rate

    setSelectedDeviceCo2Rate(roundToTwo(Number(deviceCo2Rate)))
    setSelectedDevice(device)

    setIsModalOpen(true)
  }

  const onHide = () => {
    setSelectedDeviceCo2Rate(undefined)
    setIsModalOpen(false)
  }

  const handleUpdateDeviceInfo = async () => {
    if (!selectedDevice) return

    const updatedDevice = { ...selectedDevice }
    updatedDevice.deviceSettings.co2Rate = Number(selectedDeviceCo2Rate)

    if (updatedDevice.dUUID) {
      await updateDeviceData(updatedDevice?.dUUID, DeviceIDTypes.dUUID, updatedDevice)
    }

    setDidDeviceInfoChange(false)
    setIsModalOpen(false)
  }

  const onSearchDeviceTermChange = (e: React.FormEvent<HTMLInputElement>) => {
    setSearchTerm(e.currentTarget.value)
  }

  let totalDailyUsage = 0
  let totalWeeklyUsage = 0
  let totalMonthlyUsage = 0
  let totalYearlyUsage = 0

  return (
    <div className="devices-co2-produced mt-3">
      <h1>
        Total Co<sub>2</sub> Produced
      </h1>

      {devices.length > 10 && (
        <div className="search-section">
          <div className="form-group search-bar">
            <span className="fa fa-search search-icon"></span>
            <input
              type="text"
              className="form-control"
              placeholder="Search by name or device id"
              value={searchTerm}
              onChange={onSearchDeviceTermChange}
            />
            {searchTerm !== '' && (
              <span className="fa fa-times clear-icon" onClick={clearSearchTerm}></span>
            )}
          </div>
          <div className="result-number">
            <span>( {filteredDevices.length} )</span>
          </div>
        </div>
      )}

      <Table className="w-100">
        <thead style={{ width: '15%', position: 'sticky', top: 0, zIndex: 2 }}>
          <tr>
            {COLUMNS.map((column) => (
              <th key={column.key}>{column.name}</th>
            ))}
          </tr>
        </thead>

        <tbody>
          {waterUsageStats &&
            Object.values(waterUsageStats)
              .map((stats) => {
                const device = filteredDevices.find((d) => d.deviceId === stats.deviceId)

                return {
                  ...stats,
                  device,
                }
              })
              .filter((stats) => !!stats?.device?.deviceName)
              .map((stats: WaterUsageStatsWithDevice) => {
                if (stats.device) {
                  const dailyUsageCo2 = calculateCo2Produced(
                    userInfo.preferences.uom,
                    stats.dailyUsage,
                    [stats.device],
                  )
                  const weeklyUsageCo2 = calculateCo2Produced(
                    userInfo.preferences.uom,
                    stats.weeklyUsage,
                    [stats.device],
                  )
                  const monthlyUsageCo2 = calculateCo2Produced(
                    userInfo.preferences.uom,
                    stats.monthlyUsage,
                    [stats.device],
                  )
                  const yearlyUsageCo2 = calculateCo2Produced(
                    userInfo.preferences.uom,
                    stats.yearlyUsage,
                    [stats.device],
                  )

                  totalDailyUsage += dailyUsageCo2.value
                  totalWeeklyUsage += weeklyUsageCo2.value
                  totalMonthlyUsage += monthlyUsageCo2.value
                  totalYearlyUsage += monthlyUsageCo2.value

                  return (
                    <tr
                      key={stats.deviceId}
                      onClick={() => hasPermissions(permissions, ['UPDATE:DEVICE:PROD:USER']) && stats.device && openModal(stats.device)}
                      style={{ cursor: hasPermissions(permissions, ['UPDATE:DEVICE:PROD:USER']) ? 'pointer' : 'default' }}
                    >
                      <td>
                        {hasPermissions(permissions, ['ACCOUNT:ADMIN:SMARTFLOW']) ? (
                          <SectorIcon
                            sector={stats?.device?.deviceSettings?.sectorType}
                            occupants={stats?.device?.deviceSettings.occupants}
                          />
                        ) : null}{' '}
                        {stats?.device?.deviceName}
                      </td>

                      <td>{`${dailyUsageCo2.value + dailyUsageCo2.unit}`}</td>
                      <td>{`${weeklyUsageCo2.value + weeklyUsageCo2.unit}`}</td>
                      <td>{`${monthlyUsageCo2.value + monthlyUsageCo2.unit}`}</td>
                      <td>{`${yearlyUsageCo2.value + yearlyUsageCo2.unit}`}</td>
                    </tr>
                  )
                }
              })}
        </tbody>
        <tfoot>
          {filteredDevices.length > 0 && (
            <tr>
              <td>
                <b>
                  Total Co<sub>2</sub> Produced
                </b>
              </td>
              <td>
                <b>{roundToTwo(totalDailyUsage)}Kg</b>
              </td>
              <td>
                <b>{roundToTwo(totalWeeklyUsage)}Kg</b>
              </td>
              <td>
                <b>{roundToTwo(totalMonthlyUsage)}Kg</b>
              </td>
              <td>
                <b>{roundToTwo(totalYearlyUsage)}Kg</b>
              </td>
            </tr>
          )}
        </tfoot>
      </Table>

      {isModalOpen && (
        <Modal
          show={isModalOpen}
          onHide={onHide}
          backdrop="static"
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="device-information-modal"
        >
          <Modal.Header className="modal-header" closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Device Information</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h4>{selectedDevice?.deviceName}</h4>
            <hr />
            <Form className="device-information-form">
              <Row className="w-50">
                <Form.Group as={Col} controlId="formGridCurrency">
                  <Form.Label>
                    Co<sub>2</sub> Rate{' '}
                    <span className="red">{selectedDeviceCo2Rate === 0 ? '*' : ''}</span>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    min="0.01"
                    placeholder="e.g. 1.93"
                    value={selectedDeviceCo2Rate}
                    onChange={(e) =>
                      isNaN(parseFloat(e.target.value))
                        ? 0
                        : setSelectedDeviceCo2Rate(parseFloat(e.target.value))
                    }
                  />
                </Form.Group>
              </Row>
              <div className="d-flex justify-content-between">
                <Button
                  id="update-device-info"
                  className="mt-5"
                  variant="primary"
                  type="button"
                  disabled={!didDeviceInfoChange || deviceUpdating}
                  onClick={handleUpdateDeviceInfo}
                >
                  {deviceUpdating ? (
                    <>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      <span>Loading...</span>
                    </>
                  ) : (
                    <>Update</>
                  )}
                </Button>
                <Button
                  className="mt-5"
                  variant="outline"
                  type="button"
                  disabled={deviceUpdating}
                  onClick={() => setIsModalOpen(false)}
                >
                  Close
                </Button>
              </div>
            </Form>
          </Modal.Body>
        </Modal>
      )}
    </div>
  )
}
