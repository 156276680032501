import backend from '@api/backend'
import Account from '../../account/model/Account'
import { ActionTypes } from '../context/location.reducer'

export const loadLocationsAction = async (dispatch: any, accounts: Account[]) => {
  try {
    dispatch({ type: ActionTypes.LOADING })

    const locations = (await backend.get('/customer_locations')).data
    dispatch({ type: ActionTypes.INIT, payload: { locations, accounts } })
  } catch (error) {
    console.log(error)
    dispatch({ type: ActionTypes.ERROR })
  }
}
