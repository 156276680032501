import { mapUser } from '../../customerManager/customer-manager.reducer'
import User from '../model/user'

export enum ActionTypes {
  LOAD_USER,
  SUCCESS,
  UPDATING,
  ERROR,
  UPDATING_PROFILE,
  PROFILE_UPDATED,
  UPDATING_PREFERENCES,
  PREFERENCES_UPDATED,
  CONFIRMING_EMAIL,
  EMAIL_CONFIRMED,
  RESETTING_PASSWORD,
  PASSWORD_RESET,
  REQUESTING_CONFIRM_EMAIL,
  CONFIRM_EMAIL_SENT,
  UPDATING_PASSWORD,
  PASSWORD_UPDATED,
  SENDING_FORGOT_EMAIL,
  FORGOT_PASSWORD_SENT,
}

interface State {
  userInfo: User
  loading: boolean
  error?: boolean
  updatingProfile: boolean
  updatingPreferences: boolean
  confirmingEmail: boolean
  emailConfirmed: boolean
  resettingPassword: boolean
  requestingConfirmEmail: boolean
  confirmEmailSent: boolean
  updatingPassword: boolean
  passwordUpdated: boolean
  sendingForgotEmail: boolean
  forgotPasswordEmailSent: boolean
}

export interface Action {
  type: ActionTypes
  payload?: {
    data: any
  }
}

export const initialState: State = {
  userInfo: {
    id: '',
    firstname: '',
    lastname: '',
    initials: '',
    email: '',
    mobile: '',
    active: false,
    groups: [],
    accountIds: [],
    adminAccountIds: [],
    viewAllLocationsAccountIds: [],
    adminCustomerLocationIds: [],
    customerLocationIds: [],
    alertLocationIds: [],
    customerLocations: [],
    accounts: [],
    preferences: {
      uom: '',
      lang: '',
    },
    accountContactIds: [],
  },
  loading: true,
  updatingProfile: false,
  updatingPreferences: false,
  confirmingEmail: false,
  emailConfirmed: false,
  resettingPassword: false,
  requestingConfirmEmail: false,
  confirmEmailSent: false,
  updatingPassword: false,
  passwordUpdated: false,
  sendingForgotEmail: false,
  forgotPasswordEmailSent: false,
}

export const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case ActionTypes.LOAD_USER: {
      if (!action.payload) return state
      const { data } = action.payload
      return {
        ...state,
        userInfo: data ? mapUser(data) : state.userInfo,
        loading: false,
        error: false,
      }
    }
    case ActionTypes.CONFIRMING_EMAIL:
      return { ...state, confirmingEmail: true, emailConfirmed: false, error: false }
    case ActionTypes.EMAIL_CONFIRMED:
      return { ...state, confirmingEmail: false, emailConfirmed: true, error: false }
    case ActionTypes.RESETTING_PASSWORD:
      return { ...state, resettingPassword: true, error: false }
    case ActionTypes.PASSWORD_RESET:
      return { ...state, resettingPassword: false, error: false }
    case ActionTypes.UPDATING_PROFILE:
      return { ...state, updatingProfile: true, profileUpdated: false, error: false }
    case ActionTypes.PROFILE_UPDATED:
      if (action.payload && action.payload.data) {
        const { first_name, last_name, mobile } = action.payload.data
        const updatedInfo = {
          ...state.userInfo,
          firstname: first_name,
          lastname: last_name,
          mobile,
        }
        return { ...state, updatingProfile: false, userInfo: updatedInfo, error: false }
      }
      return { ...state, updatingProfile: false, error: false }
    case ActionTypes.UPDATING_PREFERENCES:
      return { ...state, updatingPreferences: true, error: false }
    case ActionTypes.PREFERENCES_UPDATED:
      if (action.payload && action.payload.data) {
        const { preferences } = action.payload.data

        const updatedInfo = {
          ...state.userInfo,
          preferences: preferences,
        }
        return { ...state, updatingPreferences: false, userInfo: updatedInfo, error: false }
      }
      return { ...state, updatingProfile: false, error: false }

    case ActionTypes.REQUESTING_CONFIRM_EMAIL:
      return { ...state, requestingConfirmEmail: true, confirmEmailSent: false, error: false }
    case ActionTypes.CONFIRM_EMAIL_SENT:
      return { ...state, requestingConfirmEmail: false, confirmEmailSent: true, error: false }
    case ActionTypes.UPDATING_PASSWORD:
      return { ...state, updatingPassword: true, passwordUpdated: false, error: false }
    case ActionTypes.PASSWORD_UPDATED:
      return { ...state, updatingPassword: false, passwordUpdated: true, error: false }
    case ActionTypes.SENDING_FORGOT_EMAIL:
      return { ...state, sendingForgotEmail: true, error: false }
    case ActionTypes.FORGOT_PASSWORD_SENT:
      return { ...state, sendingForgotEmail: false, forgotPasswordEmailSent: true }
    case ActionTypes.UPDATING:
      return { ...state, loading: true, error: false }
    case ActionTypes.ERROR:
      return {
        ...state,
        loading: false,
        updatingProfile: false,
        confirmingEmail: false,
        resettingPassword: false,
        requestingConfirmEmail: false,
        updatingPassword: false,
        error: true,
      }
    default:
      return state
  }
}
