import { useState, useEffect, useRef, useCallback } from 'react'
import moment from 'moment'
import { Button, Form, Modal, Row, Col } from 'react-bootstrap'
import CreatableSelect from 'react-select/creatable'
import Handlebars from 'handlebars'
import { displayToast } from '@common/utils/appToast'
import User from '@context/user/model/user'

import backend from '@api/backend'
import Account from '@context/account/model/Account'
import {
  handleReportUpdateReactQuery,
  useReportsTracking,
} from '@data/reportsTracking/reportsTracking'

interface Props {
  onHide: () => void
  selectedAccount: Account
  pdfAttachment: string
  accountUsers: User[]
}

interface Recipient {
  email: string
}

const PERFORMANCE_SUMMARY_EMAIL_TEMPLATE = 'd-3f962b76af274c4e87ebdcc53d840f85'

const HomeTacticalEmailModal = ({
  onHide,
  selectedAccount,
  pdfAttachment,
  accountUsers,
}: Props) => {
  const [allRecipients, setAllRecipients] = useState<{
    to: Recipient[]
    bcc: Recipient[]
    cc: Recipient[]
  }>({ to: [], cc: [], bcc: [] })
  const [toRecipients, setToRecipients] = useState<Recipient[]>([])
  const [ccRecipients, setCcRecipients] = useState<Recipient[]>([])
  const [bccRecipients, setBccRecipients] = useState<Recipient[]>([])

  const [contactName, setContactName] = useState('Team')
  const [renderedEmail, setRenderedEmail] = useState('')

  const renderTemplate = (template: any) => setRenderedEmail(template(mapDynamicProperties()))
  const [templateContent, setTemplateContent] = useHookWithRefCallback(renderTemplate)

  const { data: reports } = useReportsTracking()

  const { mutate: updateReporStatus } = handleReportUpdateReactQuery()

  const mapDynamicProperties = () => {
    return {
      device_name: selectedAccount.name,
      contact_name: contactName,
    }
  }

  useEffect(() => {
    if (accountUsers?.length > 0) {
      const adminUsers = getUserEmailsByAccountId(accountUsers, selectedAccount.id)

      setAllRecipients((prevState) => ({
        ...prevState,
        to: adminUsers.map((email) => ({ email })),
      }))
    }
  }, [accountUsers])

  useEffect(() => {
    backend
      .get(`/mail_templates/${PERFORMANCE_SUMMARY_EMAIL_TEMPLATE}`)
      .then((response) => response.data)
      .then(function (response) {
        Handlebars.registerHelper('helperMissing', function (/* dynamic arguments */) {
          let options = arguments[arguments.length - 1]
          return new Handlebars.SafeString(`{{${options.name}}}`)
        })
        Handlebars.registerHelper('equals', function (this: any, lvalue, rvalue, options) {
          if (arguments.length < 3) throw new Error('Handlebars Helper equal needs 2 parameters')
          if (lvalue !== rvalue) {
            return options.inverse(this)
          } else {
            return options.fn(this)
          }
        })
        const template = Handlebars.compile(response.content.html_content)
        setTemplateContent(template)
      })
  }, [])

  useEffect(() => {
    if (!templateContent.current) return
    renderTemplate(templateContent.current)
  }, [contactName])

  useEffect(() => {
    setToRecipients(allRecipients.to)
    setCcRecipients(allRecipients.cc)
    setBccRecipients(allRecipients.bcc)
  }, [allRecipients])

  function useHookWithRefCallback(fn: (node: any) => void) {
    const ref = useRef<any>()
    const setRef = useCallback((node: any) => {
      if (node) {
        fn(node)
      }
      ref.current = node
    }, [])

    return [ref, setRef] as any[]
  }

  function getUserEmailsByAccountId(users: User[], accountId: string) {
    return users
      .filter((user) => user.adminAccountIds.includes(accountId))
      .map((user) => user.email)
  }

  const sendEmail = async () => {
    if (toRecipients.length === 0) return

    const request = {
      template_id: PERFORMANCE_SUMMARY_EMAIL_TEMPLATE,
      recipients: {
        to: toRecipients.map((r) => r.email),
        cc: ccRecipients.map((r) => r.email),
        bcc: bccRecipients.map((r) => r.email),
      },
      dynamic_template_data: mapDynamicProperties(),
      attachments: [
        {
          data: pdfAttachment
            ? pdfAttachment.substring(pdfAttachment.indexOf(';base64,') + ';base64,'.length)
            : null,
          mime: 'application/pdf',
          file_name: `${selectedAccount.name} - SMART FLOW year to date summary ${moment().format('DD-MM-YYYY')}.pdf`,
          disposition: 'inline',
          cid: 'image_1',
        },
      ],
    }

    try {
      const btn = document.getElementById('send-email-button')! as HTMLButtonElement
      btn.disabled = true
      await backend.post(`/reports/send_esg_report`, request)

      const currentMonth = moment().month() + 1 // month() returns 0-11
      const currentYear = moment().year()

      const devicesToUpdateReports = reports?.filter(
        (report) =>
          report.accountId === Number(selectedAccount.id) &&
          report.status === false &&
          report.month === (currentMonth === 1 ? 12 : currentMonth - 1) &&
          report.year === (currentMonth === 1 ? currentYear - 1 : currentYear),
      )

      if (devicesToUpdateReports) {
        const data = devicesToUpdateReports.map((report) => {
          return {
            report_id: report.id,
            status: true,
          }
        })

        const accountId = Number(selectedAccount.id)

        updateReporStatus({ accountId, data })
      }

      displayToast({
        type: 'success',
        message: 'Email sent successfully',
      })

      onHide()
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <Modal show={true} onHide={onHide} fullscreeen="true" size="xl">
      <Modal.Header className="modal-header" closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Send Home Tactical by email</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="container">
          <Row>
            <Col sm={4}>
              <Form>
                <Form.Group className="mb-3">
                  <Form.Label>Recipient To:</Form.Label>
                  <CreatableSelect
                    isMulti
                    value={toRecipients}
                    onChange={(options: any) => setToRecipients(options)}
                    options={allRecipients.to}
                    getOptionValue={(option: any) =>
                      option.__isNew__ ? option.value : String(option.id)
                    }
                    getOptionLabel={(option: any) =>
                      option.__isNew__ ? option.label : String(option.email)
                    }
                    onCreateOption={(input) => {
                      setToRecipients([...toRecipients, { email: input }])
                    }}
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Recipient CC:</Form.Label>
                  <CreatableSelect
                    isMulti
                    value={ccRecipients}
                    onChange={(options: any) => setCcRecipients(options)}
                    options={allRecipients.cc}
                    getOptionValue={(option: any) =>
                      option.__isNew__ ? option.value : String(option.id)
                    }
                    getOptionLabel={(option: any) =>
                      option.__isNew__ ? option.label : String(option.email)
                    }
                    onCreateOption={(input) => {
                      setCcRecipients([...ccRecipients, { email: input }])
                    }}
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Recipient BCC:</Form.Label>
                  <CreatableSelect
                    isMulti
                    value={bccRecipients}
                    onChange={(options: any) => setBccRecipients(options)}
                    options={allRecipients.bcc}
                    getOptionValue={(option: any) =>
                      option.__isNew__ ? option.value : String(option.id)
                    }
                    getOptionLabel={(option: any) =>
                      option.__isNew__ ? option.label : String(option.email)
                    }
                    onCreateOption={(input) => {
                      setBccRecipients([...bccRecipients, { email: input }])
                    }}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                  <Form.Label>Contact Name</Form.Label>
                  <Form.Control
                    type="text"
                    value={contactName}
                    onChange={(e) => setContactName(e.target.value)}
                  />
                </Form.Group>

                <Button
                  id="send-email-button"
                  style={{ marginRight: '1em', marginBottom: '1em' }}
                  variant="primary"
                  type="button"
                  onClick={() => sendEmail()}
                >
                  Send Email
                </Button>
              </Form>
            </Col>
            <Col sm={8}>
              <div dangerouslySetInnerHTML={{ __html: renderedEmail }}></div>
            </Col>
          </Row>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default HomeTacticalEmailModal
