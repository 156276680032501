import React from 'react'
import {
  BrowserRouter,
  Route,
  Routes,
  Navigate,
  useNavigationType,
  useLocation,
  createRoutesFromChildren,
  matchRoutes,
} from 'react-router-dom'

import SideBar from './components/nav/SideBar/Sidebar'
import TopBar from './components/nav/TopBar/TopBar'
import Login from './components/main/Login/LoginForm'
import MainContent from './components/main/MainContent'
import { useAuthState } from './context/auth/context/auth.context'
import { AccountContextProvider } from './context/account/context/account.context'
import { LocationContextProvider } from './context/location/context/location.context'
import { DeviceContextProvider } from './context/device/context/device.context'
import { AlertContextProvider } from './context/alert/context/alert.context'
import ForgotPassword from './components/main/Login/ForgotPassword'
import ConfirmEmail from './components/main/Login/ConfirmEmail'
import GlobalDetectIdle from './components/general/GlobalDetectIdle/GlobalDetectIdle'
import ResetPassword from './components/main/Login/ResetPassword'
import { CustomerManagerContextProvider } from './context/customerManager/customer-manager.context'
import { ToastContainer } from 'react-toastify'
import Loading from './components/general/Loading/Loading'
import RedirectRoute from './RedirectRoute'
import * as Sentry from '@sentry/react'
import { AxiosInterceptor } from '@api/backend'

import './App.scss'
import './common/styles'
import 'react-toastify/dist/ReactToastify.css'

Sentry.init({
  dsn: import.meta.env.VITE_APP_SENTRY_DSN || '',
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  enabled:
    import.meta.env.VITE_APP_SENTRY_ENABLED === true && import.meta.env.VITE_APP_SENTRY_DSN !== '',
  environment: import.meta.env.VITE_APP_ENV_STATE,
})

export const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes)

const App: React.FC = () => {
  const { authenticated, loading } = useAuthState()
  if (loading) {
    return <Loading />
  }

  return (
    <BrowserRouter>
      <ToastContainer />
      {!authenticated ? (
        <AxiosInterceptor>
          <SentryRoutes>
            <Route path="/" element={<Login />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/users/confirm_email/:token" element={<ConfirmEmail />} />
            <Route path="/users/register/:token" element={<ConfirmEmail />} />
            <Route path="/users/reset_password/:token" element={<ResetPassword />} />
            <Route
              path="*"
              element={
                <RedirectRoute>
                  <Navigate to="/" replace />
                </RedirectRoute>
              }
            />
          </SentryRoutes>
        </AxiosInterceptor>
      ) : (
        <GlobalDetectIdle>
          <div id="wrapper">
            <AccountContextProvider>
              <LocationContextProvider>
                <DeviceContextProvider>
                  <SideBar />
                  <div id="content-wrapper" className="d-flex flex-column content-wrapper">
                    <TopBar />
                    <AlertContextProvider>
                      <CustomerManagerContextProvider>
                        <AxiosInterceptor>
                          <MainContent />
                        </AxiosInterceptor>
                      </CustomerManagerContextProvider>
                    </AlertContextProvider>
                  </div>
                </DeviceContextProvider>
              </LocationContextProvider>
            </AccountContextProvider>
          </div>
        </GlobalDetectIdle>
      )}
    </BrowserRouter>
  )
}

export default App
