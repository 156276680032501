import { NavLink } from 'react-router-dom'
import { useLayoutDispatch } from '@context/layout/context/layout.context'

function SideBarMenuBlock(props: any) {
  const { toggleSideBar } = useLayoutDispatch()

  return (
    <div>
      <hr className="sidebar-divider" />
      <div className="sidebar-heading">{props.heading}</div>
      {props.menuItems.map((menuItem: any) => (
        <li onClick={toggleSideBar} key={menuItem.href} className="nav-item">
          <NavLink
            className={({ isActive }) => 'nav-link pb-1' + (isActive ? ' active' : '')}
            to={menuItem.href}
          >
            <i className={'fas fa-fw ' + menuItem.icon}></i>
            <span style={{ marginLeft: '1em' }}>{menuItem.title}</span>
          </NavLink>
        </li>
      ))}
    </div>
  )
}

export default SideBarMenuBlock
