import { useEffect, useState } from 'react'
import Select, { components } from 'react-select'
import { Form, Dropdown, DropdownButton } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

import { displayToast } from '@common/utils/appToast'
import { useUserState } from '@context/user/context/user.context'
import { useAuthDispatch, useAuthState } from '@context/auth/context/auth.context'
import { useLayoutDispatch } from '@context/layout/context/layout.context'
import { useAccountDispatch, useAccountState } from '@context/account/context/account.context'
import LocationSelector from '../../main/Components/LocationSelector/LocationSelector'
import { ChevronLeft, ChevronRight } from 'lucide-react'
import { hasPermissions } from '@common/utils/helperFunctions'
import { useWaterUsageCost, useWaterUsageStat } from '@data/waterUsage/waterUsage'
import { useDeviceState } from '@context/device/context/device.context'

import './TopBar.scss'

const ValueContainer = (containerProps: any) => (
  <components.ValueContainer {...containerProps} className={'d-flex'}>
    Accounts ({containerProps?.children[0]?.length ? containerProps.children[0].length : 0} /{' '}
    {containerProps.options.length}){containerProps.children[1]}
  </components.ValueContainer>
)

const Option = (containerProps: any) => {
  return (
    <components.Option {...containerProps}>
      <Form.Check checked={containerProps.isSelected} readOnly label={containerProps.children} />
    </components.Option>
  )
}

function TopBar() {
  const navigate = useNavigate()
  const { userInfo } = useUserState()
  const { permissions } = useAuthState()
  const { logout } = useAuthDispatch()
  const { toggleSideBar } = useLayoutDispatch()

  const { accounts, selectedAccounts, loading } = useAccountState()
  const { loadingDevices } = useDeviceState()
  const { setSelectedAccounts } = useAccountDispatch()

  const [hideAccountsDropdown, setHideAccountsDropdown] = useState<boolean>(false)

  const { isLoading: isLoadingCosts, isFetching: isFetchingCosts } = useWaterUsageCost(
    userInfo.preferences.uom,
  )

  const { isLoading: isLoadingStats, isFetching: isFetchingStats } = useWaterUsageStat(
    userInfo.preferences.uom,
  )

  const isLoadingData =
    loading ||
    isLoadingCosts ||
    isLoadingStats ||
    isFetchingCosts ||
    isFetchingStats ||
    loadingDevices

  const handleMyProfileClick = () => {
    navigate('/user-profile')
  }

  const url = window.location.hostname
  const isTestEnvironment = url.includes('uat')

  const activeAccounts = accounts.filter((account) => account.isActive)

  const handleLogout = () => {
    navigate('/', { replace: true, state: null })

    logout()
  }

  useEffect(() => {
    if (!activeAccounts.length && !loading) {
      displayToast({
        type: 'error',
        message:
          'An Error occurred while trying to load user profile. Please contact our support team.',
        id: 'innactive-account',
      })

      handleLogout()
    }
  }, [accounts])

  const optionStyles = (styles: any, { isFocused }: any) => {
    return {
      ...styles,
      backgroundColor: isFocused ? '#eee' : null,
      color: '#333',
    }
  }

  const MenuList = (props: any) => {
    return (
      <components.MenuList {...props}>
        {selectedAccounts.length > 0 ? (
          <button className="btn btn-link" onClick={() => setSelectedAccounts([])}>
            deselect all accounts
          </button>
        ) : (
          <button className="btn btn-link" onClick={() => setSelectedAccounts(activeAccounts)}>
            select all accounts
          </button>
        )}
        {props.children}
      </components.MenuList>
    )
  }

  const toggleAccountsDropdown = () => {
    setHideAccountsDropdown(!hideAccountsDropdown)
  }

  return (
    <div className="">
      <nav className="navbar navbar-expand navbar-light topbar static-top shadow">
        <div className="d-flex align-items-center w-100">
          <button
            id="sidebarToggleTop"
            className="btn btn-link rounded-circle mr-2 ml-2"
            onClick={toggleSideBar}
          >
            <i className="fa fa-bars"></i>
          </button>
          <div className="d-flex flex-column flex-md-row w-50 align-items-center dropdown-container">
            {activeAccounts.length > 1 ? (
              <>
                {hasPermissions(permissions, ['ACCOUNT:ADMIN:SMARTFLOW']) && (
                  <div className="ms-md-2 d-none d-sm-block d-sm-none d-md-block" role="button">
                    {hideAccountsDropdown ? (
                      <ChevronRight onClick={toggleAccountsDropdown} />
                    ) : (
                      <ChevronLeft onClick={toggleAccountsDropdown} />
                    )}
                  </div>
                )}

                {!hideAccountsDropdown && (
                  <div className="pr-3 mb-2 mb-md-0 account-selector" style={{ fontSize: '0.9em' }}>
                    <Select
                      styles={{ option: optionStyles }}
                      components={{ MenuList, ValueContainer, Option }}
                      className="basic-multi-select "
                      value={selectedAccounts}
                      onChange={(options: any) => setSelectedAccounts([...options])}
                      options={activeAccounts}
                      isMulti
                      name="accounts"
                      classNamePrefix="select"
                      getOptionValue={(option) => option.id}
                      getOptionLabel={(option) => `${option.name}`}
                      isClearable={false}
                      hideSelectedOptions={false}
                      closeMenuOnSelect={false}
                      isLoading={activeAccounts.length === 0 || isLoadingData}
                      isDisabled={activeAccounts.length === 0 || isLoadingData}
                    />
                  </div>
                )}
              </>
            ) : (
              <h4 className="mb-2 w-100">{activeAccounts[0]?.name}</h4>
            )}
            <div className="w-100 mb-2 mb-md-0">{!isLoadingData && <LocationSelector />}</div>
          </div>

          <div className="account-icons-container ml-auto d-flex align-items-center">
            <div className="sidebar-brand-icon">
              <img alt="Smartflow" src="/logo.png" />
            </div>
            <div className="current-user">
              <div className="topbar-divider d-none d-sm-block"></div>
              <DropdownButton
                className="user-dropdown btn-circle mr-3"
                id="dropdown-item-button"
                title={`${userInfo.initials}`}
                variant="info"
              >
                <Dropdown.Item as="button" onClick={handleMyProfileClick}>
                  My Profile
                </Dropdown.Item>
                <Dropdown.Item as="button" onClick={handleLogout}>
                  Log out
                </Dropdown.Item>
              </DropdownButton>
            </div>
          </div>
        </div>
      </nav>
      {isTestEnvironment && (
        <div className="w-100 bg-danger text-white text-center">TEST ENVIRONMENT</div>
      )}
    </div>
  )
}

export default TopBar
