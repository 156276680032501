import React from 'react'

import WaterUsageComponent from '../../Components/WaterUsage/WaterUsageComponent'
import { useDeviceState } from '@context/device/context/device.context'

const WaterUsage: React.FC = () => {
  const { testDevices } = useDeviceState()

  return (
    <div>
      <WaterUsageComponent devices={testDevices} />
    </div>
  )
}

export default WaterUsage
