import { Button, Col, Form, Modal, Row, Spinner, Tab, Tabs } from 'react-bootstrap'
import { useDeviceState } from '@context/device/context/device.context'
import { useEffect, useState } from 'react'
import { useLocationDispatch } from '@context/location/context/location.context'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import { useCustomerManagerDispatch } from '@context/customerManager/customer-manager.context'
import { useAccountState } from '@context/account/context/account.context'
import CommentComponent from '../Components/Comment/CommentComponent'
import { IComments } from '@data/comments/model/comments.model'
import {
  useLocationComments,
  useLocationCommentsCreation,
  useLocationCommentsUpdate,
} from '@data/comments/locationComments/locationComments'

interface IModalProps {
  show: boolean
  locationData: any
  onHide: () => void
}

const CustomerJourneyModal: React.FC<IModalProps> = ({
  show,
  locationData,
  onHide,
}: IModalProps) => {
  const { accounts } = useAccountState()

  const { loadCustomerLocations } = useLocationDispatch()
  const { addLocationToAccount } = useCustomerManagerDispatch()
  const { deviceUpdating } = useDeviceState()

  const [newOnboardingDate, setNewOnboardingDate] = useState<Date | null>(null)
  const [locationOnboardingDate, setLocationOnboardingDate] = useState<Date | null>(null)

  const [didDeviceInfoChange, setDidDeviceInfoChange] = useState(false)

  const { data: locationComments, isLoading } = useLocationComments(locationData?.id)

  const {
    mutate: createNewLocationComment,
    isPending,
    isError,
    isSuccess,
  } = useLocationCommentsCreation()
  const { mutate: updateComment } = useLocationCommentsUpdate()

  useEffect(() => {
    if (show && locationData) {
      if (locationData.settings.onboardingStatus) {
        const value = moment(locationData.settings.onboardingDate).toDate()
        setNewOnboardingDate(value)
        setLocationOnboardingDate(value)
      }
    }
  }, [show, locationData])

  useEffect(() => {
    if (locationOnboardingDate !== newOnboardingDate) {
      // Some value has changed
      setDidDeviceInfoChange(true)
    } else {
      setDidDeviceInfoChange(false)
    }
  }, [locationData, newOnboardingDate])

  const handleUpdate = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    if (!locationData) return

    const updatedDevice = { ...locationData }

    if (locationOnboardingDate !== newOnboardingDate && locationData) {
      const updatedLocation = { ...locationData }
      const accountLocation = accounts.find((account) => account.id === updatedLocation.accountId)

      updatedLocation.settings.onboardingStatus = true
      updatedLocation.settings.onboardingDate = newOnboardingDate
      if (accountLocation) {
        addLocationToAccount(updatedLocation, accountLocation)
      }
    }

    onHide()
  }

  const handleNewComment = async (
    newComment: string,
    newDepartment: string,
    newCategory: string,
  ) => {
    const newCommentData = {
      location_id: locationData.id,
      comment: newComment,
      department: newDepartment,
      category: newCategory,
    }

    createNewLocationComment(newCommentData)
    refetchLocations()
  }

  const handleUpdateComment = (comment: IComments, updatedComment: string) => {
    const updatedCommentData = {
      location_id: locationData.id,
      comment_id: comment.id,
      comment: updatedComment,
      department: comment.department,
      category: comment.category,
    }

    updateComment(updatedCommentData)
    refetchLocations()
  }

  const refetchLocations = async () => {
    onHide()
    await loadCustomerLocations()
  }

  if (!locationData) return null

  return (
    <Modal
      show={show}
      onHide={onHide}
      backdrop="static"
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="device-information-modal"
    >
      <Modal.Header className="modal-header" closeButton>
        <Modal.Title id="contained-modal-title-vcenter">{`${locationData?.name}`}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Tabs transition={false} id="controlled-tab">
          <Tab eventKey={'reports'} title="Reports">
            <Form className="device-information-form mt-4">
              <Row className="mb-4">
                <Form.Group as={Col} controlId="formGridId">
                  <Form.Label>Location On Boarding Date</Form.Label>
                  <div className="date-picker">
                    <DatePicker
                      selected={newOnboardingDate}
                      onChange={(date: Date | null) => setNewOnboardingDate(date)}
                      placeholderText="Select onboarding date"
                      showTimeSelect
                      dateFormat="dd/MM/yyyy HH:00"
                      timeFormat="HH:mm"
                    />
                  </div>
                </Form.Group>
                <Form.Group as={Col} controlId="formGridId"></Form.Group>
              </Row>

              <div className="d-flex justify-content-between w-100">
                <Button id="close-modal" onClick={onHide} variant="outline">
                  Close
                </Button>
                <Button
                  id="update-device-pulses"
                  size="lg"
                  variant="primary"
                  type="button"
                  className="mt-4"
                  disabled={!didDeviceInfoChange}
                  onClick={handleUpdate}
                >
                  {deviceUpdating ? (
                    <>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      <span>Loading...</span>
                    </>
                  ) : (
                    <>Update</>
                  )}
                </Button>
              </div>
            </Form>
          </Tab>
          <Tab eventKey={'comments'} title="Comments" className="mt-4">
            {!isLoading ? (
              <div className="overflow-auto" style={{ maxHeight: '600px' }}>
                <CommentComponent
                  comments={locationComments}
                  handleNewComment={handleNewComment}
                  handleUpdateComment={handleUpdateComment}
                  showDepartmentAndCategory
                  isPending={isPending}
                  isError={isError}
                  isSuccess={isSuccess}
                />
              </div>
            ) : (
              <div className="d-flex justify-content-center m-auto h-100 mt-5 mb-5">
                <div className="spinner-border text-primary m-auto">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            )}
          </Tab>
        </Tabs>
        <></>
      </Modal.Body>
    </Modal>
  )
}

export default CustomerJourneyModal
