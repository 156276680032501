import React, { useEffect } from 'react'
import { loadLocationsAction } from '../action/location.action'
import CustomerLocation from '../../account/model/CustomerLocation'
import { Action, ActionTypes, initialState, reducer } from './location.reducer'
import { useAccountState } from '../../account/context/account.context'
import { useUserState } from '../../user/context/user.context'

const StateContext = React.createContext(initialState)
const DispatchContext = React.createContext(undefined as any)

export const LocationContextProvider = ({ children }: any) => {
  const [state, dispatch] = React.useReducer(reducer, initialState)
  const { accounts, selectedAccounts, loading } = useAccountState()
  useEffect(() => {
    if (accounts.length > 0) {
      loadLocationsAction(dispatch, accounts)
    }
  }, [accounts])

  useEffect(() => {
    !loading && dispatch({ type: ActionTypes.FILTER_ACCOUNT_LOCATIONS, payload: selectedAccounts })
  }, [selectedAccounts, loading])

  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>{children}</DispatchContext.Provider>
    </StateContext.Provider>
  )
}

export const useLocationState = () => {
  return React.useContext(StateContext)
}

export const useLocationDispatch = () => {
  const { accounts } = useAccountState()

  const dispatch = React.useContext(DispatchContext) as (action: Action) => any

  if (dispatch === undefined) {
    throw new Error('useLocationDispatch must be used within a LocationContextProvider')
  }

  const setSelectedLocations = React.useCallback(
    (setSelectedLocations: CustomerLocation[]) =>
      dispatch({ type: ActionTypes.SELECT_LOCATIONS, payload: setSelectedLocations }),
    [dispatch],
  )

  const loadCustomerLocations = React.useCallback(
    async () => await loadLocationsAction(dispatch, accounts),
    [dispatch],
  )

  return React.useMemo(
    () => ({
      setSelectedLocations,
      loadCustomerLocations,
    }),
    [setSelectedLocations, loadCustomerLocations],
  )
}
