import { useState, useEffect, useRef } from 'react'
import { Bar, Line, getElementAtEvent } from 'react-chartjs-2'
import {
  Chart as ChartJS,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  LineElement,
  BarElement,
  LineController,
  PointElement,
} from 'chart.js'
import { Tabs, Tab } from 'react-bootstrap'
import Select from 'react-select'
import { getRandomDarkColor, hasPermissions, hexToRgb } from '@common/utils/helperFunctions'
import { useDeviceState } from '@context/device/context/device.context'
import Device from '@context/device/model/device'
import UsageNavigator from '../UsageNavigator/UsageNavigator'
import { blueSmartflow } from '@common/utils/constants'
import { useAuthState } from '@context/auth/context/auth.context'
import { useUserState } from '@context/user/context/user.context'
import { useAccountState } from '@context/account/context/account.context'
import { UOM_OPTIONS } from '../../DeviceManagement/DeviceInformationManager/constants'
import { useWaterUsageStat } from '@data/waterUsage/waterUsage'
import { Pagination } from '../../../general/Pagination/Pagination'
import { useSearchParams } from 'react-router-dom'
import { WaterUsageStats } from '@data/waterUsage/model/waterUsage.model'
import { Dataset, getChartOptions, hourlyLabels } from './waterUsageConstants'

import '../Home.scss'
import './CurrentWaterUsageComponent.scss'

ChartJS.register(
  LineController,
  PointElement,
  Tooltip,
  Legend,
  LineController,
  LineElement,
  Title,
  LinearScale,
  BarElement,
)

type DeviceWithUsage = Device & {
  waterUsageObj: WaterUsageStats // Merging the types
}

const DEVICES_PER_PAGE = 10

function buildDataset(label: string, data: number[], color?: string): Dataset {
  const chartType = label.toLowerCase()

  if (
    chartType.includes('weekly') ||
    chartType.includes('monthly') ||
    chartType.includes('yearly')
  ) {
    color = blueSmartflow
  } else {
    if (!color) {
      const colorRGB: any = hexToRgb(getRandomDarkColor())
      color = `rgb(${colorRGB.r}, ${colorRGB.g}, ${colorRGB.b})`
    }
  }

  return { label, data, borderColor: color, backgroundColor: color, fill: false }
}

export function CurrentWaterUsageComponent() {
  const { devices } = useDeviceState()
  const { userInfo } = useUserState()
  const { selectedAccounts } = useAccountState()

  const [selectedDevices, setSelectedDevices] = useState<Device[]>([])
  const [selectedDevicesWithUsage, setSelectedDevicesWithUsage] = useState<DeviceWithUsage[]>([])
  const [devicesWithUsage, setDevicesWithUsage] = useState<DeviceWithUsage[]>([])
  const [singleSelectedDevice, setSingleSelectedDevice] = useState<Device | null>(null)

  const [hourlyDatasets, setHourlyDatasets] = useState<any>([])
  const [dailyDatasets, setDailyDatasets] = useState<any>()
  const [weeklyDatasets, setWeeklyDatasets] = useState<any>()
  const [monthlyDatasets, setMonthlyDatasets] = useState<any>()
  const [annuallyDatasets, setAnnuallyDatasets] = useState<any>([])

  const [searchParams, setSearchParams] = useSearchParams()
  const currentPage = searchParams.get('page') ? Number(searchParams.get('page')) : 1
  const [displayPagination, setDisplayPagination] = useState(false)

  const [updateData, setUpdateData] = useState('')
  const [devicesToShow, setDevicesToShow] = useState<DeviceWithUsage[]>([])

  const [offPeakFrom, setOffPeakFrom] = useState<number>(0)
  const [offPeakTo, setOffPeakTo] = useState('')

  const [tabKey, setTabKey] = useState('hourly')
  const [showUsageNavigator, setShowUsageNavigator] = useState<boolean>(false)
  const { permissions } = useAuthState()
  const chartRef = useRef<ChartJS<'line', number[], string>>(null)

  const [selectedUom, setSelectedUom] = useState<string>('')

  const { data: waterUsageStats, isFetching: fetchingStats } = useWaterUsageStat(selectedUom)

  useEffect(() => {
    setSelectedUom(userInfo.preferences.uom)
  }, [userInfo])

  useEffect(() => {
    setSelectedDevices([])
  }, [selectedAccounts])

  const onClick = (event: any) => {
    if (selectedDevices.length === 1) {
      setSingleSelectedDevice(selectedDevices[0])
      setShowUsageNavigator(true)
    } else {
      if (!chartRef) return

      const { current } = chartRef
      if (current) {
        const chartElement = getElementAtEvent(current, event)
        if (!chartElement.length) return
        const { datasetIndex } = chartElement[0]
        const selectedDevice = selectedDevices[datasetIndex]
        if (selectedDevice) {
          setSingleSelectedDevice(selectedDevice)
          setShowUsageNavigator(true)
        }
      }
    }
  }

  useEffect(() => {
    if (selectedDevices.length && waterUsageStats) {
      const mergedDevices = selectedDevices.map((device) => ({
        ...device,
        waterUsageObj: waterUsageStats[device.deviceId] || {}, // Merges the usage data if it exists
      }))

      setSelectedDevicesWithUsage(mergedDevices)
      setDevicesWithUsage(mergedDevices)
    }
  }, [selectedDevices, waterUsageStats, fetchingStats])

  const sortSelectedDevicesAlphabetically = () => {
    const newSelectedDevices = selectedDevicesWithUsage.sort((a, b) => {
      if (a.deviceName.toLowerCase() < b.deviceName.toLowerCase()) {
        return -1
      }
      if (a.deviceName.toLowerCase() > b.deviceName.toLowerCase()) {
        return 1
      }
      return 0
    })

    handlePageReset()

    setDisplayPagination(true)
    setSelectedDevicesWithUsage(newSelectedDevices)
    setUpdateData('alphabetically')
  }
  const sortSelectedDevicesHighToLow = () => {
    const usageBasedOnCurrentTab = `${tabKey}Usage` as keyof WaterUsageStats

    if (tabKey === 'hourly') {
      const newDevices = selectedDevicesWithUsage.sort((a, b) => {
        const maxA = Math.max(
          ...a.waterUsageObj.hourlyUsage
            .filter((item) => item !== null && item?.value !== null)
            .map((item) => item!.value!),
        )

        const maxB = Math.max(
          ...b.waterUsageObj.hourlyUsage
            .filter((item) => item !== null && item?.value !== null)
            .map((item) => item!.value!),
        )

        return maxB - maxA // Sort in descending order
      })

      setSelectedDevicesWithUsage(newDevices)
    } else {
      const newDevices = selectedDevicesWithUsage.sort(
        (a, b) =>
          Number(b.waterUsageObj[usageBasedOnCurrentTab]) -
          Number(a.waterUsageObj[usageBasedOnCurrentTab]),
      )

      setSelectedDevicesWithUsage(newDevices)
    }

    handlePageReset()
    setDisplayPagination(true)
    setUpdateData('highToLow')
  }

  const handleDisplayAllDevices = () => {
    setDisplayPagination(false)
    setUpdateData('showAll')
  }

  const handlePageReset = () => {
    setSearchParams((params) => {
      params.set('page', '1')

      return params
    })
  }

  const applyOffpeakFilter = () => {
    if (offPeakFrom >= 0 || offPeakTo) {
      const filteredDevicesss: DeviceWithUsage[] = []

      handlePageReset()

      if (tabKey === 'hourly') {
        selectedDevicesWithUsage.map((device) => {
          const values = device.waterUsageObj.hourlyUsage.map((hourly) => hourly?.value ?? 0)
          const filteredValues = values.filter((h) => h !== null)

          const maxValue = Math.max(...filteredValues)
          if (maxValue >= offPeakFrom && maxValue <= parseInt(offPeakTo)) {
            filteredDevicesss.push(device)
          }
        })
      } else if (tabKey === 'daily') {
        selectedDevicesWithUsage.map((device) => {
          if (
            device.waterUsageObj.dailyUsage >= offPeakFrom &&
            device.waterUsageObj.dailyUsage <= parseInt(offPeakTo)
          ) {
            filteredDevicesss.push(device)
          }
        })
      } else if (tabKey === 'weekly') {
        selectedDevicesWithUsage.map((device) => {
          if (
            device.waterUsageObj.weeklyUsage >= offPeakFrom &&
            device.waterUsageObj.weeklyUsage <= parseInt(offPeakTo)
          ) {
            filteredDevicesss.push(device)
          }
        })
      } else if (tabKey === 'monthly') {
        selectedDevicesWithUsage.map((device) => {
          if (
            device.waterUsageObj.monthlyUsage >= offPeakFrom &&
            device.waterUsageObj.monthlyUsage <= parseInt(offPeakTo)
          ) {
            filteredDevicesss.push(device)
          }
        })
      } else if (tabKey === 'yearly') {
        selectedDevicesWithUsage.map((device) => {
          if (offPeakFrom >= 0 && offPeakTo) {
            if (
              device.waterUsageObj.yearlyUsage >= offPeakFrom &&
              device.waterUsageObj.yearlyUsage <= parseInt(offPeakTo)
            ) {
              filteredDevicesss.push(device)
            }
          } else if (offPeakFrom >= 0 && device.waterUsageObj.yearlyUsage >= offPeakFrom) {
            filteredDevicesss.push(device)
          } else if (offPeakTo && device.waterUsageObj.yearlyUsage <= parseInt(offPeakTo)) {
            filteredDevicesss.push(device)
          }
        })
      }

      setSelectedDevicesWithUsage(filteredDevicesss)
      setUpdateData('offPeakFilter')
    }
  }

  useEffect(() => {
    if (selectedDevicesWithUsage.length > 5) {
      setDisplayPagination(true)
    } else {
      setDisplayPagination(false)
      searchParams.delete('page')
    }

    setUpdateData('')
  }, [selectedDevicesWithUsage, currentPage, tabKey])

  useEffect(() => {
    if (
      displayPagination ||
      updateData === 'alphabetically' ||
      updateData === 'highToLow' ||
      updateData === 'offPeakFilter'
    ) {
      const startIndex = (Number(currentPage) - 1) * DEVICES_PER_PAGE
      const endIndex = startIndex + DEVICES_PER_PAGE
      setDevicesToShow(selectedDevicesWithUsage.slice(startIndex, endIndex))
    } else {
      setDevicesToShow(selectedDevicesWithUsage)
    }
  }, [displayPagination, currentPage, selectedDevicesWithUsage, updateData])

  useEffect(() => {
    getChartData()
  }, [devicesToShow])

  const isUsageData = (obj: any): obj is WaterUsageStats => {
    return (
      typeof obj === 'object' &&
      obj !== null &&
      'deviceId' in obj &&
      'dailyUsage' in obj &&
      'avgDaily' in obj &&
      'weeklyUsage' in obj &&
      'avgWeekly' in obj &&
      'monthlyUsage' in obj &&
      'avgMonthly' in obj &&
      'yearlyUsage' in obj &&
      'hourlyUsage' in obj
    )
  }

  const getChartData = () => {
    if (!fetchingStats) {
      for (let i = 0; i < devicesToShow.length; i += 1) {
        const waterUsageObj = devicesToShow[i].waterUsageObj

        if (isUsageData(waterUsageObj)) {
          if (tabKey === 'hourly') {
            const hourlyDSBuilt = buildDataset(
              devicesToShow[i].deviceName,
              waterUsageObj.hourlyUsage.map((hu) => hu.value ?? 0),
            )
            hourly_flows.push(hourlyDSBuilt)
          }

          if (tabKey === 'daily') {
            daily_flows[0].data.push(waterUsageObj.dailyUsage)
            daily_flows[1].data.push(waterUsageObj.avgDaily)
          }

          if (tabKey === 'weekly') {
            weekly_flows[0].data.push(waterUsageObj.weeklyUsage)
            weekly_flows[1].data.push(waterUsageObj.avgWeekly)
          }

          if (tabKey === 'monthly') {
            monthly_flows[0].data.push(waterUsageObj.monthlyUsage)
            monthly_flows[1].data.push(waterUsageObj.avgMonthly)
          }

          if (tabKey === 'yearly') {
            annually_flows[0].data.push(waterUsageObj.yearlyUsage)
          }
        }
        setHourlyDatasets(hourly_flows)
        setDailyDatasets(daily_flows)
        setWeeklyDatasets(weekly_flows)
        setMonthlyDatasets(monthly_flows)
        setAnnuallyDatasets(annually_flows)
      }
    }
  }

  useEffect(() => {
    resetOffPeak()
  }, [tabKey])

  const resetOffPeak = () => {
    setSelectedDevicesWithUsage(devicesWithUsage)
    setOffPeakFrom(0)
    setOffPeakTo('')

    getChartData()
  }

  const hourly_flows: Dataset[] = []
  const daily_flows: Dataset[] = [
    {
      label: 'Total Flow',
      data: [],
      backgroundColor: '#1F77B4',
      borderColor: '#4C1D',
    },
    {
      label: 'Average Flow',
      data: [],
      backgroundColor: '#FF7F0E',
      borderColor: '#4C1D',
    },
  ]
  const weekly_flows: Dataset[] = [
    {
      label: 'Total Flow',
      data: [],
      backgroundColor: '#1F77B4',
      borderColor: '#4C1D',
    },
    {
      label: 'Average Flow',
      data: [],
      backgroundColor: '#FF7F0E',
      borderColor: '#4C1D',
    },
  ]
  const monthly_flows: Dataset[] = [
    {
      label: 'Total Flow',
      data: [],
      backgroundColor: '#1F77B4',
      borderColor: '#4C1D',
    },
    {
      label: 'Average Flow',
      data: [],
      backgroundColor: '#FF7F0E',
      borderColor: '#4C1D',
    },
  ]
  const annually_flows: Dataset[] = [
    {
      label: 'Total Flow',
      data: [],
      backgroundColor: '#1F77B4',
      borderColor: '#4C1D',
    },
  ]

  return (
    <div className="current-water-usage">
      <div className="card shadow h-100 py-2 mb-4">
        <div className="card-body">
          <div className="row no-gutters align-items-center">
            <div className="col px-3">
              <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                Water Usage
              </div>
              <div className="row no-gutters align-items-center px-2">
                <div className="col">
                  <div className="multiselect_text">
                    Select device(s) (
                    <button
                      className="btn btn-link te text-decoration-none px-1"
                      onClick={() => {
                        setSelectedDevices(devices)
                      }}
                    >
                      select all
                    </button>
                    |
                    <button
                      className="btn btn-link text-decoration-none px-1"
                      onClick={() => setSelectedDevices([])}
                    >
                      clear all
                    </button>
                    ):
                  </div>
                  <div className="multiselect-container">
                    <Select
                      className="basic-multi-select"
                      value={selectedDevices}
                      onChange={(devices: any) => setSelectedDevices(devices)}
                      options={devices}
                      isMulti
                      name="devices"
                      classNamePrefix="select"
                      getOptionValue={(option: Device) => option.deviceId}
                      getOptionLabel={(option: Device) => `${option.deviceName}`}
                      isClearable={false}
                      isLoading={devices.length === 0}
                      isDisabled={devices.length === 0}
                    />
                  </div>

                  <div className="row w-25">
                    <div className="section-header">
                      <div className="">Unit of measure</div>
                    </div>
                    <Select
                      classNamePrefix="select"
                      value={{
                        value: selectedUom,
                        label: selectedUom,
                      }}
                      onChange={(e: any) => setSelectedUom(e.value)}
                      options={UOM_OPTIONS.map((uom) => {
                        return {
                          value: uom,
                          label:
                            uom === userInfo.preferences?.uom
                              ? `${uom} (your set preference)`
                              : uom,
                        }
                      })}
                      getOptionValue={(option) => option.value}
                      getOptionLabel={(option) => `${option.label}`}
                      isClearable={false}
                      isDisabled={devices.length === 0}
                    />
                  </div>
                  <br />
                  {selectedDevices.length > 1 && (
                    <div>
                      <div>Off peak</div>
                      <div className="mt-2">
                        <div className="d-flex">
                          <div className="d-flex align-items-center">
                            <span className="mr-2">from</span>
                            <input
                              type="number"
                              className="form-control "
                              style={{ width: '10rem' }}
                              value={offPeakFrom}
                              min={0}
                              step={100}
                              onChange={(e) => setOffPeakFrom(parseInt(e.target.value))}
                            />
                          </div>
                          <div className="d-flex align-items-center">
                            <span className="mr-2 ml-3">to</span>
                            <input
                              type="number"
                              className="form-control "
                              style={{ width: '10rem' }}
                              value={offPeakTo}
                              min={0}
                              step={100}
                              onChange={(e) => setOffPeakTo(e.target.value)}
                            />
                          </div>
                        </div>

                        <div className="mt-3 gap-2">
                          <button
                            className="btn btn-sm btn-primary"
                            onClick={() => applyOffpeakFilter()}
                            disabled={
                              !selectedDevices.length || (offPeakFrom === 0 && offPeakTo === '')
                            }
                          >
                            UPDATE
                          </button>

                          <button
                            className="btn btn-link text-decoration-none"
                            onClick={() => resetOffPeak()}
                            disabled={
                              !selectedDevices.length || (offPeakFrom === 0 && offPeakTo === '')
                            }
                          >
                            CLEAR
                          </button>
                        </div>
                      </div>
                    </div>
                  )}

                  <br />

                  {selectedDevices.length > 0 && (
                    <>
                      <Tabs
                        // transition={false}
                        id="controlled-tab-example"
                        activeKey={tabKey}
                        onSelect={(k: any) => setTabKey(k)}
                      >
                        <Tab eventKey="hourly" title="Hourly" disabled={!selectedDevices.length}>
                          <article className="canvas-container col-lg-12">
                            {selectedDevices && selectedDevices.length && fetchingStats ? (
                              'LOADING........'
                            ) : selectedDevices && selectedDevices.length ? (
                              <Line
                                data={{ labels: hourlyLabels, datasets: hourlyDatasets }}
                                ref={chartRef}
                                onClick={onClick}
                                options={getChartOptions('hourly', selectedUom)}
                              />
                            ) : (
                              'Select a device'
                            )}
                          </article>
                        </Tab>
                        {hasPermissions(permissions, ['READ:USAGE:STATS']) ? (
                          <Tab eventKey="daily" title="Daily" disabled={!selectedDevices.length}>
                            <article className="canvas-container col-lg-12">
                              {dailyDatasets ? (
                                <Bar
                                  data={{
                                    labels: devicesToShow.map((device) => device.deviceName),
                                    datasets: dailyDatasets,
                                  }}
                                  options={getChartOptions('daily', selectedUom)}
                                />
                              ) : null}
                            </article>
                          </Tab>
                        ) : null}
                        {hasPermissions(permissions, ['READ:USAGE:STATS']) ? (
                          <Tab eventKey="weekly" title="Weekly" disabled={!selectedDevices.length}>
                            <article className="canvas-container col-lg-12">
                              {weeklyDatasets ? (
                                <Bar
                                  data={{
                                    labels: devicesToShow.map((device) => device.deviceName),
                                    datasets: weeklyDatasets,
                                  }}
                                  options={getChartOptions('weekly', selectedUom)}
                                />
                              ) : null}
                            </article>
                          </Tab>
                        ) : null}
                        {hasPermissions(permissions, ['READ:USAGE:STATS']) ? (
                          <Tab
                            eventKey="monthly"
                            title="Monthly"
                            disabled={!selectedDevices.length}
                          >
                            <article className="canvas-container col-lg-12">
                              {monthlyDatasets ? (
                                <Bar
                                  data={{
                                    labels: devicesToShow.map((device) => device.deviceName),
                                    datasets: monthlyDatasets,
                                  }}
                                  options={getChartOptions('monthly', selectedUom)}
                                />
                              ) : null}
                            </article>
                          </Tab>
                        ) : null}
                        {hasPermissions(permissions, ['READ:USAGE:STATS']) ? (
                          <Tab eventKey="yearly" title="Yearly" disabled={!selectedDevices.length}>
                            <article className="canvas-container col-lg-12">
                              {annuallyDatasets ? (
                                <Bar
                                  data={{
                                    labels: devicesToShow.map((device) => device.deviceName),
                                    datasets: annuallyDatasets,
                                  }}
                                  options={getChartOptions('annually', selectedUom)}
                                />
                              ) : null}
                            </article>
                          </Tab>
                        ) : null}
                      </Tabs>

                      {selectedDevices.length > 5 && (
                        <div>
                          <div className="w-25">
                            <input
                              id="sort-alphabetically"
                              type="radio"
                              onClick={sortSelectedDevicesAlphabetically}
                              checked={updateData === 'alphabetically'}
                            />{' '}
                            <label htmlFor="sort-alphabetically">Sort alphabetically</label>
                          </div>

                          <div className="w-25">
                            <input
                              id="sort-highToLow"
                              type="radio"
                              onClick={sortSelectedDevicesHighToLow}
                              checked={updateData === 'highToLow'}
                            />{' '}
                            <label htmlFor="sort-highToLow">Sort hight to low</label>
                          </div>

                          {selectedDevices.length > DEVICES_PER_PAGE && (
                            <div className="w-25">
                              <input
                                id="sort-showAll"
                                type="radio"
                                onClick={handleDisplayAllDevices}
                                checked={updateData === 'showAll'}
                              />{' '}
                              <label htmlFor="sort-showAll">Show all devices</label>
                            </div>
                          )}
                        </div>
                      )}

                      <div>
                        {displayPagination && (
                          <Pagination
                            pages={Math.ceil(selectedDevicesWithUsage.length / DEVICES_PER_PAGE)}
                            itemsPerPage={DEVICES_PER_PAGE}
                            items={selectedDevicesWithUsage.length}
                            page={currentPage}
                          />
                        )}
                      </div>
                    </>
                  )}

                  <div>
                    {singleSelectedDevice && (
                      <UsageNavigator
                        show={showUsageNavigator}
                        deviceSelected={singleSelectedDevice}
                        onHide={() => setShowUsageNavigator(false)}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
