import backend from '@api/backend'
import { ActionTypes } from '../customer-manager.reducer'
import { UserLocation } from '../model/customer-manager'

export const addUserLocationAction = (id: string, locations: UserLocation[]) => {
  return async (dispatch: any) => {
    dispatch({
      type: ActionTypes.USER_UPDATING,
    })
    try {
      const response = await backend.put(
        `/users/${id}/customer_locations`,
        locations.map((loc) => ({ location_id: loc.locationId, admin: loc.admin })),
      )
      dispatch({
        type: ActionTypes.USER_UPDATED,
        payload: { updatedUser: response.data },
      })
    } catch (error: any) {
      dispatch({
        type: ActionTypes.ERROR,
        payload: { errorMsg: error.response.data },
      })
    }
  }
}

export const deleteUserLocationAction = (id: string, locations: UserLocation[]) => {
  return async (dispatch: any) => {
    dispatch({
      type: ActionTypes.USER_UPDATING,
    })
    try {
      const response = await backend.delete(`/users/${id}/customer_locations`, {
        data: { location_ids: locations.map((loc: UserLocation) => loc.locationId) },
      })
      dispatch({
        type: ActionTypes.USER_UPDATED,
        payload: { updatedUser: response.data },
      })
    } catch (error: any) {
      dispatch({
        type: ActionTypes.ERROR,
        payload: { errorMsg: error.response.data },
      })
    }
  }
}

export const updateUserLocationAction = (id: string, locations: UserLocation[]) => {
  return async (dispatch: any) => {
    dispatch({
      type: ActionTypes.USER_UPDATING,
    })
    try {
      const response = await backend.patch(
        `/users/${id}/customer_locations`,
        locations.map((loc) => ({
          location_id: loc.locationId,
          admin: loc.admin,
          emergency_contact: loc.emergencyContact,
        })),
      )
      dispatch({
        type: ActionTypes.USER_UPDATED,
        payload: { updatedUser: response.data },
      })
    } catch (error: any) {
      dispatch({
        type: ActionTypes.ERROR,
        payload: { errorMsg: error.response.data },
      })
    }
  }
}
