import React, { useEffect } from 'react'
import {
  updateProfileAction,
  confirmEmailAction,
  resetPasswordAction,
  requestConfirmEmailAction,
  updateMyPasswordAction,
  forgotPasswordAction,
  deleteUserAction,
  registerUserAction,
  loadUserAction,
  updateUserPreferences,
} from '../action/user.action'
import { reducer, initialState } from './user.reducer'
import { useAuthState } from '../../auth/context/auth.context'

const StateContext = React.createContext(initialState)
const DispatchContext = React.createContext(undefined as any)

export const UserContextProvider = ({ children }: any) => {
  const [state, dispatch] = React.useReducer(reducer, initialState)
  const { authenticated, loggedIn, updating } = useAuthState()

  useEffect(() => {
    if (authenticated && (loggedIn || updating)) {
      loadUserAction(dispatch)
    }
  }, [authenticated, loggedIn, updating])
  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>{children}</DispatchContext.Provider>
    </StateContext.Provider>
  )
}

export const useUserState = () => {
  return React.useContext(StateContext)
}

export const useUserDispatch = () => {
  const dispatch = React.useContext(DispatchContext)

  if (dispatch === undefined) {
    throw new Error('useUserDispatch must be used within a UserContextProvider')
  }

  const updateProfile = React.useCallback(
    async (firstName: string, lastName: string, mobile: string) =>
      await updateProfileAction({ firstName, lastName, mobile }, dispatch),
    [dispatch],
  )

  const updatePreferences = React.useCallback(
    async (uom: string) => await updateUserPreferences({ uom }, dispatch),
    [dispatch],
  )

  const confirmEmail = React.useCallback(
    async (newPassword: string, newPasswordConfirmation: string, token: string) =>
      await confirmEmailAction({ newPassword, newPasswordConfirmation, token }, dispatch),
    [dispatch],
  )

  const registerUser = React.useCallback(
    async (
      firstname: string,
      lastname: string,
      mobile: string,
      newPassword: string,
      newPasswordConfirmation: string,
      token: string,
      uom: string,
    ) =>
      await registerUserAction(
        { firstname, lastname, mobile, newPassword, newPasswordConfirmation, token, uom },
        dispatch,
      ),
    [dispatch],
  )

  const resetPassword = React.useCallback(
    async (newPassword: string, newPasswordConfirmation: string, token: string) =>
      await resetPasswordAction({ newPassword, newPasswordConfirmation, token }, dispatch),
    [dispatch],
  )

  const updateMyPassword = React.useCallback(
    async (
      email: string,
      currentPassword: string,
      newPassword: string,
      newPasswordConfirmation: string,
    ) =>
      await updateMyPasswordAction(
        { email, currentPassword, newPassword, newPasswordConfirmation },
        dispatch,
      ),
    [dispatch],
  )

  const forgotPassword = React.useCallback(
    async (email: string) => await forgotPasswordAction({ email }, dispatch),
    [dispatch],
  )

  const requestConfirmEmail = React.useCallback(
    async (email: string) => await requestConfirmEmailAction({ email }, dispatch),
    [dispatch],
  )

  const deleteUser = React.useCallback(
    async (userId: number) => await deleteUserAction({ userId }, dispatch),
    [dispatch],
  )

  return React.useMemo(
    () => ({
      updateProfile,
      updatePreferences,
      confirmEmail,
      registerUser,
      resetPassword,
      updateMyPassword,
      forgotPassword,
      requestConfirmEmail,
      deleteUser,
    }),
    [
      updateProfile,
      updatePreferences,
      confirmEmail,
      resetPassword,
      updateMyPassword,
      forgotPassword,
      requestConfirmEmail,
      deleteUser,
    ],
  )
}
