import { toast } from 'react-toastify'
import axios from 'axios';

type ToastTypes = 'default' | 'error' | 'info' | 'success' | 'warning'

type ToastPosition =
  | 'bottom-left'
  | 'bottom-center'
  | 'bottom-right'
  | 'top-left'
  | 'top-center'
  | 'top-right'


interface TProps {
  type: ToastTypes
  position?: ToastPosition
  id?: string
  autoClose?: false | number
}

interface ToastProps extends TProps {
  message: string
}

interface ToastErrorProps extends TProps{
  error: any
}

export const displayToast = ({ type, message, position = 'bottom-left', id, autoClose = 5000 }: ToastProps) => {
  toast(message, {
    position,
    type,
    toastId: id,
    autoClose: autoClose,
  })
}


export const displayToastError = ({ type, error, position = 'bottom-left', id,  autoClose = false }: ToastErrorProps) => {
  if (axios.isAxiosError(error)) {
    console.error(error.response);
    displayToast({
      type: type,
      message: error.response?.data.detail || error.response?.data.message || 'Something went wrong',
      autoClose: autoClose,
      position: position,
      id: id,
    })
  } else {
    console.error(error);
  }
}