import React, { useState } from 'react'
import { Button, Spinner } from 'react-bootstrap'

import ChangePassword from './ChangePassword/ChangePassword'
import UserInfo from './UserInfo/UserInfo'
import Divider from '../../general/Divider/Divider'
import Select from 'react-select'
import { UOM_OPTIONS } from '../DeviceManagement/DeviceInformationManager/constants'
import { useUserDispatch, useUserState } from '@context/user/context/user.context'

import './UserProfile.scss'

const UserProfile: React.FC = () => {
  const { userInfo, updatingPreferences } = useUserState()
  const { updatePreferences } = useUserDispatch()

  const [showChangePasswordModal, setShowChangePasswordModal] = useState<boolean>(false)
  const [showUserInfoModal, setShowUserInfoModal] = useState<boolean>(false)

  const [selectedUom, setSelectedUom] = useState(userInfo.preferences?.uom || 'Liters')

  const handleUpdateUserPreferences = () => {
    updatePreferences(selectedUom)
  }

  return (
    <div className="user-profile">
      <h2>User Info </h2>
      <Button id="user-info-modal-button" onClick={(e) => setShowUserInfoModal(true)}>
        Update Personal Information
      </Button>
      <UserInfo show={showUserInfoModal} onHide={() => setShowUserInfoModal(false)} />
      <Divider />

      <h2>Preferable unit of measure </h2>
      <div className="col-sm-3 p-0">
        <Select
          classNamePrefix="select"
          value={{
            value: selectedUom,
            label: selectedUom,
          }}
          onChange={(e: any) => setSelectedUom(e.value)}
          options={UOM_OPTIONS.map((uom) => {
            return { value: uom, label: uom }
          })}
          getOptionValue={(option) => option.value}
          getOptionLabel={(option) => `${option.label}`}
          isClearable={false}
        />
      </div>
      <br />
      <Button
        id="user-info-modal-button"
        onClick={handleUpdateUserPreferences}
        disabled={selectedUom === userInfo.preferences?.uom || updatingPreferences}
      >
        {updatingPreferences ? (
          <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
        ) : (
          'Save'
        )}
      </Button>

      <Divider />

      <h2>Password Management </h2>
      <Button id="change-password-modal-button" onClick={(e) => setShowChangePasswordModal(true)}>
        Change Password
      </Button>
      <ChangePassword
        show={showChangePasswordModal}
        onHide={() => setShowChangePasswordModal(false)}
      />
      <Divider />
    </div>
  )
}

export default UserProfile
