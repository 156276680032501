import { useState, useEffect } from 'react'
import { Button, Spinner } from 'react-bootstrap'
import DeviceModal from './DeviceModal/DeviceModal'
import CustomSearchTable from '../../../general/CustomSearchTable/CustomSearchTable'
import { COLUMNS, USER_COLUMNS } from './constants'

import { useDeviceDispatch, useDeviceState } from '@context/device/context/device.context'
import { useAuthState } from '@context/auth/context/auth.context'
import Device from '@context/device/model/device'
import { hasPermissions } from '@common/utils/helperFunctions'

import './DeviceInformationManager.scss'


const DeviceInformationManager = () => {
  const { devices, loadingDevices } = useDeviceState()
  const { permissions } = useAuthState()
  const { loadDevices } = useDeviceDispatch()

  const [selectedDevice, setSelectedDevice] = useState<Device>()
  const [modalShow, setModalShow] = useState(false)

  useEffect(() => {
    setSelectedDevice((sd) => devices.find((d) => d.deviceId === sd?.deviceId))
  }, [devices])

  function onRowClick(rowData: Device) {
    if (rowData) {
      setSelectedDevice(rowData)
      setModalShow(true)
    }
  }

  function onHideDeviceModal() {
    setModalShow(false)
    setSelectedDevice(undefined)
  }

  return (
    <div className="device-information-manager">
      {loadingDevices ? (
        <div className="d-flex justify-content-center mt-5">
          <Spinner
            as="span"
            animation="border"
            role="status"
            aria-hidden="true"
            variant="primary"
          />
        </div>
      ) : (
        <div>
          <Button variant="outline-primary" onClick={loadDevices}>
            Refresh Devices
          </Button>
          <CustomSearchTable
            dataArray={devices}
            columns={hasPermissions(permissions, ['UPDATE:DEVICE:PROD:ADMIN']) ? COLUMNS : USER_COLUMNS}
            onRowClickHandler={(rowData) => onRowClick(rowData)}
            filterByDate
            exportToCSV
            allowColumnPicker
            displayPagination
          />
          {modalShow && selectedDevice ?
            <DeviceModal
              show={modalShow}
              onHide={onHideDeviceModal}
              deviceData={selectedDevice}
            /> : null}
        </div>
      )}
    </div>
  )
}

export default DeviceInformationManager
