import { useEffect, useState } from 'react'
import { Card, Row, Col, Container } from 'react-bootstrap'
import { GiCash } from 'react-icons/gi'

import { WaterUsageCosts } from '@data/waterUsage/model/waterUsage.model'
import { formatCurrency } from '@common/utils/helperFunctions'
import { useDeviceState } from '@context/device/context/device.context'
import { useWaterCostFilter, useWaterUsageCost } from '@data/waterUsage/waterUsage'
import { useUserState } from '@context/user/context/user.context'

import 'bootstrap/dist/css/bootstrap.css'
import './layout.scss'

export function CostSummary() {
  const [totalCost, setTotalCost] = useState<{
    [key: string]: { total: number; devices: number }
  }>({})
  const { loadingDevices, devices } = useDeviceState()
  const { userInfo } = useUserState()

  const {
    data: waterUsageCosts,
    isLoading,
    isFetching,
  } = useWaterUsageCost(userInfo.preferences.uom)

  const { data: filteredUsageCosts } = useWaterCostFilter(devices, waterUsageCosts)

  useEffect(() => {
    const totalYearlyCost =
      filteredUsageCosts?.mainMetersWaterUsageCosts &&
      Object.values(filteredUsageCosts.mainMetersWaterUsageCosts).reduce(
        (acc: { [key: string]: { total: number; devices: number } }, stat: WaterUsageCosts) => {
          if (stat.currency && !acc[stat.currency]) {
            acc[stat.currency] = {
              total: 0,
              devices: 0,
            }
          }
          return {
            ...acc,
            [stat.currency!]: {
              total: acc[stat.currency!].total + stat.yearlyUsage,
              devices: acc[stat.currency!].devices + 1,
            },
          }
        },
        {},
      )

    if (totalYearlyCost) {
      setTotalCost(totalYearlyCost)
    }
  }, [filteredUsageCosts?.filteredWaterUsageCosts])

  const childIdsByCurrency: Record<string, number> = {}

  devices.forEach((device) => {
    const { currency } = device.deviceSettings
    const count = device.childDeviceIds.length

    if (!childIdsByCurrency[currency]) {
      childIdsByCurrency[currency] = count
    } else {
      childIdsByCurrency[currency] += count
    }
  })

  return (
    <Card className="summary-card card-bg-colour">
      <Card.Body>
        <div>
          <Row className={'pt-1'}>
            <Col>
              <h4 className={'text-secondary font-weight-bold'}>Total Spend</h4>
            </Col>
            <Col className="p-0 d-flex justify-content-center">
              <GiCash size={50} color={'green'} />
            </Col>
          </Row>
        </div>
        {loadingDevices || isLoading || isFetching ? (
          <Container fluid className={'px-0'}>
            <Row className={'no-gutters align-items-center'}>
              <Col>
                <div className="d-flex justify-content-center m-auto h-100">
                  <div className="spinner-border text-primary m-auto">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        ) : (
          <div className={'border rounded card-div-bg-colour mt-4 py-3 '}>
            {totalCost
              ? Object.entries(totalCost).map((currency, i) => (
                  <div key={i} className={'pl-3'}>
                    <h5 key={i}>
                      <strong>
                        {currency[0]} Cost - {formatCurrency(currency[1].total, currency[0])}
                        <br />
                        <span style={{ fontSize: '0.8rem' }}>
                          ({currency[1].devices}{' '}
                          {currency[1].devices > 1 ? 'main meters' : 'main meter'}
                          {childIdsByCurrency[currency[0]] > 0
                            ? ` & ${childIdsByCurrency[currency[0]]} ` +
                              `${childIdsByCurrency[currency[0]] > 1 ? 'sub meters' : 'sub meter'}`
                            : ''}
                          )
                        </span>
                      </strong>
                    </h5>
                  </div>
                ))
              : null}
          </div>
        )}
      </Card.Body>
    </Card>
  )
}
