import React, { useState, useEffect } from 'react'
import { Modal, Form, Col, Button, Spinner, Row, InputGroup } from 'react-bootstrap'
import { EyeIcon, EyeOffIcon } from 'lucide-react'
import { useUserDispatch, useUserState } from '@context/user/context/user.context'
import { useAuthDispatch } from '@context/auth/context/auth.context'
import { validatePasswordStructure } from '@common/utils/helperFunctions'

import './ChangePassword.scss'

interface IChangePasswordModalProps {
  show: boolean
  onHide: () => void
}

const ChangePassword: React.FC<IChangePasswordModalProps> = ({
  show,
  onHide,
}: IChangePasswordModalProps) => {
  const { userInfo, updatingPassword, error } = useUserState()
  const { updateMyPassword } = useUserDispatch()
  const { logout } = useAuthDispatch()

  const [currentPassword, setCurrentPassword] = useState<string>('')
  const [newPassword, setNewPassword] = useState<string>('')
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState<string>('')
  const [isPasswordShown, setIsPasswordShown] = useState<boolean>(false)

  const [isNewPasswordValid, setIsNewPasswordValid] = useState<boolean>(false)
  const [doNewPasswordsMatch, setDoNewPasswordsMatch] = useState<boolean>(false)

  const [passwordUpdated, setPasswordUpdated] = useState<boolean>(false)
  const [wasUpdateRequested, setWasUpdateRequested] = useState<boolean>(false)

  useEffect(() => {
    if (passwordUpdated && !error) {
      setCurrentPassword('')
      setNewPassword('')
      setNewPasswordConfirmation('')
      startLogout()
    }
  }, [passwordUpdated])

  useEffect(() => {
    if (!show) {
      setPasswordUpdated(false)
    }
  }, [show])

  useEffect(() => {
    if (wasUpdateRequested && !updatingPassword && !error) {
      setPasswordUpdated(true)
    } else {
      setPasswordUpdated(false)
    }
  }, [updatingPassword, error])

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    if (
      userInfo &&
      currentPassword &&
      newPassword &&
      newPasswordConfirmation &&
      isNewPasswordValid &&
      doNewPasswordsMatch
    ) {
      updateMyPassword(userInfo.email, currentPassword, newPassword, newPasswordConfirmation)
      setWasUpdateRequested(true)
    }
  }

  const startLogout = () => {
    setTimeout(() => {
      logout()
    }, 5000)
  }

  const onCurrentPasswordChange = (password: string) => {
    setCurrentPassword(password)
  }

  const onNewPasswordChange = (password: string) => {
    setNewPassword(password)
    const isValid = validatePasswordStructure(password)
    setIsNewPasswordValid(isValid)
  }

  const onNewConfirmationPasswordChange = (password: string) => {
    setNewPasswordConfirmation(password)
    const areMatching = newPassword === password
    setDoNewPasswordsMatch(areMatching)
  }

  return (
    <Modal
      onHide={onHide}
      show={show}
      backdrop="static"
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="" closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Reset Password</Modal.Title>
      </Modal.Header>
      <Modal.Body className="custom-modal-body">
        <Form className="" onSubmit={handleSubmit}>
          <Row>
            <Form.Label>Current Password</Form.Label>
            <InputGroup as={Col} controlId="update-password-1-id" className="form-group">
              <Form.Control
                type={isPasswordShown ? 'text' : 'password'}
                placeholder="Enter your current password"
                onChange={(e) => onCurrentPasswordChange(e.currentTarget.value)}
                isInvalid={!currentPassword && !!newPassword}
              />
              <InputGroup.Text
                role="button"
                className="rounded-right"
                onClick={() => setIsPasswordShown(!isPasswordShown)}
              >
                {isPasswordShown ? <EyeIcon /> : <EyeOffIcon />}
              </InputGroup.Text>
            </InputGroup>
          </Row>
          <Row>
            <Form.Label>New Password</Form.Label>
            <InputGroup as={Col} controlId="update-password-2-id" className="form-group">
              <Form.Control
                type={isPasswordShown ? 'text' : 'password'}
                placeholder="Enter new password"
                onChange={(e) => onNewPasswordChange(e.currentTarget.value)}
                isInvalid={!!newPassword && !isNewPasswordValid}
              />
              <InputGroup.Text
                role="button"
                className="rounded-right"
                onClick={() => setIsPasswordShown(!isPasswordShown)}
              >
                {isPasswordShown ? <EyeIcon /> : <EyeOffIcon />}
              </InputGroup.Text>
              <Form.Control.Feedback type="invalid">
                <div className="input-feedback_error">
                  Password should:
                  <ul>
                    <li>be (at least) 8 characters long </li>
                    <li>have a minimum of 1 letter </li>
                    <li>have a minimum of 1 numeric character </li>
                    <li>have a minimum of 1 special character </li>
                  </ul>
                </div>
              </Form.Control.Feedback>
            </InputGroup>
          </Row>
          <Row>
            <Form.Label>Confirm New Password</Form.Label>
            <InputGroup as={Col} controlId="update-password-3-id" className="form-group">
              <Form.Control
                type={isPasswordShown ? 'text' : 'password'}
                placeholder="Enter new password again"
                onChange={(e) => onNewConfirmationPasswordChange(e.currentTarget.value)}
                isInvalid={!!newPassword && !!newPasswordConfirmation && !doNewPasswordsMatch}
              />
              <InputGroup.Text
                role="button"
                className="rounded-right"
                onClick={() => setIsPasswordShown(!isPasswordShown)}
              >
                {isPasswordShown ? <EyeIcon /> : <EyeOffIcon />}
              </InputGroup.Text>
              <Form.Control.Feedback type="invalid">Passwords don't match!</Form.Control.Feedback>
            </InputGroup>
          </Row>
          <Button
            variant="primary"
            type="submit"
            disabled={
              !currentPassword ||
              !newPassword ||
              !newPasswordConfirmation ||
              !isNewPasswordValid ||
              !doNewPasswordsMatch ||
              passwordUpdated
            }
          >
            {updatingPassword ? (
              <>
                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                <span>Updating password...</span>
              </>
            ) : (
              <>Update Password</>
            )}
          </Button>
        </Form>
      </Modal.Body>
      <Modal.Footer className="custom-modal-footer">
        <div>
          <strong>Note: </strong> You will be logged out after changing your password
        </div>
        <Button
          id="close"
          variant="secondary"
          disabled={updatingPassword || passwordUpdated}
          onClick={onHide}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ChangePassword
