import moment from 'moment-timezone'
import { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { formatPercentage } from '@common/utils/helperFunctions'
import { useDeviceState } from '@context/device/context/device.context'
import { useAuthState } from '@context/auth/context/auth.context'
import { hasPermissions } from '@common/utils/helperFunctions'
import SectorIcon from '../../../general/SectorIcon/SectorIcon'
import DeviceModal from '../../DeviceManagement/DeviceInformationManager/DeviceModal/DeviceModal'
import Device from '@context/device/model/device'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'

import './DeviceStatusComponent.scss'

interface ConnectionState {
  deviceId: string
  deviceName: string
  sectorType: string
  occupants: number
  lastOnline: Date
  deviceIDActivationDate: Date
  power: boolean
}

interface ConnectionStateNoPing extends Omit<ConnectionState, 'lastOnline'> {
  lastOnline: string
}

export function DeviceStatusComponent() {
  const { devices, loadingDevices } = useDeviceState()
  const { permissions } = useAuthState()

  const [selectedDevice, setSelectedDevice] = useState<Device>()
  const [modalShow, setModalShow] = useState(false)

  const isUserSmartFlowAdmin = hasPermissions(permissions, ['ACCOUNT:ADMIN:SMARTFLOW'])

  const offlineDevices = devices
    .filter((d) => d.connectionStatus === 'Disconnected' && d.deviceSettings?.active === true)
    .reduce(
      (acc: { ping: ConnectionState[]; noPing: ConnectionStateNoPing[] }, v) => {
        if (v.lastOnline && v.lastOnline >= new Date(v.deviceSettings.deviceIDActivationDate)) {
          acc.ping.push({
            deviceId: v.deviceId,
            deviceName: v.deviceName,
            sectorType: v.deviceSettings.sectorType,
            occupants: v.deviceSettings.occupants,
            lastOnline: v.lastOnline,
            deviceIDActivationDate: new Date(v.deviceSettings.deviceIDActivationDate),
            power:
              ['BATTERY_OK', 'BATTERY_FULL', 'BATTERY_LOW'].includes(v.batteryStatus) &&
              v.deviceVendor === 'SMARTFLOW',
          })
        } else {
          acc.noPing.push({
            deviceId: v.deviceId,
            deviceName: v.deviceName,
            sectorType: v.deviceSettings.sectorType,
            occupants: v.deviceSettings.occupants,
            lastOnline: 'Never Online',
            deviceIDActivationDate: new Date(v.deviceSettings.deviceIDActivationDate),
            power:
              ['BATTERY_OK', 'BATTERY_FULL', 'BATTERY_LOW'].includes(v.batteryStatus) &&
              v.deviceVendor === 'SMARTFLOW',
          })
        }
        return acc
      },
      { ping: [], noPing: [] },
    )
  const onlinePercentage =
    1 - (offlineDevices.ping.length + offlineDevices.noPing.length) / devices.length

  const onBatteryDevices = devices.filter(
    (d) =>
      ['BATTERY_OK', 'BATTERY_FULL', 'BATTERY_LOW'].includes(d.batteryStatus) &&
      d.deviceSettings?.active === true &&
      d.connectionStatus === 'Connected' &&
      d.deviceVendor === 'SMARTFLOW',
  )
  const onBatteryPercentage = 1 - onBatteryDevices.length / devices.length

  const openDeviceModal = (deviceId: string) => {
    const device = devices.find((d) => d.deviceId === deviceId)

    if (device && isUserSmartFlowAdmin) {
      setSelectedDevice(device)
      setModalShow(true)
    }
  }

  function onHideDeviceModal() {
    setModalShow(false)
    setSelectedDevice(undefined)
  }

  return (
    <div className="row">
      <div className="col-xl-6 mb-3 mb-md-0">
        <div
          className={
            'card border-left-' +
            (onlinePercentage > 0.9 ? 'success' : 'danger') +
            ' shadow h-100 py-2 mb-4'
          }
        >
          <div className="card-body">
            <div className="row no-gutters align-items-center">
              <div className="col">
                <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                  Connection Status
                </div>
              </div>
            </div>
            {loadingDevices ? (
              <div className="row no-gutters align-items-center">
                <div className="d-flex justify-content-center m-auto h-100">
                  <div className="spinner-border text-primary m-auto">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              </div>
            ) : (
              <>
                {!isNaN(onlinePercentage) ? (
                  <div className="row no-gutters align-items-center">
                    <div className="col-auto">
                      <div className="h5 mb-1 mr-3 font-weight-bold text-gray-800">
                        {formatPercentage(onlinePercentage)} online
                      </div>
                    </div>
                    <div className="col">
                      <div className="progress progress-sm mr-2">
                        <div
                          className={
                            'progress-bar bg-' + (onlinePercentage > 0.9 ? 'success' : 'danger')
                          }
                          role="progressbar"
                          style={{ width: formatPercentage(onlinePercentage) }}
                        ></div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>no account selected</div>
                )}

                {offlineDevices.ping.length > 0 || offlineDevices.noPing.length ? (
                  <div>
                    <div>Devices offline:</div>
                    <div className="device-list-container">
                      <ul className="device-status-list list-unstyled">
                        {offlineDevices.ping
                          .sort((a, b) => b.lastOnline.getTime() - a.lastOnline.getTime())
                          .map((d) => (
                            <li
                              key={d.deviceId}
                              onClick={() => openDeviceModal(d.deviceId)}
                              style={{
                                cursor: `${isUserSmartFlowAdmin ? 'pointer' : 'auto'} `,
                              }}
                            >
                              {d.sectorType && isUserSmartFlowAdmin ? (
                                <SectorIcon sector={d.sectorType} occupants={d.occupants} />
                              ) : null}{' '}
                              {d.deviceName} - {d.deviceId} - {moment(d.lastOnline).fromNow()}
                              {d.power ? (
                                <OverlayTrigger
                                  delay={{ hide: 450, show: 300 }}
                                  overlay={(props: any) => <Tooltip {...props}>Unplugged</Tooltip>}
                                  placement="bottom"
                                >
                                  <i className="fas fa-bolt power-ico ml-2" aria-hidden="true"></i>
                                </OverlayTrigger>
                              ) : null}
                            </li>
                          ))}
                        {offlineDevices.noPing
                          .sort(
                            (a, b) =>
                              b.deviceIDActivationDate.getTime() -
                              a.deviceIDActivationDate.getTime(),
                          )
                          .map((d) => (
                            <li
                              key={d.deviceId}
                              onClick={() => openDeviceModal(d.deviceId)}
                              style={{
                                cursor: `${isUserSmartFlowAdmin ? 'pointer' : 'auto'} `,
                              }}
                            >
                              {d.sectorType && isUserSmartFlowAdmin ? (
                                <SectorIcon sector={d.sectorType} occupants={d.occupants} />
                              ) : null}{' '}
                              {d.deviceName} - {d.deviceId} - {d.lastOnline}
                            </li>
                          ))}
                      </ul>
                    </div>
                  </div>
                ) : null}
                <NavLink to="/device-status" className="mt-3 btn btn-secondary btn-sm">
                  Monitor Devices
                </NavLink>
              </>
            )}
          </div>
        </div>
      </div>

      <div className="col-xl-6 mb-3 mb-md-0">
        <div
          className={
            'card border-left-' +
            (onBatteryPercentage > 0.9 ? 'success' : 'danger') +
            ' shadow h-100 py-2 mb-4'
          }
        >
          <div className="card-body">
            <div className="row no-gutters align-items-center">
              <div className="col">
                <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                  Battery Status
                </div>
              </div>
            </div>
            {loadingDevices ? (
              <div className="row no-gutters align-items-center">
                <div className="d-flex justify-content-center m-auto h-100">
                  <div className="spinner-border text-primary m-auto">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              </div>
            ) : (
              <>
                {!isNaN(onBatteryPercentage) ? (
                  <div className="row no-gutters align-items-center">
                    <div className="col-auto">
                      <div className="h5 mb-1 mr-3 font-weight-bold text-gray-800">
                        {formatPercentage(onBatteryPercentage)} On DC Power
                      </div>
                    </div>
                    <div className="col">
                      <div className="progress progress-sm mr-2">
                        <div
                          className={
                            'progress-bar bg-' + (onBatteryPercentage > 0.9 ? 'success' : 'danger')
                          }
                          role="progressbar"
                          style={{ width: formatPercentage(onBatteryPercentage) }}
                        ></div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>no account selected</div>
                )}

                {onBatteryDevices.length > 0 ? (
                  <div>
                    <div>Devices on battery:</div>
                    <div className="device-list-container">
                      <ul className="device-status-list list-unstyled">
                        {onBatteryDevices.map((d) => (
                          <li
                            key={d.deviceId}
                            onClick={() => openDeviceModal(d.deviceId)}
                            style={{
                              cursor: `${isUserSmartFlowAdmin ? 'pointer' : 'auto'} `,
                            }}
                          >
                            {isUserSmartFlowAdmin ? (
                              <SectorIcon
                                sector={d.deviceSettings.sectorType}
                                occupants={d.deviceSettings.occupants}
                              />
                            ) : null}{' '}
                            {d.deviceName} - {d.deviceId} - {moment(d.onBattDate).fromNow()}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                ) : null}
                <NavLink to="/device-status" className="mt-3 btn btn-secondary btn-sm">
                  Monitor Devices
                </NavLink>
              </>
            )}
          </div>
        </div>
      </div>
      {modalShow && selectedDevice?
        <DeviceModal
          show={modalShow}
          onHide={onHideDeviceModal}
          deviceData={selectedDevice}
        /> : null}
    </div>
  )
}
