import React, { useEffect, useState } from 'react'
import { useIdleTimer } from 'react-idle-timer'
import { Button, Modal } from 'react-bootstrap'

import { idleTimeOut } from '@common/utils/constants'
import { useAuthDispatch } from '@context/auth/context/auth.context'

const GlobalDetectIdle = ({ children }: { children: React.JSX.Element }) => {
  const { logout } = useAuthDispatch()

  const [remaining, setRemaining] = useState<number>(10)
  const [disaplyIdleModal, setDisplayIdleModal] = useState<boolean>(false)

  const [minutes, setMinutes] = useState<number>(5)
  const [seconds, setSeconds] = useState<number>(0)

  useEffect(() => {
    if (remaining === 0) setDisplayIdleModal(true)
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000))
    }, 500)

    return () => {
      clearInterval(interval)
    }
  }, [remaining])

  useEffect(() => {
    if (disaplyIdleModal) {
      const myInterval = setInterval(() => {
        if (seconds > 0) {
          setSeconds(seconds - 1)
        }
        if (seconds === 0) {
          if (minutes === 0) {
            clearInterval(myInterval)
            handleLogout()
          } else {
            setMinutes(minutes - 1)
            setSeconds(59)
          }
        }
      }, 1000)
      return () => {
        clearInterval(myInterval)
      }
    }
  }, [disaplyIdleModal, minutes, seconds])


  const { getRemainingTime } = useIdleTimer({
    timeout: import.meta.env.VITE_APP_IDLE_TIME_OUT ? import.meta.env.VITE_APP_IDLE_TIME_OUT : idleTimeOut,
    throttle: 500,
  })

  const handleCancel = async () => {
    setDisplayIdleModal(false)
    setMinutes(2)
    setSeconds(0)
  }

  const handleLogout = () => {
    logout()
  }

  return (
    <div>
      {children}

      {disaplyIdleModal && (
        <Modal show={true} onHide={handleCancel} fullscreeen="true" size="lg" backdrop="static">
          <Modal.Header className="modal-header" closeButton></Modal.Header>
          <Modal.Body>
            <div className="container d-flex flex-column align-items-center">
              <h2>Your session is idle</h2>
              <img
                className="idle-img"
                src="assets/img/idle-session.png"
                alt="idle session"
                height={200}
                width={200}
              />

              <div className="mt-5">
                Automatically logout in{' '}
                <span className="text-danger font-weight-bold">
                  {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
                </span>
              </div>

              <div className="mt-5">
                Session timeouts are required by PCI and GDPR compliance rules to protect your data.
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <div className="d-flex justify-content-sm-between w-100">
              <Button onClick={handleCancel}>Restore Session</Button>
              <Button onClick={handleLogout}>Logout</Button>
            </div>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  )
}

export default GlobalDetectIdle
