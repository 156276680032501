import { decodeJWT } from '@common/utils/helperFunctions'
import { JwtPayloadCustom, UserGroup, UserPermission, UserRole } from '../model/token'

export enum ActionTypes {
  LOGIN,
  LOGGING_IN,
  LOGOUT,
  REFRESH,
  UPDATING,
  ERROR,
  CLEAR,
}

interface State {
  permissions: UserPermission[]
  roles: UserRole[]
  groups: UserGroup[]
  loggingIn: boolean
  loading: boolean
  error?: boolean
  authenticated: boolean
  loggedIn: boolean
  updating: boolean
}

export interface Action {
  type: ActionTypes
  payload?: {
    token: {
      access_token: string
      refresh_token: string
    }
  }
}

export const initialState: State = {
  permissions: [],
  roles: [],
  groups: [],
  loggingIn: false,
  loading: true,
  authenticated: false,
  loggedIn: false,
  updating: false,
}

export const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case ActionTypes.LOGIN:
    case ActionTypes.REFRESH: {
      if (!action.payload) {
        return state
      }
      const { token } = action.payload
      const newState = { ...state }
      if (token) {
        const decodedAccessToken: JwtPayloadCustom = decodeJWT(token.access_token)
        newState.permissions = decodedAccessToken.permissions.map((item: string) => {
          return { name: item }
        })
        newState.roles = decodedAccessToken.roles.map((item: string) => {
          return { name: item }
        })
        newState.groups = decodedAccessToken.groups.map((item: string) => {
          return { name: item }
        })
      }
      return {
        ...newState,
        loggingIn: false,
        loading: false,
        error: false,
        authenticated: true,
        loggedIn: true,
        updating: false,
        sessionExpired: false,
      }
    }
    case ActionTypes.LOGGING_IN: {
      return {
        ...state,
        loggingIn: true,
        error: false,
        loggedIn: false,
        loading: false,
      }
    }
    case ActionTypes.LOGOUT: {
      return {
        ...state,
        loading: false,
        loggingIn: false,
        authenticated: false,
        error: false,
      }
    }
    case ActionTypes.UPDATING:
      return { ...state, updating: true, error: false, loading: false }
    case ActionTypes.CLEAR:
      return { ...state }
    case ActionTypes.ERROR: {
      return {
        ...state,
        error: true,
      }
    }
    default:
      return state
  }
}
