import React, { useState, useEffect, useRef } from 'react'
import { Modal, Form, Button, Col, Row, Tab, Tabs, Table } from 'react-bootstrap'
import { Bar } from 'react-chartjs-2'
import {
  Chart as ChartJS,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  BarElement,
  PointElement,
} from 'chart.js'
import moment, { Moment } from 'moment'
import jsPDF from 'jspdf'
import Device from '@context/device/model/device'
import { hasPermissions, roundToTwo } from '@common/utils/helperFunctions'
import { COLUMNS } from '../../WaterUsage/constants'
import { deviceWaterUsage } from '@data/waterUsage/model/waterUsage.model'
import { useAuthState } from '@context/auth/context/auth.context'
import { useUserState } from '@context/user/context/user.context'
import { BsBarChartLine, BsTable } from 'react-icons/bs'
import { buildPDFFile } from './buildPdfFile'
import { useDevicesWaterUsage } from '@data/waterUsage/waterUsage'

import './UsageNavigator.scss'

ChartJS.register(PointElement, Tooltip, Legend, Title, LinearScale, BarElement)

interface IUsageNavigatorProps {
  show: boolean
  deviceSelected: Device
  onHide: () => void
}

const buildData = (deviceInfo: Device, deviceUsage: deviceWaterUsage[]) => {
  const labels = deviceUsage.map((usage: deviceWaterUsage) => {
    return usage.hour < 10 ? `0${usage.hour}:00` : `${usage.hour}:00`
  })
  const datasets = [
    {
      label: deviceInfo.deviceName,
      data: deviceUsage.map((usage: deviceWaterUsage) => usage.flow),
      fill: false,
      backgroundColor: 'rgb(255, 99, 132)',
      borderColor: 'rgba(255, 99, 132, 0.2)',
    },
  ]
  return {
    labels,
    datasets,
  }
}

const TODAY = moment().startOf('day')
const UsageNavigator: React.FC<IUsageNavigatorProps> = ({
  show,
  deviceSelected,
  onHide,
}: IUsageNavigatorProps) => {
  const [currentDate, setCurrentDate] = useState<Moment | null>(TODAY)
  const [totalUsage, setTotalUsage] = useState<null | number>(null)

  const [enableRefreshAction, setEnableRefreshAction] = useState<boolean>(false)
  const [enableFetchData, setEnableFetchData] = useState<boolean>(false)
  const [lastDateFetched, setLastDateFetched] = useState<Moment | null>(null)

  const { permissions } = useAuthState()
  const { userInfo } = useUserState()

  const chartRef = useRef()
  const tableRef = useRef(null)

  const userUom = userInfo.preferences.uom

  const query = {
    deviceIds: [deviceSelected.deviceId],
    from: currentDate?.format('YYYY-MM-DDTHH:00:00')!,
    to: currentDate?.format('YYYY-MM-DDT23:59:59')!,
    uom: userUom,
  }

  const {
    data: multipleDeviceUsage,
    isLoading,
    refetch,
    isFetching,
  } = useDevicesWaterUsage(query, enableFetchData, enableRefreshAction)

  useEffect(() => {
    if (deviceSelected.deviceId && show) {
      const lastDeviceIdFetched =
        multipleDeviceUsage && Object.keys(multipleDeviceUsage?.totalUsageByDevice)

      setEnableFetchData(true)
      setLastDateFetched(currentDate)

      if (
        currentDate !== TODAY ||
        (lastDeviceIdFetched && lastDeviceIdFetched[0] !== deviceSelected.deviceId) ||
        lastDateFetched !== TODAY
      ) {
        refetch()
      }
    }
  }, [show, currentDate])

  useEffect(() => {
    setCurrentDate(TODAY)

    if (!show) {
      setEnableFetchData(false)
      setEnableRefreshAction(false)
    }
  }, [show])

  useEffect(() => {
    if (multipleDeviceUsage) {
      setTotalUsage(multipleDeviceUsage.totalUsage)
    }
  }, [multipleDeviceUsage])

  useEffect(() => {
    if (enableRefreshAction) {
      setEnableRefreshAction(false)
      refetch()
    }
  }, [enableRefreshAction])

  const onNextButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    if (currentDate) {
      const followingDay = currentDate.clone().add(1, 'days')
      setCurrentDate(followingDay)
    }
  }

  const onRefreshButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    if (TODAY.isSame(currentDate)) {
      setEnableRefreshAction(true)
    }
  }

  const onPreviousButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    if (currentDate) {
      const previousDay = currentDate.clone().add(-1, 'days')
      setCurrentDate(previousDay)
    }
  }

  function downloadWaterGraph() {
    const dailyChart = document.getElementById('daily-usage-chart') as HTMLCanvasElement
    const tableHTML = tableRef.current

    if (dailyChart && currentDate && tableHTML) {
      buildPDFFile(dailyChart, tableHTML)
        .then((pdf: jsPDF) => {
          const fileName = `${deviceSelected.deviceName}-${currentDate.format('DD-MM-YYYY')}`
          pdf.save(fileName)
        })
        .catch((error) => {
          console.log('Something wrong happened while generating PDF: ', error)
        })
    }
  }

  // Save | Download image
  function downloadImage(data: any, filename = 'untitled.jpeg') {
    const a = document.createElement('a')
    a.href = data
    a.download = filename
    document.body.appendChild(a)
    a.click()
    // Q. Probably should removeChild?
  }

  const options: any = {
    plugins: {
      tooltips: {
        mode: 'label',
        titleFontSize: 18,
        bodyFontSize: 18,
      },
    },
    scales: {
      x: {
        offset: true,
        title: {
          display: true,
          text: 'Hours',
          color: 'black',
          padding: 8,
          font: {
            size: 12,
          },
        },
      },
      y: {
        title: {
          display: true,
          text: `${userUom}`,
          color: 'black',
          font: {
            size: 12,
          },
        },
        ticks: {
          beginAtZero: true,
          suggestedMin: 0,
        },
      },
    },
  }

  const getHeaders = () => {
    const periodColumns: Array<any> = [
      {
        name: 'Date',
        key: 'date',
        width: '120px',
      },
      {
        name: `Usage in ${userUom}`,
        key: 'usage',
        width: '220px',
      },
    ]

    const headersObj = [...periodColumns, ...COLUMNS]

    return headersObj.map((header: any) => (
      <th style={{ width: header.width ?? '80px', fontSize: '.75rem' }} key={header.key}>
        {header.name}
      </th>
    ))
  }

  const getRows = (waterUsage: any) => {
    const groupedByDay = waterUsage.reduce((acc: any, dailyUsage: any) => {
      // create a composed key: 'year-week'
      const date = dailyUsage.date.split('T')[0]
      const flow = dailyUsage.flow
      // add this key as a property to the result object
      if (!acc[date]) {
        acc[date] = {
          totalFlow: flow,
          date: date,
          ['totalFlowHour' + dailyUsage.hour]: flow,
        }
      } else {
        // push the current date that belongs to the year-week calculated before
        acc[date].totalFlow += flow
        acc[date]['totalFlowHour' + dailyUsage.hour] = flow
      }
      return acc
    }, {})

    return Object.values(groupedByDay).map((usage: any, idx: number) => {
      const renderHourCell = (hour: number) => {
        const value = usage[`totalFlowHour${hour}`]
        return value !== null ? (value !== 0 ? value : 0) : '-'
      }
      return (
        <tr key={idx} style={{ fontSize: '.8rem' }}>
          <td> {usage.date} </td>
          <td> {roundToTwo(usage.totalFlow)}</td>
          {[...Array(24).keys()].map((hour) => (
            <td key={hour}>{renderHourCell(hour)}</td>
          ))}
        </tr>
      )
    })
  }

  return (
    <Modal
      onHide={onHide}
      show={show}
      backdrop="static"
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="" closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {deviceSelected.deviceName} ({deviceSelected.deviceId})
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="custom-modal-body">
        <Form className="device-information-form">
          <Row>
            <Form.Group as={Col} controlId="formGridName">
              <Form.Label>{currentDate && currentDate.format('dddd, MMMM Do YYYY')}</Form.Label>
              <Form.Group as={Col} controlId="navigation-buttons">
                <div className="navigation-buttons-container">
                  <Button
                    className="btn btn-secondary"
                    onClick={onPreviousButtonClick}
                    disabled={
                      isFetching ||
                      (currentDate !== null &&
                        currentDate.startOf('day') <=
                          moment(deviceSelected.deviceSettings.usageStartDate).startOf('day'))
                    }
                  >
                    Previous Day
                  </Button>
                  {!TODAY.isSame(currentDate) ? (
                    <Button onClick={onNextButtonClick} disabled={isFetching}>
                      Next Day
                    </Button>
                  ) : hasPermissions(permissions, ['READ:DEVICE:DLF']) &&
                    deviceSelected.deviceVendor === 'SMARTFLOW' ? (
                    <Button onClick={onRefreshButtonClick} disabled={isFetching}>
                      Refresh
                    </Button>
                  ) : null}
                </div>
              </Form.Group>
            </Form.Group>
          </Row>
        </Form>

        {isFetching || isLoading ? (
          <div className="d-flex justify-content-center m-auto h-100 mt-5 mb-5">
            <div className="spinner-border m-auto text-primary">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        ) : (
          multipleDeviceUsage && (
            <>
              <Tabs transition={false} id="controlled-tab" className="mt-3">
                <Tab eventKey={'usage'} title={<BsBarChartLine />}>
                  <div className="chart-container">
                    <Bar
                      id="daily-usage-chart"
                      data={buildData(deviceSelected, multipleDeviceUsage.usage[0])}
                      options={options}
                      ref={chartRef}
                    />
                  </div>
                </Tab>
                <Tab eventKey={'table'} title={<BsTable />}>
                  <article id="canvas-container-id-table" className="canvas-container">
                    <div className="table-container">
                      <Table id="usage-table" ref={tableRef} responsive>
                        <thead>
                          <tr>{getHeaders()}</tr>
                        </thead>
                        <tbody>{getRows(multipleDeviceUsage?.usage[0])}</tbody>
                      </Table>
                    </div>
                  </article>
                </Tab>
              </Tabs>
              <div className="total-usage mt-3">
                <b>Total Usage: </b>
                {totalUsage} {userUom}
              </div>
            </>
          )
        )}
      </Modal.Body>
      <Modal.Footer className="custom-modal-footer">
        <Button id="download" onClick={() => downloadWaterGraph()} variant="secondary">
          <span className="fas fa-download fa-lg mr-2"></span>
          Download
        </Button>
        <Button id="close" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default UsageNavigator
