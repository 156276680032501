import { AlertGroup, AlertStatus } from '@context/alert/model/alert.model'

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LineController,
  PointElement,
  Filler,
} from 'chart.js'
import { useAlertWaterUsage } from '@data/waterUsage/waterUsage'
import { useAlertState } from '@context/alert/context/alert.context'
import { useAuthState } from '@context/auth/context/auth.context'
import { useEffect, useRef, useState } from 'react'
import { hasPermissions, roundToTwo } from '@common/utils/helperFunctions'
import { Aggregator, HourlyUsage } from '@data/waterUsage/model/waterUsage.model'
import moment from 'moment-timezone'
import { Line } from 'react-chartjs-2'
import { Tab, Table, Tabs } from 'react-bootstrap'
import { BsBarChartLine, BsTable } from 'react-icons/bs'
import { COLUMNS } from './helper-functions'

import './WaterUseChart.scss'

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LineController,
  PointElement,
  Filler,
)

interface IWaterUseChartProps {
  alertGroup: AlertGroup
  setEstimatedLeakCallback: React.Dispatch<React.SetStateAction<number>>
  setChartRef: React.Dispatch<
    React.SetStateAction<React.RefObject<ChartJS<'line', number[], string>>>
  >
  chartAnimationCallback: React.Dispatch<React.SetStateAction<boolean>>
  selectedUom: string
  chartId: string
}

interface IChartData {
  labels: string[]
  datasets: IChartDataSets[]
}

interface IChartDataSets {
  label: string
  type?: string
  data?: [] | {}
  fill?: boolean | { target: string | number; above: string; below: string }
  radius?: number
  backgroundColor?: string | string[]
  borderColor?: string | string[]
  borderWidth?: number
  lineTension?: number
  order?: number
  maxBarThickness?: number
  borderDash?: number[]
  hidden?: boolean
}

const WaterUseChart = ({
  alertGroup,
  setEstimatedLeakCallback,
  setChartRef,
  chartAnimationCallback,
  selectedUom,
  chartId,
}: IWaterUseChartProps) => {
  const { queue } = useAlertState()
  const { permissions } = useAuthState()
  const [chartData, setChartData] = useState<any>()
  const [selectedAlertDayUsage, setSelectedAlertDayUsage] = useState<HourlyUsage[]>([])
  const viewAlertScorePermission = hasPermissions(permissions, ['READ:ALERTS:SCORE'])
  const chartRef = useRef<ChartJS<'line', number[], string>>(null)
  const deviceId = alertGroup.deviceId

  function alertGroupToQuery(data: AlertGroup) {
    return {
      deviceId,
      from: moment(data.startAlertTimeUTC).add(-5, 'hours').format('YYYY-MM-DDTHH:mm:ss'),
      to:
        data.status === AlertStatus.CLOSED || import.meta.env.VITE_APP_ENV_STATE === 'demo'
          ? moment(data.endAlertTimeUTC).add(+5, 'hours').format('YYYY-MM-DDTHH:mm:ss')
          : moment.utc().startOf('hour').add(+3, 'hours').format('YYYY-MM-DDTHH:mm:ss'),
      dateType: 'utc',
      uom: selectedUom,
    }
  }

  const {
    data: alertWaterUsage,
    isLoading,
    refetch,
    isFetching,
  } = useAlertWaterUsage(alertGroupToQuery(alertGroup))

  useEffect(() => {
    refetch()
  }, [deviceId, alertGroup, selectedUom])

  useEffect(() => {
    if (alertWaterUsage) {
      const waterUsageForDevice = alertWaterUsage[JSON.stringify(alertGroupToQuery(alertGroup))]
      if (!waterUsageForDevice) {
        setChartData(undefined)
        return
      }

      // Find all usage current and baseline between start and end alert times and calculate waste value
      const estimateLeak = waterUsageForDevice.aggregated[Aggregator.CURRENT]
        .map((u: HourlyUsage, i: number) => {
          const expectedBaseline: HourlyUsage =
            waterUsageForDevice.aggregated[Aggregator.EXPECTED_BASELINE][i]
          const usageDiff =
            u.value! > expectedBaseline.value! ? u.value! - expectedBaseline.value! : 0
          return {
            value: usageDiff,
            date: u.date,
          }
        })
        .filter(
          (u) =>
            moment(u.date) >= moment(alertGroup.startAlertTime) &&
            moment(u.date) <= moment(alertGroup.endAlertTime),
        )
        .reduce((acc: number, waste: HourlyUsage) => {
          return acc + waste.value!
        }, 0)

      setEstimatedLeakCallback(roundToTwo(estimateLeak))

      const alertDayUsage = waterUsageForDevice.aggregated[Aggregator.CURRENT]
      // const showBaseline = waterUsageForDevice.aggregated[Aggregator.BASELINE_UPPER_LIMIT].some(v => v.value !== null)

      setSelectedAlertDayUsage(alertDayUsage)

      const localChartData: IChartData = {
        labels: alertDayUsage.map((usage: HourlyUsage) => usage.date.substring(11, 13) + ':00'),
        datasets: [
          {
            label: 'Alerts',
            type: 'bar',
            order: 1,
            data: alertDayUsage.map((usage: HourlyUsage) => {
              const hasAlert = alertGroup.alerts.find((alert) => alert.time === usage.date)
              return {
                x: usage.date.substring(11, 13) + ':00',
                y: hasAlert ? usage.value : null,
                alert_score: hasAlert ? hasAlert.alert_score : null,
              }
            }),
            fill: false,
            backgroundColor: ['rgba(247, 30, 30, 0.55)'],
            borderColor: ['#f71e1e'],
            maxBarThickness: 7,
            // hidden: true,
          },
          {
            type: 'line',
            label: 'Day of the alert',
            order: 2,
            data: alertDayUsage.map((usage: HourlyUsage) => {
              return {
                x: usage.date.substring(11, 13) + ':00',
                y: usage.value,
              }
            }),
            backgroundColor: ['rgba(48, 214, 214, 0.507)'],
            borderColor: ['#163535'],
            borderWidth: 2,
            lineTension: 0.1,
            //fill: true,
            fill: true,
          },
          {
            label: 'Expected Baseline',
            type: 'line',
            order: 3,
            data: waterUsageForDevice.aggregated[Aggregator.EXPECTED_BASELINE].map(
              (usage: HourlyUsage) => usage.value,
            ),
            fill: true,
            borderDash: [5, 5],
            backgroundColor: ['rgba(65, 148, 224, 0.3)'],
            borderColor: ['#0a0a06'],
            lineTension: 0.7,
          },
          {
            label: '1 week earlier',
            type: 'line',
            order: 4,
            data: waterUsageForDevice.aggregated[Aggregator.ONE_WEEK_PRIOR].map(
              (usage: HourlyUsage) => usage.value,
            ),
            fill: true,
            backgroundColor: ['rgba(187, 190, 190, 0.6)'],
            borderColor: ['#7bb1f8'],
            lineTension: 0.1,
            hidden: false,
          },
        ],
      }

      setChartData(localChartData)
      // setIsChartLoading(false)
    }
  }, [alertWaterUsage, deviceId, queue, alertGroup])

  interface chartContext {
    chart: ChartJS
    currentStep: number
    initial: boolean
    numSteps: number
  }

//   const backgroundColorPlugin = {
//     id: 'canvasBgColor',
//     beforeDraw: (chart: ChartJS, args: any, options: {color: string}) => {
//         const {ctx} = chart;
//         ctx.save();
//         ctx.globalCompositeOperation = 'destination-over';
//         ctx.fillStyle = options.color;
//         ctx.fillRect(0, 0, chart.width, chart.height);
//         ctx.restore();
//     },
//     defaults: {
//         color: 'lightGreen'
//     }
// }


  const localChartOptions: any = {
    animation: {
      // duration: 500
      onComplete: function (context: chartContext) {
        setChartRef(chartRef)
        chartAnimationCallback(false)
      },
    },
    maintainAspectRatio: false,
    plugins: {
      // canvasBgColor:  {color: 'white'} ,
      // afterRender: {
      //     filename: 'chart-foo.png'
      // },
      title: {
        display: true,
        text: `${moment(alertGroup.startAlertTime).format('dddd, DD. MMMM YYYY')} - ${moment(
          alertGroup.endAlertTime,
        ).format('dddd, DD. MMMM YYYY')}`,
        position: 'top',
        align: 'center',
        padding: 22,
        font: {
          size: 18,
        },
      },
      legend: {
        display: true,
        position: 'bottom' as const,
        labels: {
          color: '#323130',
          boxWidth: 8,
          padding: 8,
          usePointStyle: true,
          font: {
            size: 10,
          },
        },
      },
      tooltip: {
        mode: 'index',
        callbacks: {
          label: function (context: any) {
            let {
              dataset: { label, data },
              dataIndex,
              parsed: { y },
            } = context
            if (label === 'Alerts') {
              if (viewAlertScorePermission) {
                const alertScore = data[dataIndex].alert_score
                return y && alertScore ? `Alert Score: ${alertScore}` : null
              } else {
                return null
              }
            }
            return `${label}: ${y} ${selectedUom}`
          },
        },
      },
    },
    interaction: {
      mode: 'nearest',
    },
    scales: {
      y: {
        position: 'right',
        title: {
          display: true,
          text: `${selectedUom}`,
        },
        grid: {
          display: false,
        },
        ticks: {
          suggestedMin: 0,
        },
      },
      x: {
        title: {
          display: true,
          text: 'Time',
        },
        grid: {
          display: false,
        },
        ticks: {
          color: 'black',
          display: true,
        },
      },
    },
  }

  const getHeaders = () => {
    const headersObj = [...COLUMNS]

    return headersObj.map((header: any) => (
      <th style={{ width: header.width ?? '80px', fontSize: '0.9rem' }} key={header.key}>
        {header.name}
      </th>
    ))
  }

  const getRows = () => {
    if (alertWaterUsage) {
      return selectedAlertDayUsage.map((usage: HourlyUsage, idx: number) => {
        const hasAlert = alertGroup.alerts.find((alert) => alert.time === usage.date)

        const rowColour = hasAlert
          ? hasAlert.hourlyAlertsSent
            ? hasAlert.hourlyAlertsSent.action_type === 1
              ? 'table-warning'
              : 'table-success'
            : 'table-danger'
          : ''

        return (
          <tr key={idx} className={rowColour} style={{ fontSize: '0.75rem' }}>
            <td>{moment(usage.date).format('DD/MM/YYYY HH:mm')}</td>
            <td>{usage.value}</td>
            <td>{hasAlert ? hasAlert.alert_score : null}</td>
            <td>
              {hasAlert
                ? moment.utc(hasAlert.createdAt).tz(moment.tz.guess()).format('DD/MM/YYYY LT')
                : null}
            </td>
            <td>
              {hasAlert
                ? hasAlert.hourlyAlertsSent
                  ? hasAlert.hourlyAlertsSent.action_type === 1
                    ? 'Raised'
                    : 'Resolved'
                  : 'Open'
                : null}
            </td>
            <td>
              {hasAlert && hasAlert.hourlyAlertsSent
                ? moment
                    .utc(hasAlert.hourlyAlertsSent.sent_at)
                    .tz(moment.tz.guess())
                    .format('DD/MM/YYYY LT')
                : null}
            </td>
            <td>
              {hasAlert && hasAlert.hourlyAlertsSent ? hasAlert.hourlyAlertsSent.user_id_ref : null}
            </td>
          </tr>
        )
      })
    }
  }

  return isFetching || isLoading ? (
    <div className="spinner-border m-auto mb-5 mt-5 d-flex text-primary"></div>
  ) : (
    <Tabs transition={false} id="controlled-tab">
      <Tab eventKey={'alert-chart'} title={<BsBarChartLine />}>
        <article className="canvas-container col" id={chartId}>
          {chartData && !isFetching && !isLoading ? (
            <>
              <Line
                data={chartData}
                options={localChartOptions}
                // plugins={[backgroundColorPlugin]}
                id="water-usage-chart"
                className="water-usage-chart"
              />
              <div
                id="chart-renderer"
                style={{
                  position: 'fixed',
                  width: '1524px',
                  height: '512px',
                  left: '0',
                  top: '-512px',
                }}
              >
                {/*<Line data={chartData} plugins={[backgroundColorPlugin]} options={localChartOptions} ref={chartRef} />*/}
                <Line data={chartData} options={localChartOptions} ref={chartRef} />
              </div>
            </>
          ) : null}
        </article>
      </Tab>

      {hasPermissions(permissions, ['ACCOUNT:ADMIN:SMARTFLOW']) && (
        <Tab eventKey={'table'} title={<BsTable />}>
          <div className="water-use-table-container">
            <Table id="usage-table" responsive>
              <thead>
                <tr>{getHeaders()}</tr>
              </thead>
              <tbody>{getRows()}</tbody>
            </Table>
          </div>
        </Tab>
      )}
    </Tabs>
  )
}

export default WaterUseChart
