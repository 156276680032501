import React, { useEffect, useRef, useState } from 'react'
import DatePicker from 'react-datepicker'
import Select from 'react-select'
import { Button, Spinner, Tab, Table, Tabs, Form, Row, Col } from 'react-bootstrap'

import { Bar } from 'react-chartjs-2'
import { Chart as ChartJS, CategoryScale, BarElement, Title, Tooltip, Legend } from 'chart.js'

import jsPDF from 'jspdf'
import moment, { Moment } from 'moment'
import { CSVLink } from 'react-csv'

import Device, { TestDevice } from '@context/device/model/device'
import { PeriodType } from '@common/types/chart-types'
import { useDevicesWaterUsage } from '@data/waterUsage/waterUsage'
import {
  buildPDFReport,
  getAnnuallyDatasets,
  getChartOptions,
  getDailyDatasets,
  getMonthlyDatasets,
  getWeeklyDatasets,
} from '../../WaterUsage/helper-functions'
import { convertToUnitOfVolume, roundToTwo } from '@common/utils/helperFunctions'
import { COLUMNS } from '../../WaterUsage/constants'
import { UOM_OPTIONS } from '../../DeviceManagement/DeviceInformationManager/constants'
import { useUserState } from '@context/user/context/user.context'

import 'react-datepicker/dist/react-datepicker.css'
import 'react-datepicker/dist/react-datepicker-cssmodules.css'
import './WaterUsage.scss'

ChartJS.register(CategoryScale, BarElement, Title, Tooltip, Legend)

interface IWaterUsageProps {
  devices: Device[] | TestDevice[]
}

const TODAY: Moment = moment()

/*
  0: Not asked
  1: Asked, not ready
  2: Ready
*/
type GenerateReportState = 0 | 1 | 2

const WaterUsageComponent: React.FC<IWaterUsageProps> = ({ devices }: IWaterUsageProps) => {
  const { userInfo } = useUserState()

  const [deviceSelected, setDeviceSelected] = useState<Device | TestDevice | null>(null)
  const [selectedPeriod, setSelectedPeriod] = useState<PeriodType>('daily')
  const [fromDate, setFromDate] = useState<Date>(
    TODAY.clone().subtract(30, 'days').startOf('day').toDate(),
  )
  const [toDate, setToDate] = useState<Date>(TODAY.clone().endOf('day').toDate())

  const [dailyDatasets, setDailyDatasets] = useState<any>()
  const [weeklyDatasets, setWeeklyDatasets] = useState<any>()
  const [monthlyDatasets, setMonthlyDatasets] = useState<any>()
  const [annuallyDatasets, setAnnuallyDatasets] = useState<any>()

  const [totalUsage, setTotalUsage] = useState<null | number>(null)
  const [didSomethingChange, setDidSomethingChange] = useState(true)
  const [generateReportState, setGenerateReportState] = useState<GenerateReportState>(0)
  const [occupants, setOccupants] = useState<number>(0)
  const [csvData, setCsvData] = useState<any>([])
  const tableRef = useRef(null)
  const [fileName, setFileName] = useState('')
  const [selectedUom, setSelectedUom] = useState(userInfo.preferences?.uom || 'Liters')

  const startDate = moment(fromDate).format('YYYY-MM-DDTHH:00:00')
  let endDate = moment(toDate).format('YYYY-MM-DDT23:59:59')

  if (String(toDate) !== String(TODAY.clone().endOf('day').toDate())) {
    const endMinutes = toDate.getMinutes()
    const endHour = toDate.getHours()

    if (endMinutes === 0 && endHour === 0) {
      const newEndDay = moment(toDate).subtract(1, 'days').endOf('day').toDate()

      endDate = moment(newEndDay).format(`YYYY-MM-DDTHH:mm:ss`)
    } else {
      endDate = moment(toDate).format(`YYYY-MM-DDTHH:mm:ss`)
    }
  }

  const query = {
    deviceIds: [deviceSelected?.deviceId!],
    from: startDate,
    to: endDate,
    uom: selectedUom,
  }

  const { data: deviceWaterUsage, isLoading, refetch, isFetching } = useDevicesWaterUsage(query)

  useEffect(() => {
    if (deviceWaterUsage && !isLoading && !isFetching) {
      const deviceUsage = deviceWaterUsage.usage[0]
      const dailyDatasets_local = getDailyDatasets(deviceUsage)
      setDailyDatasets(dailyDatasets_local)

      const weeklyDatasets_local = getWeeklyDatasets(deviceUsage)
      setWeeklyDatasets(weeklyDatasets_local)

      const monthlyDatasets_local = getMonthlyDatasets(deviceUsage)
      setMonthlyDatasets(monthlyDatasets_local)

      const annuallyDatasets_local = getAnnuallyDatasets(deviceUsage)
      setAnnuallyDatasets(annuallyDatasets_local)

      setTotalUsage(deviceWaterUsage.totalUsage)
    }
  }, [
    deviceWaterUsage,
    isLoading,
    isFetching,
    setDailyDatasets,
    setWeeklyDatasets,
    setMonthlyDatasets,
    setAnnuallyDatasets,
  ])

  useEffect(() => {
    const from = moment(fromDate)
    const to = moment(toDate)
    if (from.isSameOrAfter(to)) {
      setFromDate(to.subtract(1, 'days').toDate())
    }
  }, [toDate])

  useEffect(() => {
    if (didSomethingChange) {
      setGenerateReportState(0)
    }
    const from = moment(fromDate).format('DD-MM-YYYY')
    const to = moment(toDate).format('DD-MM-YYYY')
    if (deviceSelected) {
      setFileName(`${deviceSelected.deviceName}-${from}--${to}_${selectedPeriod}Flow`)
    }
  }, [didSomethingChange])

  const handleDeviceSelection = (selectedOption: Device) => {
    if (selectedOption && Object.keys(selectedOption).length > 0) {
      const currentSelectedDevice = deviceSelected
      setDeviceSelected(selectedOption)
      setOccupants(selectedOption.deviceSettings.occupants)
      if (!currentSelectedDevice || selectedOption.deviceId !== currentSelectedDevice.deviceId) {
        setDidSomethingChange(true)
      }
    }
  }

  const handleFromRangeChange = (date: Date | null) => {
    if (date && date.getTime() !== fromDate.getTime()) {
      setFromDate(date)
      setDidSomethingChange(true)
    }
  }

  const handleToRangeChange = (date: Date | null) => {
    if (date && date.getTime() !== toDate.getTime()) {
      setToDate(date)
      setDidSomethingChange(true)
    }
  }

  const handleOccupantsChange = (occupantsNumber: number) => {
    setOccupants(occupantsNumber)
    setDidSomethingChange(true)
  }

  const handleUomChange = (uom: string) => {
    setSelectedUom(uom)
    setDidSomethingChange(true)
  }

  const askForData = () => {
    refetch()
    setDidSomethingChange(false)
  }

  const downloadPdfDocument = () => {
    if (deviceSelected) {
      const rootElementId: string = 'canvas-container-id-' + selectedPeriod
      const chartElement: HTMLElement | null = document.getElementById(rootElementId)
      const tableHTML = tableRef.current
      if (chartElement && tableHTML) {
        setGenerateReportState(1)
        buildPDFReport(
          deviceSelected as Device,
          fromDate,
          toDate,
          chartElement,
          tableHTML,
          totalUsage,
          selectedUom,
        )
          .then((pdf: jsPDF) => {
            const from = moment(fromDate).format('DD-MM-YYYY')
            const to = moment(toDate).format('DD-MM-YYYY')
            const fileName = `${deviceSelected.deviceName}-${from}--${to}_${selectedPeriod}Flow`
            setGenerateReportState(2)
            pdf.save(fileName)
          })
          .catch((error) => {
            console.log('Something wrong happened while generating PDF: ', error)
            setGenerateReportState(0)
          })
      }
    }
  }

  const downloadCsvDocument = () => {
    const groupedByDay = deviceWaterUsage?.usage[0].reduce((acc: any, dailyUsage: any) => {
      // create a composed key: 'year-week'
      const d: moment.Moment = moment(dailyUsage.date)
      const date: string = d.format('YYYY-MM-DD')
      const hour: string = d.format('HH:mm')
      const flow = dailyUsage.flow
      // add this key as a property to the result object
      if (!acc[date]) {
        acc[date] = {
          usage: flow,
          date: date,
          [hour]: flow,
        }
      } else {
        // push the current date that belongs to the year-week calculated before
        acc[date].usage += flow
        acc[date][hour] = flow
      }
      return acc
    }, {})
    const data = Object.values(groupedByDay).map((d: any) => {
      if (occupants > 0) {
        return {
          per_occupant: occupants > 0 && roundToTwo(d.usage / occupants),
          ...d,
        }
      } else {
        return {
          ...d,
        }
      }
    })

    setCsvData(data)
  }

  const getHeaders = (period: string) => {
    let periodColumns: Array<any> = [
      {
        name: 'Date',
        key: 'date',
        width: '120px',
      },
      {
        name: `Usage in ${selectedUom}`,
        key: 'usage',
        width: '120px',
      },
    ]

    if (occupants > 0) {
      periodColumns = [
        ...periodColumns,
        {
          name: `${selectedUom || 'Litres'} per occupant`,
          key: 'per_occupant',
          width: '120px',
        },
      ]
    }

    const headersObj = [...periodColumns, ...COLUMNS]

    return headersObj.map((header: any) => (
      <th style={{ width: header.width ?? '80px' }} key={header.key}>
        {header.name}
      </th>
    ))
  }

  const getRows = (waterUsage: any) => {
    const groupedByDay = waterUsage.reduce((acc: any, dailyUsage: any) => {
      // create a composed key: 'year-week'
      const date = dailyUsage.date.split('T')[0]
      const flow = dailyUsage.flow
      // add this key as a property to the result object
      if (!acc[date]) {
        acc[date] = {
          totalFlow: flow,
          date: date,
          ['totalFlowHour' + dailyUsage.hour]: flow,
        }
      } else {
        // push the current date that belongs to the year-week calculated before
        acc[date].totalFlow += flow
        acc[date]['totalFlowHour' + dailyUsage.hour] = flow
      }
      return acc
    }, {})

    return Object.values(groupedByDay).map((usage: any, idx: number) => {
      const renderHourCell = (hour: number) => {
        const value = usage[`totalFlowHour${hour}`]
        return value !== null ? (value !== 0 ? value : 0) : '-'
      }
      return (
        <tr key={idx}>
          <td> {usage.date} </td>
          <td> {roundToTwo(usage.totalFlow)}</td>
          {occupants > 0 && <td>{roundToTwo(usage.totalFlow / occupants)}</td>}
          {[...Array(24).keys()].map((hour) => (
            <td key={hour}>{renderHourCell(hour)}</td>
          ))}
        </tr>
      )
    })
  }

  return (
    <div className="water-usage">
      <h1>Water Usage</h1>
      <div className="main-content">
        <div className="row">
          <div className="section-header">
            <div className="">Device:</div>
          </div>
          <div className="col-md-6">
            <div className="multiselect-container">
              <Select
                classNamePrefix="select"
                className="basic-multi-select"
                value={deviceSelected}
                onChange={(device: any) => handleDeviceSelection(device)}
                options={devices}
                name="device"
                getOptionValue={(option) => option.deviceId}
                getOptionLabel={(option) => `${option.deviceName}`}
                isClearable={false}
                isLoading={isFetching || devices.length === 0}
                isDisabled={isFetching || devices.length === 0}
              />
            </div>
          </div>
        </div>

        <Form className="device-information-form w-50">
          <Row className="mb-3 mt-3">
            <div className="section-header">
              <div className="">Range:</div>
            </div>
            <Form.Group as={Col} controlId="formGridFrom" className="d-flex flex-column">
              <Form.Label>From: </Form.Label>
              <DatePicker
                selected={fromDate}
                onChange={handleFromRangeChange}
                maxDate={moment(toDate).clone().subtract(1, 'days').toDate()}
                placeholderText="Select a date after 5 days ago"
                dateFormat="dd/MM/yyyy HH:mm"
                showTimeInput
                timeInputLabel="Time:"
              />
            </Form.Group>
            <Form.Group as={Col} controlId="formGridTo" className="d-flex flex-column">
              <Form.Label>To: </Form.Label>
              <DatePicker
                selected={toDate}
                onChange={handleToRangeChange}
                maxDate={TODAY.toDate()}
                placeholderText="Select a date after 5 days ago"
                dateFormat="dd/MM/yyyy HH:mm"
                showTimeInput
                timeInputLabel="Time:"
              />
            </Form.Group>
          </Row>

          <Row className="w-25">
            <Form.Group as={Col} controlId="formGridTown">
              <Form.Label>Occupants</Form.Label>
              <Form.Control
                type="number"
                min={0}
                value={occupants}
                onChange={(e) => handleOccupantsChange(parseInt(e.target.value))}
              />
            </Form.Group>

            <Form.Group />
          </Row>
          <Row className="mb-3 w-50">
            <Form.Group as={Col} controlId="expected-percentage-savings">
              <Form.Label>Unit of measure</Form.Label>
              <Select
                classNamePrefix="select"
                value={{
                  value: selectedUom,
                  label: selectedUom,
                }}
                onChange={(e: any) => handleUomChange(e.value)}
                options={UOM_OPTIONS.map((uom) => {
                  return {
                    value: uom,
                    label:
                      uom === deviceSelected?.deviceSettings?.uom
                        ? `${uom} (device default)`
                        : uom === userInfo.preferences?.uom
                          ? `${uom} (your set preference)`
                          : uom,
                  }
                })}
                getOptionValue={(option) => option.value}
                getOptionLabel={(option) => `${option.label}`}
                isClearable={false}
              />
            </Form.Group>
          </Row>
        </Form>
        <div className="get-data-button mt-5">
          <Button
            variant="primary"
            onClick={askForData}
            disabled={!deviceSelected || !didSomethingChange}
          >
            {isFetching ? (
              <>
                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                <span>Getting Data...</span>
              </>
            ) : (
              <>Get Data</>
            )}
          </Button>
        </div>
        <div className="data-container">
          {isFetching ? (
            <div>Loading...</div>
          ) : (
            dailyDatasets &&
            weeklyDatasets &&
            monthlyDatasets &&
            annuallyDatasets &&
            !didSomethingChange && (
              <div className="data-visualizations">
                <Tabs
                  transition={false}
                  id="controlled-tab-example"
                  activeKey={selectedPeriod}
                  onSelect={(k: any) => setSelectedPeriod(k)}
                >
                  <Tab eventKey={'daily'} title="Daily">
                    <article id="canvas-container-id-daily" className="canvas-container">
                      <Bar
                        width={2500}
                        height={600}
                        data={dailyDatasets}
                        options={getChartOptions('daily', selectedUom)}
                      />
                    </article>
                  </Tab>
                  <Tab eventKey={'weekly'} title="Weekly">
                    <article id="canvas-container-id-weekly" className="canvas-container">
                      <Bar
                        width={2500}
                        height={600}
                        data={weeklyDatasets}
                        options={getChartOptions('weekly', selectedUom)}
                      />
                    </article>
                  </Tab>
                  <Tab eventKey={'monthly'} title="Monthly">
                    <article id="canvas-container-id-monthly" className="canvas-container">
                      <Bar
                        width={2500}
                        height={600}
                        data={monthlyDatasets}
                        options={getChartOptions('monthly', selectedUom)}
                      />
                    </article>
                  </Tab>
                  <Tab eventKey={'annually'} title="Annually">
                    <article id="canvas-container-id-annually" className="canvas-container">
                      <Bar
                        width={2500}
                        height={600}
                        data={annuallyDatasets}
                        options={getChartOptions('annually', selectedUom)}
                      />
                    </article>
                  </Tab>
                </Tabs>
                <div className="total-usage">
                  <b>Total Usage: </b>
                  <br />
                  {totalUsage} {selectedUom || 'Liters'}
                  <br />
                  {totalUsage ? convertToUnitOfVolume(selectedUom || 'Liters', totalUsage) : null}
                </div>
                <div className="download-pdf-button mb-2 mt-2">
                  <Button variant="primary" onClick={downloadPdfDocument}>
                    <div>
                      {generateReportState === 1 ? (
                        <>
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                          <span>Generating...</span>
                        </>
                      ) : (
                        <>Generate PDF Report</>
                      )}
                    </div>
                  </Button>
                </div>
                <div className="download-csv-button">
                  <CSVLink
                    data={csvData}
                    headers={
                      csvData.length
                        ? Object.keys(csvData[0]).map((key: any) => {
                            return {
                              label:
                                key === 'per_occupant'
                                  ? `${selectedUom} per occupant`
                                  : key === 'usage'
                                    ? `usage in ${selectedUom}`
                                    : key,
                              key: key,
                            }
                          })
                        : []
                    }
                    onClick={downloadCsvDocument}
                    className="btn btn-primary"
                    filename={`${fileName}.csv`}
                  >
                    Generate CSV Report
                  </CSVLink>
                </div>
                <article id="canvas-container-id-table" className="canvas-container">
                  <div className="table-container">
                    <Table id="usage-table" ref={tableRef} responsive>
                      <thead>
                        <tr>{getHeaders(selectedPeriod)}</tr>
                      </thead>
                      <tbody>{getRows(deviceWaterUsage?.usage[0])}</tbody>
                    </Table>
                  </div>
                </article>
              </div>
            )
          )}
        </div>
      </div>
    </div>
  )
}

export default WaterUsageComponent
