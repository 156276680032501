import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'

import { useDeviceState } from '@context/device/context/device.context'
import { useAlertState } from '@context/alert/context/alert.context'
import { AlertStatus } from '@context/alert/model/alert.model'
import { useAuthState } from '@context/auth/context/auth.context'
import { hasPermissions } from '@common/utils/helperFunctions'
import SectorIcon from '../../../general/SectorIcon/SectorIcon'

import 'bootstrap/dist/css/bootstrap.css'
import './DeviceStatusComponent.scss'

export function UserDeviceListComponent() {
  const { devices, loadingDevices, allDevices } = useDeviceState()
  const { queue } = useAlertState()
  const { permissions } = useAuthState()
  const inProgressAlerts = queue.filter((a) => a.status === AlertStatus.IN_PROGRESS)
  const userDevices = devices
    .map((d, i) => ({
      ...d,
      activeIssue:
        !!inProgressAlerts.find((alert) => alert.deviceId === d.deviceId) ||
        ['BATTERY_OK', 'BATTERY_FULL', 'BATTERY_LOW'].includes(d.batteryStatus),
      alertInProgress: !!inProgressAlerts.find((alert) => alert.deviceId === d.deviceId),
    }))
    .sort((a: any, b: any) => b.activeIssue - a.activeIssue)
  return (
    <div className="row">
      <div className="col-xl-12">
        <div className="card border-left-success">
          <div className="card-body">
            <div className="row no-gutters align-items-center">
              <div className="col">
                <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                  Devices{allDevices.length > 0 ? ` (${devices.length})` : null}
                </div>
              </div>
            </div>
            {loadingDevices ? (
              <div className="row no-gutters align-items-center">
                <div className="d-flex justify-content-center m-auto h-100">
                  <div className="spinner-border text-primary m-auto">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              </div>
            ) : (
              <>
                {devices.length > 0 ? (
                  <div>
                    <div className="device-list-container">
                      <ul className="device-status-list p-1">
                        {userDevices.map((d) => (
                          <li key={d.deviceId} className="list-unstyled">
                            <span className="device-span">
                              {hasPermissions(permissions, ['ACCOUNT:ADMIN:SMARTFLOW']) ? (
                                <SectorIcon
                                  sector={d.deviceSettings.sectorType}
                                  occupants={d.deviceSettings.occupants}
                                />
                              ) : null}
                              {d.deviceName}
                            </span>
                            {d.alertInProgress ? (
                              <OverlayTrigger
                                delay={{ hide: 450, show: 300 }}
                                overlay={(props: any) => (
                                  <Tooltip {...props}>Active Alert(s)</Tooltip>
                                )}
                                placement="bottom"
                              >
                                <i className="fa fa-tint leak-ico ml-2" aria-hidden="true"></i>
                              </OverlayTrigger>
                            ) : null}
                            {['BATTERY_OK', 'BATTERY_FULL', 'BATTERY_LOW'].includes(
                              d.batteryStatus,
                            ) ? (
                              <OverlayTrigger
                                delay={{ hide: 450, show: 300 }}
                                overlay={(props: any) => <Tooltip {...props}>Unplugged</Tooltip>}
                                placement="bottom"
                              >
                                <i className="fas fa-bolt power-ico ml-2" aria-hidden="true"></i>
                              </OverlayTrigger>
                            ) : null}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                ) : null}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
