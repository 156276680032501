import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import backend from '@api/backend'
import { IComments, ICommentsRaw, NewCommentData, UpdateCommentData } from '../model/comments.model'
import { displayToast } from '@common/utils/appToast'

export const useDeviceComments = (deviceId: string | undefined) => {
  return useQuery({
    queryKey: ['device-comments', deviceId],
    queryFn: () => getDeviceComments(deviceId),
    enabled: true,
    staleTime: 1000 * 60 * 15, // 15 minutes,
  })
}

export const useDeviceCommentsCreation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: postDeviceComment,
    onSuccess: (data: ICommentsRaw, { device_id }) => {
      const newComment = {
        id: data.id,
        comment: data.comment,
        createdAt: data.date,
        updatedAt: data.valid_from,
        author: data.user_name,
        department: data.department,
        category: data.category,
        history: [],
      }

      const updatedComments = queryClient.setQueryData(
        ['device-comments', device_id],
        (old: IComments[]) => {
          return [newComment, ...old]
        },
      )

      displayToast({
        type: 'success',
        message: 'Comment created successfully',
      })

      return updatedComments
    },
    onError: () => {
      displayToast({
        type: 'error',
        message: 'Something went wrong',
      })
    },
  })
}

export async function getDeviceComments(deviceId: string | undefined) {
  const response = await backend.get(`/devices/${deviceId}/comments`, { params: { history: true } })

  response.data.sort((a: ICommentsRaw, b: ICommentsRaw) => b.id - a.id)

  const deviceComments: IComments[] = response.data.map((comment: ICommentsRaw) => {
    return {
      id: comment.id,
      comment: comment.comment,
      createdAt: comment.date,
      updatedAt: comment.valid_from,
      author: comment.user_name,
      department: comment.department,
      category: comment.category,
      history: comment.history?.map((history: any) => {
        return {
          id: history.id,
          comment: history.comment,
          author: history.user_name,
          createdAt: history.valid_from,
        }
      }),
    }
  })

  return deviceComments
}

export const useDeviceCommentsUpdate = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: updateDeviceComment,
    onSuccess: (data: ICommentsRaw, { device_id }) => {
      const updatedComment = {
        id: data.id,
        comment: data.comment,
        createdAt: data.date,
        updatedAt: data.valid_from,
        author: data.user_name,
        department: data.department,
        category: data.category,
        history: data.history?.map((h) => ({
          comment: h.comment,
          author: h.user_name,
          createdAt: h.valid_from,
          updatedAt: data.valid_to,
        })),
      }

      const updatedComments = queryClient.setQueryData(
        ['device-comments', device_id],
        (oldComments: IComments[]) => {
          return oldComments.map((comment: IComments) =>
            comment.id === updatedComment.id ? updatedComment : comment,
          )
        },
      )

      displayToast({
        type: 'success',
        message: 'Comment updated successfully',
      })

      return updatedComments
    },
    onError: () => {
      displayToast({
        type: 'error',
        message: 'Something went wrong',
      })
    },
  })
}

async function postDeviceComment(data: NewCommentData) {
  const response = await backend.post(`devices/${data.device_id}/comments`, data)

  return response.data
}

async function updateDeviceComment(data: UpdateCommentData) {
  const response = await backend.patch(`devices/${data.device_id}/comments`, data)

  return response.data
}
