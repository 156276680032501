import { Button, Col, Form, Modal, Row, Spinner, Tab, Tabs } from 'react-bootstrap'
import { useDeviceState } from '@context/device/context/device.context'
import { useEffect, useState } from 'react'
import CommentComponent from '../Components/Comment/CommentComponent'
import { IComments } from '@data/comments/model/comments.model'
import { ReportsTracking } from '@data/reportsTracking/model/reportsTracking.model'
import ExSummaryReport from './ExSummaryReport'
import { handleReportUpdateReactQuery } from '@data/reportsTracking/reportsTracking'
import {
  useAccountComments,
  useAccountCommentsCreation,
  useAccountCommentsUpdate,
} from '@data/comments/accountComments/accountComments'
import { useCustomerManagerDispatch } from '@context/customerManager/customer-manager.context'

interface IModalProps {
  show: boolean
  accountData: any
  onHide: () => void
}

const CustomerJourneyAccountModal: React.FC<IModalProps> = ({
  show,
  accountData,
  onHide,
}: IModalProps) => {
  const { deviceUpdating } = useDeviceState()

  const { updateAccount } = useCustomerManagerDispatch()

  const [didDeviceInfoChange, setDidDeviceInfoChange] = useState(false)
  const [reportToUpdate, setReportToUpdate] = useState<ReportsTracking[]>([])

  const [isExSummaryReportEnabled, setIsExSummaryReportEnabled] = useState(
    accountData.exSummaryEnabled,
  )

  const { data: accountComments, isLoading } = useAccountComments(accountData.id, true)

  const {
    mutate: createNewAccountComment,
    isPending,
    isError,
    isSuccess,
  } = useAccountCommentsCreation()

  const { mutate: updateComment } = useAccountCommentsUpdate()

  const { mutate: updateReporStatus } = handleReportUpdateReactQuery()

  useEffect(() => {
    if (reportToUpdate.length > 0 || isExSummaryReportEnabled !== accountData.exSummaryEnabled) {
      // Some value has changed
      setDidDeviceInfoChange(true)
    } else {
      setDidDeviceInfoChange(false)
    }
  }, [reportToUpdate, isExSummaryReportEnabled])

  const handleUpdate = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    if (!accountData) return

    if (reportToUpdate.length > 0) {
      const data = reportToUpdate.map((report) => {
        return {
          report_id: report.id,
          status: report.status,
        }
      })

      const accountId = accountData.id

      updateReporStatus({ accountId, data })
    }

    if (isExSummaryReportEnabled !== accountData.exSummaryEnabled) {
      updateAccount(accountData, { ...accountData, exSummaryEnabled: isExSummaryReportEnabled })
    }
    onHide()
  }

  const handleReportUpdate = (newReports: ReportsTracking[]) => {
    setReportToUpdate((prevReports) => [...prevReports, ...newReports])
  }

  const handleNewComment = async (
    newComment: string,
    newDepartment: string,
    newCategory: string,
  ) => {
    const newCommentData = {
      accountId: accountData.id,
      comment: newComment,
      department: newDepartment,
      category: newCategory,
    }

    createNewAccountComment(newCommentData)
  }

  const handleUpdateComment = async (comment: IComments, updatedComment: string) => {
    const updatedCommentData = {
      accountId: accountData.id,
      comment_id: comment.id,
      comment: updatedComment,
      department: comment.department,
      category: comment.category,
    }

    updateComment(updatedCommentData)
  }

  if (!accountData) return null

  return (
    <Modal
      show={show}
      onHide={onHide}
      backdrop="static"
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="device-information-modal"
    >
      <Modal.Header className="modal-header" closeButton>
        <Modal.Title id="contained-modal-title-vcenter">{`${accountData?.name}`}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Tabs transition={false} id="controlled-tab">
          <Tab eventKey={'reports'} title="Reports">
            <Form className="device-information-form mt-4">
              <Form.Label>Executive Summary </Form.Label>
              <Row className="mb-2 ml-2 mt-4">
                <Form.Check
                  type="checkbox"
                  inline
                  onChange={() => setIsExSummaryReportEnabled(!isExSummaryReportEnabled)}
                  checked={isExSummaryReportEnabled}
                  label="Ex Summary Report Enabled"
                  id={`inline-30-day-report-completed`}
                />
              </Row>
              {accountData.reports &&
                Object.keys(accountData?.reports).length > 0 &&
                isExSummaryReportEnabled && (
                  <Row className="mb-2">
                    <Form.Group as={Col} controlId="formGrid30DayReport">
                      <ExSummaryReport
                        reportData={accountData.reports}
                        onReportUpdate={handleReportUpdate}
                      />
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGrid30DayReport"></Form.Group>
                  </Row>
                )}

              <div className="d-flex justify-content-between w-100">
                <Button id="close-modal" onClick={onHide} variant="outline">
                  Close
                </Button>
                <Button
                  id="update-device-pulses"
                  size="lg"
                  variant="primary"
                  type="button"
                  className="mt-4"
                  disabled={!didDeviceInfoChange}
                  onClick={handleUpdate}
                >
                  {deviceUpdating ? (
                    <>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      <span>Loading...</span>
                    </>
                  ) : (
                    <>Update</>
                  )}
                </Button>
              </div>
            </Form>
          </Tab>
          <Tab eventKey={'comments'} title="Comments" className="mt-4">
            {!isLoading ? (
              <div className="overflow-auto" style={{ maxHeight: '600px' }}>
                <CommentComponent
                  comments={accountComments}
                  handleNewComment={handleNewComment}
                  handleUpdateComment={handleUpdateComment}
                  showDepartmentAndCategory
                  isPending={isPending}
                  isError={isError}
                  isSuccess={isSuccess}
                />
              </div>
            ) : (
              <div className="d-flex justify-content-center m-auto h-100 mt-5 mb-5">
                <div className="spinner-border text-primary m-auto">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            )}
          </Tab>
        </Tabs>
        <></>
      </Modal.Body>
    </Modal>
  )
}

export default CustomerJourneyAccountModal
