import React, { useEffect } from 'react'
import { loginAction, logoutAction, refreshAction } from '../action/auth.actions'
import { reducer, initialState } from './auth.reducer'

const StateContext = React.createContext(initialState)
const DispatchContext = React.createContext(undefined as any)

export const AuthContextProvider = ({ children }: any) => {
  const [state, dispatch] = React.useReducer(reducer, initialState)

  useEffect(() => {
    refreshAction(dispatch)
  }, [])


  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>{children}</DispatchContext.Provider>
    </StateContext.Provider>
  )
}

export const useAuthState = () => {
  return React.useContext(StateContext)
}

export const useAuthDispatch = () => {
  const dispatch = React.useContext(DispatchContext)

  if (dispatch === undefined) {
    throw new Error('useAuthDispatch must be used within a AuthContextProvider')
  }

  const login = React.useCallback(
    async (email: string, password: string) => await loginAction({ email, password }, dispatch),
    [dispatch],
  )

  const refresh = React.useCallback(
    async () => await refreshAction(dispatch),
    [dispatch],
  )

  const logout = React.useCallback(async () => await logoutAction(dispatch), [dispatch])

  return React.useMemo(
    () => ({
      login,
      logout,
      refresh
    }),
    [
      login,
      logout,
      refresh
    ],
  )
}
