import { useState, useEffect } from 'react'
import { Button, Col, Form, Modal, Row, Table } from 'react-bootstrap'
import { Info } from 'lucide-react'
import Select from 'react-select'
import { UserInviteLocation } from '@context/customerManager/model/customer-manager'
import CustomerLocation from '@context/account/model/CustomerLocation'
import { Group, UserInvite } from '@context/user/model/user'
import Account from '@context/account/model/Account'
import {
  useCustomerManagerDispatch,
  useCustomerManagerState,
} from '@context/customerManager/customer-manager.context'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import { useAuthState } from '@context/auth/context/auth.context'
import { popoverAccountAdmin, popoverAutoAddAlerts, popoverAutoAddLocations } from './Popovers'
import { hasPermissions } from '@common/utils/helperFunctions'

interface IModalEditProps {
  show: boolean
  invite: UserInvite
  account: Account
  onHide: () => void
}

export const EditInviteModal: React.FC<IModalEditProps> = ({
  show,
  account,
  invite,
  onHide,
}: IModalEditProps) => {
  const { groups, locationsByAccount } = useCustomerManagerState()
  const { permissions } = useAuthState()
  const { updateUserInvite } = useCustomerManagerDispatch()

  const [accountLocations, setAccountLocations] = useState<CustomerLocation[]>([])
  const [email, setEmail] = useState('')
  const [selectedGroups, setSelectedGroups] = useState<Group[]>([])
  const [selectedLocations, setSelectedLocations] = useState<UserInviteLocation[]>([])
  const [isAccountAdmin, setIsAccountAdmin] = useState(false)
  const [autoAddNewLocations, setAutoAddNewLocations] = useState<boolean>(false)
  const [autoAddAlerts, setAutoAddAlerts] = useState(false)
  const [accountInvoice, setAccountInvoice] = useState(false)

  useEffect(() => {
    if (show) {
      setEmail(invite.email)
      setSelectedGroups(groups.filter((g) => invite?.groupIds?.includes(g.id)))
      setSelectedLocations(invite.locations || [])
      setIsAccountAdmin(
        !!invite.accounts.find((a) => String(a.accountId) === account.id && a.admin),
      )
      setAutoAddNewLocations(
        !!invite.accounts.find((a) => String(a.accountId) === account.id && a.autoAddLocations),
      )
      setAutoAddAlerts(
        !!invite.accounts.find((a) => String(a.accountId) === account.id && a.autoAddAlerts),
      )
      setAccountInvoice(
        !!invite.accounts.find((a) => String(a.accountId) === account.id && a.invoice),
      )
    }
  }, [show])

  useEffect(() => {
    if (locationsByAccount[account.id]) {
      setAccountLocations(locationsByAccount[account.id])
    }
  }, [locationsByAccount])

  const setLocations = (locationId: string) => {
    const locations = selectedLocations.filter((loc) => loc.locationId !== locationId)
    if (locations.length === selectedLocations.length) {
      const loc: UserInviteLocation = {
        locationId: locationId,
        admin: false,
        alerts: false,
        emergencyContact: false,
        invitationIdRef: invite.id,
      }
      locations.push(loc)
    }
    setSelectedLocations(locations)
  }

  const setLocationAdmins = (locationId: string) => {
    const locations = selectedLocations.map((loc) => {
      if (loc.locationId === locationId) {
        loc.admin = !loc.admin
      }
      return loc
    })
    setSelectedLocations(locations)
  }

  const setLocationAlerts = (locationId: string) => {
    const locations = selectedLocations.map((loc) => {
      if (loc.locationId === locationId) {
        loc.alerts = !loc.alerts
      }
      return loc
    })
    setSelectedLocations(locations)
  }

  const handleSave = () => {
    const accounts = invite.accounts.map((a) => {
      if (String(a.accountId) === account.id) {
        return {
          ...a,
          admin: isAccountAdmin,
          autoAddLocations: autoAddNewLocations,
          autoAddAlerts: autoAddAlerts,
          invoice: accountInvoice,
        }
      } else {
        return a
      }
    })

    const updatedInvite: UserInvite = {
      ...invite,
      accounts: accounts,
      locations: selectedLocations,
      groupIds: selectedGroups.map((g) => g.id),
    }
    updateUserInvite(updatedInvite, account)
    onHide()
  }

  const handleAccountAdmin = (val: boolean) => {
    setIsAccountAdmin(val)
    if (!val) {
      setSelectedLocations([])
      setAutoAddNewLocations(false)
    } else {
      const locations: UserInviteLocation[] = accountLocations.map((l: CustomerLocation) => {
        return {
          locationId: String(l.id),
          admin: true,
          alerts: !!selectedLocations.find((loc) => loc.locationId === l.id),
          invitationIdRef: invite.id,
        } as UserInviteLocation
      })
      setSelectedLocations(locations)
      setAutoAddNewLocations(true)
    }
  }

  // noinspection RequiredAttributes
  return (
    <Modal
      show={show}
      onHide={onHide}
      backdrop="static"
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="modal-header" closeButton>
        <Modal.Title id="contained-modal-title-vcenter">{'Edit User Invitation'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form className="invite-information-form">
          <Row>
            <Form.Group as={Col} controlId="formGridEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>
          </Row>

          <Row className={'mt-3'}>
            <Form.Group as={Col} controlId="accountOptions">
              <Form.Label>Account Options</Form.Label>
              <div>
                <Form.Check
                  type="checkbox"
                  inline
                  // onChange={(e) => setIsAccountAdmin(!isAccountAdmin)}
                  onChange={() => handleAccountAdmin(!isAccountAdmin)}
                  checked={isAccountAdmin}
                  label={
                    <>
                      Account Admin
                      <OverlayTrigger
                        delay={{ hide: 450, show: 300 }}
                        trigger={['hover', 'focus']}
                        overlay={popoverAccountAdmin}
                        placement="bottom"
                      >
                        <Info size={15} className={'ml-1 text-info'} />
                      </OverlayTrigger>
                    </>
                  }
                  name="accountOptions"
                  id={`inline-account-options-1`}
                />
                <Form.Check
                  type="checkbox"
                  inline
                  onChange={() => setAccountInvoice(!accountInvoice)}
                  checked={accountInvoice}
                  label={
                    <>
                      Account Invoices
                      <OverlayTrigger
                        delay={{ hide: 450, show: 300 }}
                        trigger={['hover', 'focus']}
                        overlay={popoverAutoAddAlerts}
                        placement="bottom"
                      >
                        <Info size={15} className={'ml-1 text-info'} />
                      </OverlayTrigger>
                    </>
                  }
                  name="accountOptions"
                  id={`inline-account-options-2`}
                />
                <Form.Check
                  type="checkbox"
                  inline
                  onChange={() => setAutoAddNewLocations(!autoAddNewLocations)}
                  checked={autoAddNewLocations || isAccountAdmin}
                  disabled={isAccountAdmin}
                  label={
                    <>
                      Auto Add New Locations
                      <OverlayTrigger
                        delay={{ hide: 450, show: 300 }}
                        trigger={['hover', 'focus']}
                        overlay={popoverAutoAddLocations}
                        // containerPadding={20}
                        placement="bottom"
                      >
                        <Info size={15} className={'ml-1 text-info'} />
                      </OverlayTrigger>
                    </>
                  }
                  name="accountOptions"
                  id={`inline-account-options-3`}
                />
                <Form.Check
                  type="checkbox"
                  inline
                  onChange={() => setAutoAddAlerts(!autoAddAlerts)}
                  checked={autoAddAlerts}
                  label={
                    <>
                      Auto Add Alerts
                      <OverlayTrigger
                        delay={{ hide: 450, show: 300 }}
                        trigger={['hover', 'focus']}
                        overlay={popoverAutoAddAlerts}
                        // containerPadding={20}
                        placement="bottom"
                      >
                        <Info size={15} className={'ml-1 text-info'} />
                      </OverlayTrigger>
                    </>
                  }
                  name="accountOptions"
                  id={`inline-account-options-4`}
                />
              </div>
            </Form.Group>
          </Row>

          {hasPermissions(permissions, ['ACCOUNT:ADMIN:SMARTFLOW']) && (
            <Row>
              <Form.Group as={Col} controlId="formGridDroups">
                <Form.Label>Groups</Form.Label>
                <Select
                  className="basic-multi-select"
                  styles={{ menuPortal: (base) => ({ ...base, zIndex: 2 }) }}
                  menuPosition={'fixed'}
                  value={selectedGroups}
                  onChange={(options: any) => setSelectedGroups([...options])}
                  options={groups}
                  isMulti
                  getOptionValue={(option) => option.name}
                  getOptionLabel={(option) => option.description}
                  isClearable={false}
                  // defaultValue={}
                />
              </Form.Group>
            </Row>
          )}

          <Row className={'mt-3'}>
            <Form.Group as={Col} controlId="formGridLocations">
              <Form.Label>Locations</Form.Label>
              <div style={{ width: '100%', height: '300px', overflowY: 'auto' }}>
                <Table striped>
                  <thead
                    style={{
                      width: '15%',
                      position: 'sticky',
                      top: -1,
                      zIndex: 1,
                    }}
                  >
                    <tr>
                      <th>Location Name</th>
                      <th>Admin</th>
                      <th>Alerts</th>
                      <th>&nbsp;</th>
                    </tr>
                  </thead>
                  <tbody>
                    {accountLocations.map((l) => (
                      <tr key={l.id}>
                        <td>
                          <Form.Check
                            type={'checkbox'}
                            label={l.name}
                            value={l.id}
                            id={`default-${l.id}`}
                            onChange={(e) => setLocations(e.target.value)}
                            className={'ml-2'}
                            checked={
                              !!selectedLocations.find((loc) => loc.locationId.toString() === l.id)
                            }
                          />
                        </td>
                        <td>
                          <Form.Check
                            type="checkbox"
                            value={l.id}
                            label={''}
                            disabled={
                              !selectedLocations.find((loc) => loc.locationId.toString() === l.id)
                            }
                            checked={
                              !!selectedLocations.find(
                                (loc) => loc.admin && loc.locationId.toString() === l.id,
                              )
                            }
                            onChange={(e) => setLocationAdmins(e.target.value)}
                          />
                        </td>
                        <td>
                          <Form.Check
                            type="checkbox"
                            value={l.id}
                            label={''}
                            disabled={
                              !selectedLocations.find((loc) => loc.locationId.toString() === l.id)
                            }
                            checked={
                              !!selectedLocations.find(
                                (loc) => loc.alerts && loc.locationId.toString() === l.id,
                              )
                            }
                            onChange={(e) => setLocationAlerts(e.target.value)}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </Form.Group>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button className="btn btn-secondary" onClick={onHide}>
          Cancel
        </Button>
        <Button onClick={handleSave}>{'Save'}</Button>
      </Modal.Footer>
    </Modal>
  )
}
