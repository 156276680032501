import moment from 'moment-timezone'
import { AlertGroup, AlertStats, AlertStatus, HistoricalAlert } from '../model/alert.model'
import { v4 as uuid } from 'uuid'
import Device from '../../device/model/device'

export interface IAction {
  type: ActionTypes
  payload?: any
}

export enum ActionTypes {
  INIT,
  LOADING,
  ERROR,
  ACK_ALERT,
  ALERT_UPDATING,
  FILTER_ACCOUNT_ALERTS,
  HISTORICAL_ALERTS,
  SINGLE_DEVICE_STATS,
  MULTIPLE_DEVICES_STATS,
  FILTER_HISTORICAL_ALERTS,
}

interface State {
  isLoading: boolean
  error: string
  queue: AlertGroup[]
  filteredQueue: AlertGroup[]
  filteredHistorical: HistoricalAlert[]
  historical: HistoricalAlert[]
  alertStatsSingleDevice: AlertStats
  alertStatsMultipleDevices: AlertStats
}

export const INITIAL_STATE: State = {
  isLoading: false,
  queue: [],
  historical: [],
  filteredQueue: [],
  filteredHistorical: [],
  alertStatsSingleDevice: {},
  alertStatsMultipleDevices: {},
  error: '',
}

export const reducer = (state: State, action: IAction): State => {
  switch (action.type) {
    case ActionTypes.INIT:
      const initialQueue = toAlertGroups([...action.payload.alerts])
      return { ...state, queue: initialQueue, error: '' }
    case ActionTypes.FILTER_ACCOUNT_ALERTS:
      const selectedDevices: Device[] = action.payload
      const alertQueue = state.queue
      const filteredQueue = alertQueue.filter(
        (q) => selectedDevices.findIndex((d) => d.deviceId === q.deviceId) > -1,
      )
      return { ...state, filteredQueue: filteredQueue, error: '' }
    case ActionTypes.HISTORICAL_ALERTS: {
      const historical = action.payload.historicalAlerts[0]
        ? Object.entries(action.payload.historicalAlerts[0] as any).map(([deviceId, hist]) => {
            return {
              deviceId: String(deviceId),
              tier1: (hist as any[]).find((h) => h.ack_result === 1)?.group_dates ?? [],
              tier2: (hist as any[]).find((h) => h.ack_result === 2)?.group_dates ?? [],
              tier3: (hist as any[]).find((h) => h.ack_result === 3)?.group_dates ?? [],
            } as HistoricalAlert
          })
        : []
      return {
        ...state,
        historical: historical,
        filteredHistorical: historical,
        isLoading: false,
        error: '',
      }
    }
    case ActionTypes.FILTER_HISTORICAL_ALERTS: {
      const historical: HistoricalAlert[] = state.historical
      const selectedDevices: Device[] = action.payload
      const filteredHistorical = historical.filter((h) => {
        const device: Device | undefined = selectedDevices.find(
          (d: Device) => d.deviceId === h.deviceId,
        )
        if (device) return h
      })
      return {
        ...state,
        filteredHistorical: filteredHistorical,
        isLoading: false,
        error: '',
      }
    }
    case ActionTypes.ACK_ALERT:
      const alertGroup: AlertGroup = {
        ...action.payload.alertGroup,
        priorityTier: action.payload.priorityTier,
      }
      alertGroup.alerts = alertGroup.alerts.map((alert) => {
        return {
          ...alert,
          priorityTier: action.payload.priorityTier,
        }
      })

      // const queue = state.queue.filter((g) => g.id !== alertGroup.id);
      const ackQueue =
        alertGroup.status !== 2 && alertGroup.status !== 0
          ? state.queue.map((g) => {
              if (g.id === alertGroup.id) {
                return { ...g, priorityTier: alertGroup.priorityTier }
              }
              return g
            })
          : state.queue.filter((g) => g.id !== alertGroup.id)

      const ackFilteredQueue =
        alertGroup.status !== 2 && alertGroup.status !== 0
          ? state.filteredQueue.map((g) => {
              if (g.id === alertGroup.id) {
                return { ...g, priorityTier: alertGroup.priorityTier }
              }
              return g
            })
          : state.filteredQueue.filter((g) => g.id !== alertGroup.id)
      return {
        ...state,
        queue: ackQueue,
        filteredQueue: ackFilteredQueue,
        isLoading: false,
        error: '',
      }
    case ActionTypes.SINGLE_DEVICE_STATS: {
      const alertStatsSingleDevice: AlertStats = {}
      const data = action.payload
      Object.keys(data).forEach((key) => {
        alertStatsSingleDevice[key] = data[key].map((s: any) => {
          return {
            ack_result: s.ack_result,
            alert_count: s.alert_count,
          }
        })
      })
      return {
        ...state,
        alertStatsSingleDevice: alertStatsSingleDevice,
        isLoading: false,
        error: '',
      }
    }
    case ActionTypes.MULTIPLE_DEVICES_STATS: {
      const data = action.payload

      const alertStatsMultipleDevices: AlertStats = data.reduce((result: AlertStats, item: any) => {
        const key = Object.keys(item)[0] // Get the key (e.g., "all" or "period")
        const value = item[key] // Get the corresponding value

        if (!result[key]) {
          result[key] = [] // Initialize an array for the key if it doesn't exist in the result
        }

        result[key] = result[key].concat(value) // Concatenate the value to the result array

        return result
      }, {})

      return {
        ...state,
        alertStatsMultipleDevices: alertStatsMultipleDevices,
        isLoading: false,
        error: '',
      }
    }
    case ActionTypes.ALERT_UPDATING:
      return { ...state, isLoading: true, error: '' }
    case ActionTypes.LOADING:
      return { ...state, isLoading: true, error: '' }
    case ActionTypes.ERROR:
      return { ...state, isLoading: false, error: '' }
    default:
      return state
  }
}

function toAlertGroups(alerts: any[]): AlertGroup[] {
  const alertsByDevice: { [key: string]: any } = alerts.reduce((acc, alert) => {
    const key = String(alert.device_id)
    if (!acc[key]) acc[key] = []
    acc[key].push(alert)
    return acc
  }, {})

  const alertGroups: AlertGroup[][] = Object.values(alertsByDevice).reduce(
    (acc, deviceAlerts: any[]) => {
      const splitAlerts = deviceAlerts
        .sort((a: any, b: any) => moment(a.alert_time).diff(b.alert_time))
        .reduce((splitArray: AlertGroup[], alert) => {
          // If any alert in the group has a status if 1 or higher set the status of the group to that status(in_progress/closed) else 0(open)
          let lastStatus = 0
          lastStatus = mapStatus(alert.status) > lastStatus ? mapStatus(alert.status) : lastStatus
          if (splitArray.length === 0) {
            splitArray.push({
              id: alert.gid,
              deviceId: String(alert.device_id),
              dlId: alert.dl_id,
              deviceLocation: alert.device_location,
              priorityTier: alert.ack_result,
              startAlertTime: alert.alert_time,
              endAlertTime: alert.alert_time,
              startAlertTimeUTC: alert.alert_time_utc,
              endAlertTimeUTC: alert.alert_time_utc,
              status: lastStatus,
              triggerType: alert.trigger_type,
              ackBy: alert.ack_by,
              alerts: [
                {
                  id: alert.id,
                  time: alert.alert_time,
                  priorityTier: alert.ack_result,
                  createdAt: alert.created_at,
                  alert_score: alert.alert_score,
                  status: mapStatus(alert.status),
                  triggerType: alert.trigger_type,
                  actioned: alert.actioned,
                  ackBy: alert.ack_by,
                  estimatedLeak: alert.estimated_leak,
                  valid: alert.valid,
                  gid: alert.gid,
                  hourlyAlertsSent: alert.hourly_alerts_sent,
                },
              ],
              hourlyAlertsSentDate: alert.hourly_alerts_sent_date,
              estimatedLeakTotal: 0,
            } as AlertGroup)
            return splitArray
          }

          const lastGroup = splitArray[splitArray.length - 1]
          splitArray[splitArray.length - 1].endAlertTime = alert.alert_time
          splitArray[splitArray.length - 1].endAlertTimeUTC = alert.alert_time_utc
          splitArray[splitArray.length - 1].hourlyAlertsSentDate =
            alert.hourly_alerts_sent_date !== null
              ? alert.hourly_alerts_sent_date
              : splitArray[splitArray.length - 1].hourlyAlertsSentDate
          splitArray[splitArray.length - 1].alerts.push({
            id: alert.id,
            time: alert.alert_time,
            createdAt: alert.created_at,
            priorityTier: alert.ack_result,
            alert_score: alert.alert_score,
            status: mapStatus(alert.status),
            triggerType: alert.trigger_type,
            actioned: alert.actioned,
            estimatedLeak: alert.estimated_leak,
            valid: alert.valid,
            gid: alert.gid,
            hourlyAlertsSent: alert.hourly_alerts_sent,
          })
          splitArray[splitArray.length - 1].estimatedLeakTotal += alert.estimated_leak
          if (
            lastGroup.priorityTier == null ||
            (alert.ack_result != null && lastGroup.priorityTier > alert.ack_result)
          ) {
            splitArray[splitArray.length - 1].priorityTier = alert.ack_result
          }
          // if (lastGroup.status > mapStatus(alert.status)) {
          //     splitArray[splitArray.length - 1].status = mapStatus(alert.status);
          // }
          return splitArray
        }, [] as AlertGroup[])
      acc.push(splitAlerts)
      return acc
    },
    [] as AlertGroup[][],
  )

  return alertGroups
    .reduce((acc, item) => {
      acc.push(...item)
      return acc
    }, [])
    .sort((a: any, b: any) => -moment(a.endAlertTime).diff(b.endAlertTime))
}

function mapStatus(status: string) {
  switch (status) {
    case 'open':
      return AlertStatus.OPEN
    case 'in_progress':
      return AlertStatus.IN_PROGRESS
    case 'closed':
      return AlertStatus.CLOSED
  }
  return AlertStatus.OPEN
}
