import { useEffect, useState } from 'react'
import Select from 'react-select'
import { FormGroup } from 'react-bootstrap'
import { FaPlus, FaTrash, FaCog } from 'react-icons/fa'
import { hasPermissions } from '@common/utils/helperFunctions'
import { useAccountState } from '@context/account/context/account.context'
import Account from '@context/account/model/Account'
import { useAuthState } from '@context/auth/context/auth.context'
import { useCustomerManagerDispatch } from '@context/customerManager/customer-manager.context'
import AccountEditModal from './AccountModal/AccountEditModal'
import DeleteModal from '../../general/Modals/DeleteModal'
import AccountDetails from './AccountDetails'

const AccountSelector = () => {
  const { accounts } = useAccountState()
  const [selectedAccount, setSelectedAccount] = useState<Account | null>(null)
  const [editAccountModal, setEditAccountModal] = useState<boolean>(false)
  const [deleteAccountModal, setDeleteAccountModal] = useState<boolean>(false)
  const { deleteAccount } = useCustomerManagerDispatch()
  const { permissions } = useAuthState()
  const defaultAccount: Account = {
    id: '',
    name: '',
    addressLine1: '',
    addressLine2: '',
    town: '',
    postcode: '',
    county: '',
    country: '',
    phone: '',
    accountAlias: '',
    exSummaryEnabled: false,
  }

  useEffect(() => {
    if (accounts.length === 1) {
      setSelectedAccount(accounts[0])
    }
    const updatedAccount = accounts.find(a => a.id === selectedAccount?.id)
    if (updatedAccount) {
      setSelectedAccount(updatedAccount)
    }
  }, [accounts])

  return (
    <>
      <div className="mb-2 d-flex mt-5">
        {accounts.length > 1 && <h2 className="my-auto mr-3">Accounts</h2>}
        {hasPermissions(permissions, ['CREATE:ACCOUNT']) ? (
          <button
            onClick={() => {
              setSelectedAccount(null)
              setEditAccountModal(true)
            }}
            className="my-auto btn btn-sm btn-primary mr-1"
          >
            <FaPlus className={'mr-1'} />
            New Account
          </button>
        ) : null}
      </div>
      <FormGroup controlId="formBasicEmail">
        <div style={{ display: 'flex' }}>
          <div className="col">
            {accounts.length > 1 ? (
              <Select
                classNamePrefix="select"
                value={selectedAccount}
                onChange={(account: any) => {
                  if (account?.id !== selectedAccount?.id) setSelectedAccount(account)
                }}
                options={accounts}
                getOptionValue={(option: Account) => option.id}
                getOptionLabel={(option: Account) => `${option.name}`}
                isClearable={true}
              />
            ) : (
              <h3>{accounts[0]?.name}</h3>
            )}
          </div>

          {hasPermissions(permissions, ['UPDATE:ACCOUNT']) ? (
            <button
              onClick={() => setEditAccountModal(true)}
              className="my-auto btn btn-sm btn-info mr-1"
              disabled={!selectedAccount}
            >
              <FaCog className={'mr-1'} />
              Edit Account
            </button>
          ) : null}

          {hasPermissions(permissions, ['DELETE:ACCOUNT']) ? (
            <button
              onClick={() => setDeleteAccountModal(true)}
              className="my-auto btn btn-sm btn-danger"
              disabled={!selectedAccount}
            >
              <FaTrash className={'mr-1'} />
              Delete Account
            </button>
          ) : null}
        </div>
      </FormGroup>
      {selectedAccount ? <AccountDetails selectedAccount={selectedAccount} /> : null}

      {editAccountModal && (
        <AccountEditModal
          show={editAccountModal}
          account={selectedAccount ? selectedAccount : defaultAccount}
          onHide={() => setEditAccountModal(false)}
          onSave={() => {
            setEditAccountModal(false)
          }}
        />
      )}

      <DeleteModal
        show={deleteAccountModal}
        data={selectedAccount}
        onHide={() => setDeleteAccountModal(false)}
        onDelete={(account: Account) => deleteAccount(account)}
        toString={(account: Account) => `${account.name}`}
      />
    </>
  )
}

export default AccountSelector
