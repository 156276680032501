import React, { useState, useEffect } from 'react'
import { Form, Col, Button, Spinner, Alert, Row, InputGroup } from 'react-bootstrap'
import { EyeIcon, EyeOffIcon } from 'lucide-react'
import { Link, useParams } from 'react-router-dom'

import { useUserDispatch, useUserState } from '@context/user/context/user.context'
import { validatePasswordStructure } from '@common/utils/helperFunctions'

import './ResetPassword.scss'

const ResetPassword: React.FC = () => {
  const { resettingPassword, error } = useUserState()
  const { resetPassword } = useUserDispatch()
  let { token } = useParams<{ token: string }>()

  const [newPassword, setNewPassword] = useState<string>('')
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState<string>('')

  const [isPasswordShown, setIsPasswordShown] = useState<boolean>(false)
  const [isPasswordValid, setIsPasswordValid] = useState<boolean>(false)
  const [doPasswordsMatch, setDoPasswordsMatch] = useState<boolean>(false)
  const [wasConfirmationRequested, setWasConfirmationRequested] = useState<boolean>(false)

  const [passwordReset, setPasswordReset] = useState<boolean>(false)

  useEffect(() => {
    if (wasConfirmationRequested && !resettingPassword && !error) {
      setPasswordReset(true)
    } else {
      setPasswordReset(false)
    }
  }, [resettingPassword, error])

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    if (newPassword && newPasswordConfirmation && isPasswordValid && doPasswordsMatch) {
      resetPassword(newPassword, newPasswordConfirmation, String(token))
      setWasConfirmationRequested(true)
    }
  }

  const onNewPasswordChange = (password: string) => {
    setNewPassword(password)
    const isValid = validatePasswordStructure(password)
    setIsPasswordValid(isValid)
  }

  const onNewConfirmationPasswordChange = (password: string) => {
    setNewPasswordConfirmation(password)
    const areMatching = newPassword === password
    setDoPasswordsMatch(areMatching)
  }

  if (!token) {
    return <div>Invalid token</div>
  }

  return (
    <div className="reset-password background">
      <div className="login-form">
        <img src="/assets/img/logo.svg" alt="Smartflow Logo" width="200" />
        <h1>Reset Password</h1>
        <Form className="" onSubmit={handleSubmit}>
          <Row>
            <Form.Label>New Password</Form.Label>
            <InputGroup as={Col} controlId="reset-password-1-id" className="form-group">
              <Form.Control
                type={isPasswordShown ? 'text' : 'password'}
                placeholder="Enter new password"
                onChange={(e) => onNewPasswordChange(e.currentTarget.value)}
                isInvalid={!!newPassword && !isPasswordValid}
              />
              <InputGroup.Text
                role="button"
                className="rounded-right"
                onClick={() => setIsPasswordShown(!isPasswordShown)}
              >
                {isPasswordShown ? <EyeIcon /> : <EyeOffIcon />}
              </InputGroup.Text>
              <Form.Control.Feedback type="invalid">
                <div className="input-feedback_error">
                  Password should:
                  <ul>
                    <li>be (at least) 8 characters long </li>
                    <li>have a minimum of 1 letter </li>
                    <li>have a minimum of 1 numeric character </li>
                    <li>have a minimum of 1 special character </li>
                  </ul>
                </div>
              </Form.Control.Feedback>
            </InputGroup>
          </Row>
          <Row>
            <Form.Label>Confirm New Password</Form.Label>
            <InputGroup as={Col} controlId="reset-password-2-id" className="form-group">
              <Form.Control
                type={isPasswordShown ? 'text' : 'password'}
                placeholder="Enter new password again"
                onChange={(e) => onNewConfirmationPasswordChange(e.currentTarget.value)}
                isInvalid={!!newPassword && !!newPasswordConfirmation && !doPasswordsMatch}
              />
              <InputGroup.Text
                role="button"
                className="rounded-right"
                onClick={() => setIsPasswordShown(!isPasswordShown)}
              >
                {isPasswordShown ? <EyeIcon /> : <EyeOffIcon />}
              </InputGroup.Text>
              <Form.Control.Feedback type="invalid">Passwords don't match!</Form.Control.Feedback>
            </InputGroup>
          </Row>
          <Button
            variant="primary"
            type="submit"
            disabled={
              !newPassword ||
              !newPasswordConfirmation ||
              !isPasswordValid ||
              !doPasswordsMatch ||
              passwordReset
            }
          >
            {resettingPassword ? (
              <>
                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                <span>Resetting password...</span>
              </>
            ) : (
              <>Reset Password</>
            )}
          </Button>
          {!resettingPassword && wasConfirmationRequested && (
            <div className="alert-container">
              {(error && (
                <Alert variant="danger">
                  An Error occurred while resetting your password. Please try again...
                </Alert>
              )) ||
                (passwordReset && <Alert variant="success">Password reset successfully!</Alert>)}
            </div>
          )}
          <Link to="/">
            <Button variant="secondary">
              <i className="fas fa-fw  fa-arrow-left" />
              Back to Login
            </Button>
          </Link>
        </Form>
      </div>
    </div>
  )
}

export default ResetPassword
