import { useQuery } from '@tanstack/react-query'
import moment from 'moment'
import backend from '@api/backend'
import { extractFlow, formatCurrency, roundToTwo } from '@common/utils/helperFunctions'
import {
  Aggregator,
  HourlyUsage,
  WaterUsage,
  WaterUsageCosts,
  WaterUsageStats,
  deviceWaterUsage,
} from './model/waterUsage.model'
import Device from '@context/device/model/device'
import { WaterUsageQuery, WaterUsageQueryMultipleDevices } from './model/waterUsage.model'
import { displayToast } from '@common/utils/appToast'
import { AggUsageQuery, MonthlyLeakQuery } from './model/usageStats.model'

export const useWaterUsageStat = (uom: string) => {
  const query = useQuery({
    queryKey: ['water-usage-stats', uom],
    queryFn: () => getWaterUsageStats(uom),
    enabled: uom !== '',
    staleTime: 1000 * 60 * 15, // 15 minutes,
  })

  return query
}

export const useWaterUsageCost = (uom: string) => {
  const query = useQuery({
    queryKey: ['water-usage-costs', uom],
    queryFn: () => getWaterUsageCosts(uom),
    enabled: uom !== '',
    staleTime: 1000 * 60 * 15, // 15 minutes,
  })

  return query
}

export const useWaterCostFilter = (
  selectedDevices: Device[],
  waterUsageCosts: { [key: string]: WaterUsageCosts } | undefined,
) => {
  const query = useQuery({
    queryKey: ['costs-filter', selectedDevices, waterUsageCosts],
    queryFn: () => filterWaterUsageCosts(selectedDevices, waterUsageCosts),
  })

  return query
}

export const useWaterStatsFilter = (
  selectedDevices: Device[],
  waterUsageStats: { [key: string]: WaterUsageStats } | undefined,
) => {
  const query = useQuery({
    queryKey: ['stats-filter', selectedDevices, waterUsageStats],
    queryFn: () => filterWaterUsageStats(selectedDevices, waterUsageStats),
  })

  return query
}

export const useDevicesWaterUsage = (
  params: WaterUsageQueryMultipleDevices,
  isFetchEnabled: boolean = false,
  isRefreshAction: boolean = false,
) => {
  const query = useQuery({
    queryKey: ['devices-water-usage'],
    queryFn: () => getDevicesWaterUsage(params, isRefreshAction),
    enabled: isFetchEnabled,
    staleTime: 1000 * 60 * 15, // 15 minutes,
  })

  return query
}

export const useAlertWaterUsage = (params: WaterUsageQuery) => {
  const query = useQuery({
    queryKey: ['alert-water-usage'],
    queryFn: () => getAlertWaterUsage(params),
    enabled: false,
  })

  return query
}

export const useAggregatedUsage = (params: AggUsageQuery) => {
  const query = useQuery({
    queryKey: ['aggregated-water-usage'],
    queryFn: () => getAggregatedUsage(params),
    staleTime: 1000 * 60 * 15, // 15 minutes,
    enabled: !!params.uom,
  })

  return query
}

export const useMonthlyLeakStats = (params: MonthlyLeakQuery) => {
  const query = useQuery({
    queryKey: ['monthly-leak-stats', params.uom],
    queryFn: () => getMonthlyLeakStats(params),
    enabled: params.uom !== '',
    staleTime: 1000 * 60 * 15, // 15 minutes,
  })

  return query
}

export const useMonthlyLeakStatsFilter = (
  devices: Device[],
  monthlyLeakStats: { [key: string]: {} },
) => {
  const query = useQuery({
    queryKey: ['monthly-leak-stats-filter', devices],
    queryFn: () => filterMonthlyLeakStats(devices, monthlyLeakStats),
    enabled: !!monthlyLeakStats,
  })

  return query
}

export async function getWaterUsageStats(uom: string) {
  const responseHourly = await backend.get(`/water_usage`, {
    params: {
      start_date: moment().startOf('day').format('YYYY-MM-DDTHH:mm:ss'),
      end_date: moment().format('YYYY-MM-DDTHH:mm:ss'),
      uom,
    },
  })

  const responseStats = await backend.get(`/water_usage/stats`, {
    params: {
      uom,
    },
  })

  const statsArr = responseStats?.data?.usage_data
  const hourly = responseHourly?.data?.usage_data

  const hourlyMap: HourlyUsage[] = hourly.reduce((acc: any, h: any) => {
    if (!acc[h.device_id]) acc[h.device_id] = Array(24)
    acc[h.device_id][h.hour] = {
      date: `${h.date}`,
      value: h.flow,
    }
    return acc
  }, {})
  const waterUsageStatsArray: WaterUsageStats[] = statsArr.map((stats: any) => {
    return {
      deviceId: stats.device_id + '',
      dailyUsage: stats.Daily_Usage,
      avgDaily: stats.Avg_Daily,
      weeklyUsage: stats.Weekly_Usage,
      avgWeekly: stats.Avg_Weekly,
      monthlyUsage: stats.Monthly_Usage,
      avgMonthly: stats.Avg_Monthly,
      yearlyUsage: stats.Yearly_Usage,
      hourlyUsage: hourlyMap[stats.device_id] || Array(24),
    }
  })
  const waterUsageStats = waterUsageStatsArray.reduce(
    (agg: { [key: string]: WaterUsageStats }, curr) => {
      agg[curr.deviceId] = curr
      return agg
    },
    {},
  )

  return waterUsageStats
}

export async function getWaterUsageCosts(uom: string) {
  const responseCosts = await backend.get(`/water_usage/cost`, {
    params: {
      uom,
    },
  })

  const costs = responseCosts?.data?.usage_data

  const waterUsageCostsArray: WaterUsageCosts[] = costs.map((stats: any) => {
    return {
      deviceId: stats.device_id + '',
      dailyUsageFormatted: formatCurrency(stats.Daily_Usage, stats.Currency),
      avgDailyFormatted: formatCurrency(stats.Avg_Daily, stats.Currency),
      weeklyUsageFormatted: formatCurrency(stats.Weekly_Usage, stats.Currency),
      avgWeeklyFormatted: formatCurrency(stats.Avg_Weekly, stats.Currency),
      monthlyUsageFormatted: formatCurrency(stats.Monthly_Usage, stats.Currency),
      avgMonthlyFormatted: formatCurrency(stats.Avg_Monthly, stats.Currency),
      yearlyUsageFormatted: formatCurrency(stats.Yearly_Usage, stats.Currency),
      dailyUsage: stats.Daily_Usage,
      avgDaily: stats.Avg_Daily,
      weeklyUsage: stats.Weekly_Usage,
      avgWeekly: stats.Avg_Weekly,
      monthlyUsage: stats.Monthly_Usage,
      avgMonthly: stats.Avg_Monthly,
      yearlyUsage: stats.Yearly_Usage,
      hourlyUsage: [],
      currency: stats.Currency,
    }
  })
  const waterUsageCosts = waterUsageCostsArray.reduce(
    (agg: { [key: string]: WaterUsageCosts }, curr) => {
      agg[curr.deviceId] = curr
      return agg
    },
    {},
  )

  return waterUsageCosts
}

export const filterWaterUsageCosts = (
  selectedDevices: Device[],
  waterUsageCosts: { [key: string]: WaterUsageCosts } | undefined,
) => {
  if (waterUsageCosts) {
    const filteredWaterUsageCosts: WaterUsageStats | {} = Object.keys(waterUsageCosts)
      .filter((deviceId) => {
        const device = selectedDevices.find((d: Device) => d.deviceId === deviceId)
        if (device) {
          return deviceId
        }
      })
      .reduce((cur, key) => {
        return Object.assign(cur, { [key]: waterUsageCosts[key] })
      }, {})

    const mainMetersWaterUsageCosts: WaterUsageStats | {} = Object.keys(filteredWaterUsageCosts)
      .filter((deviceId) => {
        const device = selectedDevices.find(
          (d: Device) => d.deviceId === deviceId && d.deviceSettings.masterDeviceIdRef === null,
        )
        if (device) {
          return deviceId
        }
      })
      .reduce((cur, key) => {
        return Object.assign(cur, { [key]: waterUsageCosts[key] })
      }, {})

    const subMetersWaterUsageCosts: WaterUsageStats | {} = Object.keys(filteredWaterUsageCosts)
      .filter((deviceId) => {
        const device = selectedDevices.find(
          (d: Device) => d.deviceId === deviceId && d.deviceSettings.masterDeviceIdRef !== null,
        )
        if (device) {
          return deviceId
        }
      })
      .reduce((cur, key) => {
        return Object.assign(cur, { [key]: waterUsageCosts[key] })
      }, {})

    return {
      filteredWaterUsageCosts: filteredWaterUsageCosts,
      mainMetersWaterUsageCosts: mainMetersWaterUsageCosts as { [key: string]: WaterUsageStats },
      subMetersWaterUsageCosts: subMetersWaterUsageCosts,
    }
  } else {
    return {
      filteredWaterUsageCosts: {},
      mainMetersWaterUsageCosts: {},
      subMetersWaterUsageCosts: {},
    }
  }
}

export const filterWaterUsageStats = (
  selectedDevices: Device[],
  waterUsageStats: { [key: string]: WaterUsageStats } | undefined,
) => {
  if (waterUsageStats) {
    const filteredWaterUsageStats: WaterUsageStats | {} = Object.keys(waterUsageStats)
      .filter((deviceId: string) => selectedDevices.find((d: Device) => d.deviceId === deviceId))
      .reduce((cur, key) => {
        return Object.assign(cur, { [key]: waterUsageStats[key] })
      }, {})

    const mainMetersWaterUsageStats: WaterUsageStats | {} = Object.keys(filteredWaterUsageStats)
      .filter((deviceId: string) =>
        selectedDevices.find(
          (d: Device) => d.deviceId === deviceId && d.deviceSettings.masterDeviceIdRef === null,
        ),
      )
      .reduce((cur, key) => {
        return Object.assign(cur, { [key]: waterUsageStats[key] })
      }, {})

    const subMetersWaterUsageStats: WaterUsageStats | {} = Object.keys(filteredWaterUsageStats)
      .filter((deviceId: string) =>
        selectedDevices.find(
          (d: Device) => d.deviceId === deviceId && d.deviceSettings.masterDeviceIdRef !== null,
        ),
      )
      .reduce((cur, key) => {
        return Object.assign(cur, { [key]: waterUsageStats[key] })
      }, {})

    return {
      filteredWaterUsageStats: filteredWaterUsageStats as { [key: string]: WaterUsageStats },
      mainMetersWaterUsageStats: mainMetersWaterUsageStats as { [key: string]: WaterUsageStats },
      subMetersWaterUsageStats,
    }
  } else {
    return {
      filteredWaterUsageStats: {},
      mainMetersWaterUsageStats: {},
      subMetersWaterUsageStats: {},
    }
  }
}

export async function getDevicesWaterUsage(
  params: WaterUsageQueryMultipleDevices,
  isRefreshAction: boolean = false,
) {
  const multipleDeviceWaterUsage: deviceWaterUsage[][] = []

  try {
    await Promise.all(
      params.deviceIds.map(async (deviceId: string) => {
        let response
        if (!isRefreshAction) {
          response = await backend.get(`/water_usage/${deviceId}`, {
            params: {
              start_date: params.from,
              end_date: params.to,
              uom: params.uom,
            },
          })
        } else {
          response = await backend.get(`/devices/${deviceId}/daily_flow`, {
            params: {
              req_date: moment(params.from).format('YYYY-MM-DD'),
              uom: params.uom,
            },
          })
        }

        const usageTransformed: deviceWaterUsage[] = !response.data.usage_data.Flow
          ? JSON.parse(JSON.stringify(response.data.usage_data))
          : (response.data.usage_data.Flow as any[]).slice(1).map((f, i) => {
              return {
                date: moment(params.from)
                  .utc()
                  .startOf('day')
                  .add(i, 'hour')
                  .format('YYYY-MM-DDTHH:mm:ss'),
                deviceId: deviceId,
                hour: i,
                flow: roundToTwo(f),
              }
            })

        multipleDeviceWaterUsage.push(usageTransformed)
      }),
    )

    const totalUsageOnDevices = multipleDeviceWaterUsage.map((usage: deviceWaterUsage[]) => {
      return roundToTwo(
        usage.reduce((acc, usage) => {
          if (usage.flow !== null && !isNaN(usage.flow)) {
            return acc + usage.flow
          } else {
            return acc
          }
        }, 0),
      )
    })

    const totalUsageByDevice: Record<string, number> = {}

    multipleDeviceWaterUsage.forEach((usage: deviceWaterUsage[]) => {
      usage.forEach((usage: deviceWaterUsage) => {
        const { device_id, flow } = usage
        if (totalUsageByDevice.hasOwnProperty(device_id)) {
          if (flow !== null && !isNaN(flow)) {
            totalUsageByDevice[device_id] += flow
          }
        } else {
          if (flow !== null && !isNaN(flow)) {
            totalUsageByDevice[device_id] = flow
          } else {
            totalUsageByDevice[device_id] = 0
          }
        }
      })
    })

    const totalUsage = totalUsageOnDevices.reduce((acc, curr) => acc + curr, 0)

    const multipleDeviceUsage = {
      usage: multipleDeviceWaterUsage,
      totalUsageByDevice,
      totalUsage,
    }

    return multipleDeviceUsage
  } catch (e: any) {
    displayToast({
      type: 'error',
      message: 'There was an error trying to fetch data, please try again later',
      id: 'getDevicesWaterUsage',
    })
  }
}

const getAlertWaterUsage = async (params: WaterUsageQuery) => {
  try {
    const response = await backend.get(`/water_usage/${params.deviceId}/alert_average`, {
      params: {
        start_date: params.from,
        end_date: params.to,
        date_type: params.dateType,
        uom: params.uom,
      },
    })

    const usageData = response.data?.usage_data

    const alertWaterUsage: { [x: string]: WaterUsage } = {}

    alertWaterUsage[JSON.stringify(params)] = {
      ...params,
      aggregated: {
        [`${Aggregator.CURRENT.toString()}`]: extractFlow(usageData, 'flow'),
        [`${Aggregator.ACTUAL_BASELINE.toString()}`]: extractFlow(usageData, 'average'),
        [`${Aggregator.EXPECTED_BASELINE.toString()}`]: extractFlow(usageData, 'expected_baseline'),
        [`${Aggregator.ONE_WEEK_PRIOR.toString()}`]: extractFlow(usageData, 'flow_7d_p'),
        [`${Aggregator.BASELINE_UPPER_LIMIT.toString()}`]: extractFlow(
          usageData,
          'upper_flow_limit',
        ),
        [`${Aggregator.NINETY_DAYS_WX.toString()}`]: extractFlow(usageData, 'average_w_excluded'),
      },
    }

    return alertWaterUsage
  } catch (e) {
    displayToast({
      type: 'error',
      message: 'There was an error trying to fetch data, please try again later',
      id: 'getAlertWaterUsage',
    })
  }
}

const getAggregatedUsage = async (params: AggUsageQuery) => {
  const response = await backend.get(`/water_usage/aggregated/month`, {
    params: {
      start_date: params.queryStart,
      end_date: params.queryEnd,
      agg_type: params.aggType,
      uom: params.uom,
    },
  })

  const monthlyAggStats = response.data.usage_data.reduce((acc: any, currentVal: any) => {
    if (!acc[currentVal.dl_id]) {
      acc[currentVal.dl_id] = {}
    }

    if (!acc[currentVal.dl_id][currentVal.Year]) {
      acc[currentVal.dl_id][currentVal.Year] = {}
    }

    if (!acc[currentVal.dl_id][currentVal.Year][currentVal.time_value]) {
      acc[currentVal.dl_id][currentVal.Year][currentVal.time_value] = currentVal.Usage
    }

    return acc
  }, {})

  return monthlyAggStats
}

const getMonthlyLeakStats = async (params: MonthlyLeakQuery) => {
  const response = await backend.get('/usage_alerts/stats/leak/month', {
    params: {
      start_year: params.queryStartYear,
      start_month: params.queryStartMonth,
      end_year: params.queryEndYear,
      end_month: params.queryEndMonth,
      agg_type: params.aggType,
      uom: params.uom,
    },
  })

  const monthlyLeakStats: any = response.data.data.reduce((acc: any, currentVal: any) => {
    if (!acc[currentVal.dl_id]) {
      acc[currentVal.dl_id] = {}
    }

    if (!acc[currentVal.dl_id][currentVal.year]) {
      acc[currentVal.dl_id][currentVal.year] = {}
    }

    if (!acc[currentVal.dl_id][currentVal.year][currentVal.time_value]) {
      acc[currentVal.dl_id][currentVal.year][currentVal.time_value] = currentVal.value
    }
    return acc
  }, {})

  return monthlyLeakStats
}

const filterMonthlyLeakStats = (devices: Device[], monthlyLeakStats: { [key: string]: {} }) => {
  const filteredMonthlyLeakStats: { [key: string]: {} } = Object.keys(monthlyLeakStats).reduce(
    (acc, key) => {
      const d: Device | undefined = devices.find((d: Device) => d.dlId?.toString() === key)
      if (d) {
        return Object.assign(acc, { [key]: monthlyLeakStats[key] })
      } else {
        return acc
      }
    },
    {},
  )

  return filteredMonthlyLeakStats
}
