import { useEffect, useMemo, useReducer, createContext, useCallback, useContext } from 'react'

import { hasPermissions } from '@common/utils/helperFunctions'
import {
  ackAlertsAction,
  loadAlertsAction,
  loadHistoricalAlertsAction,
  loadHistoricalAlertsStatsDeviceAction,
  loadMultipleDevicesHistoricalAlertsStatsAction,
} from '../action/alert.actions'
import { AlertGroup, AlertStatus } from '../model/alert.model'
import { ActionTypes, INITIAL_STATE, reducer } from './alert.reducer'
import { useDeviceState } from '../../device/context/device.context'
import { useAuthState } from '../../auth/context/auth.context'

const StateContext = createContext(INITIAL_STATE)
const DispatchContext = createContext(undefined as any)

export const AlertContextProvider = ({ children }: any) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE)

  const { devices } = useDeviceState()
  const { permissions } = useAuthState()

  useEffect(() => {
    if (devices.length > 0) {
      dispatch({ type: ActionTypes.FILTER_ACCOUNT_ALERTS, payload: devices })
      dispatch({ type: ActionTypes.FILTER_HISTORICAL_ALERTS, payload: devices })
    }
  }, [devices])

  useEffect(() => {
    if (
      hasPermissions(permissions, ['READ:ALERTS:USAGE:OPEN']) ||
      hasPermissions(permissions, ['READ:ALERTS:USAGE:IN_PROGRESS']) ||
      hasPermissions(permissions, ['READ:ALERTS:USAGE:CLOSED'])
    )
      loadAlertsAction()(dispatch)
  }, [])

  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>{children}</DispatchContext.Provider>
    </StateContext.Provider>
  )
}

export const useAlertState = () => {
  return useContext(StateContext)
}

export const useAlertDispatch = () => {
  const dispatch = useContext(DispatchContext) as (action: any) => any

  const { permissions } = useAuthState()

  if (dispatch === undefined) {
    throw new Error('useAlertDispatch must be used within a AlertContextProvider')
  }

  const loadHistoricalAlerts = useCallback(
    async (query: any = null) => {
      if (hasPermissions(permissions, ['READ:ALERTS:USAGE:HISTORY']))
        await loadHistoricalAlertsAction(query)(dispatch)
    },
    [permissions],
  )

  const loadSingleDeviceStats = useCallback(
    async (query: any) => {
      if (hasPermissions(permissions, ['READ:ALERTS:USAGE:HISTORY']))
        await loadHistoricalAlertsStatsDeviceAction(query)(dispatch)
    },
    [permissions],
  )

  const loadMultipleDevicesStats = useCallback(
    async (query: any) => {
      if (hasPermissions(permissions, ['READ:ALERTS:USAGE:HISTORY']))
        await loadMultipleDevicesHistoricalAlertsStatsAction(query)(dispatch)
    },
    [permissions],
  )

  const ackAlerts = useCallback(
    async (alertGroup: AlertGroup, priorityTier: number, status: AlertStatus) => {
      if (hasPermissions(permissions, ['UPDATE:ALERTS:USAGE']))
        await ackAlertsAction(alertGroup, priorityTier, status)(dispatch)
    },
    [permissions],
  )

  return useMemo(
    () => ({
      ackAlerts,
      loadHistoricalAlerts,
      loadSingleDeviceStats,
      loadMultipleDevicesStats,
    }),
    [ackAlerts, loadHistoricalAlerts, loadSingleDeviceStats, loadMultipleDevicesStats],
  )
}
