import { useState } from 'react'
import { Tabs, Tab } from 'react-bootstrap'

import './UserManagement.scss'
import UsersComponent from './Users/UsersComponent'
import React from 'react'
import RolesComponent from './Roles/RolesComponent'
import PermissionsComponent from './Permissions/PermissionsComponent'
import GroupsComponent from './Groups/GroupsComponent'
import { CustomerManagerContextProvider } from '@context/customerManager/customer-manager.context'

interface IMenuOption {
  key: string
  title: string
  component: any
}

const MENU_OPTIONS: IMenuOption[] = [
  {
    key: 'Users',
    title: 'Users',
    component: UsersComponent,
  },
  {
    key: 'Groups',
    title: 'Groups',
    component: GroupsComponent,
  },
  {
    key: 'Roles',
    title: 'Roles',
    component: RolesComponent,
  },
  {
    key: 'Permissions',
    title: 'Permissions',
    component: PermissionsComponent,
  },
]

const UserManagement = () => {
  const [selectedMenu, setSelectedMenu] = useState<string>(MENU_OPTIONS[0].key)

  return (
    <CustomerManagerContextProvider>
      <div className="device-management">
        <h1>
          <span className="fas fa-user mr-3"></span>User Management
        </h1>
        <div className="container-fluid flex-container ">
          <Tabs
            transition={false}
            id="controlled-tab-example"
            activeKey={selectedMenu}
            onSelect={(k: any) => setSelectedMenu(k)}
          >
            {MENU_OPTIONS.map((it) => {
              return (
                <Tab eventKey={it.key} title={it.title}>
                  <div className="tab-view">{React.createElement(it.component)}</div>
                </Tab>
              )
            })}
          </Tabs>
        </div>
      </div>
    </CustomerManagerContextProvider>
  )
}

export default UserManagement
